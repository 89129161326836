import React from 'react';
import Modal from 'utils/Modal/Modal';
import ModalPortal from 'utils/Modal/ModalPortal';
import { Button } from '@progress/kendo-react-buttons';
import { Paragraph } from 'components/Typography/Paragraph';
import ModalBase from './ModalBase';
import { ModalButtonsWrapper, ModalWrapper } from './ModalStyles';

type ConfirmChangeModeRemoteSessionProps = {
  handleClose: () => void;
  handleAccept: () => void;
};

const ConfirmChangeModeRemoteSessionModal = ({
  handleClose,
  handleAccept
}: ConfirmChangeModeRemoteSessionProps) => (
  <ModalPortal>
    <Modal>
      <ModalBase handleClick={handleClose} header='Switch mode'>
        <ModalWrapper>
          <Paragraph>
            After switching mode you will lose current mode changes, do you want to switch?
          </Paragraph>
          <ModalButtonsWrapper>
            <Button onClick={handleClose} type='button'>
              Cancel
            </Button>
            <Button type='button' onClick={handleAccept} themeColor='secondary'>
              Yes, switch
            </Button>
          </ModalButtonsWrapper>
        </ModalWrapper>
      </ModalBase>
    </Modal>
  </ModalPortal>
);

export default ConfirmChangeModeRemoteSessionModal;
