import Card from 'components/atoms/Card/Card';
import { HeaderM } from 'components/atoms/Typography/Typography';
import styled from 'styled-components';
import { ReactComponent as Times } from 'assets/times-solid.svg';
import { SvgIcon } from '@progress/kendo-react-common';

export const CalendarDay = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
  color: ${({ active }) => (active ? 'auto' : '#adb2b6')};
`;

export const CalendarHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
  align-items: center;

  h2 {
    ${HeaderM};
  }
`;

export const CalendarBadge = styled.span`
  background-color: ${({ theme }) => theme.palette.primary};
  color: white;
  border-radius: 50%;
  padding: 2px 8px;
  min-width: 10px;
  text-align: center;
  display: inline-block;
  font-weight: bold;
  font-size: 14px;
`

export const CalendarTable = styled.table`
  width: 100%;
  border-spacing: 0;
  text-align: center;
  table-layout: fixed;

  tr {
    th {
      font-size: 16px;
      font-weight: bold;
      background-color: ${({ theme }) => theme.palette.primary};
      color: white;
      height: 40px;
      padding-left: 8px;
      border-left: none;
      border-right: none;
      text-align: left;

      &:first-child {
        border-top-left-radius: 5px;
      }

      &:last-child {
        border-top-right-radius: 5px;
      }
    }
  }

  th,
  td {
    height: 120px;
    border: 1px solid ${({ theme }) => theme.colorFill};

    &:hover {
      border-color: ${({ theme }) => theme.palette.secondary};

      ${CalendarDay} {
        color: ${({ theme }) => theme.palette.secondary};
      }
    }
  }
`;

export const CalendarDayWrapper = styled.td`
  position: relative;
  padding: 8px;
`;

export const EventWrapper = styled.div`
  position: absolute;
  bottom: 8px;
  right: 8px;
  left: 8px;
  height: 60px;
  background-color: rgba(13, 110, 253, 0.15);
  border-radius: 8px;
  padding: 8px 8px 8px 12px;
  color: ${({ theme }) => theme.palette.primary};
  text-align: left;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
`;

export const DetailsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ClinicianImg = styled.img`
  width: 12px;
  height: 12px;
  object-fit: cover;
  border-radius: 50%;
`;

export const ModalWrapper = styled.div`
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);

  ${Card} {
    position: absolute;
    width: 275px;
  }
`;

export const ModalInnerWrapper = styled.div`
  text-align: left;
  display: grid;
  gap: 16px;
`;

export const ModalDetail = styled.div`
  h5 {
    font-size: 14px;
    color: ${({ theme }) => theme.palette.headerColor};
    margin-bottom: 8px;
  }

  p {
    font-size: 16px;
    font-weight: ${({ theme }) => theme.typography.fontWeights.bold};
  }
`;

export const ModalButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 8px;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
  padding-top: 15px;
  position: relative;
  
  > button {
    width: 100%;
  }
`;

export const StyledTimes = styled(SvgIcon)`
  width: 15px;
  cursor: pointer;
  color: ${({ theme }) => theme.typography.colors.subtitle};
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 10000;

  &&:hover {
    transform: scale(1.1, 1.1);
    transition: transform 0.2s;
  }
`;
