import { createAsyncThunk, createSlice, Reducer } from '@reduxjs/toolkit';
import { updateItem } from '../reducerUtils';
import {
  addLiveHistoryReducer,
  addSessionRestorePointReducer,
  consumeLiveHistorySessionReducer,
  consumeLiveHistorySessionSectionReducer,
  consumeRestorePointsReducer
} from './reducers';
import { getP2PSession } from '../../api/liveSession/liveSession';
import { P2PSession } from '../../api/liveSession/liveSession.types';
import { getInitialConfigAPI } from '../bluetoothReducer/bluetoothHelpers/asyncThunks';
import { defaultConfig } from '../../consts/deviceConfig/deviceConfig';

type LiveConfiguratorState = {
  enabled: boolean;
  device: number | null;
  configFetched: boolean;
  channel: {
    id: number | null;
    name: string;
    emgName: '';
    connected: boolean;
    clinicianId: string;
  };
  sessionApi: P2PSession | null;
  originalConfig: null | {};
  sessionHistory: {
    chooseGripsHistory: number[][];
    emgSettingsHistory: number[][];
    emgThresholdsHistory: number[][] | null;
    gripPairsConfigHistory: number[][];
    gripSequentialConfigHistory: number[][];
    controlConfigHistory: number[][];
    prosthesisSettingsHistory: number[][][];
    emgGainsHistory: number[][];
    gripsConfigurationHistory: number[][];
  };
  sessionRestorePoints: any[];
};

const initialState: LiveConfiguratorState = {
  enabled: false,
  device: null,
  configFetched: false,
  channel: {
    id: null,
    name: '',
    emgName: '',
    connected: false,
    clinicianId: ''
  },
  sessionApi: null,
  originalConfig: defaultConfig,
  sessionHistory: {
    chooseGripsHistory: [],
    emgSettingsHistory: [],
    emgThresholdsHistory: [],
    gripPairsConfigHistory: [],
    gripSequentialConfigHistory: [],
    controlConfigHistory: [],
    prosthesisSettingsHistory: [],
    emgGainsHistory: [],
    gripsConfigurationHistory: []
  },
  sessionRestorePoints: []
};

export const getLiveSessionApi = createAsyncThunk(
  'bluetooth/getLiveSessionApi',
  async (_, { getState }: any) => {
    try {
      const { amputeeId } = getState().deviceInfo;
      const { me } = getState().auth;
      return await getP2PSession(amputeeId, me.id);
    } catch (err: any) {
      console.log(err);
      return false;
    }
  }
);

export const liveConfiguratorSlice = createSlice({
  name: 'liveConfigurator',
  initialState,
  reducers: {
    setChannel: updateItem,
    setIsLiveEnabled: updateItem,
    setIsConfigFetched: updateItem,
    addSessionRestorePoint: addSessionRestorePointReducer,
    addLiveHistory: addLiveHistoryReducer,
    consumeLiveHistorySession: consumeLiveHistorySessionReducer,
    consumeLiveHistorySection: consumeLiveHistorySessionSectionReducer,
    consumeRestorePoints: consumeRestorePointsReducer
  },
  extraReducers: (builder) => {
    builder.addCase(getLiveSessionApi.fulfilled, (state, action) => {
      state.sessionApi = action.payload;
    });
    builder.addCase(getInitialConfigAPI.fulfilled, (state, action) => {
      if (
        action.payload &&
        action?.payload?.config?.config &&
        action.payload.constructor.name !== 'Array'
      ) {
        console.log(action.payload, 'PAYLOAD');
        // @ts-ignore
        state.originalConfig = { ...state.originalConfig, ...JSON.parse(action?.payload?.config) };
      }
    });
  }
});

export const selectLiveConfigHistory = (state) => ({
  chooseGripsHistory: state.liveConfigurator.sessionHistory.chooseGripsHistory,
  emgSettingsHistory: state.liveConfigurator.sessionHistory.emgSettingsHistory,
  emgThresholdsHistory: state.liveConfigurator.sessionHistory.emgThresholdsHistory,
  gripPairsConfigHistory: state.liveConfigurator.sessionHistory.gripPairsConfigHistory,
  gripSequentialConfigHistory: state.liveConfigurator.sessionHistory.gripSequentialConfigHistory,
  controlConfigHistory: state.liveConfigurator.sessionHistory.controlConfigHistory,
  prosthesisSettingsHistory: state.liveConfigurator.sessionHistory.prosthesisSettingsHistory,
  emgGainsHistory: state.liveConfigurator.sessionHistory.emgGainsHistory,
  gripsConfigurationHistory: state.liveConfigurator.sessionHistory.gripsConfigurationHistory
});

export const {
  setChannel,
  addSessionRestorePoint,
  setIsLiveEnabled,
  setIsConfigFetched,
  addLiveHistory,
  consumeLiveHistorySession,
  consumeLiveHistorySection,
  consumeRestorePoints
} = liveConfiguratorSlice.actions;

export default liveConfiguratorSlice.reducer as Reducer<LiveConfiguratorState>;
