import { useState } from 'react';

export interface PageState {
  skip: number;
  take: number;
}

export const useTablePages = (initialSkip: number = 0, perPage: number = 12) => {
  const [page, setPage] = useState<PageState>({ skip: initialSkip, take: perPage });

  const pageChange = async (event) => {
    console.log('pageChange', event);
    setPage({ skip: event.skip, take: event.take });
  };

  return {
    page,
    pageSize: perPage,
    perPage: page?.take,
    currentPage: (page?.skip + page?.take) / page?.take,
    pageChange,
    setPage
  };
};
