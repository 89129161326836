import React, { useEffect, useRef, useState } from 'react';
import { useGraph } from 'hooks/useGraph';
import { useSelector } from 'react-redux';
import { emgColors } from 'theme/emgColors/emgColors';
import useTelemetry from 'hooks/bluetooth/useTelemetry';
import TelemetryController from 'bluetooth-handler/telemetryController';
import Dropdown2 from 'components/atoms/Dropdown/Dropdown2';
import { ChartCombined, ChartProportional, ChartSeparate } from './Charts';

const StandaloneChart = ({ ...props }) => {
  const graphContainer = useRef(null);
  const graphContainerSeparate1 = useRef(null);
  const graphContainerSeparate2 = useRef(null);
  const graphContainerProportional = useRef(null);
  const {
    device,
    config: { emgThresholds }
  } = useSelector((state: any) => state.bluetooth);
  const { enabled: liveConfiguratorEnabled, channel } = useSelector(
    (state: any) => state.liveConfigurator
  );
  useTelemetry();
  const [graphMode, setGraphMode] = useState('Combined');

  const { updateData: updateDataCombined } = useGraph(
    graphContainer,
    {
      time: 500,
      interval: 400,
      thresholds: [
        {
          values: emgThresholds[0],
          label: 'CS Open',
          color: emgColors.csOpen
        },
        {
          values: emgThresholds[1],
          label: 'CS Close',
          color: emgColors.csClose
        },
        {
          values: emgThresholds[2],
          label: 'SCS Open',
          color: emgColors.activationOpen
        },
        {
          values: emgThresholds[5],
          label: 'SCS Close',
          color: emgColors.activationClose
        }
      ],
      showThreshold: false
    },
    500,
    [graphMode],
    [graphMode]
  );
  const { updateData: updateDataSeparate1 } = useGraph(
    graphContainerSeparate1,
    {
      time: 500,
      interval: 400,
      thresholds: [
        {
          values: emgThresholds[0],
          label: 'CS Open',
          color: emgColors.csOpen
        },
        {
          values: emgThresholds[2],
          label: 'SCS Open',
          color: emgColors.activationOpen
        }
      ],
      showThreshold: false
    },
    500,
    [graphMode],
    [graphMode]
  );
  const { updateData: updateDataSeparate2 } = useGraph(
    graphContainerSeparate2,
    {
      time: 500,
      interval: 400,
      thresholds: [
        {
          values: emgThresholds[1],
          label: 'CS Close',
          color: emgColors.csClose
        },
        {
          values: emgThresholds[5],
          label: 'SCS Close',
          color: emgColors.activationClose
        }
      ],
      showThreshold: false
    },
    500,
    [graphMode],
    [graphMode]
  );
  const { updateData: updateDataProportional } = useGraph(
    graphContainerProportional,
    {
      time: 500,
      interval: 400,
      thresholds: [
        {
          values: emgThresholds[9],
          label: 'Power Closing',
          color: emgColors.powerClose
        },
        {
          values: emgThresholds[4],
          label: 'Speed 3 Opening',
          color: emgColors.speed3Open
        },
        {
          values: emgThresholds[7],
          label: 'Speed 3 Closing',
          color: emgColors.speed3Close
        },
        {
          values: emgThresholds[3],
          label: 'Speed 2 Opening',
          color: emgColors.speed2Open
        },
        {
          values: emgThresholds[6],
          label: 'Speed 2 Closing',
          color: emgColors.speed2Close
        },
        {
          values: emgThresholds[2],
          label: 'Speed 1 Opening',
          color: emgColors.activationOpen
        },
        {
          values: emgThresholds[5],
          label: 'Speed 1 Closing',
          color: emgColors.activationClose
        },
        {
          values: emgThresholds[1],
          label: 'SCS Closing',
          color: emgColors.csClose
        },
        {
          values: emgThresholds[0],
          label: 'SCS Opening',
          color: emgColors.csOpen
        }
      ]
    },
    500,
    [graphMode],
    [graphMode]
  );

  const updateTelemetryData = () => {
    const emgExtension: any = TelemetryController.telemetryData?.[0];
    const emgFlexion: any = TelemetryController.telemetryData?.[1];

    if (emgExtension && emgFlexion) {
      const emgExtensionData = emgExtension.map((point, i) => [i, point]);
      const emgFlexionData = emgFlexion.map((point, i) => [i, point]);

      const emgExtensionOptions = {
        values: emgExtensionData,
        label: 'EMG extension',
        color: emgColors.emgExtension
      };
      const emgFlexionOptions = {
        values: emgFlexionData,
        label: 'EMG flexion',
        color: emgColors.emgFlexion
      };
      if (graphContainer.current) {
        updateDataCombined([emgExtensionOptions, emgFlexionOptions]);
      }
      if (graphContainerSeparate2.current) {
        updateDataSeparate1([emgExtensionOptions]);
        updateDataSeparate2([emgFlexionOptions]);
      }
      if (graphContainerProportional.current) {
        updateDataProportional([emgExtensionOptions, emgFlexionOptions]);
      }
    }
  };

  useEffect(() => {
    let graphInterval;
    if (device.connected) {
      graphInterval = setInterval(updateTelemetryData, 50);
    }

    if (liveConfiguratorEnabled) {
      console.log('liveConfiguratorEnable', liveConfiguratorEnabled);
      console.log('channel', channel);
      graphInterval = setInterval(updateTelemetryData, 50);
    }

    return function clear() {
      if (device.connected) {
        clearInterval(graphInterval);
      }
    };
  }, [device.connected, liveConfiguratorEnabled]);

  return (
    <div {...props} style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      {graphMode === 'Combined' && (
        <ChartCombined graphContainer={graphContainer} style={{ flex: '1' }} />
      )}
      {graphMode === 'Separate' && (
        <ChartSeparate
          graphContainer1={graphContainerSeparate1}
          graphContainer2={graphContainerSeparate2}
          style={{ flex: '1' }}
        />
      )}
      {graphMode === 'Proportional' && (
        <ChartProportional graphContainer={graphContainerProportional} style={{ flex: '1' }} />
      )}
      <Dropdown2
        options={[
          { id: 'Combined', name: 'Combined' },
          { id: 'Separate', name: 'Separate' },
          { id: 'Proportional', name: 'Proportional' }
        ]}
        onChange={(id) => setGraphMode(id)}
        selected={graphMode}
        style={{ margin: '8px 0' }}
        flipY
      />
    </div>
  );
};

export default StandaloneChart;
