import styled from 'styled-components';

export const Paragraph = styled.p`
  font-size: 16px;
`;

export const ParagraphBold = styled.p`
  font-size: 16px;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  color: ${({ theme }) => theme.palette.headerColor};
`;
