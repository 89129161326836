import styled from 'styled-components';

export const Table = styled.table`
  width: 100%;
  border-spacing: 0;
  text-align: center;
  border-collapse: collapse;
  table-layout: fixed;
  border: none;
  
  th,
  td {
    padding: 12px 0;
    text-align: left;
    border-bottom: 1px solid ${({ theme }) => theme.palette.configFill};
  }

  caption {
    background-color: rgba(61, 72, 68, 0.05);
    box-shadow: 0 4px 20px 0 rgba(61, 72, 68, 0.05);
    border-radius: 8px;
    padding: 10px 0;
    font-weight: bold;
    margin-bottom: 5px;
    color: ${({ theme }) => theme.palette.primary};
  }
`;
