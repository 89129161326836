/* eslint-disable no-restricted-syntax */
import { gripsGroupsOptionsMap } from 'utils/definesLocal';

export const transformConfig = (config) => {
  const gripsTransformed: any[] = [];
  let gripSequentialConfig: any[] = [];
  let gripPairsConfig: any[] = [];
  if (config.gripsPositions) {
    for (const grip of Object.keys(config.gripsPositions)) {
      gripsTransformed.push(gripsGroupsOptionsMap.get(parseInt(grip, 10)));
    }
  }
  if (config.gripPairsConfig) {
    gripPairsConfig = config.gripPairsConfig.map((grip) => gripsGroupsOptionsMap.get(grip));
  }
  if (config.gripSequentialConfig) {
    gripSequentialConfig = config.gripSequentialConfig.map((grip) =>
      gripsGroupsOptionsMap.get(grip)
    );
  }

  return { gripsTransformed, gripPairsConfig, gripSequentialConfig };
};

export const transformConfigAPI = (changeEntry: any, modesList) => {
  const parsedConfig = JSON.parse(changeEntry.config);
  const modeAffected = changeEntry.entries.find(
    (entry) => entry.config_entry.mode_id || entry.config_entry.mode_id === 0
  );
  const modeAffectedId = modeAffected ? modeAffected.config_entry.mode_id : modesList[0].id;
  const completeConfig = {
    ...parsedConfig.common,
    ...parsedConfig[`mode_${modeAffectedId}`]
  };

  return { modeAffectedId, completeConfig, modeAffected, parsedConfig };
};
