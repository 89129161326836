import React from 'react';
import styled from 'styled-components';
import Modal from 'utils/Modal/Modal';
import ModalPortal from 'utils/Modal/ModalPortal';
import { TextS } from 'components/atoms/Typography/Typography';
import { useDispatch } from 'react-redux';
import { getInitialConfigAPI } from 'reducers/bluetoothReducer/bluetoothHelpers/asyncThunks';
import { Button } from '@progress/kendo-react-buttons';
import { setItemUI } from 'reducers/uiReducer';
import ModalBase from './ModalBase';
import { ModalButtonsWrapper, ModalWrapper } from './ModalStyles';

const ParagraphBold = styled.p`
  ${TextS};
  margin-top: 30px;
  margin-bottom: 40px;
`;

type RestoreRecentConfigModalProps = {
  handleClose: () => void;
};

const RestoreRecentConfigModal = ({ handleClose }: RestoreRecentConfigModalProps) => {
  const dispatch = useDispatch();

  const restoreRecentConfig = async () => {
    await dispatch(getInitialConfigAPI());
    await dispatch(setItemUI({ type: 'shownGlobalModal', payload: null }));
  };

  return (
    <ModalPortal>
      <Modal>
        <ModalBase handleClick={handleClose} header='Restore configuration'>
          <ModalWrapper>
            <ParagraphBold>
              Do you want to restore recent config? Unsaved changes will be lost.
            </ParagraphBold>
            <ModalButtonsWrapper>
              <Button onClick={handleClose} type='button'>
                Cancel
              </Button>
              <Button type='button' onClick={restoreRecentConfig} themeColor='secondary'>
                Restore recent
              </Button>
            </ModalButtonsWrapper>
          </ModalWrapper>
        </ModalBase>
      </Modal>
    </ModalPortal>
  );
};

export default RestoreRecentConfigModal;
