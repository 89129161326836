import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

export const ModalBackground = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(21, 30, 54, 0.6);
  z-index: 20;
`;

const Modal = ({ children }) => <ModalBackground>{children}</ModalBackground>;

Modal.propTypes = {
  children: PropTypes.node.isRequired
};

export default Modal;
