import { useCallback, useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { refreshTokenApi } from 'api/authentication/authentication';
import { setApiAuthToken } from 'api/utils/apiClient';
import { loginSuccess } from 'reducers/authReducer';
import useDebounce from 'hooks/useDebounce';
import useLogout from 'hooks/api/useLogout';

const useRefreshToken = () => {
  const dispatch = useDispatch();
  const [delayedFlag, setDelayedFlag] = useState(false);
  const { offlineLogout } = useLogout();
  const { isLoading, mutateAsync: refreshToken } = useMutation(refreshTokenApi, {
    onError() {
      offlineLogout();
    },
    onSuccess(data) {
      if (data) {
        setApiAuthToken(data.token);
        dispatch(loginSuccess(data));
      }
    },
    onSettled() {}
  });

  const debounced = useDebounce(refreshToken, 500);

  const delayedRefresh = useCallback(() => {
    setDelayedFlag(true);
  }, []);

  useEffect(() => {
    if (delayedFlag) {
      setDelayedFlag(false);
      debounced();
    }
  }, [delayedFlag, refreshToken]);

  return {
    delayedRefresh,
    isLoading,
    refreshToken
  };
};

export default useRefreshToken;
