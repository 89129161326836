import { HeaderL, HeaderXL } from 'components/atoms/Typography/Typography';
import styled, { css } from 'styled-components';

export const Header1 = styled.h2`
  ${HeaderXL};
  color: ${({ theme }) => theme.colorPrimary};
`;

export const Header2Wrapper = styled.div`
  margin-bottom: 20px;

  @media (min-width: ${({ theme }) => theme.breakpoints.large}) {
    margin-bottom: 40px;
  }
`;

export const Header2 = styled.h3`
  ${HeaderL};
  color: ${({ theme }) => theme.colorSecondary};
`;

export const IconStyle = css`
  width: 16px;
  height: 16px;
  fill: white;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  margin-bottom: 12px;

  @media (min-width: ${({ theme }) => theme.breakpoints.large}) {
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    margin-bottom: 20px;
  }
`;

export const ToggleViewWrapper = styled.div`
  display: flex;
  align-items: center;

  span {
    margin-right: 16px;
  }
`;

export const ToggleViewButtonsWrapper = styled.div`
  display: flex;
  gap: 8px;
`;

export const ToggleViewButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  width: 44px;
  height: 44px;
  border: 1px solid ${({ theme }) => theme.colorFill};
  padding: 8px;
  cursor: pointer;
  border-radius: 5px;

  ${({ active }) =>
    active &&
    css`
      border-color: ${({ theme }) => theme.palette.secondary};
      background-color: ${({ theme }) => theme.palette.secondary};
    `};
`;
