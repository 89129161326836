import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { fetchUserData } from 'api/authentication/authentication';
import { MfaRequire } from 'api/authentication/authentication.types';
import { mfaCodeRequire } from 'reducers/authReducer';
import { getUser, getUsers } from '../../api/users/users';
import { UserQueryParams, UsersQueryParams } from '../../api/users/users.types';

export const ME_QUERY_KEY = 'me';
export const USER_QUERY_KEY = 'user';
export const USERS_QUERY_KEY = 'users';

export const useUserMe = (enabled = true) => {
  const dispatch = useDispatch();
  const { data, isLoading, isError, refetch, isRefetching, isRefetchError, isFetched, isSuccess } =
    useQuery([ME_QUERY_KEY], fetchUserData, {
      enabled,
      retry: 0,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      onError(error: any) {
        if (error.isAxiosError && error.response.status === 401) {
          const data: MfaRequire = {
            require: true,
            channel: error.response.data.method
          };
          dispatch(mfaCodeRequire(data));
        }
      }
    });

  return {
    result: data || null,
    isLoading,
    isError,
    refetch,
    isRefetching,
    isRefetchError,
    isFetched,
    isSuccess
  };
};

export const useUser = (userId: number | string, params: UserQueryParams = {}) => {
  const { data, isLoading, isError } = useQuery([USER_QUERY_KEY, userId, params], () =>
    getUser(userId, { params })
  );

  return {
    result: data || null,
    isLoading,
    isError
  };
};

export const useUsers = (params: UsersQueryParams = {}) => {
  const { data, isLoading, isError, refetch } = useQuery([USERS_QUERY_KEY, params], () =>
    getUsers({ params })
  );

  return {
    result: data?.items || null,
    total: data?.paginator ? data.paginator.total : null,
    lastPage: data?.paginator ? data.paginator.last_page : null,
    isLoading,
    isError,
    refetch
  };
};
