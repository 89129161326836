/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
export enum ProcedureTypes {
  none = 0,
  calibrateSoftGrip = 1,
  calibrateEncoders = 2,
  testClosing = 3,
  testSetPosition = 4,
  calibrateStallCurrent = 5,
  gripsTesting = 6,
  calibrateThumbPotentiometer = 7,
  procedureCurrentTest = 8,
  testEncodersBinaryState = 9,
  calibrateAutoGrasp = 10,
}
