import React from 'react';
import { Toaster } from 'react-hot-toast';
import { useSelector } from 'react-redux';
import Toolbar from 'components/Toolbar/Toolbar';
import styled from 'styled-components';
import Navbar from '../components/Navbar/Navbar';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-x: hidden;
`;

const MainWrapper = styled.main`
  background-color: ${({ theme }) => theme.palette.background};
  padding: 50px 60px 64px 60px;
  flex: 1;
`;

const ChildWrapper = styled.div`
  margin: 0 auto;
  max-width: 1840px;
  height: 100%;
`;

const DefaultLayout = ({ children, ...rest }) => {
  const { device } = useSelector((state: any) => state.bluetooth);

  return (
    <Wrapper>
      <div style={{ position: 'relative' }}>
        <Navbar />
        <Toolbar unsaved={false} device={device.connected} />
        <MainWrapper>
          <ChildWrapper {...rest}>{children}</ChildWrapper>
        </MainWrapper>
      </div>
      <Toaster
        toastOptions={{
          className: '',
          style: {
            marginTop: '30px'
          }
        }}
      />
    </Wrapper>
  );
};

export default DefaultLayout;
