import React from 'react';
import DefaultLayout from 'layouts/DefaultLayout';

import ConfigTemplateDetailsComponent from './ConfigTemplateDetailsComponent';

const ConfigTemplateDetails = () => (
  <DefaultLayout>
    <ConfigTemplateDetailsComponent />
  </DefaultLayout>
);

export default ConfigTemplateDetails;
