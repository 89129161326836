import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  *, *::before, *::after {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  html {
    font-size: 62.5%;
  }

  body, ul, li, nav {
    padding: 0;
    margin: 0;
  }

  p {
    margin: 0;
    padding: 0;
  }

  h1, h2, h3, h4, h5 {
    padding: 0;
    margin: 0;
    font-size: inherit;
    font-weight: inherit;
  }

  html {
    font-size: 16px;
    height: 100%;
  }

  body {
    font-family: 'Nunito', sans-serif;
    height: 100%;
  }

  #root {
    height: 100%;
    color: #3b3b3d;
    background-color: #f4f6fa;
  }
`;

export default GlobalStyle;
