/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-shadow */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */

import React, { useEffect, useState } from 'react';
import DefaultLayout from 'layouts/DefaultLayout';
import Card from 'components/atoms/Card/Card';
import InnerCard from 'components/atoms/Card/InnerCard';
import { useDispatch, useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import dayjs from 'dayjs';
import { map, values } from 'lodash';
import { transformConfig } from 'utils/Config/transformConfig';
import { Header1, HeaderWrapper } from '../styled';
import { Table } from '../../../components/atoms/Table/Table';
import {
  compareConfigBaseOnHistory,
  compareHistory,
  HistoryItem
} from '../../../reducers/bluetoothReducer/bluetoothHelpers/bluetoothHelpers';
import { CheckComponent } from '../components';
import Button from '../../../components/atoms/Button/Button';
import { StyledUndo } from '../../../components/atoms/Icons/Icons';
import Dropdown from '../../../components/atoms/Dropdown/Dropdown';
import Divider from '../../../components/atoms/Divider/Divider';
import Accordion from '../../../components/atoms/Accordion/Accordion';
import ConfigItem from '../../../components/atoms/ConfigItem/ConfigItem';
import {
  configSettingsNamesMap,
  controlModeMap,
  genericSwitchingMap,
  gripsGroupsOptionsMap,
  gripsGroupsOptionsReversedMap,
  gripSwitchingMap,
  inputSiteMap,
  speedControlMap
} from '../../../utils/definesLocal';
import { TypesConfig } from '../../../types';
import {
  consumeConfigHistory,
  consumeHistory,
  consumeHistoryChooseGrips,
  consumeHistoryEmgSettings,
  consumeHistoryGripsConfiguration,
  consumeHistoryProsthesisSettings,
  importConfig
} from '../../../reducers/bluetoothReducer/bluetoothReducer';
import { setItemUI } from '../../../reducers/uiReducer';
import {
  consumeLiveHistorySection,
  selectLiveConfigHistory
} from '../../../reducers/liveConfiguratorReducer/liveConfiguratorReducer';
import { RootState } from '../../../store/store';
import useRemoteSession from '../../../hooks/useRemoteSession';
import { HistoryConfiguratorContainer } from './styled';
import {
  ConfigToNameFunctionMapping,
  ConfigToValueFunctionMapping
} from '../DeviceConfig/DeviceConfigComponent';

const ChangeItemWrapper = styled.tr`
  td {
    word-wrap: break-word;
  }
`;

const StyledDropdown = styled(Dropdown)`
  width: 400px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  gap: 20px;
`;

const ValueWrapper = styled.span`
  background-color: ${({ theme }) => theme.colorFill};
  border-radius: 5px;
  padding: 5px;
  margin-bottom: 5px;
  display: inline-block;
`;

const HeaderInnerWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const ConfigWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 30px;
  padding: 10px;
`;

const DividerWrapper = styled.div`
  display: grid;
  gap: 16px;
`;

const ConfigList = styled.ul`
  display: grid;
  gap: 16px;
  list-style: none;
  width: 100%;
`;

const GripPositionWrapper = styled.div`
  flex: 1;
`;

const ConfigListWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex: 1;
  gap: 20px;
`;

const TableStyle = css`
  display: grid;
  grid-template-columns: 40% 1fr 1fr 1fr;
  justify-items: start;
  align-items: center;
`;

const TableBody = styled.div`
  ${TableStyle};
`;

const TableBodyConfig = styled.div`
  ${TableStyle};
  &:first-child {
    padding: 0 0 20px 0;
  }
  padding: 20px 0;
  border-bottom: 2px solid ${({ theme }) => theme.colorFill};
`;

const TableHeader = styled.div`
  font-weight: 700;
  font-size: 18px;
`;

interface ConfigComponentsProps {
  config?: any;
  transformedConfig?: any;
}

const DeviceRestoreEntry = ({ config, transformedConfig }: ConfigComponentsProps) => {
  const [selectedGrip, setSelectedGrip] = useState('Rest opp');
  const gripNumber: any = gripsGroupsOptionsReversedMap.get(selectedGrip);
  return (
    <Card>
      <ConfigWrapper>
        <GripPositionWrapper>
          <Accordion header='Grip positions'>
            <DividerWrapper>
              <div style={{ width: 300 }}>
                <Dropdown
                  options={transformedConfig?.grips}
                  label='Grip:'
                  selected={selectedGrip}
                  onChange={(e) => setSelectedGrip(e)}
                />
              </div>
              <InnerCard>
                <Table>
                  <tr>
                    <th style={{ width: '60%' }}>Finger</th>
                    <th>Initial</th>
                    <th>Limit</th>
                  </tr>
                  <tr>
                    <td>Thumb</td>
                    <td>{config?.gripsPositions?.[gripNumber]?.initial?.[0]}</td>
                    <td>{config?.gripsPositions?.[gripNumber]?.limit?.[0]}</td>
                  </tr>
                  <tr>
                    <td>Index</td>
                    <td>{config?.gripsPositions?.[gripNumber]?.initial?.[1]}</td>
                    <td>{config?.gripsPositions?.[gripNumber]?.limit?.[1]}</td>
                  </tr>
                  <tr>
                    <td>Middle</td>
                    <td>{config?.gripsPositions?.[gripNumber]?.initial?.[2]}</td>
                    <td>{config?.gripsPositions?.[gripNumber]?.limit?.[2]}</td>
                  </tr>
                  <tr>
                    <td>Ring</td>
                    <td>{config?.gripsPositions?.[gripNumber]?.initial?.[3]}</td>
                    <td>{config?.gripsPositions?.[gripNumber]?.limit?.[3]}</td>
                  </tr>
                  <tr>
                    <td>Pinky</td>
                    <td>{config?.gripsPositions?.[gripNumber]?.initial?.[4]}</td>
                    <td>{config?.gripsPositions?.[gripNumber]?.limit?.[4]}</td>
                  </tr>
                </Table>
              </InnerCard>
            </DividerWrapper>
          </Accordion>
          <Divider margin='20px' />
          <Accordion header='Pairs config'>
            <DividerWrapper>
              <InnerCard>
                <Table>
                  <caption>Opposed</caption>
                  <tr>
                    <th colSpan={2} style={{ width: '50%' }}>
                      Primary
                    </th>
                    <th colSpan={2} style={{ width: '50%' }}>
                      Secondary
                    </th>
                  </tr>
                  <tr>
                    <td>{transformedConfig?.gripPairsConfig[0]}</td>
                    <td>{transformedConfig?.gripPairsConfig[1]}</td>
                    <td>{transformedConfig?.gripPairsConfig[2]}</td>
                    <td>{transformedConfig?.gripPairsConfig[3]}</td>
                  </tr>
                </Table>
                <Divider margin='20px' />
                <Table>
                  <caption>Non-opposed</caption>
                  <tr>
                    <th colSpan={2}>Primary</th>
                    <th colSpan={2}>Secondary</th>
                  </tr>
                  <tr>
                    <td colSpan={1}>{transformedConfig?.gripPairsConfig[4]}</td>
                    <td colSpan={1}>{transformedConfig?.gripPairsConfig[5]}</td>
                    <td colSpan={1}>{transformedConfig?.gripPairsConfig[6]}</td>
                    <td colSpan={1}>{transformedConfig?.gripPairsConfig[7]}</td>
                  </tr>
                </Table>
              </InnerCard>
            </DividerWrapper>
          </Accordion>
          <Divider margin='20px' />
          <Accordion header='Grips sequential config'>
            <DividerWrapper>
              <InnerCard>
                <Table>
                  <caption>Opposed</caption>
                  <tr>
                    <td>{transformedConfig?.gripSequentialConfig[0]}</td>
                    <td>{transformedConfig?.gripSequentialConfig[1]}</td>
                    <td>{transformedConfig?.gripSequentialConfig[2]}</td>
                    <td>{transformedConfig?.gripSequentialConfig[3]}</td>
                    <td>{transformedConfig?.gripSequentialConfig[4]}</td>
                  </tr>
                </Table>
                <Divider margin='20px' />
                <Table>
                  <caption>Non-opposed</caption>
                  <tr>
                    <td>{transformedConfig?.gripSequentialConfig[6]}</td>
                    <td>{transformedConfig?.gripSequentialConfig[7]}</td>
                    <td>{transformedConfig?.gripSequentialConfig[8]}</td>
                    <td>{transformedConfig?.gripSequentialConfig[9]}</td>
                    <td>{transformedConfig?.gripSequentialConfig[10]}</td>
                  </tr>
                </Table>
              </InnerCard>
            </DividerWrapper>
          </Accordion>
        </GripPositionWrapper>
        <ConfigListWrapper>
          <ConfigList>
            <li>
              <ConfigItem
                header='Emg spike'
                items={[
                  {
                    header: 'Status',
                    content: `${genericSwitchingMap.get(config?.emgSpike?.[0])}`,
                    type: TypesConfig.bool,
                    value: config?.emgSpike?.[0]
                  },
                  { header: 'Value', content: `${config?.emgSpike?.[1]} ms` }
                ]}
              />
            </li>
            <li>
              <ConfigItem
                header='EMG gains'
                items={[
                  { header: 'Extension', content: `${config?.emgGains?.[0]}%` },
                  { header: 'Flexion', content: `${config?.emgGains?.[1]}%` }
                ]}
              />
            </li>
            <li>
              <ConfigItem
                header='EMG thresholds'
                items={[
                  {
                    header: 'CS extension thresholds',
                    content: `${config?.emgThresholds?.[0]}`
                  },
                  {
                    header: 'CS flexion thresholds',
                    content: `${config?.emgThresholds?.[1]}`
                  },
                  {
                    header: 'Threshold control open value',
                    content: `${config?.emgThresholds?.[2]}`
                  },
                  {
                    header: 'Proportional control open second value',
                    content: `${config?.emgThresholds?.[3]}`
                  },
                  {
                    header: 'Proportional control open third value',
                    content: `${config?.emgThresholds?.[4]}`
                  },
                  {
                    header: 'Threshold control close value',
                    content: `${config?.emgThresholds?.[5]}`
                  },
                  {
                    header: 'Proportional control close second value',
                    content: `${config?.emgThresholds?.[6]}`
                  },
                  {
                    header: 'Proportional control close third value',
                    content: `${config?.emgThresholds?.[7]}`
                  },
                  {
                    header: 'Soft-grip open threshold second value',
                    content: `${config?.emgThresholds?.[8]}`
                  },
                  {
                    header: 'Soft-grip close threshold second value',
                    content: `${config?.emgThresholds?.[9]}`
                  }
                ]}
              />
            </li>
          </ConfigList>
          <ConfigList>
            <li>
              <ConfigItem
                header='Input sites'
                items={[
                  {
                    header: 'Value',
                    content: `${inputSiteMap.get(config?.inputSite[0])}`
                  }
                ]}
              />
            </li>
            <li>
              <ConfigItem
                header='Speed control strategy'
                items={[
                  {
                    header: 'Value',
                    content: `${speedControlMap.get(config?.speedControlStrategy[0])}`
                  }
                ]}
              />
            </li>
            <li>
              <ConfigItem
                header='Grip switching modes'
                items={[
                  {
                    header: 'Value',
                    content: `${gripSwitchingMap.get(config?.gripSwitchingMode[0])}`
                  }
                ]}
              />
            </li>
            <li>
              <ConfigItem
                header='Control mode'
                items={[
                  {
                    header: 'Value',
                    content: `${controlModeMap.get(config?.controlMode[0])}`
                  }
                ]}
              />
            </li>
            <li>
              <ConfigItem
                header='Soft-grip'
                items={[
                  {
                    header: 'Status',
                    content: genericSwitchingMap.get(config?.softGrip?.[0]),
                    type: TypesConfig.bool,
                    value: config?.softGrip?.[0]
                  },
                  { header: 'Value', content: `${config?.fingerStrength?.[1]}` }
                ]}
              />
            </li>
            <li>
              <ConfigItem
                header='Auto grasp'
                items={[
                  {
                    header: 'Status',
                    content: `${genericSwitchingMap.get(config?.autoGrasp?.[0])}`,
                    type: TypesConfig.bool,
                    value: config?.autoGrasp?.[0]
                  },
                  { header: 'Value', content: `${config?.autoGrasp?.[1]}` }
                ]}
              />
            </li>
            <li>
              <ConfigItem
                header='Hold open timings'
                items={[
                  { header: 'Primary hold open time', content: `${config?.holdOpen?.[0]} ms` },
                  { header: 'Secondary hold open time', content: `${config?.holdOpen?.[1]} ms` }
                ]}
              />
            </li>
            <li>
              <ConfigItem
                header='Pulse timings'
                items={[
                  { header: 'Min. pulse time', content: `${config?.pulseTimings?.[0]} ms` },
                  { header: 'Max. pulse time', content: `${config?.pulseTimings?.[1]} ms` },
                  {
                    header: 'Min. time between pulses',
                    content: `${config?.pulseTimings?.[2]} ms`
                  },
                  { header: 'Max. time between pulses', content: `${config?.pulseTimings?.[3]} ms` }
                ]}
              />
            </li>
            <li>
              <ConfigItem
                header='Co-contraction timings'
                items={[
                  {
                    header: 'Long co-contraction time',
                    content: `${config?.coContractionTimings?.[0]} ms`
                  },
                  {
                    header: 'Signal rise offset time',
                    content: `${config?.coContractionTimings?.[1]} ms`
                  }
                ]}
              />
            </li>
          </ConfigList>
        </ConfigListWrapper>
      </ConfigWrapper>
    </Card>
  );
};

const ConfiguratorLimitWrapper = (initial: number[], limit: number[]) => (
  <HistoryConfiguratorContainer>
    <div>
      <span>Initial</span>
      <span>Limit</span>
    </div>
    {/* eslint-disable-next-line react/destructuring-assignment */}
    {initial.map((item, index) => (
      <div>
        <ValueWrapper>{item}</ValueWrapper>
        {/* eslint-disable-next-line react/destructuring-assignment */}
        <ValueWrapper>{limit[index]}</ValueWrapper>
      </div>
    ))}
  </HistoryConfiguratorContainer>
);

const SessionHistoryItem = ({ name, configName, after, before, now, timestamp, id }) => {
  const dispatch = useDispatch();
  const { sendConfig } = useRemoteSession();
  const handleUndo = async () => {
    await dispatch(consumeConfigHistory({ id }));
    /* dispatch(
      consumeLiveHistorySection({
        consumer: name
      })
    ); */
    sendConfig();
  };
  const HistoricItemWrapper = (type: string, value: any, index, currentConfig = false) => {
    if (type === 'gripsPositions') {
      const grip = Object.keys(value)[0];
      return ConfiguratorLimitWrapper(value[grip].initial, value[grip].limit);
    }

    /* if (type.includes('gripsPositions')) {
      const names = name.split('.');

      if (currentConfig) value = now.gripsPositions[`${names[1]}`][`${names[2]}`][index];

      return value || value === 0 ? (
        <ValueWrapper>{ConfigToValueFunctionMapping[`${names[0]}`](value, index)}</ValueWrapper>
      ) : (
        <ValueWrapper />
      );
    } */

    if (type.includes('fingerStrength') && currentConfig) {
      value = now.fingerStrength?.[1];
    }

    return value || value === 0 ? (
      <ValueWrapper>{ConfigToValueFunctionMapping[`${name}`](value, index)}</ValueWrapper>
    ) : (
      <ValueWrapper />
    );
  };

  const HeaderItemWrapper = (type: string, value: any) => {
    if (type.includes('gripsPositions')) {
      const grip = Object.keys(before)[0];
      return <div>{gripsGroupsOptionsMap.get(Number(grip))}</div>;
    }

    return value || value === 0 ? (
      <div>{ConfigToNameFunctionMapping[`${name}`](value)}</div>
    ) : (
      <div />
    );
  };

  // eslint-disable-next-line arrow-body-style
  const NameWrapper = (configName: string) => {
    if (name.includes('gripsPositions')) {
      const grip = Object.keys(before)[0];
      return `${configSettingsNamesMap.get(configName)} (${gripsGroupsOptionsMap.get(
        Number(grip)
      )})`;
    }
    return configSettingsNamesMap.get(configName);
  };

  if (name === 'fingerStrength') {
    after = [after?.[1]];
  }

  if (name === 'gripsPositions') {
    return (
      <Accordion
        header={NameWrapper(configName)}
        style={{ marginTop: '24px' }}
        actions={
          <>
            <Button small onClick={handleUndo} Pressed>
              Restore
            </Button>
            <div>{dayjs(timestamp).format('MM.DD.YYYY HH:mm')}</div>
          </>
        }>
        <TableBodyConfig>
          {HeaderItemWrapper(name, 0)}
          {HistoricItemWrapper(name, before, 0)}
          {HistoricItemWrapper(name, after, 0)}
          {HistoricItemWrapper(name, now[`${name}`], 0, true)}
        </TableBodyConfig>
      </Accordion>
    );
  }

  if (name === 'gripPairsConfig' || name === 'gripSequentialConfig') {
    return (
      <Accordion
        header={NameWrapper(configName)}
        style={{ marginTop: '24px' }}
        actions={
          <>
            <Button small onClick={handleUndo} Pressed>
              Restore
            </Button>
            <div>{dayjs(timestamp).format('MM.DD.YYYY HH:mm')}</div>
          </>
        }>
        <TableBodyConfig>
          {HeaderItemWrapper(name, 0)}
          {HistoricItemWrapper(name, before, 0)}
          {HistoricItemWrapper(name, after, 0)}
          {HistoricItemWrapper(name, now[`${name}`], 0, true)}
        </TableBodyConfig>
        <TableBodyConfig>
          {HeaderItemWrapper(name, 1)}
          {HistoricItemWrapper(name, before, 1)}
          {HistoricItemWrapper(name, after, 1)}
          {HistoricItemWrapper(name, now[`${name}`], 1, true)}
        </TableBodyConfig>
      </Accordion>
    );
  }

  return (
    <Accordion
      header={NameWrapper(configName)}
      style={{ marginTop: '24px' }}
      actions={
        <>
          <Button small onClick={handleUndo} Pressed>
            Restore
          </Button>
          <div>{dayjs(timestamp).format('MM.DD.YYYY HH:mm')}</div>
        </>
      }>
      {after.map((value, index) => (
        <TableBodyConfig>
          {HeaderItemWrapper(name, index)}
          {HistoricItemWrapper(name, before ? before[index] : null, index)}
          {HistoricItemWrapper(name, value, index)}
          {HistoricItemWrapper(name, now[`${name}`] ? now[`${name}`][index] : null, index, true)}
        </TableBodyConfig>
      ))}
    </Accordion>
  );
};
/* const SessionHistoryItem = ({ name, configName, timestamp, after, before, restorePoint, raw }) => {
  const dispatch = useDispatch();
  const { sendConfig } = useRemoteSession();
  const handleUndo = () => {
    dispatch(
      consumeHistory({
        provider: raw.name,
        consumer: configName
      })
    );
    dispatch(
      consumeLiveHistorySection({
        consumer: raw.name
      })
    );
    sendConfig();
  };

  const HistoricItemWrapper = (type: any, value: any, side: string = 'before') => {
    if (type === 'gripsConfigurationHistory') {
      return map(raw[side], (item) => ConfiguratorLimitWrapper(item.initial, item.limit));
    }

    if (Array.isArray(value)) {
      // eslint-disable-next-line react/destructuring-assignment
      return value.map((item) => (
        <ValueWrapper key={item}>
          {item}
          <br />
        </ValueWrapper>
      ));
    }

    return <ValueWrapper>{value}</ValueWrapper>;
  };

  return (
    <ChangeItemWrapper>
      <td>{name}</td>
      <td>{configName}</td>
      <td>{HistoricItemWrapper(raw.name, before, 'before')}</td>
      <td>{HistoricItemWrapper(raw.name, after, 'after')}</td>
      <td>{dayjs(timestamp).format('MM.DD.YYYY HH:mm')}</td>
      <td>{!restorePoint ? '-' : <CheckComponent />}</td>
      <td>
        <Button small onClick={handleUndo}>
          <StyledUndo style={{ marginRight: '9px' }} />
          Undo
        </Button>
      </td>
    </ChangeItemWrapper>
  );
};
 */
const SessionHistory = () => {
  const { config, configHistory } = useSelector((state: RootState) => state.bluetooth);
  const { sessionRestorePoints } = useSelector((state: RootState) => state.liveConfigurator);
  const history = useSelector(selectLiveConfigHistory);
  const { consumeAllSessionChanges } = useRemoteSession();
  const { sendConfig } = useRemoteSession();
  const [transformedConfig, setTransformedConfig] = useState<any>(null);
  const [sessionHistory, setSessionHistory] = useState<any>(null);
  const [configRestorePoints, setConfigRestorePoints] = useState<any[]>([]);
  const [selectedEntry, setSelectedEntry] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    if (configHistory) {
      setSessionHistory(compareHistory(config, configHistory));
    }
  }, [configHistory]);

  useEffect(() => {
    if (sessionRestorePoints && sessionRestorePoints.length > 0) {
      setConfigRestorePoints(
        sessionRestorePoints.map((item, index) => ({
          id: index,
          name: `#${index + 1}`,
          config: item
        }))
      );
    } else {
      setConfigRestorePoints([]);
    }
  }, [sessionRestorePoints]);

  useEffect(() => {
    const item = configRestorePoints[selectedEntry];
    if (item) {
      const { config } = item;
      const { gripsTransformed, gripPairsConfig, gripSequentialConfig } = transformConfig(config);
      setTransformedConfig({ grips: gripsTransformed, gripPairsConfig, gripSequentialConfig });
    }
  }, [selectedEntry, sessionHistory]);

  const restoreAllChanges = () => {
    dispatch(setItemUI({ type: 'shownGlobalModal', payload: 'resetAllChanges' }));
    sendConfig();
  };

  const restorePoint = async (selectedItem) => {
    await dispatch(importConfig({ config: configRestorePoints[selectedItem].config }));
    consumeAllSessionChanges(false);
    sendConfig();
  };

  return (
    <DefaultLayout>
      <HeaderWrapper>
        <Header1>Session history</Header1>
        <ButtonWrapper>
          <Button small onClick={restoreAllChanges}>
            Reset all changes
          </Button>
        </ButtonWrapper>
      </HeaderWrapper>
      {/*       <Card>
        <InnerCard>
          <Table>
            <tr>
              <th>Settings name</th>
              <th>Configuration</th>
              <th>Value before</th>
              <th>Value after</th>
              <th>Timestamp</th>
              <th>Restore point</th>
              <th>Actions</th>
            </tr>
            {sessionHistory &&
              sessionHistory.map((item) => (
                <SessionHistoryItem key={item.timestamp + Math.random()} {...item} />
              ))}
          </Table>
        </InnerCard>
      </Card> */}
      <Card>
        <TableBody>
          <TableHeader>Configuration</TableHeader>
          <TableHeader>Value before</TableHeader>
          <TableHeader>Value after</TableHeader>
          <TableHeader>Value now</TableHeader>
        </TableBody>
        {sessionHistory &&
          sessionHistory.map((entry) => (
            <SessionHistoryItem
              key={entry.timestamp + Math.random()}
              name={entry.key}
              configName={entry.key}
              before={entry.before}
              after={entry.after}
              now={config}
              timestamp={entry.timestamp}
              id={entry.id}
            />
          ))}
      </Card>
      <Divider margin='40px' />
      {configRestorePoints && configRestorePoints.length > 0 && (
        <>
          <HeaderWrapper>
            <HeaderInnerWrapper>
              <Header1>Restore point</Header1>
              <Button small onClick={() => restorePoint(selectedEntry)} Pressed>
                Load
              </Button>
            </HeaderInnerWrapper>
            <HeaderInnerWrapper>
              <StyledDropdown
                label='Entry name:'
                options={configRestorePoints.map((item) => item.name)}
                selected={configRestorePoints[selectedEntry].name}
                onChange={(e, _, index) => setSelectedEntry(index)}
              />
            </HeaderInnerWrapper>
          </HeaderWrapper>
          <DeviceRestoreEntry
            config={configRestorePoints[selectedEntry].config || null}
            transformedConfig={transformedConfig}
          />
        </>
      )}
    </DefaultLayout>
  );
};

export default SessionHistory;
