import React from 'react';
import styled from 'styled-components';
import CompanyImg from 'assets/company_img.jpg';

const AuthorImgWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const AuthorImg = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 8px;
  border-radius: 50%;
`;

const AuthorTitle = styled.span`
  font-weight: ${({ theme }) => theme.typography.fontWeights.bold};
  font-size: 16px;
`

export const AvatarSnack = ({ img, name }) => (
  <AuthorImgWrapper>
    <AuthorImg src={CompanyImg || img} alt='Author' />
    <AuthorTitle>{name}</AuthorTitle>
  </AuthorImgWrapper>
);
