/* eslint-disable prefer-destructuring */
/* eslint-disable no-plusplus */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useImperativeHandle, forwardRef, useEffect } from "react";
import styled from "styled-components";
import Chart from "chart.js/auto";

const ChartWrapper = styled.canvas`
  height: 100%;
`;

let chartSeparate2;

const ChartJS = ({ id, emgData, thresholdsData }, ref) => {
  useEffect(() => {
    const ctx = document.getElementById(id).getContext("2d");
    chartSeparate2 = new Chart(ctx, {
      type: "line",
      data: {
        datasets: [...thresholdsData, ...emgData],
      },
      options: {
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            enabled: false,
          },
        },
        maintainAspectRatio: false,
        animation: false,
        responsive: true,
        scales: {
          x: {
            type: "linear",
            display: false,
            reverse: true,
            min: 0,
            max: 100,
          },
          y: {
            position: "right",
            display: true,
            min: 0,
            max: 100,
          },
        },
      },
    });
  }, []);

  useImperativeHandle(ref, () => ({
    updateData: (data) => {
      const newData = data.map((item) => {
        const datasetObject = {
          label: item.label,
          data: item.values,
        };
        return datasetObject;
      });
      chartSeparate2.data.datasets.forEach((dataset) => {
        newData.forEach((element) => {
          if (dataset.label === element.label) {
            dataset.data = element.data;
          }
        });
      });
      chartSeparate2.update();
    },
    updateTime: (time) => {
      chartSeparate2.options.scales.x.max = time;
      chartSeparate2.update("none");
    },
    updateThresholds: (chartParameters, thresholds, isShown) => {
      const newThresholds = thresholds.map((item, index) => {
        const datasetObject = {
          label: item.label,
          data: [
            { x: 0, y: item.values },
            { x: chartParameters.time, y: item.values },
          ],
          borderColor: item.values > 0 ? item.color : "rgba(0,0,0,0)",
          hidden: isShown ? !isShown[index] : false,
        };
        return datasetObject;
      });
      chartSeparate2.data.datasets.forEach((dataset) => {
        newThresholds.forEach((element) => {
          if (dataset.label === element.label) {
            dataset.data = element.data;
            dataset.hidden = element.hidden;
            dataset.borderColor = element.borderColor;
          }
        });
      });
      chartSeparate2.update("none");
    },
  }));

  return <ChartWrapper id={id} />;
};

export default forwardRef(ChartJS);
