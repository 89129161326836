/* eslint-disable no-bitwise */
/* eslint-disable no-underscore-dangle */

import { Commands } from 'bluetooth/Bluetooth/Defines';
import TelemetryParser from 'utils/TelemetryParser';
import BluetoothWebControllerLE from './bluetoothLE';
import BluetoothWebControllerSerial from './bluetoothSerial';

const bluetoothLE = new BluetoothWebControllerLE();
const bluetoothSerial = new BluetoothWebControllerSerial();
const telemetryParser = new TelemetryParser();

const TelemetryController = {
  gripInTransition: false,
  telemetryEnabled: false,
  telemetryListening: false,
  telemetryData: <any>[],
  eventListenerController: new AbortController(),
  prosthesisGrip: null,
  parseTelemetry(telemetryData: any) {
    const [{ payload: telemetry }] = telemetryData.detail;
    const newData = telemetryParser.parseTelemetry(telemetryData.detail, 500);
    const binaryStates = telemetry[22];
    const newGrip = telemetry[23];
    const newGripInTransition = ((binaryStates >> 3) & 1) === 1;
    if (newGrip !== this.prosthesisGrip) {
      this.prosthesisGrip = newGrip;
    }
    this.gripInTransition = newGripInTransition;
    // @ts-ignore
    this.telemetryData = newData;
    console.log('RECEIVING');
  },
  initiateTelemetry() {
    this.eventListenerController = new AbortController();
    if (!this.telemetryListening) {
      window.addEventListener(
        `received${Commands.kTelemetryData}`,
        this.parseTelemetry.bind(this),
        { signal: this.eventListenerController.signal }
      );
      this.telemetryListening = true;
    }
  },
  abortTelemetry() {
    this.eventListenerController.abort();
    this.telemetryListening = false;
  },
  async telemetryOn(mode = 'classic') {
    this.telemetryEnabled = true;
    if (mode === 'classic') {
      await bluetoothSerial.telemetryOn();
    } else {
      await bluetoothLE.telemetryOn();
    }
  },
  async telemetryOff(mode = 'classic') {
    this.telemetryEnabled = false;
    if (mode === 'classic') {
      await bluetoothSerial.telemetryOff();
    } else {
      await bluetoothLE.telemetryOff();
    }
  }
};

export default TelemetryController;
