import { createAsyncThunk, createSlice, Reducer } from '@reduxjs/toolkit';
import { getModesConfigForDevice } from 'api/modes/modes';

type ModesState = {
  modals: {
    showConfirmSave: boolean;
    switchOnRemoteSession: boolean;
  };
  modeId: number | null;
  modeToChangeId: number | null;
  modesList: null | any[];
};

const initialState: ModesState = {
  modals: {
    showConfirmSave: false,
    switchOnRemoteSession: false
  },
  modeId: null,
  modeToChangeId: null,
  modesList: null
};

export const getModesListAPI = createAsyncThunk(
  'modes/getModesListAPI',
  async ({ deviceId }: { deviceId: number }) => {
    try {
      return await getModesConfigForDevice({ deviceId });
    } catch (err: any) {
      console.log(err);
      return false;
    }
  }
);

export const modesSlice = createSlice({
  name: 'modes',
  initialState,
  reducers: {
    setShowConfirmSave: (state, action) => {
      state.modals.showConfirmSave = action.payload;
    },
    setSwitchModeOnRemoteSession: (state, action) => {
      state.modals.switchOnRemoteSession = action.payload;
    },
    setModeId: (state, action) => {
      state.modeId = action.payload;
    },
    setModeToChange: (state, action) => {
      state.modeToChangeId = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getModesListAPI.fulfilled, (state, action) => {
      const activeModesList = action.payload.filter((item) => item.active);
      state.modesList = activeModesList;
      state.modeId = activeModesList[0].id;
    });
  }
});

export const { setShowConfirmSave, setModeId, setSwitchModeOnRemoteSession, setModeToChange } =
  modesSlice.actions;

export default modesSlice.reducer as Reducer<ModesState>;
