import styled from 'styled-components';

export const InnerWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.dimensions.spacing * 2}px;
  gap: ${({ theme }) => theme.dimensions.spacing * 1.5}px;
`;

export const GripsWrapper = styled.div`
  display: grid;
  gap: 40px;
  grid-template-columns: repeat(auto-fit, minmax(800px, 1fr));
`;

export const ButtonsWrapper = styled.div`
  display: grid;
  grid-template-columns: 150px 150px;
`;
