import dayjs from 'dayjs';

export const groupConfigEntries = (configEntries) => {
  const grouped: any = [];
  let array: any = [];

  const configItems = configEntries.items;

  for (let index = 0; index < configItems.length; index += 1) {
    const currentDate = dayjs(configItems[index].created_at);
    let nextDate;

    array.push(configItems[index]);

    if (configItems[index + 1]) {
      nextDate = dayjs(configItems[index + 1].created_at);
      if (!currentDate.isSame(nextDate, 'day')) {
        grouped.push(array);
        array = [];
      }
    } else {
      grouped.push(array);
      array = [];
    }
  }
  return grouped;
};
