/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as AngleDown } from 'assets/angle-down-solid.svg';
import uniqid from 'uniqid';
import { OpacityAnimation } from '../Animations/Animations';
import Checkbox from '../Checkbox/Checkbox';
import { TextXS } from '../Typography/Typography';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
`;

const Box = styled.div`
  position: relative;
  display: flex;
  align-items: space-between;
  min-height: 30px;
  width: auto;
  outline: 1px solid ${({ theme }) => theme.colorPrimary};
  border-radius: 5px;
  font-size: 14px;
  background-color: ${({ theme }) => theme.colorFill};
  cursor: pointer;
  height: 44px;
  padding: 10px;
`;

const Choosen = styled.div`
  width: 100%;
`;

const Label = styled.div`
  font-size: 16px;
  width: max-content;
  margin-right: ${({ inactive }) => (inactive ? '0' : '16px')};
`;

const Select = styled.ul`
  position: absolute;
  width: 100%;
  display: ${({ open }) => (open ? 'grid' : 'none')};
  outline: 1px solid ${({ theme }) => theme.colorPrimary};
  border-radius: 5px;
  padding: 5px;
  right: 0;
  top: ${({ flipY }) => (flipY ? '0' : 'calc(100% + 1px)')};
  list-style: none;
  z-index: 999;
  background-color: ${({ theme }) => theme.colorFill};
  animation: ${OpacityAnimation} 0.3s linear;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  transform: ${({ flipY }) => (flipY ? 'translateY(-100%)' : 'translateY(0)')};
  ${props => props.maxHeight && props.maxHeight !== 'auto' && `max-height: ${props.maxHeight}px; overflow-y: auto`}
`;

const Option = styled.li`
  padding: 10px;

  &&:hover {
    background-color: #3788b34d;
    border-radius: 5px;
  }
`;

const CheckboxWrapper = styled.li`
  display: flex;
  align-items: center;
`;

const StyledCheckbox = styled(Checkbox)`
  margin: 10px;
`;

const Header = styled.h4`
  ${TextXS};
  opacity: ${({ active }) => (active ? '1' : '0.5')};
`;

const IconDown = styled(AngleDown)`
  color: ${({ theme }) => theme.colorPrimary};
  right: 25px;
  top: 9px;
  width: 18px;
  transform: ${({ flipY }) => (flipY ? 'rotate(180deg)' : 'translateY(0)')};
`;

type DropdownProps = {
  options: any;
  label?: string;
  onChange: Function;
  selected: any;
  responsive?: boolean;
  isOpen?: boolean;
  directControl?: boolean;
  multi?: boolean;
  setOpenParent?: Function;
  style?: any;
  flipY?: boolean;
  maxHeight?: number|string
};

const Dropdown2 = ({
  options = [],
  label = '',
  onChange,
  selected,
  maxHeight = 'auto',
  responsive = false,
  isOpen = false,
  directControl = false,
  multi = false,
  setOpenParent = () => false,
  flipY = false,
  style,
  ...props
}: DropdownProps) => {
  const [open, setOpen] = useState(false);

  const updateOption = (option, index, e) => {
    if (multi) {
      e.stopPropagation();
    }
    onChange(option, open, index);
  };

  const updateOpen = () => {
    setOpen((prev) => !prev);
  };
  return (
    <Wrapper open={directControl ? isOpen : open} responsive={responsive ? 1 : 0} {...props}>
      <Label inactive={label === ''}>{label}</Label>
      <Box
        responsive={responsive ? 1 : 0}
        onClick={directControl ? setOpenParent : updateOpen}
        style={style}>
        {multi && Array.isArray(selected) && selected.length > 0 && (
          <Choosen>
            {options
              .filter((option) => selected.includes(option.id))
              .map((item) => item?.name)
              .join(', ')}
          </Choosen>
        )}
        {!multi && <Choosen>{options.find((option) => option.id === selected)?.name}</Choosen>}
        <IconDown responsive={responsive ? 1 : 0} flipY={flipY} />
        <Select open={directControl ? isOpen : open} flipY={flipY} maxHeight={maxHeight}>
          {options.map((option, index) => (
            <>
              {multi && (
                <CheckboxWrapper>
                  <StyledCheckbox
                    onClick={(e) => updateOption(option.id, index, e)}
                    checked={selected && selected.includes(option.id)}
                    key={uniqid()}
                    label={option.name}
                    id={`checkbox${index}`}
                    name={`checkbox${index}`}
                  />
                  <Header active>{option.name}</Header>
                </CheckboxWrapper>
              )}

              {!multi && (
                <Option
                  onClick={(e) => {
                    updateOption(option.id, index, e);
                  }}
                  value='option'
                  key={uniqid()}>
                  {option.name}
                </Option>
              )}
            </>
          ))}
        </Select>
      </Box>
    </Wrapper>
  );
};

export default Dropdown2;
