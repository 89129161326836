import { useDispatch } from 'react-redux';
import {
  CHOOSE_GRIPS,
  EMG_SETTINGS,
  GRIPS_CONFIGURATION,
  PROSTHESIS_SETTINGS
} from '../consts/routes';
import {
  consumeHistoryChooseGrips,
  consumeHistoryEmgSettings,
  consumeHistoryGripsConfiguration,
  consumeHistoryProsthesisSettings
} from '../reducers/bluetoothReducer/bluetoothReducer';
import { consumeLiveHistorySection } from '../reducers/liveConfiguratorReducer/liveConfiguratorReducer';

const detectUndoFunction = (pathname: string) => {
  switch (pathname) {
    case CHOOSE_GRIPS:
      return { asyncFunction: consumeHistoryChooseGrips, sessionConsumer: 'chooseGripsHistory' };
    case EMG_SETTINGS:
      return { asyncFunction: consumeHistoryEmgSettings, sessionConsumer: 'emgSettingsHistory' };
    case GRIPS_CONFIGURATION:
      return {
        asyncFunction: consumeHistoryGripsConfiguration,
        sessionConsumer: 'gripsConfigurationHistory'
      };
    case PROSTHESIS_SETTINGS:
      return {
        asyncFunction: consumeHistoryProsthesisSettings,
        sessionConsumer: 'prosthesisSettingsHistory'
      };
    default:
      return false;
  }
};

const useUndoFunction = (pathname: string, remoteSessionEnabled = false) => {
  const dispatch = useDispatch();
  const handleUndoFunction = () => {
    // @ts-ignore
    const { asyncFunction, sessionConsumer } = detectUndoFunction(pathname);
    if (remoteSessionEnabled) {
      dispatch(consumeLiveHistorySection({ consumer: sessionConsumer }));
    }
    dispatch(asyncFunction());
  };

  return {
    isUndoFunctionIsAvailableOnPage: () => !!detectUndoFunction(pathname),
    handleUndoFunction
  };
};

export default useUndoFunction;
