/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from "react";
import styled from "styled-components";
import { ReactComponent as AngleDown } from "assets/angle-down-solid.svg";
import uniqid from "uniqid";
import { TextM, TextS } from "../Typography/Typography";
import { OpacityAnimation } from "../Animations/Animations";

const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
`;

const Box = styled.div`
  position: relative;
  min-height: 30px;
  margin-left: 15px;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colorPrimary};
  border-radius: 5px;
  ${TextS};
  background-color: ${({ theme }) => theme.colorFill};
  cursor: pointer;

  @media (min-width: ${({ theme }) => theme.breakpoints.large}) {
    min-height: 50px;
    padding: 5px;
  }
`;

const Choosen = styled.div`
  padding: 10px;
`;

const Label = styled.div`
  margin-top: 6px;
  ${TextM};
`;

const Select = styled.ul`
  position: absolute;
  width: 100%;
  display: ${({ open }) => (open ? "grid" : "none")};
  border: 1px solid ${({ theme }) => theme.colorPrimary};
  border-radius: 5px;
  padding: 5px;
  left: 0;
  list-style: none;
  z-index: 999;
  background-color: ${({ theme }) => theme.colorFill};
  animation: ${OpacityAnimation} 0.3s linear;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
`;

const Option = styled.li`
  padding: 10px;
  &&:hover {
    background-color: #3788b34d;
    border-radius: 5px;
  }
`;

const IconDown = styled(AngleDown)`
  position: absolute;
  color: ${({ theme }) => theme.colorPrimary};
  right: 5px;
  top: 9px;
  width: 12px;

  @media (min-width: ${({ theme }) => theme.breakpoints.large}) {
    width: 24px;
    right: 20px;
    top: 8px;
  }
`;

type DropdownProps = {
  options: any;
  label?: string;
  onChange: Function;
  selected: any;
  responsive?: boolean;
  isOpen?: boolean;
  directControl?: boolean;
  setOpenParent?: Function;
};

const Dropdown = ({
  options = [],
  label = "",
  onChange,
  selected,
  responsive = false,
  isOpen = false,
  directControl = false,
  setOpenParent = () => false,
  ...props
}: DropdownProps) => {
  const [open, setOpen] = useState(false);

  const updateOption = (option, index) => {
    onChange(option, open, index);
  };

  const updateOpen = () => {
    setOpen((prev) => !prev);
  };

  return (
    <Wrapper
      open={directControl ? isOpen : open}
      responsive={responsive ? 1 : 0}
      {...props}
    >
      <Label>{label}</Label>
      <Box
        responsive={responsive ? 1 : 0}
        onClick={directControl ? setOpenParent : updateOpen}
      >
        <Choosen>{selected}</Choosen>
        <IconDown responsive={responsive ? 1 : 0} />
        <Select open={directControl ? isOpen : open}>
          {options.map((option, index) => (
            <Option
              onClick={() => {
                updateOption(option, index);
              }}
              value="option"
              key={uniqid()}
            >
              {option}
            </Option>
          ))}
        </Select>
      </Box>
    </Wrapper>
  );
};

export default Dropdown;
