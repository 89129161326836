const theme = {
  colorPrimary: 'hsl(201, 53%, 46%)',
  colorDanger: 'hsl(0, 71%, 53%)',
  colorPrimaryShades: [
    'hsl(201, 53%, 32%)',
    'hsl(201, 55%, 40%)',
    'hsl(201, 57%, 48%)',
    'hsl(201, 59%, 56%)',
    'hsl(201, 61%, 64%)',
    'hsl(201, 63%, 72%)',
    'hsl(201, 65%, 80%)'
  ],
  colorSecondary: 'hsl(201, 100%, 65%)',
  colorSupporting: '#FFFFFF',
  colorFill: '#F2F2F2',
  fontFamilyPrimary: "'Nunito', sans-serif",
  colorError: 'hsl(0, 71%, 53%)',
  colorErrorShades: ['hsl(0, 71%, 45%%)', 'hsl(0, 71%, 61%)'],
  breakpoints: {
    hdWidth: '1450px',
    hdWidthNum: 1450,
    large: '1450px',
    largeNum: 1450,
    hdWidthSmall: '1124px',
    hdWidthSmallNum: 1124,
    hdHeight: '800px',
    hdHeightNum: 800,
    medium: '1124px',
    mediumNum: 1124,
    small: '924px',
    smallNum: 924,
    xsmall: '724px',
    xsmallNum: 724,
    mediumHeight: '730px',
    mediumHeightNum: 730,
    smallHeight: '520px',
    smallHeightNum: 520
  },
  typography: {
    fonts: {
      OpenSans: "'Nunito', sans-serif"
    },
    fontSizes: {
      xxs: '10px',
      xs: '14px',
      s: '18px',
      m: '24px',
      l: '28px',
      xl: '36px'
    },
    fontWeights: {
      light: 300,
      regular: 400,
      semiBold: 600,
      bold: 700
    },
    colors: {
      primary: '#FFFFFF',
      secondary: '#393939',
      tetiary: '#4FC1FF',
      green: '#378805',
      red: 'hsl(1, 100%, 50%)',
      subtitle: 'hsl(0, 0%, 50%)'
    }
  },
  palette: {
    primary: '#33418e',
    primaryLight: '#fff',
    secondary: '#F1C152',
    background: '#f4f6fa',
    headerTitle: '#1d2b4f',
    headerColor: '#3b3b3d',
    neutral: '#6c757d',
    border: '#ced4da',
    error: '#d04a57',
    error2: '#dc3534',
    success: '#20c997',
    success2: '#28a745',
    subtleText: '#626c84',
    configFill: '#e9ecef',
    link: '#626c84',
    link2: '#0e63f4',
    linkClicked: '#6F42C1',
    separator: '#dee2e6',
    orange: '#eea537',
    green: '#54bd8d',
    red: '#eea537',
    info: '#e8f0fc',
    infoText: '#2f538e',
    secondaryText: '#fff',
    paragraph: '#3b3b3d'
  },
  dimensions: {
    contentMaxWidthBig: 1080,
    contentMaxWidthMedium: 912,
    contentMaxWidthSmall: 768,
    spacing: 16,
    formWidth: 400,
    spacingHeader: 12,
    iconSize: 16
  },
  fontWeight: {
    light: 300,
    medium: 500,
    bolder: 600,
    bold: 700
  }
};

export default theme;
