import styled, { css } from 'styled-components';
import { HeaderS } from '../Typography/Typography';

const Button = styled.button`
  width: 100%;
  padding: 10px 15px;
  ${HeaderS};
  border: none;
  border-radius: 5px;
  cursor: pointer;
  filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.25));
  background-color: ${({ theme }) => theme.colorSecondary};
  color: ${({ theme }) => theme.typography.colors.primary};
  transition: background-color 0.3s;

  &&:active {
    transform: scale(1, 1);
    background-color: #82d3ff;
  }

  &&:disabled {
    background-color: ${({ theme }) => theme.colorSecondary}66;
  }

  &&:hover {
    transform: scale(1.02, 1.02);
    transition: transform 0.2s;
  }

  &&:focus {
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    padding: 13px 20px;
  }

  ${({ Pressed }) =>
    Pressed &&
    css`
      background-color: ${({ theme }) => theme.colorPrimary};

      &&:hover {
        transform: none;
      }
    `};

  ${({ secondary }) =>
    secondary &&
    css`
      background-color: #fff;
      color: black;
      border: solid 1px #e9ecef;
      filter: none;
    `};

  ${({ tertiary }) =>
    tertiary &&
    css`
      display: flex;
      align-items: flex-end;
      &&& {
        padding: 0 0 0.5em 0;
      }
      background-color: #fff;
      color: ${({ theme }) => theme.colorSecondary};
      border: none;
      filter: none;
    `};

  ${({ small }) =>
    small &&
    css`
      display: flex !important;
      align-items: center !important;
      padding: 8px 12px !important;
      font-size: 14px !important;
      width: max-content !important;
    `};

  ${({ error }) =>
    error &&
    css`
      background-color: ${({ theme }) => theme.colorError};
      border: solid 1px ${({ theme }) => theme.colorError};
      filter: none;

      &&:active {
        background-color: ${({ theme }) => theme.colorErrorShades[1]};
      }
    `};
`;

export default Button;
