import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  MfaOptions,
  MfaRequire,
  UserLoginPayload
} from '../api/authentication/authentication.types';

export interface AuthState {
  token: null | string;
  tokenTime: null | string;
  mfa: {
    required: boolean;
    channel?: MfaOptions | null;
    codeFromUrl: string | null | number;
  };
}

const initialState: AuthState = {
  token: null,
  tokenTime: null,
  mfa: {
    required: false,
    channel: null,
    codeFromUrl: null
  }
};

function loginReducer(state: AuthState, action: PayloadAction<UserLoginPayload>) {
  state.token = action.payload.access_token;
  state.tokenTime = action.payload.expires;
}

function mfaCodeRequireReducer(state: AuthState, action: PayloadAction<MfaRequire>) {
  state.mfa.required = action.payload.require;
  state.mfa.channel = action.payload.channel;
  state.mfa.codeFromUrl = action.payload?.code || null;
}

function logoutReducer(state: AuthState) {
  state.token = initialState.token;
  state.tokenTime = initialState.tokenTime;
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginSuccess: loginReducer,
    logoutSuccess: logoutReducer,
    mfaCodeRequire: mfaCodeRequireReducer
  }
});

export const { loginSuccess, logoutSuccess, mfaCodeRequire } = authSlice.actions;

export const tokenSelector = (state: any) => state.auth.token;
export const tokenTimeSelector = (state: any) => state.auth.tokenTime;
export const mfaSelector = (state: any) => state.auth.mfa;

export default authSlice.reducer;
