import styled from 'styled-components';

const InnerCard = styled.div`
  border-radius: ${({ borderRadius }) => borderRadius || '5px'};
  background: ${({ theme }) => theme.colorSupporting};
  border: 1px solid ${({ theme }) => theme.colorFill};
  padding: 10px;

  @media (min-width: ${({ theme }) => theme.breakpoints.small}) {
    padding: ${({ padding }) => padding || '20px'};
  }
`;

export default InnerCard;
