import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import GripsChooserSequential from 'components/GripsChooser/GripsChooserSequential';
import GraphChooserTree from 'components/GripsChooser/GripsChooserTree';
import { useDispatch, useSelector } from 'react-redux';
import DefaultLayout from 'layouts/DefaultLayout';
import {
  addHistory,
  addHistoryConfig,
  setControlModes,
  setGripSequential,
  setGripsPairs
} from 'reducers/bluetoothReducer/bluetoothReducer';
import { Header } from 'components/Typography/Header';
import LiveConfigurator from 'components/organisms/LiveConfigurator/LiveConfigurator';
import { OpposedGrips, NonOpposedGrips } from 'consts/consts';
import useRemoteSession from 'hooks/useRemoteSession';
import { gripsImagesMap } from 'utils/gripsImages';
import { Button } from '@progress/kendo-react-buttons';
import { addLiveHistory } from 'reducers/liveConfiguratorReducer/liveConfiguratorReducer';
import Sidebar from 'components/Sidebar/Sidebar';
import { Grips } from '../../bluetooth/Bluetooth/Grips';
import { ControlModes } from '../../bluetooth/Bluetooth/Control';
import { ButtonsWrapper, GripsWrapper, InnerWrapper } from './styled';
import Card from '../../components/Card/Card';
import { ChartCombined, ChartProportional, ChartSeparate } from '../Graph/Charts';
import {
  ActivationSeparate1Wrapper,
  ActivationSeparate2Wrapper,
  ActivationWrapper, AdjusterWrapper, ContractionSeparate1Wrapper, ContractionSeparate2Wrapper,
  ContractionWrapper,
  ControlsWrapper, graphBreakpoints, Spacer, Wrapper
} from '../Graph/styled';
import VerticalGraphSlider from '../../components/molecules/VerticalGraphSlider/VerticalGraphSlider';
import PositionsAdjuster from '../../components/molecules/PositionsAdjuster/PositionsAdjuster';
import ChooseGripsGraph from './ChooseGripsGraph';

const ChooseGrips = () => {
  const [sequentialMode, setSequentialMode] = useState(false);
  const [gripsOpposed, setGripsOpposed] = useState<Grips[]>(OpposedGrips);
  const [gripsNonOpposed, setGripsNonOpposed] = useState<Grips[]>(NonOpposedGrips);
  const { sendConfig } = useRemoteSession();
  const { modeId } = useSelector((state: any) => state.modes);
  const [graphMode, setGraphMode] = useState('Combined');
  const dispatch = useDispatch();
  const {
    config: {
      gripPairsConfig,
      controlMode: [controlMode],
      gripSequentialConfig,
      gripsPositions
    }
  } = useSelector((state: any) => state.bluetooth);
  const { t } = useTranslation();
  const pairsOpposed = [
    gripPairsConfig[0],
    gripPairsConfig[1],
    gripPairsConfig[2],
    gripPairsConfig[3]
  ];
  const pairsNonOpposed = [
    gripPairsConfig[4],
    gripPairsConfig[5],
    gripPairsConfig[6],
    gripPairsConfig[7]
  ];
  const sequentialOpposed = [
    gripSequentialConfig[0],
    gripSequentialConfig[1],
    gripSequentialConfig[2],
    gripSequentialConfig[3],
    gripSequentialConfig[4],
    gripSequentialConfig[5]
  ];
  const sequentialNonOpposed = [
    gripSequentialConfig[6],
    gripSequentialConfig[7],
    gripSequentialConfig[8],
    gripSequentialConfig[9],
    gripSequentialConfig[10],
    gripSequentialConfig[11]
  ];

  useEffect(() => {
    if (Object.keys(gripsPositions).length > 0) {
      setGripsOpposed(
        Object.keys(gripsPositions)
          .map((position) => parseInt(position, 10))
          .filter(
            (position) =>
              position !== Grips.kGripCamera &&
              position !== Grips.kGripTypeUnknown &&
              OpposedGrips.includes(position)
          )
      );
      setGripsNonOpposed(
        Object.keys(gripsPositions)
          .map((position) => parseInt(position, 10))
          .filter(
            (position) =>
              position !== Grips.kGripCamera &&
              position !== Grips.kGripTypeUnknown &&
              NonOpposedGrips.includes(position)
          )
      );
    }
  }, [gripsPositions]);

  const updateHistory = async () => {
    const historyPayload = {
      gripPairsConfig: [...pairsOpposed, ...pairsNonOpposed],
      gripSequentialConfig: [...sequentialOpposed, ...sequentialNonOpposed]
    };

    await dispatch(addHistoryConfig());
    await dispatch(
      addHistory({
        type: 'chooseGripsHistory',
        payload: historyPayload
      })
    );
    await dispatch(
      addLiveHistory({
        type: 'chooseGripsHistory',
        payload: { ...historyPayload, timestamp: Date.now() }
      })
    );
  };

  useEffect(() => {
    if (controlMode !== null) {
      setSequentialMode(controlMode === ControlModes.kGripSequence);
    }
  }, [controlMode]);

  const updateMode = async (mode: ControlModes) => {
    await updateHistory();
    dispatch(setControlModes({ payload: mode, modeId }));
  };

  const updatePairGrips = async (option, gripOrder, isNonOpposed) => {
    let nonOpposed;
    let opposed;

    if (sequentialMode) {
      nonOpposed = sequentialNonOpposed;
      opposed = sequentialOpposed;
    } else {
      nonOpposed = pairsNonOpposed;
      opposed = pairsOpposed;
    }

    let newGripsArray: Grips[] = [];
    let oldGripsArray: Grips[] = [];
    if (isNonOpposed) {
      oldGripsArray = nonOpposed;
    } else {
      oldGripsArray = opposed;
    }
    newGripsArray = oldGripsArray.map((element, index) => {
      if (index === gripOrder) {
        element = option;
      }
      return element;
    });

    let gripsArray: Grips[] = [];

    if (isNonOpposed) {
      gripsArray = [...opposed, ...newGripsArray];
    } else {
      gripsArray = [...newGripsArray, ...nonOpposed];
    }
    await updateHistory();
    if (sequentialMode) {
      await dispatch(
        // @ts-ignore
        setGripSequential({ payload: gripsArray, modeId })
        // @ts-ignore
      ).unwrap();
    } else {
      await dispatch(
        // @ts-ignore
        setGripsPairs({ payload: gripsArray, modeId })
        // @ts-ignore
      );
    }
  };

  useEffect(() => {
    if (gripPairsConfig && gripSequentialConfig) {
      sendConfig();
    }
  }, [
    JSON.stringify(gripPairsConfig),
    JSON.stringify(gripSequentialConfig),
    JSON.stringify(controlMode),
    modeId
  ]);

  return (
    <DefaultLayout>
      <LiveConfigurator>
        <InnerWrapper>
          <Header margin={false}>{t('views.choose_grips')}</Header>
          <ButtonsWrapper>
            <Button
              onClick={() => updateMode(ControlModes.kGripPairs)}
              themeColor={sequentialMode ? 'base' : 'primary'}>
              {t('prosthesis_settings.pairing_mode')}
            </Button>
            <Button
              onClick={() => updateMode(ControlModes.kGripSequence)}
              themeColor={!sequentialMode ? 'base' : 'primary'}>
              {t('prosthesis_settings.sequential_mode')}
            </Button>
          </ButtonsWrapper>
        </InnerWrapper>
        <GripsWrapper>
          {sequentialMode ? (
            <GripsChooserSequential
              data-tour-general='opposed'
              grips={gripsOpposed}
              gripsImgs={gripsImagesMap}
              values={sequentialOpposed}
              onChange={updatePairGrips}
              indexes={[0, 1, 2, 3, 4]}
            />
          ) : (
            <GraphChooserTree
              grips={gripsOpposed}
              gripsImgs={gripsImagesMap}
              values={pairsOpposed}
              onChange={updatePairGrips}
              indexes={[0, 1, 2, 3]}
            />
          )}
          {sequentialMode ? (
            <GripsChooserSequential
              grips={gripsNonOpposed}
              gripsImgs={gripsImagesMap}
              nonOpposed
              indexes={[5, 6, 7, 8, 9]}
              values={sequentialNonOpposed}
              onChange={updatePairGrips}
            />
          ) : (
            <GraphChooserTree
              grips={gripsNonOpposed}
              gripsImgs={gripsImagesMap}
              values={pairsNonOpposed}
              nonOpposed
              onChange={updatePairGrips}
              indexes={[4, 5, 6, 7]}
            />
          )}
        </GripsWrapper>

        <Card style={{marginTop: '36px'}}>
          <ChooseGripsGraph/>
        </Card>
      </LiveConfigurator>
    </DefaultLayout>
  );
};

export default ChooseGrips;
