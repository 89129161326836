import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useModes from 'hooks/useModes';
import ConfirmSave from 'components/molecules/Modals/ConfirmSave';
import RestoreRecentConfigModal from 'components/molecules/Modals/RestoreRecentConfigModal';
import PreventInputModal from 'components/molecules/Modals/PreventInputModal';
import AuthorizeModal from 'components/molecules/Modals/AuthorizeModal';
import { selectShownGlobalModalUI, setItemUI } from '../reducers/uiReducer';
import CloseSessionModal from '../components/molecules/Modals/CloseSessionModal';
import ConfigsConflictModal from '../components/molecules/Modals/ConfigsConflictModal';
import FirmwareModal from '../components/molecules/Modals/FirmwareModal';
import HandDetails from '../components/molecules/Modals/HandDetails';
import ResetAllChangesModal from '../components/molecules/Modals/ResetAllChangesModal';
import SaveAsRestorePointModal from '../components/molecules/Modals/SaveAsRestorePointModal';
import SessionWasEndedServicing from '../components/molecules/Modals/SessionWasEndedServicing';
import ConfirmChangeModeRemoteSessionModal from '../components/molecules/Modals/ConfirmChangeModeRemoteSessionModal';

const Modals = () => {
  const shownGlobalModal = useSelector(selectShownGlobalModalUI);
  const dispatch = useDispatch();
  const {
    showConfirmSave,
    switchOnRemoteSession,
    handleCloseSave,
    handleSaveMode,
    handleConfirmChangeMode,
    handleCancelChangeMode
  } = useModes();
  const closeModal = () => dispatch(setItemUI({ type: 'shownGlobalModal', payload: null }));

  return (
    <>
      {shownGlobalModal === 'authorize' && <AuthorizeModal />}
      {shownGlobalModal === 'configConflict' && <ConfigsConflictModal handleClose={closeModal} />}
      {shownGlobalModal === 'firmware' && <FirmwareModal handleClose={closeModal} />}
      {shownGlobalModal === 'closeSession' && <CloseSessionModal handleClose={closeModal} />}
      {shownGlobalModal === 'servicingModal' && (
        <SessionWasEndedServicing handleClose={closeModal} />
      )}
      {shownGlobalModal === 'resetAllChanges' && <ResetAllChangesModal handleClose={closeModal} />}
      {shownGlobalModal === 'restorePoint' && <SaveAsRestorePointModal handleClose={closeModal} />}
      {shownGlobalModal === 'info' && <HandDetails handleClose={closeModal} />}
      {shownGlobalModal === 'restoreRecent' && (
        <RestoreRecentConfigModal handleClose={closeModal} />
      )}
      {showConfirmSave && (
        <ConfirmSave handleClose={handleCloseSave} handleAccept={handleSaveMode} />
      )}
      {switchOnRemoteSession && (
        <ConfirmChangeModeRemoteSessionModal
          handleClose={handleCancelChangeMode}
          handleAccept={handleConfirmChangeMode}
        />
      )}
      {shownGlobalModal === 'prevent' && <PreventInputModal />}
    </>
  );
};
export default Modals;
