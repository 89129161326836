/* eslint-disable no-async-promise-executor */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-plusplus */
/* eslint-disable import/prefer-default-export */
const changeRoute = (history, route) => {
  history.push(route);
};

const checkLength = (dataset, length) => {
  if (dataset.length > length) {
    dataset.splice(length, dataset.length - length);
  }
  return dataset;
};

function arraysEqual(a, b) {
  if (a === b) {
    return true;
  }
  if (a == null || b == null) {
    return false;
  }
  if (a.length !== b.length) {
    return false;
  }

  for (let i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) {
      return false;
    }
  }
  return true;
}

const getScrollbarWidth = () => {
  // Creating invisible container
  const outer = document.createElement('div');
  outer.style.visibility = 'hidden';
  outer.style.overflow = 'scroll'; // forcing scrollbar to appear
  document.body.appendChild(outer);

  // Creating inner element and placing it in the container
  const inner = document.createElement('div');
  outer.appendChild(inner);

  // Calculating difference between container's full width and the child width
  const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;

  // Removing temporary elements from the DOM
  outer.parentNode!.removeChild(outer);

  return scrollbarWidth;
};

const timeoutCommand = async (func, errorMessage): Promise<any> => {
  let result: any = false;
  let iterator = 0;

  while (result === false && iterator < 10) {
    console.log('TIMEOUT COMMAND');
    result = await Promise.race([
      new Promise(async (resolve) => {
        const payload = await func();
        resolve(payload);
      }),
      new Promise((resolve) => setTimeout(resolve, 500, false))
    ]);
    iterator += 1;
    console.log(iterator, result);
  }

  console.log('Result', result);
  if (result === false) {
    console.log('Throw error');
    throw new Error(errorMessage);
  }
  return result;
};

const timeoutCommandLite = async (func): Promise<any> => {
  let result: any = false;
  let iterator = 0;

  while (result === false && iterator < 10) {
    result = await Promise.race([
      new Promise(async (resolve) => {
        const payload = await func();
        resolve(payload);
      }),
      new Promise((resolve) => setTimeout(resolve, 250, false))
    ]);
    iterator += 1;
  }

  return result;
};

const timeoutCommandFirmware = async (func): Promise<any> => {
  const timeoutEvent = new Promise((resolve) => setTimeout(resolve, 300, false));

  const timeoutFunc = new Promise(async (resolve) => {
    const payload = await func();
    resolve(payload);
  });

  const result = await Promise.race([timeoutFunc, timeoutEvent]);
  return result;
};

const timeoutCommandLiteCustom = async (func, ms, iterations): Promise<any> => {
  let result: any = false;
  let iterator = 0;

  while (result === false && iterator < iterations) {
    result = await Promise.race([
      new Promise(async (resolve) => {
        const payload = await func();
        resolve(payload);
      }),
      new Promise((resolve) => setTimeout(resolve, ms, false))
    ]);
    iterator += 1;
  }

  return result;
};

const timeoutCommandCustom = async (func, ms): Promise<any> => {
  const timeoutEvent = new Promise((resolve) => setTimeout(resolve, ms, false));

  const timeoutFunc = new Promise(async (resolve) => {
    const payload = await func();
    resolve(payload);
  });

  const result = await Promise.race([timeoutFunc, timeoutEvent]);
  return result;
};

export {
  changeRoute,
  checkLength,
  arraysEqual,
  timeoutCommand,
  timeoutCommandLite,
  timeoutCommandLiteCustom,
  timeoutCommandCustom,
  timeoutCommandFirmware,
  getScrollbarWidth
};

export const numericalFirmwareVersion = (firmwareVersionParsed) =>
  firmwareVersionParsed ? Number(firmwareVersionParsed.replaceAll('.', '')) : 1;

export const parseBluetoothFirmware = (bluetoothFirmware) =>
  `${bluetoothFirmware[1]}.${bluetoothFirmware[4]}.${bluetoothFirmware[7]}`;
