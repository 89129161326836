import styled from 'styled-components';

export const HistoryConfiguratorContainer = styled.div`
  display: grid;
  grid-row-gap: 10px;
  margin-right: 20px;
  > div {
    display: grid;
    grid-template-columns: repeat(2, auto);
    grid-column-gap: 15px
  }
`;