import { Grips } from 'bluetooth/Bluetooth/Grips';
import powerGripImg from 'assets/power.png';
import precisionOpenGripImg from 'assets/precision-open.png';
import precisionClosedGripImg from 'assets/precision-closed.png';
import triggerGripImg from 'assets/trigger.png';
import tripodClosedGripImg from 'assets/tripod-closed.png';
import tripodOpenGripImg from 'assets/tripod-open.png';
import hookGripImg from 'assets/hook.png';
import fingerPointGripImg from 'assets/fingerPoint.png';
import keyGripImg from 'assets/key.png';
import mouseGripImg from 'assets/mouse.png';
import restGripImg from 'assets/rest.png';
import openPalmGripImg from 'assets/open-palm.png';
import activeIndexGripImg from 'assets/active-index.png';

const gripsImages: any = [
  [Grips.kGripPower, powerGripImg],
  [Grips.kGripTripodClosed, tripodClosedGripImg],
  [Grips.kGripPrecisionOpen, precisionOpenGripImg],
  [Grips.kGripPrecisionClosed, precisionClosedGripImg],
  [Grips.kGripTripodOpen, tripodOpenGripImg],
  [Grips.kGripTrigger, triggerGripImg],
  [Grips.kGripHook, hookGripImg],
  [Grips.kGripKey, keyGripImg],
  [Grips.kGripFingerPoint, fingerPointGripImg],
  [Grips.kGripMouse, mouseGripImg],
  [
    Grips.kGripTypeUnknown,
    'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=='
  ],
  [Grips.kGripRestOpp, restGripImg],
  [Grips.kGripRestNopp, openPalmGripImg],
  [Grips.kGripFingerPointOpen, activeIndexGripImg]
];
export const gripsImagesMap = new Map(gripsImages);
