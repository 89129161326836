import { useMutation, useQuery } from 'react-query';
import toast from 'react-hot-toast';
import { ServicingPartQueryParams } from '../../api/servicing/servicing.types';
import { createServicingRepair, getServicingParts } from '../../api/servicing/servicing';

export const SERVICING_PARTS_QUERY_KEY = 'servicingParts';

export const useServicingParts = (
  params: ServicingPartQueryParams = {},
  enabled: boolean = true
) => {
  const { data, isLoading, isError, refetch, isRefetching, isRefetchError } = useQuery(
    [SERVICING_PARTS_QUERY_KEY, params],
    () => getServicingParts(params),
    {
      enabled,
      onError() {
        toast.error('Failed to fetch servicing parts list');
      }
    }
  );

  return {
    result: data ? data.items : null,
    lastPage: data?.paginator ? data.paginator.last_page : null,
    isLoading,
    isError,
    refetch,
    isRefetching,
    isRefetchError,
    total: data?.paginator ? data.paginator.total : null
  };
};

export const useServicingRepair = () => {
  const { data, mutateAsync, isLoading, isError } = useMutation(createServicingRepair, {
    onSuccess() {
      toast.success('Create servicing repair request');
    },
    onError() {
      toast.error('Failed to create servicing repair request');
    }
  });

  return {
    result: data,
    mutateAsync,
    isLoading,
    isError
  };
};
