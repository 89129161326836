import 'theme/web-prosthesis.css';
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { tokenSelector } from 'reducers/authReducer';
import { setApiAuthToken } from 'api/utils/apiClient';
import ConfirmationLoader from 'layouts/ConfirmationLoader/ConfirmationLoader';
import useRefreshToken from 'hooks/api/useRefreshToken';
import useBluetooth from 'hooks/bluetooth/useConnect';
import { ViewModes } from 'reducers/meetingReducer/meetingReducer';
import Meeting from 'components/organisms/MeetingComponents/Meeting';
import ChooseGrips from './ChooseGrips/ChooseGrips';
import ProsthesisSettings from './ProsthesisSettings/ProsthesisSettings';
import ApplicationSettings from './ApplicationSettings';
import GripsConfiguration from './GripsConfiguration/GripsConfiguration';
import Graph from './Graph/Graph';
import ServiceMenu from './ServiceMenu';
import TokenRefresh from './Auth/TokenRefresh/TokenRefresh';
import DeviceInfo from './DeviceInfo/DeviceInfo';
import DeviceConfig from './DeviceConfig/DeviceConfig/DeviceConfig';
import SessionHistory from './DeviceConfig/SessionHistory/SessionHistory';
import Modals from './Modals';
import ConfigTemplates from './Templates/ConfigTemplates/ConfigTemplates';
import ConfigTemplateDetails from './Templates/ConfigTemplateDetails/ConfigTemplateDetails';
import DeviceHistory from './DeviceConfig/DeviceHistory/DeviceHistory';
import ServicingForm from './Servicing/ServicingForm';
import MainViews from './MainViews';

const Root = () => {
  const { device, firstConnection } = useSelector((state) => state.bluetooth);
  const { meetingStatus, viewMode } = useSelector((state) => state.meeting);
  const token = useSelector(tokenSelector);
  const { refreshToken } = useRefreshToken();
  const [show, setShow] = useState(false);
  const { bluetoothDisconnect } = useBluetooth();

  useEffect(
    () =>
      function clearConnection() {
        if (device.connected) {
          bluetoothDisconnect();
        }
      },
    []
  );

  useEffect(() => {
    refreshToken().finally(() => setShow(true));
  }, [refreshToken]);

  useEffect(() => {
    setApiAuthToken(token);
  }, [token]);

  return (
    <>
      <Modals />
      <div id='modal-root' />
      <div>
        {/* <MeetingUtilityBar id='utility-bar' /> */}
        <div>
          <Route path='*' component={TokenRefresh} />
          <Route exact path='/device' component={DeviceInfo} />
          {token && (
            <Switch>
              <MainViews>
                {firstConnection !== null && (
                  <>
                    <Route exact path='/'>
                      <Redirect to='/choose-grips' />
                    </Route>
                    <Route exact path='/device'>
                      <Redirect to='/choose-grips' />
                    </Route>
                    <Route exact path='/choose-grips' component={ChooseGrips} />
                    <Route exact path='/emg-settings' component={Graph} />
                    <Route exact path='/prosthesis-settings' component={ProsthesisSettings} />
                    <Route exact path='/application-settings' component={ApplicationSettings} />
                    <Route exact path='/grips-configuration' component={GripsConfiguration} />
                    <Route exact path='/graph' component={Graph} />
                    <Route exact path='/service-menu' component={ServiceMenu} />
                    <Route exact path='/device-history/type/:type' component={DeviceHistory} />
                    <Route exact path='/device-history/:configId' component={DeviceConfig} />
                    <Route exact path='/session-history' component={SessionHistory} />
                    <Route exact path='/servicing' component={ServicingForm} />
                    <Route
                      exact
                      path='/config-templates/:templateId'
                      component={ConfigTemplateDetails}
                    />
                    <Route exact path='/config-templates' component={ConfigTemplates} />
                  </>
                )}
              </MainViews>
            </Switch>
          )}
          {!show && <ConfirmationLoader />}
        </div>
        {(meetingStatus ||
          viewMode === ViewModes.minimalEMG ||
          viewMode === ViewModes.standardEMG) && <Meeting />}
      </div>
    </>
  );
};

export default Root;
