/* eslint-disable no-await-in-loop */
import { postCommunicateMode } from 'bluetooth-handler/bluetoothFunctions';
import { delay } from 'bluetooth/Bluetooth/Utilities';
import { useDispatch, useSelector } from 'react-redux';
import { sendWholeConfigDevice } from 'reducers/bluetoothReducer/bluetoothHelpers/asyncThunks';
import { compareConfigs } from 'reducers/bluetoothReducer/bluetoothHelpers/bluetoothHelpers';
import {
  importConfig,
  setItem,
  setSynchronizeConfigs
} from 'reducers/bluetoothReducer/bluetoothReducer';

interface configType {
  common: any;
  modes: any[];
}

interface configArgsType {
  deviceConfig: configType;
  apiConfig: configType;
}

const useSynchronizeBluetooth = () => {
  const dispatch = useDispatch();
  const { bluetoothMode } = useSelector((state: any) => state.bluetooth);
  const { modesList, modeId } = useSelector((state: any) => state.modes);

  const synchronizeConfig = async ({ deviceConfig, apiConfig }: configArgsType) => {
    await postCommunicateMode(0, bluetoothMode);
    await delay(100);

    const configDifferences = compareConfigs(deviceConfig.common, apiConfig.common);
    const modesDifferences = apiConfig.modes.map((mode) => ({
      differences: compareConfigs(
        deviceConfig.modes.find((_mode) => _mode.slot === mode.slot).config,
        mode.config
      ),
      slot: mode.slot
    }));

    await dispatch(sendWholeConfigDevice({ configToSend: configDifferences }));

    for (let index = 0; index < modesDifferences.length; index += 1) {
      const { slot, differences } = modesDifferences[index];
      await postCommunicateMode(slot, bluetoothMode);
      await delay(100);
      await dispatch(sendWholeConfigDevice({ configToSend: differences }));
    }

    // Switch to previously selected mode
    await postCommunicateMode(modesList.find((mode) => mode.id === modeId).slot, bluetoothMode);

    await dispatch(setItem({ payload: false, type: 'configConflict' }));
    await dispatch(setSynchronizeConfigs(apiConfig));
    const apiModeToLoad = apiConfig.modes.find((apiMode) => apiMode.id === modeId);
    const combinedConfig = { ...apiModeToLoad, ...apiConfig.common };
    dispatch(importConfig({ config: combinedConfig }));
  };

  return synchronizeConfig;
};

export default useSynchronizeBluetooth;
