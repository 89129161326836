import styled from 'styled-components';

export const Header = styled.h2`
  font-size: 24px;
  font-weight: ${({ theme }) => theme.fontWeight.bolder};
  margin-bottom: ${({ theme, margin = true }) => (margin ? theme.dimensions.spacing * 2 : 0)}px;
  color: ${({ theme }) => theme.palette.headerColor};
`;

export const HeaderSmaller = styled.h2`
  font-size: 22px;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  color: ${({ theme }) => theme.palette.headerColor};
`;

export const HeaderSmall = styled.h2`
  font-size: 20px;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  color: ${({ theme }) => theme.palette.headerColor};
`;
