/* eslint-disable no-await-in-loop */
import React, { useState } from "react";
import styled from "styled-components";
import { Fingers } from "utils/definesLocal";
import { TextS } from "components/atoms/Typography/Typography";
import {
  postJointTargetPosition,
  queryTelemetryOnce,
} from "bluetooth-handler/bluetoothFunctions";
import { useSelector } from "react-redux";
import Error from "components/atoms/Error/Error";
import { Button } from '@progress/kendo-react-buttons';
import Card from '../../atoms/Card/Card';

const HeaderSecondary = styled.h4`
  ${TextS};
  height: 30px;
  text-align: center;

  @media (min-width: ${({ theme }) => theme.breakpoints.large}) {
    margin-bottom: 30px;
  }
`;

const ActionsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 20px 1fr 20px 1fr 20px 1fr 20px 1fr;

  @media (min-width: ${({ theme }) => theme.breakpoints.large}) {
    grid-template-columns: 1fr 50px 1fr 50px 1fr 50px 1fr 50px 1fr;
  }
`;

const DividerInner = styled.hr`
  border-left: 1px solid ${({ theme }) => theme.palette.configFill};
  margin-top: 30px;
  margin-bottom: 0;

  @media (min-width: ${({ theme }) => theme.breakpoints.large}) {
    margin-top: 50px;
  }
`;

const ActionsList = styled.ul`
  list-style: none;
  display: grid;
  grid-auto-flow: row;
  grid-gap: 20px;
`;

const StyledError = styled(Error)`
  margin-top: 20px;
`;

const fingersArray = [
  { finger: Fingers.Thumb, name: "Thumb" },
  { finger: Fingers.Index, name: "Index" },
  { finger: Fingers.Middle, name: "Middle" },
  { finger: Fingers.Ring, name: "Ring" },
  { finger: Fingers.Pinky, name: "Pinky" },
];

const ServicePositions = () => {
  const [errorMessage, setErrorMessage] = useState(null);
  const { bluetoothMode, device } = useSelector((state) => state.bluetooth);

  const getTelemetryData = async () => {
    const telemetryData = await queryTelemetryOnce(bluetoothMode);
    console.log(telemetryData);
    return telemetryData;
  };

  const isClosed = (telemetryData, finger) =>
    telemetryData.fingers[finger].encoderTicks > 850;

  const closeFinger = async (finger) => {
    const telemetryData = await getTelemetryData();

    if (finger === Fingers.Index || finger === Fingers.Middle) {
      if (telemetryData.isThumbOpposed) {
        setErrorMessage(
          "When thumb is opposed, it can collide with other fingers. To close them, make thumb not opposed."
        );
      } else if (
        finger === Fingers.Index &&
        isClosed(telemetryData, Fingers.Thumb)
      ) {
        setErrorMessage(
          "When thumb is closed, it can block index finger. To move it, open the thumb first."
        );
      } else {
        await postJointTargetPosition(finger, 999, bluetoothMode);
      }
    } else {
      await postJointTargetPosition(finger, 999, bluetoothMode);
    }
  };

  const openFinger = async (finger) => {
    const telemetryData = await getTelemetryData();
    if (
      finger === Fingers.Index &&
      isClosed(telemetryData, Fingers.Thumb) &&
      isClosed(telemetryData, Fingers.Index)
    ) {
      setErrorMessage(
        "When thumb is closed, it can block index finger. To open index finger, open the thumb first."
      );
    } else {
      await postJointTargetPosition(finger, 0, bluetoothMode);
    }
  };

  const setFingerServicePosition = async (finger) => {
    const telemetryData = await getTelemetryData();
    if (telemetryData.isThumbOpposed) {
      setErrorMessage(
        "To set the service position of the fingers, switch the thumb to non-opposed position."
      );
    } else {
      for (let i = 0; i < 5; i += 1) {
        const currentFinger = i;
        if (currentFinger === finger) {
          await postJointTargetPosition(currentFinger, 0, bluetoothMode);
        } else if (currentFinger === Fingers.Thumb) {
          await postJointTargetPosition(currentFinger, 800, bluetoothMode);
        } else {
          await postJointTargetPosition(currentFinger, 999, bluetoothMode);
        }
      }
    }
  };

  const handleFingerServicePosition = (finger) => {
    if (device.connected) {
      setErrorMessage(null);
      setFingerServicePosition(finger);
    }
  };

  const handleOpenFinger = (finger) => {
    if (device.connected) {
      setErrorMessage(null);
      openFinger(finger);
    }
  };

  const handleCloseFinger = (finger) => {
    if (device.connected) {
      setErrorMessage(null);
      closeFinger(finger);
    }
  };

  return (
    <Card>
      <ActionsContainer>
        {fingersArray.map((finger, index) => (
          <>
            <div>
              <HeaderSecondary>{finger.name}</HeaderSecondary>
              <ActionsList>
                <Button
                  onClick={() =>
                    handleFingerServicePosition(finger.finger, index, 0)
                  }
                >
                  Service position
                </Button>

                <Button
                  onClick={() => handleOpenFinger(finger.finger, index, 1)}
                >
                  Open
                </Button>

                <Button
                  onClick={() => handleCloseFinger(finger.finger, index, 2)}
                >
                  Close
                </Button>
              </ActionsList>
            </div>
            {index !== fingersArray.length - 1 ? <DividerInner /> : null}
          </>
        ))}
      </ActionsContainer>
      {errorMessage && <StyledError>{errorMessage}</StyledError>}
    </Card>
  );
};

export default ServicePositions;
