/* eslint-disable dot-notation */
import axios from 'axios';

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json'
  }
});

export const withCredentials = {
  withCredentials: true
};

export const withCredentialsAndMultipartForm = {
  ...withCredentials,
  headers: {
    'content-type': 'multipart/form-data'
  }
};

export function setApiAuthToken(token: string | null) {
  if (token) {
    // @ts-ignore
    instance.defaults.headers['Authorization'] = `Bearer ${token}`;
  } else {
    // @ts-ignore
    delete instance.defaults.headers['Authorization'];
  }
}

instance.interceptors.response.use(
  (res) => res,
  async (error) => {
    if (error.response?.data?.errors) {
      console.log(error.response.data.errors);
    }
    return Promise.reject(error);
  }
);

export default instance;
