/* eslint-disable no-restricted-syntax */
/* eslint-disable no-shadow */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { selectDeviceInfo } from 'reducers/deviceInfoReducer';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import {
  CONFIG_ENTRY_NOTES_KEY,
  useConfigEntryNotes,
  useCreateConfigNote,
  useDeleteConfigNote,
  useDeviceConfigHistory
} from 'hooks/api/useDevice';
import { useHistory, useParams } from 'react-router-dom';
import { MONTHS, months, YEARS } from 'consts/consts';
import Calendar from 'components/organisms/Calendar/Calendar';
import { groupConfigEntries } from 'utils/Calendar/CalendarUtils';
import { useModal } from 'hooks/useModal';
import ModalPortal from 'utils/Modal/ModalPortal';
import { AvatarSnack } from 'components/atoms/AvatarSnack/AvatarSnack';
import RestoreConfigHistoryModal from 'components/molecules/Modals/RestoreConfigHistoryModal';
import { StyledNotesIcon } from 'components/atoms/Icons/Icons';
import { ConfigNotesSortOptions } from 'api/device/device.types';
import { SortDirs } from 'types';
import Pagination from 'components/atoms/Pagination/Pagination';
import Divider from 'components/atoms/Divider/Divider';
import { Table } from 'components/atoms/Table/Table';
import NotesList from 'components/organisms/NotesList/NotesList';
import { useQueryClient } from 'react-query';
import CalendarPicker from 'components/molecules/CalendarPicker/CalendarPicker';
import { setItemUI } from 'reducers/uiReducer';
import { transformConfigAPI } from 'utils/Config/transformConfig';
import { useDeviceManager } from 'hooks/api/useDeviceManager';
import ModalBase from 'components/molecules/Modals/ModalBase';
import Modal from 'utils/Modal/Modal';
import AddNote from 'components/molecules/Modals/AddNoteModal';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Button } from '@progress/kendo-react-buttons';
import { HeaderWrapper } from '../styled';
import { CheckComponent, ToggleHistoryView } from '../components';
import { Header } from '../../../components/Typography/Header';
import Card from '../../../components/Card/Card';
import { usePaginationParam } from '../../../hooks/usePagination';
import { HISTORY_CONFIGURATION } from '../../../consts/routes';

const SelectorWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const DropDownTitle = styled.span`
  font-size: 16px;
  width: 100%;
  word-break: keep-all;
`;

const HeaderInnerWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  flex-wrap: wrap;
`;

const ActionsWrapper = styled.div`
  display: flex;
  gap: 9px;
  align-items: center;
  flex-wrap: wrap;

  button {
    width: auto;
    display: flex;
    align-items: center;
  }
`;

const ChangeItemWrapper = styled.tr`
  td {
    word-wrap: break-word;

    &:not(:last-child) {
      padding-right: 12px;
    }
  }
`;

const StyledModalBase = styled(ModalBase)`
  width: 450px;
`;

enum sortByOptions {
  latest = 'desc',
  earliest = 'asc'
}

enum filterByOptions {
  noFilter = 'null',
  restorePoint = '1'
}

const sortBy = [
  { id: sortByOptions.latest, name: 'Latest' },
  { id: sortByOptions.earliest, name: 'Earliest' }
];

const filterBy = [
  { id: filterByOptions.noFilter, name: 'No filter' },
  { id: filterByOptions.restorePoint, name: 'Restore point' }
];

const ChangeItem = ({ changeEntry, deviceId }) => {
  const { push } = useHistory();
  const { handleClose, handleOpen, isOpen } = useModal();
  const {
    handleClose: handleCloseAddNote,
    handleOpen: handleOpenAddNote,
    isOpen: isOpenAddNote
  } = useModal();
  const {
    handleClose: handleCloseRestoreConfigHistoryModal,
    handleOpen: handleOpenRestoreConfigHistoryModal,
    isOpen: isRestoreConfigHistoryModalOpen
  } = useModal();
  const { modesList } = useSelector((state: any) => state.modes);
  const { restoreConfigHistory } = useDeviceManager();
  const { modeAffectedId, completeConfig } = transformConfigAPI(changeEntry, modesList);
  const { result: notes } = useConfigEntryNotes({
    deviceId,
    configId: changeEntry.id,
    params: { sortby: ConfigNotesSortOptions.date, sortdir: SortDirs.desc }
  });
  const { mutateAsync: createConfigNote } = useCreateConfigNote();
  const { mutateAsync: deleteConfigNote } = useDeleteConfigNote();
  const queryClient = useQueryClient();

  const handlePush = (configId: number) => {
    push({ pathname: `/device-history/${configId}`, state: { detail: changeEntry } });
  };

  const handleRestore = async () => {
    await restoreConfigHistory(modeAffectedId, changeEntry.id, completeConfig);
    handleCloseRestoreConfigHistoryModal();
  };

  const handleAddNote = async ({ note, type }) => {
    await createConfigNote({ deviceId, configId: Number(changeEntry.id), data: { note, type } });
    queryClient.invalidateQueries(CONFIG_ENTRY_NOTES_KEY);
  };

  const handleDeleteNote = async ({ noteId }) => {
    await deleteConfigNote({ deviceId, configId: Number(changeEntry.id), noteId });
    queryClient.invalidateQueries(CONFIG_ENTRY_NOTES_KEY);
  };

  return (
    <>
      {isRestoreConfigHistoryModalOpen && (
        <RestoreConfigHistoryModal
          handleClose={handleCloseRestoreConfigHistoryModal}
          handleAccept={handleRestore}
        />
      )}
      {isOpen && (
        <ModalPortal>
          <Modal>
            <StyledModalBase handleClick={handleClose} header='Notes'>
              <NotesList notes={notes} handleDelete={handleDeleteNote} />
            </StyledModalBase>
          </Modal>
        </ModalPortal>
      )}
      {isOpenAddNote && (
        <ModalPortal>
          <AddNote handleClose={handleCloseAddNote} handleSubmitNote={handleAddNote} />
        </ModalPortal>
      )}
      <ChangeItemWrapper>
        <td>
          <AvatarSnack img={changeEntry?.author?.img} name={changeEntry?.author?.name} />
        </td>
        <td>{changeEntry?.name}</td>
        <td>{changeEntry?.restore_point === 0 ? '-' : <CheckComponent />}</td>
        <td>{notes?.[0]?.note}</td>
        <td>{dayjs(changeEntry?.created_at).format('MM.DD.YYYY, HH:mm')}</td>
        <td>
          <ActionsWrapper>
            <Button
              themeColor='secondary'
              icon='reset-sm'
              onClick={handleOpenRestoreConfigHistoryModal}>
              Load
            </Button>
            <Button
              themeColor='secondary'
              icon='preview'
              onClick={() => handlePush(changeEntry.id)}>
              See
            </Button>
            {notes?.length > 0 && (
              <Button onClick={handleOpen}>
                <StyledNotesIcon style={{ marginRight: '9px' }} />
                Show notes
              </Button>
            )}
            <Button themeColor='primary' icon='plus' onClick={handleOpenAddNote}>
              <b>Add note</b>
            </Button>
          </ActionsWrapper>
        </td>
      </ChangeItemWrapper>
    </>
  );
};

const DeviceHistoryComponent = () => {
  const dispatch = useDispatch();
  const { push } = useHistory();
  // @ts-ignore
  const { type } = useParams();
  const currentMonth = useMemo(() => dayjs().month(), []);
  const currentYear = useMemo(() => dayjs().year(), []);
  const { isCalendarHistory } = useSelector((state: any) => state.ui);
  const { deviceId } = useSelector(selectDeviceInfo);
  const [selectedMonth, setSelectedMonth] = useState(
    MONTHS.find((item) => item.id === currentMonth) ?? MONTHS[0]
  );
  const [selectedYear, setSelectedYear] = useState(
    YEARS.find((item) => item.id === currentYear) ?? YEARS[0]
  );
  const [sortBySelected, setSortBySelected] = useState(sortBy[0]);
  const [filterBySelected, setFilterBySelected] = useState(filterBy[0]);
  const [dateSelected, setDateSelected] = useState<number>();

  const { result: devicesConfigHistory } = usePaginationParam(useDeviceConfigHistory, [deviceId], {
    extend: 'author,entries',
    sortby: 'date',
    perpage: 1000,
    sortdir: sortByOptions.latest
  });

  const {
    result: devicesConfigHistoryLog,
    total,
    page,
    pageSize,
    pageChange
  } = usePaginationParam(useDeviceConfigHistory, [deviceId], {
    extend: 'author,entries',
    perpage: 12,
    sortby: 'date',
    sortdir: sortBySelected.id,
    restore_point: filterBySelected.id,
    ...(dateSelected && {
      date_from: dateSelected,
      date_to: dateSelected + 24 * 60 * 60
    })
  });

  const { result: devicesConfigHistoryCalendar } = usePaginationParam(
    useDeviceConfigHistory,
    [deviceId],
    {
      extend: 'author',
      perpage: 10000
    }
  );

  useEffect(() => {
    if (type === 'calendar') {
      dispatch(setItemUI({ payload: true, type: 'isCalendarHistory' }));
    }

    if (type === 'logs') {
      dispatch(setItemUI({ payload: false, type: 'isCalendarHistory' }));
    }
  }, [type]);

  const handleApply = (selectedDate) => {
    const dateFrom = selectedDate.hour(0).minute(0).second(0).millisecond(0);
    setDateSelected(dateFrom.unix());
  };

  const handleCancel = () => {
    setDateSelected(undefined);
  };

  const CalendarHeader = (
    <>
      <Header margin={false}>Changes history logs</Header>
      <HeaderInnerWrapper>
        <SelectorWrapper>
          <SelectorWrapper>
            <DropDownTitle>Month</DropDownTitle>
            <DropDownList
              style={{
                minWidth: 150
              }}
              textField='name'
              dataItemKey='id'
              value={selectedMonth}
              data={MONTHS}
              onChange={(event) => setSelectedMonth(event.target.value)}
            />
          </SelectorWrapper>
          <SelectorWrapper>
            <DropDownTitle>Year</DropDownTitle>
            <DropDownList
              style={{
                minWidth: 150
              }}
              textField='name'
              dataItemKey='id'
              value={selectedYear}
              data={YEARS}
              onChange={(event) => setSelectedYear(event.target.value)}
            />
          </SelectorWrapper>
        </SelectorWrapper>
        <ToggleHistoryView
          status={isCalendarHistory}
          handler={() => push(HISTORY_CONFIGURATION.replace(':type', 'logs'))}
        />
      </HeaderInnerWrapper>
    </>
  );

  const LogsHeader = () => (
      <>
        <Header margin={false}>Changes history logs</Header>
        <HeaderInnerWrapper>
          {devicesConfigHistoryCalendar && (
            <CalendarPicker
              style={{ width: '150px' }}
              selected={dateSelected}
              label='Date:'
              events={groupConfigEntries(devicesConfigHistoryCalendar)}
              handleApply={handleApply}
              handleCancel={handleCancel}
            />
          )}
          <SelectorWrapper>
            <DropDownTitle>Sort by:</DropDownTitle>
            <DropDownList
              style={{
                minWidth: 150
              }}
              textField='name'
              dataItemKey='id'
              value={sortBySelected}
              data={sortBy}
              onChange={(event) => setSortBySelected(event.target.value)}
            />
          </SelectorWrapper>
          <SelectorWrapper>
            <DropDownTitle>Filter by:</DropDownTitle>
            <DropDownList
              style={{
                minWidth: 150
              }}
              textField='name'
              dataItemKey='id'
              value={filterBySelected}
              data={filterBy}
              onChange={(event) => setFilterBySelected(event.target.value)}
            />
          </SelectorWrapper>
          <ToggleHistoryView
            status={isCalendarHistory}
            handler={() => push(HISTORY_CONFIGURATION.replace(':type', 'calendar'))}
          />
        </HeaderInnerWrapper>
      </>
    );

  const CalendarView = (
    <>
      {devicesConfigHistory && (
        <Calendar
          month={dayjs().year(selectedYear.id).month(selectedMonth.id).date(1)}
          eventsGrouped={groupConfigEntries(devicesConfigHistory)}
          events={devicesConfigHistory?.items}
          months={months}
        />
      )}
    </>
  );

  const LogsView = (
    <>
      <Table>
        <tr>
          <th style={{ width: '10%' }}>Author name</th>
          <th style={{ width: '15%' }}>Entry name</th>
          <th style={{ width: '15%' }}>Restore checkpoint</th>
          <th style={{ width: '15%' }}>Note</th>
          <th style={{ width: '15%' }}>Created at</th>
          <th style={{ width: '30%' }}>Actions</th>
        </tr>
        {devicesConfigHistoryLog?.items.map((deviceConfigHistoryEntry) => (
          <ChangeItem changeEntry={deviceConfigHistoryEntry} deviceId={deviceId} />
        ))}
      </Table>
      <Divider margin='20px' />
      <Pagination
        onPageChange={pageChange}
        skip={page.skip}
        take={page.take}
        perPage={pageSize}
        total={total}
      />
    </>
  );

  return (
    <>
      <HeaderWrapper>{isCalendarHistory ? CalendarHeader : <LogsHeader />}</HeaderWrapper>
      <Card>{isCalendarHistory ? CalendarView : LogsView}</Card>
    </>
  );
};

export default DeviceHistoryComponent;
