import Button from 'components/atoms/Button/Button';
import { HeaderM, HeaderXL, TextS, TextXS } from 'components/atoms/Typography/Typography';
import Card from 'components/Card/Card';
import styled, { css } from 'styled-components';
import { DisableFunctionality } from 'utils/disableFunctionalityModifiier';

export const Header1 = styled.h2`
  ${HeaderXL};
  color: ${({ theme }) => theme.colorPrimary};
  margin-bottom: 20px;

  @media (min-width: ${({ theme }) => theme.breakpoints.large}) {
    margin-bottom: 40px;
  }
`;

export const Wrapper = styled.div`
  max-width: 900px;
  margin: 0 auto;
`;

export const SettingsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: ${({ theme }) => theme.dimensions.spacing * 2}px;

  > div > * {
    padding: 20px 0 40px 0;
    border-bottom: 2px solid ${({ theme }) => theme.colorFill};
    &:last-child {
      border: none;
      padding-bottom: 0;
    }
    &:first-child {
      padding-top: 0;
    }
  }
`;

const OptionsCSS = css`
  margin: 15px 10px 0 10px;

  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    margin: 15px 20px 0 20px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.large}) {
    margin: 15px 30px 0 30px;
  }
`;

export const OptionsWrapper = styled.div``;

export const OptionsWrapperHoldOpen = styled.div`
  display: grid;
  gap: 20px;
  grid-template-rows: auto auto;
`;

export const OptionsList = styled.ul`
  display: grid;
  grid-gap: 10px;
`;

export const SoftGrip = styled.div`
  ${({ softGrip }) => (softGrip ? true : DisableFunctionality)}
`;

export const EMGIgnoreWrapper = styled.div`
  ${({ emgSpike }) => (emgSpike ? true : DisableFunctionality)}
`;

export const AutograspWrapper = styled.div`
  ${({ autoGrasp }) => (autoGrasp ? true : DisableFunctionality)}
`;

export const HoldOpenWrapper = styled.div`
  ${({ holdOpen }) => (holdOpen ? true : DisableFunctionality)}
`;

export const SwitchWrapper = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.dimensions.spacing}px;
  align-items: center;
`;

export const TooltipWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.dimensions.spacing * 0.5}px;
`;

export const OptionsSliderDescription = styled.p`
  ${TextXS};
  width: 80%;
  color: ${({ theme }) => theme.typography.colors.subtitle};
  margin: ${({ theme }) => theme.dimensions.spacing}px 0;
`;

export const ProcedureReplyWrapper = styled.div`
  ${TextS};
  table {
    border-collapse: collapse;
    width: 100%;
  }
`;

export const ProcedureTableWrapper = styled.table`
  margin: 10px 0 5px 0;

  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    margin: 15px 0 10px 0;
  }
`;

export const ProcedureTable = styled.tbody`
  border: 2px solid #f2f2f2;
`;

export const ProcedureRow = styled.tr`
  text-align: center;

  td {
    padding: 0.5em;
    border: 2px solid #f2f2f2;
  }
`;

export const CalibrationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
`;

export const ProcedureStatusBox = styled.td`
  color: ${({ status }) => (status === 1 ? 'green' : 'red')};
`;

export const DoubleLabel = styled.div`
  display: flex;
  justify-content: space-between;
`;
