import { useMutation, useQuery } from 'react-query';
import { ModesConfigForUserQueryParams } from 'api/modes/modes.types';
import toast from 'react-hot-toast';
import {
  getModeConfig,
  getModesConfigForDevice,
  getModesConfigForUser,
  updateModeConfig
} from 'api/modes/modes';

export const MODE_CONFIG_QUERY_KEY = 'mode';
export const MODES_CONFIG_QUERY_KEY = 'modes';

export const useModeConfig = ({ deviceId, modeId }: { deviceId: number; modeId: number }) => {
  const { data, isLoading, error, refetch } = useQuery([MODES_CONFIG_QUERY_KEY, deviceId], () =>
    getModeConfig({ deviceId, modeId })
  );

  return {
    result: data || null,
    refetch,
    isLoading,
    error
  };
};

export const useModesConfig = ({
  userId,
  params = {}
}: {
  userId: number;
  params?: ModesConfigForUserQueryParams;
}) => {
  const { data, isLoading, error, refetch } = useQuery(
    [MODES_CONFIG_QUERY_KEY, userId, params],
    () => getModesConfigForUser({ userId, params })
  );

  return {
    result: data || null,
    refetch,
    isLoading,
    error
  };
};

export const useModesConfigForDevice = ({ deviceId }: { deviceId: number }) => {
  const { data, isLoading, error, refetch } = useQuery([MODES_CONFIG_QUERY_KEY, deviceId], () =>
    getModesConfigForDevice({ deviceId })
  );

  return {
    result: data || null,
    refetch,
    isLoading,
    error
  };
};

export const useUpdateModeConfig = () => {
  const { data, mutateAsync, isLoading, isError } = useMutation(updateModeConfig, {
    onSuccess: () => {
      toast.success('Mode updated');
    },
    onError: () => {
      toast.error('Mode could not be updated');
    }
  });

  return {
    mutateAsync,
    data,
    isLoading,
    isError
  };
};
