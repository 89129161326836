import { configureStore } from '@reduxjs/toolkit';
import bluetoothReducer from 'reducers/bluetoothReducer/bluetoothReducer';
import settingsReducer from 'reducers/settingsReducer';
import uiReducer from 'reducers/uiReducer';
import versionsReducer from 'reducers/versionsReducer';
import deviceInfoReducer from 'reducers/deviceInfoReducer';
import authReducer from 'reducers/authReducer';
import liveConfiguratorReducer from 'reducers/liveConfiguratorReducer/liveConfiguratorReducer';
import { FLUSH, PAUSE, PERSIST, persistReducer, PURGE, REGISTER, REHYDRATE } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import meetingReducer from 'reducers/meetingReducer/meetingReducer';
import modesReducer from 'reducers/modesReducer/modesReducer';

const persistSettingsConfig = {
  key: 'settings',
  version: 1,
  storage
};

const persistDeviceInfoConfig = {
  key: 'deviceInfo',
  storage,
  whitelist: ['deviceId']
};

const persistBluetoothConfig = {
  key: 'bluetooth',
  storage,
  whitelist: ['bluetoothMode', 'firstConnection']
  // blacklist: []
};

const persistedSettingsReducer = persistReducer(persistSettingsConfig, settingsReducer);
const persistedBluetoothReducer = persistReducer(persistBluetoothConfig, bluetoothReducer);
const persistedDeviceInfoReducer = persistReducer(persistDeviceInfoConfig, deviceInfoReducer);

const store = configureStore({
  reducer: {
    bluetooth: persistedBluetoothReducer,
    ui: uiReducer,
    versions: versionsReducer,
    settings: persistedSettingsReducer,
    deviceInfo: persistedDeviceInfoReducer,
    auth: authReducer,
    liveConfigurator: liveConfiguratorReducer,
    meeting: meetingReducer,
    modes: modesReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      }
    })
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
