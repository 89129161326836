import { Button } from '@progress/kendo-react-buttons';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { TextArea } from '@progress/kendo-react-inputs';
import { CustomLabel } from 'components/Label/Label';
import React, { useState } from 'react';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { NoteTypes } from 'types';
import Modal from 'utils/Modal/Modal';
import ModalBase from './ModalBase';
import { ModalButtonsWrapper, ModalWrapper } from './ModalStyles';

type AboutProps = {
  handleClose: () => void;
  handleSubmitNote: Function;
  showTypes?: boolean;
};

type Inputs = {
  note: string;
};

const AddNote = ({ handleClose, handleSubmitNote = () => true, showTypes = true }: AboutProps) => {
  const { handleSubmit, control } = useForm<Inputs>();
  const [selectedType, setSelectedType] = useState(NoteTypes.private);
  const onSubmit: SubmitHandler<Inputs> = (data) => {
    handleSubmitNote({ note: data.note, type: selectedType.toLowerCase() });
    handleClose();
  };

  return (
    <Modal>
      <ModalBase handleClick={handleClose} header='Add note'>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalWrapper>
            <div>
              <CustomLabel htmlFor='message'>Message</CustomLabel>
              <Controller
                name='note'
                control={control}
                render={({ field }) => (
                  <TextArea
                    {...field}
                    rows={6}
                    cols={50}
                    id='message'
                    autoSize
                    required
                    placeholder='Write something...'
                  />
                )}
              />
            </div>
            {showTypes && (
              <div>
                <CustomLabel htmlFor='message'>Type</CustomLabel>
                <DropDownList
                  data={[NoteTypes.private, NoteTypes.public]}
                  onChange={(e) => setSelectedType(e.value)}
                  value={selectedType}
                />
              </div>
            )}
            <ModalButtonsWrapper>
              <Button onClick={handleClose} type='button'>
                Cancel
              </Button>
              <Button type='submit' themeColor='secondary'>
                Add note
              </Button>
            </ModalButtonsWrapper>
          </ModalWrapper>
        </form>
      </ModalBase>
    </Modal>
  );
};

export default AddNote;
