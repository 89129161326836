import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const useFingerStrengthStep = () => {
  const { fingerStrength } = useSelector(
    (state: any) => state.bluetooth.config
  );
  const [fingerStep, setFingerStep] = useState(0);
  const [fingerStrengthCurrent, setFingerStrengthCurrent] = useState();
  useEffect(() => {
    if (fingerStrength) {
      const fingerStrengthIndex = fingerStrength[1];
      let newFingerStep;
      if (fingerStrengthIndex > 500) {
        newFingerStep = 5;
      } else if (fingerStrengthIndex > 300) {
        newFingerStep = 4;
      } else if (fingerStrengthIndex > 150) {
        newFingerStep = 3;
      } else if (fingerStrengthIndex > 100) {
        newFingerStep = 2;
      } else if (fingerStrengthIndex > 50) {
        newFingerStep = 1;
      } else {
        newFingerStep = 0;
      }
      setFingerStep(newFingerStep);
    }
  }, [fingerStrength]);

  useEffect(() => {
    let newFingerStrengthCurrent;
    if (fingerStep === 1) {
      newFingerStrengthCurrent = 100;
    } else if (fingerStep === 2) {
      newFingerStrengthCurrent = 150;
    } else if (fingerStep === 3) {
      newFingerStrengthCurrent = 300;
    } else if (fingerStep === 4) {
      newFingerStrengthCurrent = 500;
    } else if (fingerStep === 5) {
      newFingerStrengthCurrent = 700;
    } else {
      newFingerStrengthCurrent = 50;
    }
    setFingerStrengthCurrent(newFingerStrengthCurrent);
  }, [fingerStep]);

  return { fingerStep, fingerStrengthCurrent, setFingerStep, fingerStrength };
};

export default useFingerStrengthStep;
