/* eslint-disable prefer-const */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import VerticalGraphSlider from 'components/molecules/VerticalGraphSlider/VerticalGraphSlider';
import HorizontalGraphSlider from 'components/molecules/HorizontalGraphSlider/HorizontalGraphSlider';
import { Button } from '@progress/kendo-react-buttons';
import { useDispatch, useSelector } from 'react-redux';
import {
  addHistory,
  addHistoryConfig,
  setEmgGains,
  setEmgThresholds
} from 'reducers/bluetoothReducer/bluetoothReducer';
import PositionsAdjuster from 'components/molecules/PositionsAdjuster/PositionsAdjuster';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { debounce } from 'lodash';
import { emgColors } from 'theme/emgColors/emgColors';
import useTelemetry from 'hooks/bluetooth/useTelemetry';
import { useGraph } from 'hooks/useGraph';
import Card from 'components/Card/Card';
import DefaultLayout from 'layouts/DefaultLayout';
import LiveConfigurator from 'components/organisms/LiveConfigurator/LiveConfigurator';
import TelemetryController from 'bluetooth-handler/telemetryController';
import { Header } from 'components/Typography/Header';
import { ChartCombined, ChartProportional, ChartSeparate } from './Charts';
import {
  ActivationSeparate1Wrapper,
  ActivationSeparate2Wrapper,
  ActivationWrapper,
  AdjusterWrapper,
  ButtonsWrapper,
  ContractionSeparate1Wrapper,
  ContractionSeparate2Wrapper,
  ContractionWrapper,
  ControlsWrapper,
  GainsSlidersWrapper,
  graphBreakpoints,
  InnerWrapper,
  Spacer,
  Wrapper
} from './styled';
import useRemoteSession from '../../hooks/useRemoteSession';
import { addLiveHistory } from '../../reducers/liveConfiguratorReducer/liveConfiguratorReducer';
import { checkEmgValidity } from '../../reducers/bluetoothReducer/bluetoothHelpers/bluetoothHelpers';
import { SpeedControlStrategies } from '../../bluetooth/Bluetooth/Control';

const Graph = () => {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();
  const graphContainer = useRef(null);
  const graphContainerSeparate1 = useRef(null);
  const graphContainerSeparate2 = useRef(null);
  const graphContainerProportional = useRef(null);
  const dispatch = useDispatch();
  const { sendConfig } = useRemoteSession();
  const { modeId } = useSelector((state) => state.modes);
  const {
    device,
    config: { emgThresholds, emgGains }
  } = useSelector((state) => state.bluetooth);
  const { enabled: liveConfiguratorEnabled, channel } = useSelector(
    (state) => state.liveConfigurator
  );
  const [emgGainExtension, setEmgGainExtension] = useState(0);
  const [emgGainFlexion, setEmgGainFlexion] = useState(0);
  const [graphMode, setGraphMode] = useState('Combined');
  const [showThreshold, setShowThreshold] = useState([true, true, true, true]);
  useTelemetry();
  const [slidersStateSingle, setSlidersStateSingle] = useState([
    {
      name: 'Time',
      value: 500
    },
    {
      name: 'Interval',
      value: 400
    }
  ]);

  const [slidersStateDual, setSlidersStateDual] = useState([
    {
      name: 'Activation',
      valueFirst: 0,
      valueSecond: 0
    },
    {
      name: 'Contraction',
      valueFirst: 0,
      valueSecond: 0
    }
  ]);
  const [valuesProportional, setValuesProportional] = useState({
    opening: [0, 0, 0, 0, 0],
    closing: [0, 0, 0, 0, 0]
  });
  const { updateData: updateDataCombined } = useGraph(
    graphContainer,
    {
      time: slidersStateSingle[0].value,
      interval: slidersStateSingle[1].value,
      thresholds: [
        {
          values: slidersStateDual[0].valueFirst,
          label: 'CS Open',
          color: emgColors.csOpen
        },
        {
          values: slidersStateDual[0].valueSecond,
          label: 'CS Close',
          color: emgColors.csClose
        },
        {
          values: slidersStateDual[1].valueFirst,
          label: 'SCS Open',
          color: emgColors.activationOpen
        },
        {
          values: slidersStateDual[1].valueSecond,
          label: 'SCS Close',
          color: emgColors.activationClose
        }
      ],
      showThreshold
    },
    slidersStateSingle[0].value,
    [
      slidersStateDual[0].valueFirst,
      slidersStateDual[0].valueSecond,
      slidersStateDual[1].valueFirst,
      slidersStateDual[1].valueSecond,
      showThreshold,
      graphMode
    ],
    [graphMode, slidersStateSingle[0].value]
  );
  const { updateData: updateDataSeparate1 } = useGraph(
    graphContainerSeparate1,
    {
      time: slidersStateSingle[0].value,
      interval: slidersStateSingle[1].value,
      thresholds: [
        {
          values: slidersStateDual[0].valueFirst,
          label: 'CS Open',
          color: emgColors.csOpen
        },
        {
          values: slidersStateDual[1].valueFirst,
          label: 'SCS Open',
          color: emgColors.activationOpen
        }
      ],
      showThreshold: [showThreshold[0], showThreshold[1]]
    },
    slidersStateSingle[0].value,
    [
      slidersStateDual[0].valueFirst,
      slidersStateDual[0].valueSecond,
      slidersStateDual[1].valueFirst,
      slidersStateDual[1].valueSecond,
      showThreshold,
      graphMode
    ],
    [graphMode, slidersStateSingle[0].value]
  );
  const { updateData: updateDataSeparate2 } = useGraph(
    graphContainerSeparate2,
    {
      time: slidersStateSingle[0].value,
      interval: slidersStateSingle[1].value,
      thresholds: [
        {
          values: slidersStateDual[0].valueSecond,
          label: 'CS Close',
          color: emgColors.csClose
        },
        {
          values: slidersStateDual[1].valueSecond,
          label: 'SCS Close',
          color: emgColors.activationClose
        }
      ],
      showThreshold: [showThreshold[2], showThreshold[3]]
    },
    slidersStateSingle[0].value,
    [
      slidersStateDual[0].valueFirst,
      slidersStateDual[0].valueSecond,
      slidersStateDual[1].valueFirst,
      slidersStateDual[1].valueSecond,
      showThreshold,
      graphMode
    ],
    [graphMode, slidersStateSingle[0].value]
  );
  const { updateData: updateDataProportional } = useGraph(
    graphContainerProportional,
    {
      time: slidersStateSingle[0].value,
      interval: slidersStateSingle[1].value,
      thresholds: [
        {
          values: valuesProportional.closing[0],
          label: 'Power Closing',
          color: emgColors.powerClose
        },
        {
          values: valuesProportional.opening[1],
          label: 'Speed 3 Opening',
          color: emgColors.speed3Open
        },
        {
          values: valuesProportional.closing[1],
          label: 'Speed 3 Closing',
          color: emgColors.speed3Close
        },
        {
          values: valuesProportional.opening[2],
          label: 'Speed 2 Opening',
          color: emgColors.speed2Open
        },
        {
          values: valuesProportional.closing[2],
          label: 'Speed 2 Closing',
          color: emgColors.speed2Close
        },
        {
          values: valuesProportional.opening[3],
          label: 'Speed 1 Opening',
          color: emgColors.activationOpen
        },
        {
          values: valuesProportional.closing[3],
          label: 'Speed 1 Closing',
          color: emgColors.activationClose
        },
        {
          values: valuesProportional.closing[4],
          label: 'SCS Closing',
          color: emgColors.csClose
        },
        {
          values: valuesProportional.opening[4],
          label: 'SCS Opening',
          color: emgColors.csOpen
        }
      ]
    },
    slidersStateSingle[0].value,
    [
      valuesProportional.closing[0],
      valuesProportional.closing[1],
      valuesProportional.closing[2],
      valuesProportional.closing[3],
      valuesProportional.closing[4],
      valuesProportional.opening[0],
      valuesProportional.opening[1],
      valuesProportional.opening[2],
      valuesProportional.opening[3],
      valuesProportional.opening[4],
      showThreshold,
      graphMode
    ],
    [graphMode, slidersStateSingle[0].value]
  );

  useEffect(() => {
    let graphInterval;
    if (device.connected) {
      graphInterval = setInterval(parseTelemetryData, 50);
    }

    if (liveConfiguratorEnabled) {
      console.log('liveConfiguratorEnable', liveConfiguratorEnabled);
      console.log('channel', channel);
      graphInterval = setInterval(parseTelemetryData, 50);
    }

    return function clear() {
      if (device.connected) {
        clearInterval(graphInterval);
      }
    };
  }, [device.connected, liveConfiguratorEnabled]);

  const parseTelemetryData = () => {
    const emgExtension = TelemetryController.telemetryData[0];
    const emgFlexion = TelemetryController.telemetryData[1];
    if (emgExtension && emgFlexion) {
      const emgExtensionData = emgExtension.map((point, i) => [i, point]);
      const emgFlexionData = emgFlexion.map((point, i) => [i, point]);

      const emgExtensionOptions = {
        values: emgExtensionData,
        label: 'EMG extension',
        color: emgColors.emgExtension
      };
      const emgFlexionOptions = {
        values: emgFlexionData,
        label: 'EMG flexion',
        color: emgColors.emgFlexion
      };
      if (graphContainer.current) {
        updateDataCombined([emgExtensionOptions, emgFlexionOptions]);
      }
      if (graphContainerSeparate2.current) {
        updateDataSeparate1([emgExtensionOptions]);
        updateDataSeparate2([emgFlexionOptions]);
      }
      if (graphContainerProportional.current) {
        updateDataProportional([emgExtensionOptions, emgFlexionOptions]);
      }
    }
  };

  useEffect(() => {
    if (emgThresholds) {
      setSlidersStateDual([
        {
          name: 'Activation',
          valueFirst: emgThresholds[0],
          valueSecond: emgThresholds[1]
        },
        {
          name: 'Contraction',
          valueFirst: emgThresholds[2],
          valueSecond: emgThresholds[5]
        }
      ]);
      const [
        csOpening,
        csClosing,
        speed1Opening,
        speed2Opening,
        speed3Opening,
        Speed1Closing,
        Speed2Closing,
        Speed3Closing,
        powerOpening,
        powerClosing
      ] = emgThresholds;
      setValuesProportional({
        opening: [powerOpening, speed3Opening, speed2Opening, speed1Opening, csOpening],
        closing: [powerClosing, Speed3Closing, Speed2Closing, Speed1Closing, csClosing]
      });
    }
  }, [emgThresholds]);

  useEffect(() => {
    if (emgGains) {
      setEmgGainExtension(emgGains[0]);
      setEmgGainFlexion(emgGains[1]);
    }
  }, [emgGains]);

  const handleSliderSingleChange = (value, index, { name }) => {
    const newArray = slidersStateSingle.map((slider) => {
      if (slider.name === name) {
        slider.value = value;
      }
      return slider;
    });

    setSlidersStateSingle(newArray);
  };

  const updateHistory = async () => {
    const historyPayload = {
      emgThresholds,
      emgGains,
      timestamp: Date.now()
    };

    await dispatch(addHistoryConfig());
    dispatch(
      addHistory({
        type: 'emgSettingsHistory',
        payload: historyPayload
      })
    );

    dispatch(
      addLiveHistory({
        type: 'emgSettingsHistory',
        payload: historyPayload
      })
    );
  };

  const handleSliderDualChange = (value, index, { name, sliderIndex }) => {
    const newArray = slidersStateDual.map((slider) => {
      if (slider.name === name) {
        if (sliderIndex === 0) {
          slider.valueFirst = value;
        }
        if (sliderIndex === 1) {
          slider.valueSecond = value;
        }
      }
      return slider;
    });

    setSlidersStateDual(newArray);
  };

  const handleSliderDualOnAfterChange = () => {
    const CSOpen = slidersStateDual[0].valueFirst;
    const CSClose = slidersStateDual[0].valueSecond;
    const SCSOpen = slidersStateDual[1].valueFirst;
    const SCSClose = slidersStateDual[1].valueSecond;
    const {
      opening: [powerOpening, speed3Opening, speed2Opening],
      closing: [powerClosing, speed3Closing, speed2Closing]
    } = valuesProportional;
    const newEMG = [
      CSOpen,
      CSClose,
      SCSOpen,
      speed2Opening,
      speed3Opening,
      SCSClose,
      speed2Closing,
      speed3Closing,
      powerOpening,
      powerClosing
    ];
    dispatch(setEmgThresholds({ payload: newEMG, modeId }));
  };

  const setEmgGainsStore = () => {
    dispatch(setEmgGains({ payload: [emgGainExtension, emgGainFlexion], modeId }));
  };

  const handleSliderChangeProportional = (
    value,
    index,
    { index: fingerIndex, sliderType, min, max }
  ) => {
    const sanitizeInput = () => {
      let sanitizedInputValue = value;
      if (typeof sanitizedInputValue === 'string') {
        sanitizedInputValue = parseInt(value, 10);
        if (Number.isNaN(sanitizedInputValue)) sanitizedInputValue = min;
      }
      if (value > max) {
        sanitizedInputValue = max;
      }
      if (value < min) {
        sanitizedInputValue = min;
      }
      return sanitizedInputValue;
    };

    const valueSanitized = sanitizeInput();
    let newOpening = valuesProportional.opening;
    let newClosing = valuesProportional.closing;
    if (sliderType === 'primary') {
      newOpening = newOpening.map((v, i) => {
        if (i === fingerIndex) v = valueSanitized;
        return v;
      });
    } else {
      newClosing = newClosing.map((v, i) => {
        if (i === fingerIndex) v = valueSanitized;
        return v;
      });
    }
    const newGripValues = {
      opening: newOpening,
      closing: newClosing
    };
    setValuesProportional(newGripValues);
  };

  const handleSliderChangeProportionalThrottled = debounce(handleSliderChangeProportional, 10);

  const handleSliderOnAfterChangeProportional = () => {
    let {
      opening: [powerOpening, speed3Opening, speed2Opening, speed1Opening, csOpening],
      closing: [powerClosing, speed3Closing, speed2Closing, speed1Closing, csClosing]
    } = valuesProportional;
    const newEMG = [
      csOpening,
      csClosing,
      speed1Opening,
      speed2Opening,
      speed3Opening,
      speed1Closing,
      speed2Closing,
      speed3Closing,
      powerOpening,
      powerClosing
    ];
    const newValidatedEMG = checkEmgValidity(SpeedControlStrategies.kProportional, newEMG);
    dispatch(setEmgThresholds({ payload: newValidatedEMG, modeId }));
  };

  const handleInputChange = (value, index, { index: fingerSlider, sliderType, min, max }) => {
    handleSliderChangeProportional(value, index, {
      index: fingerSlider,
      sliderType,
      min,
      max
    });
  };

  const handleCheckbox = (checkboxIndex) => {
    const newShowThreshold = showThreshold.map((item, index) => {
      if (index === checkboxIndex) {
        item = !item;
      }
      return item;
    });
    setShowThreshold(newShowThreshold);
  };

  useEffect(() => {
    if (emgThresholds && emgGains) {
      sendConfig();
    }
  }, [JSON.stringify(emgThresholds), JSON.stringify(emgGains), modeId]);

  const HorizontalSliders = useMemo(
    () => (
      <>
        <HorizontalGraphSlider
          data={{ name: 'Time' }}
          handleChange={handleSliderSingleChange}
          label={`Time: last ${Math.round(slidersStateSingle[0].value / 20)} seconds`}
          value={slidersStateSingle[0].value}
          limits={{ min: 200, max: 500 }}
        />
      </>
    ),
    [slidersStateSingle[0].value]
  );

  const GainsSliders = useMemo(
    () => (
      <GainsSlidersWrapper emgGains={emgGains}>
        <HorizontalGraphSlider
          data={{ name: 'Extension' }}
          handleChange={(value) => setEmgGainExtension(value)}
          handleOnAfterChange={setEmgGainsStore}
          handleOnBeforeChange={updateHistory}
          label={`Open signal gain: ${emgGainExtension}%`}
          value={emgGainExtension}
          limits={{ min: 80, max: 120 }}
          disableSliders={!emgGains}
        />
        <HorizontalGraphSlider
          data={{ name: 'Flexion' }}
          handleChange={(value) => setEmgGainFlexion(value)}
          handleOnAfterChange={setEmgGainsStore}
          handleOnBeforeChange={updateHistory}
          label={`Close signal gain: ${emgGainFlexion}%`}
          value={emgGainFlexion}
          limits={{ min: 80, max: 120 }}
          disableSliders={!emgGains}
        />
      </GainsSlidersWrapper>
    ),
    [emgGainExtension, emgGainFlexion, emgGains]
  );

  return (
    <DefaultLayout>
      <LiveConfigurator>
        <InnerWrapper>
          <Header margin={false}>{t('views.emg_settings')}</Header>
          <ButtonsWrapper>
            <Button
              themeColor={graphMode === 'Combined' ? 'primary' : 'base'}
              onClick={() => setGraphMode('Combined')}>
              {t('emg.combined')}
            </Button>
            <Button
              themeColor={graphMode === 'Separate' ? 'primary' : 'base'}
              onClick={() => setGraphMode('Separate')}>
              {t('emg.separate')}
            </Button>
            <Button
              themeColor={graphMode === 'Proportional' ? 'primary' : 'base'}
              onClick={() => setGraphMode('Proportional')}>
              {t('emg.proportional')}
            </Button>
          </ButtonsWrapper>
        </InnerWrapper>
        <Card>
          <Wrapper graphMode={graphMode}>
            {graphMode === 'Combined' && <ChartCombined graphContainer={graphContainer} />}
            {graphMode === 'Separate' && (
              <ChartSeparate
                graphContainer1={graphContainerSeparate1}
                graphContainer2={graphContainerSeparate2}
              />
            )}
            {graphMode === 'Proportional' && (
              <ChartProportional graphContainer={graphContainerProportional} />
            )}
            <ControlsWrapper data-tour-graph='time'>
              {HorizontalSliders}
              {GainsSliders}
            </ControlsWrapper>
            {graphMode === 'Combined' && (
              <>
                <ActivationWrapper>
                  {' '}
                  <VerticalGraphSlider
                    data={{ name: 'Activation' }}
                    handleChange={handleSliderDualChange}
                    handleOnAfterChange={handleSliderDualOnAfterChange}
                    handleOnBeforeChange={updateHistory}
                    label='CS'
                    tooltipDirection='left'
                    sliders={[
                      {
                        value: slidersStateDual[0].valueFirst,
                        label: 'Open',
                        index: 0
                      },
                      {
                        value: slidersStateDual[0].valueSecond,
                        label: 'Close',
                        index: 1
                      }
                    ]}
                    handleCheckbox={handleCheckbox}
                    checked={[showThreshold[0], showThreshold[1]]}
                    checkboxIndexes={[0, 1]}
                    trackClasses={['cs-open-slider', 'cs-close-slider']}
                  />
                </ActivationWrapper>
                <ContractionWrapper>
                  <VerticalGraphSlider
                    data={{ name: 'Contraction' }}
                    handleChange={handleSliderDualChange}
                    handleOnAfterChange={handleSliderDualOnAfterChange}
                    handleOnBeforeChange={updateHistory}
                    label='Activation'
                    tooltipDirection='right'
                    sliders={[
                      {
                        value: slidersStateDual[1].valueFirst,
                        label: 'Open',
                        index: 0
                      },
                      {
                        value: slidersStateDual[1].valueSecond,
                        label: 'Close',
                        index: 1
                      }
                    ]}
                    handleCheckbox={handleCheckbox}
                    checked={[showThreshold[2], showThreshold[3]]}
                    checkboxIndexes={[2, 3]}
                    trackClasses={['activation-open-slider', 'activation-close-slider']}
                  />
                </ContractionWrapper>
              </>
            )}
            {graphMode === 'Separate' && (
              <>
                <ActivationSeparate1Wrapper>
                  <VerticalGraphSlider
                    data={{ name: 'Activation' }}
                    handleChange={handleSliderDualChange}
                    handleOnAfterChange={handleSliderDualOnAfterChange}
                    handleOnBeforeChange={updateHistory}
                    label='CS'
                    tooltipDirection='left'
                    sliders={[
                      {
                        value: slidersStateDual[0].valueFirst,
                        label: 'Open',
                        index: 0
                      }
                    ]}
                    handleCheckbox={handleCheckbox}
                    checked={[showThreshold[0]]}
                    checkboxIndexes={[0]}
                    trackClasses={['cs-open-slider']}
                  />
                </ActivationSeparate1Wrapper>
                <ActivationSeparate2Wrapper>
                  <VerticalGraphSlider
                    data={{ name: 'Activation' }}
                    handleChange={handleSliderDualChange}
                    handleOnAfterChange={handleSliderDualOnAfterChange}
                    handleOnBeforeChange={updateHistory}
                    label={graphBreakpoints.hdSmallNum > width ? 'CS' : ''}
                    tooltipDirection='left'
                    sliders={[
                      {
                        value: slidersStateDual[0].valueSecond,
                        label: 'Close',
                        index: 1
                      }
                    ]}
                    handleCheckbox={handleCheckbox}
                    checked={[showThreshold[2]]}
                    checkboxIndexes={[2]}
                    trackClasses={['cs-close-slider']}
                  />
                </ActivationSeparate2Wrapper>
                <ContractionSeparate1Wrapper>
                  <VerticalGraphSlider
                    data={{ name: 'Contraction' }}
                    handleChange={handleSliderDualChange}
                    handleOnAfterChange={handleSliderDualOnAfterChange}
                    handleOnBeforeChange={updateHistory}
                    label='Activation'
                    tooltipDirection='right'
                    sliders={[
                      {
                        value: slidersStateDual[1].valueFirst,
                        label: 'Open',
                        index: 0
                      }
                    ]}
                    handleCheckbox={handleCheckbox}
                    checked={[showThreshold[1]]}
                    checkboxIndexes={[1]}
                    trackClasses={['activation-open-slider']}
                  />
                </ContractionSeparate1Wrapper>
                <ContractionSeparate2Wrapper>
                  <VerticalGraphSlider
                    data={{ name: 'Contraction' }}
                    handleChange={handleSliderDualChange}
                    handleOnAfterChange={handleSliderDualOnAfterChange}
                    handleOnBeforeChange={updateHistory}
                    label={graphBreakpoints.hdSmallNum > width ? 'Activation' : ''}
                    tooltipDirection='right'
                    sliders={[
                      {
                        value: slidersStateDual[1].valueSecond,
                        label: 'Close',
                        index: 1
                      }
                    ]}
                    handleCheckbox={handleCheckbox}
                    checked={[showThreshold[3]]}
                    checkboxIndexes={[3]}
                    trackClasses={['activation-close-slider']}
                  />
                </ContractionSeparate2Wrapper>
              </>
            )}
            {graphMode === 'Proportional' && (
              <>
                <AdjusterWrapper>
                  <PositionsAdjuster
                    values={{
                      columnPrimary: valuesProportional.opening,
                      columnSecondary: valuesProportional.closing
                    }}
                    handleSliderChange={handleSliderChangeProportionalThrottled}
                    handleOnAfterChange={handleSliderOnAfterChangeProportional}
                    handleOnAfterChangeInput={handleSliderOnAfterChangeProportional}
                    handleOnBeforeChange={updateHistory}
                    handleInputChange={handleInputChange}
                    columns={['Opening', 'Closing']}
                    rows={['Full power', 'Speed 3', 'Speed 2', 'Speed 1', 'CS']}
                    hideButton
                    graphVersion
                    limits={[
                      { min: 0, max: 100 },
                      { min: 0, max: 100 },
                      { min: 0, max: 100 },
                      { min: 0, max: 100 },
                      { min: 0, max: 100 }
                    ]}
                    trackClasses={[
                      ['', 'power-slider'],
                      ['speed3-open-slider', 'speed3-close-slider'],
                      ['speed2-open-slider', 'speed2-close-slider'],
                      [
                        'activation-open-proportional-slider',
                        'activation-close-proportional-slider'
                      ],
                      ['cs-open-slider', 'cs-close-slider']
                    ]}
                  />
                </AdjusterWrapper>
              </>
            )}
            <Spacer />
          </Wrapper>
        </Card>
      </LiveConfigurator>
    </DefaultLayout>
  );
};

export default Graph;
