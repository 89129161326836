import React, { useEffect } from 'react';
import ServicePositions from 'components/molecules/ServicePositions/ServicePositions';
import DefaultLayout from 'layouts/DefaultLayout';
import { useDispatch, useSelector } from 'react-redux';
import { setItemUI } from '../reducers/uiReducer';
import { Header } from '../components/Typography/Header';

const ServiceMenu = () => {
  const dispatch = useDispatch();
  const { isAuthorized } = useSelector((state) => state.ui);
  useEffect(() => {
    if (!isAuthorized) {
      dispatch(setItemUI({ type: 'shownGlobalModal', payload: 'authorize' }));
    }
  }, []);

  if (!isAuthorized) return null;
  return (
    <DefaultLayout>
      <Header>Service menu</Header>
      <ServicePositions />
    </DefaultLayout>
  );
};

export default ServiceMenu;
