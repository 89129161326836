import React from 'react';
import styled from 'styled-components';
import { TypesConfig } from 'types';
import Accordion from '../Accordion/Accordion';

const OptionsList = styled.ul`
  display: grid;
  grid-gap: 16px;
`;

const StatusWrapper = styled.span`
  margin-right: 12px;
`;

const ValueWrapper = styled.span`
  background-color: ${({ theme }) => theme.colorFill};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-size: ${({ theme }) => theme.typography.fontSizes.xs};
  border-radius: 100px;
  padding: 4px 8px;
`;

export const StatusIndicator = styled.span`
  width: 12px;
  height: 12px;
  background-color: ${({ theme, status }) =>
    status === 0 ? theme.typography.colors.red : theme.typography.colors.green};
  border-radius: 50%;
  display: inline-block;
  margin-right: 6px;
`;

export const StatusIndicatorBadge = styled.span`
  padding: 2px 6px;
  border-radius: 100px;
  border: solid 1px
    ${({ theme, status }) => (status === 0 ? theme.palette.error2 : theme.palette.success2)};
  background-color: ${({ theme, status }) =>
    status === 0 ? theme.palette.error2 : theme.palette.success2};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-size: ${({ theme }) => theme.typography.fontSizes.xs};
  color: #f8f9fa;
`;

const ConfigItem = ({ header, items, ...props }: any) => (
  <Accordion header={header} {...props}>
    <OptionsList>
      {items.map((item) =>
        item.type === TypesConfig.bool ? (
          <span>
            <StatusWrapper>{item.header}:</StatusWrapper>
            <StatusIndicatorBadge status={item.value}>{item.content}</StatusIndicatorBadge>
          </span>
        ) : (
          <span>
            <StatusWrapper>{item.header}:</StatusWrapper>
            <ValueWrapper>{item.content}</ValueWrapper>
          </span>
        )
      )}
    </OptionsList>
  </Accordion>
);

export default ConfigItem;
