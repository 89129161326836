import { useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";
import useRefreshToken from "hooks/api/useRefreshToken";

const TokenRefresh = ({ location }: RouteComponentProps) => {
  const { pathname } = location;
  const { delayedRefresh } = useRefreshToken();
  useEffect(() => {
    // delayedRefresh();
    setTimeout(delayedRefresh);
  }, [delayedRefresh, pathname]);
  return null;
};

export default TokenRefresh;
