import React from 'react';
import Modal from 'utils/Modal/Modal';
import { Paragraph } from 'components/Typography/Paragraph';
import { Button } from '@progress/kendo-react-buttons';
import ModalBase from './ModalBase';
import useRemoteSession from '../../../hooks/useRemoteSession';
import { ModalButtonsWrapper, ModalWrapper } from './ModalStyles';

type ModalProps = {
  handleClose: () => void;
};

const ResetAllChangesModal = ({ handleClose }: ModalProps) => {
  const { consumeAllSessionChanges } = useRemoteSession();
  const accepted = async () => {
    consumeAllSessionChanges();
    handleClose();
  };

  return (
    <Modal>
      <ModalBase handleClick={handleClose} header='Reset changes'>
        <ModalWrapper>
          <Paragraph>Are you sure want to reset all changes? This cannot be undone.</Paragraph>
          <ModalButtonsWrapper>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={accepted} themeColor='secondary'>
              Reset all
            </Button>
          </ModalButtonsWrapper>
        </ModalWrapper>
      </ModalBase>
    </Modal>
  );
};

export default ResetAllChangesModal;
