/* eslint-disable no-underscore-dangle */
/* eslint-disable prefer-destructuring */
import {
  ControlModes,
  GripSwitchingModes,
  InputSites,
  SpeedControlStrategies
} from 'bluetooth/Bluetooth/Control';
import { Fingers } from 'bluetooth/Bluetooth/Defines';
import { Grips } from 'bluetooth/Bluetooth/Grips';
import toast from 'react-hot-toast';
import { PayloadAction } from '@reduxjs/toolkit';
import { defaultConfig } from 'consts/deviceConfig/deviceConfig';
import {
  emgThresholdsEntry,
  gripPairsConfigEntry,
  gripSequentialConfigEntry,
  emgGainsEntry
} from 'consts/deviceConfig/deviceConfig.types';
import { checkEmgValidity, findModeIndex, loadConfig } from './bluetoothHelpers';

export const resetGripsConfigurationReducer = (state) => {
  state.config.gripsPositions = defaultConfig.gripsPositions;
  toast.success('Default grips configuration restored');
};

export const resetGripConfigurationReducer = (state, action: PayloadAction<{ grip: Grips }>) => {
  state.config.gripsPositions[action.payload.grip] =
    defaultConfig.gripsPositions[action.payload.grip];
};

interface importConfigReducerPayload {
  config: any;
  isModeLoad?: boolean;
}

export const importConfigReducer = (state, action: PayloadAction<importConfigReducerPayload>) => {
  loadConfig(state, action.payload.config);
  state.unsaved.unsavedChanges = true;
};

export const setControlConfigReducer = (
  state,
  action: PayloadAction<{ payload: any; modeSelected: any }>
) => {
  const newControlConfig = action.payload.payload;
  const oldInputSite = state.config.inputSite[0];

  // Without input device
  let newInputSite = newControlConfig[0];
  const newControlMode = newControlConfig[1];
  const newSpeedControlStrategy = newControlConfig[2];
  let newGripSwitchingMode = newControlConfig[3];

  if (newInputSite === InputSites.kSingleElectrode) {
    newGripSwitchingMode = GripSwitchingModes.kSingleGripSwitching;
  } else if (oldInputSite === InputSites.kSingleElectrode) {
    newGripSwitchingMode = GripSwitchingModes.kCoContraction;
  }
  if (newGripSwitchingMode === GripSwitchingModes.kSingleGripSwitching) {
    newInputSite = InputSites.kSingleElectrode;
  }
  const newEmg = checkEmgValidity(newControlConfig[3], state.config.emgThresholds);

  const modeSelected = action.payload.modeSelected;
  const currentModeIndex = findModeIndex(state, modeSelected);

  state.modes.modesConfigs[currentModeIndex].config.controlMode = [newControlMode];
  state.modes.modesConfigs[currentModeIndex].config.speedControlStrategy = [
    newSpeedControlStrategy
  ];
  state.modes.modesConfigs[currentModeIndex].config.gripSwitchingMode = [newGripSwitchingMode];
  state.config.controlMode = [newControlMode];
  state.config.speedControlStrategy = [newSpeedControlStrategy];
  state.config.gripSwitchingMode = [newGripSwitchingMode];

  state.commonConfig.inputSite = [newInputSite];
  state.config.inputSite = [newInputSite];

  state.modes.modesConfigs[currentModeIndex].config.emgThresholds = newEmg;
  state.config.emgThresholds = newEmg;
};

export const consumeHistoryProsthesisSettingsReducer = (state) => {
  const providerLength = state.prosthesisSettingsHistory.length;
  if (providerLength > 0) {
    const providerCopy = state.prosthesisSettingsHistory;
    const providerCopyLastElement = providerCopy.pop();
    if (providerCopyLastElement) {
      const [
        newInputSite,
        newControlMode,
        newSpeedControlStrategy,
        newGripSwitchingMode,
        newEMGSpike,
        newAutoGrasp,
        newHoldOpen,
        newSoftGrip,
        newPulseTimings,
        newCoContractionTimings
      ] = providerCopyLastElement;
      state.config.inputSite = newInputSite;
      state.config.controlMode = newControlMode;
      state.config.speedControlStrategy = newSpeedControlStrategy;
      state.config.gripSwitchingMode = newGripSwitchingMode;
      state.config.emgSpike = newEMGSpike;
      state.config.autoGrasp = newAutoGrasp;
      state.config.holdOpen = newHoldOpen;
      state.config.softGrip = newSoftGrip;
      state.config.pulseTimings = newPulseTimings;
      state.config.coContractionTimings = newCoContractionTimings;
    }
    state.prosthesisSettingsHistory = [];
  }
};

export const consumeHistoryEmgSettingsReducer = (state) => {
  const providerLength = state.emgSettingsHistory.length;
  if (providerLength > 0) {
    const providerCopy = state.emgSettingsHistory;
    const providerCopyLastElement = providerCopy.pop();
    if (providerCopyLastElement) {
      const { emgThresholds, emgGains } = providerCopyLastElement;
      state.config.emgThresholds = emgThresholds;
      state.config.emgGains = emgGains;
    }
  }
  state.emgSettingsHistory = [];
};

export const consumeHistoryChooseGripsReducer = (state) => {
  const providerLength = state.chooseGripsHistory.length;
  if (providerLength > 0) {
    const providerCopy = state.chooseGripsHistory;
    const providerCopyLastElement = providerCopy.pop();
    if (providerCopyLastElement) {
      const { gripPairsConfig, gripSequentialConfig } = providerCopyLastElement;
      state.config.gripPairsConfig = gripPairsConfig;
      state.config.gripSequentialConfig = gripSequentialConfig;
    }
    state.chooseGripsHistory = [];
  }
};

export const consumeHistoryGripsConfigurationReducer = (state) => {
  const providerLength = state.gripsConfiguration.gripsConfigurationHistory.length;
  if (providerLength > 0) {
    const providerCopy = state.gripsConfiguration.gripsConfigurationHistory;
    const providerCopyLastElement = providerCopy.pop();
    if (providerCopyLastElement) {
      const { selectedGrip, values } = providerCopyLastElement;
      if (selectedGrip !== Grips.kGripTypeUnknown) {
        state.gripsConfiguration.currentGrip = selectedGrip;
        state.config.gripsPositions = values;
      }
    }
    state.gripsConfiguration.gripsConfigurationHistory = [];
    state.gripsConfigurationHistory = [];
  }
};

export const toggleComplexItemReducer = (
  state,
  action: PayloadAction<{ type: string; item: string }>
) => {
  const newState = {
    [action.payload.item]: !state[action.payload.type][action.payload.item]
  };
  state[action.payload.type] = {
    ...state[action.payload.type],
    ...newState
  };
};

export const addHistoryReducer = (state, action: PayloadAction<{ type: string; payload: any }>) => {
  const providerCopy = state[action.payload.type];
  providerCopy.push(action.payload.payload);
  state[action.payload.type] = providerCopy;
};

export const addHistoryConfigReducer = (state) => {
  state.configHistory.push({
    config: state.config,
    id: state.configHistory.length,
    timestamp: Date.now()
  });
};

export const addHistoryComplexReducer = (
  state,
  action: PayloadAction<{ type: string; nestedType: string; payload: any }>
) => {
  const providerCopy = state[action.payload.type][action.payload.nestedType];
  providerCopy.push(action.payload.payload);
  state[action.payload.type][action.payload.nestedType] = providerCopy;
};

export const consumeConfigHistoryReducer = (state, action: PayloadAction<{ id }>) => {
  if (state.configHistory.length > 0) {
    const searchFunc = (entry) => entry.id === action.payload.id;
    const configHistoryEntry = state.configHistory.find(searchFunc);
    const configHistoryEntryIndex = state.configHistory.findIndex(searchFunc);
    state.config = configHistoryEntry.config;
    state.configHistory.splice(configHistoryEntryIndex, 1);
  }
};

export const setModeSelectedReducer = (state, action) => {
  state.modes.modeSelected = action.payload;
};

export const clearConfigHistoryReducer = (state) => {
  state.configHistory = [];
};

export const consumeHistoryReducer = (state, action) => {
  const providerLength = state[action.payload.provider].length;
  if (providerLength > 0) {
    const providerCopy = state[action.payload.provider];
    const providerCopyLastElement = providerCopy.pop();
    state[action.payload.consumer] = [
      ...state[action.payload.consumer],
      ...providerCopyLastElement
    ];
    state[action.payload.provider] = state[action.payload.provider].slice(0, providerLength - 1);
  }
};

export const setSessionRestorePointReducer = (state: any, action) => {
  console.log('state', state, action);
};

export const setUnsavedReducer = (
  state: any,
  action: PayloadAction<{ type: string; payload: { unsavedChanges: boolean } }>
) => {
  state[action.payload.type] = {
    ...state[action.payload.type],
    ...action.payload.payload
  };
};

export const synchronizeDeviceConfigReducer = (state: any) => {
  state.commonConfig = state.trackedCommonConfig;

  const modes = Object.keys(state.modes.modesConfigs);

  for (let index = 0; index < modes.length; index += 1) {
    const modeConfig = state.modes.modesConfig[index];
    modeConfig.config = modeConfig.trackedConfig;
  }
};

export const setModesStateReducer = (state: any, action: any) => {
  const currentModeIndex = findModeIndex(state, action.payload.modeId);
  const configPayload = action.payload;
  state.commonConfig = configPayload.common;
  state.trackedCommonConfig = configPayload.common;
  loadConfig(state, configPayload.common);

  delete configPayload.common;

  Object.keys(configPayload).forEach((key) => {
    const mode = configPayload[key];
    const _modeId = Number(key.replace('mode_', ''));
    const currentModeIndex = findModeIndex(state, _modeId);
    state.modes.modesConfigs[currentModeIndex].config = mode;
    state.modes.modesConfigsTracked[currentModeIndex].config = mode;
  });

  loadConfig(state, state.modes.modesConfigs[currentModeIndex].config);
};

export const setGripsSequentialReducer = (
  state: any,
  action: PayloadAction<{ payload: gripSequentialConfigEntry; modeId: number }>
) => {
  const currentModeIndex = findModeIndex(state, action.payload.modeId);
  const configuration = action.payload.payload;
  state.modes.modesConfigs[currentModeIndex].config.gripSequentialConfig = configuration;
  state.config.gripSequentialConfig = configuration;
};

export const setGripsPairsReducer = (
  state: any,
  action: PayloadAction<{ payload: gripPairsConfigEntry; modeId: number }>
) => {
  const currentModeIndex = findModeIndex(state, action.payload.modeId);
  const configuration = action.payload.payload;
  state.modes.modesConfigs[currentModeIndex].config.gripPairsConfig = configuration;
  state.config.gripPairsConfig = configuration;
};

export const setGripsPositionsReducer = (state: any, action: PayloadAction<any>) => {
  state.commonConfig.gripsPositions = action.payload;
  state.config.gripsPositions = action.payload;
};

export const setEmgThresholdsReducer = (
  state: any,
  action: PayloadAction<{ payload: emgThresholdsEntry; modeId: number }>
) => {
  const currentModeIndex = findModeIndex(state, action.payload.modeId);
  const configuration = action.payload.payload;
  state.modes.modesConfigs[currentModeIndex].config.emgThresholds = configuration;
  state.config.emgThresholds = configuration;
};

export const setEmgGainsReducer = (
  state: any,
  action: PayloadAction<{ payload: emgGainsEntry; modeId: number }>
) => {
  const currentModeIndex = findModeIndex(state, action.payload.modeId);
  const configuration = action.payload.payload;
  state.modes.modesConfigs[currentModeIndex].config.emgGains = configuration;
  state.config.emgGains = configuration;
};

export const setInputSitesReducer = (state: any, action: PayloadAction<InputSites>) => {
  state.commonConfig.inputSite = [action.payload];
  state.config.inputSite = [action.payload];
};

export const setControlModesReducer = (
  state: any,
  action: PayloadAction<{ payload: ControlModes; modeId: number }>
) => {
  const currentModeIndex = findModeIndex(state, action.payload.modeId);
  const configuration = action.payload.payload;
  state.modes.modesConfigs[currentModeIndex].config.controlMode = [configuration];
  state.config.controlMode = [configuration];
};

export const setSpeedControlStrategyReducer = (
  state: any,
  action: PayloadAction<{ payload: SpeedControlStrategies; modeId: number }>
) => {
  const currentModeIndex = findModeIndex(state, action.payload.modeId);
  const configuration = action.payload.payload;
  state.modes.modesConfigs[currentModeIndex].config.speedControlStrategy = [configuration];
  state.config.speedControlStrategy = [configuration];
};

export const setGripSwitchingModesReducer = (
  state: any,
  action: PayloadAction<{ payload: GripSwitchingModes; modeId: number }>
) => {
  const currentModeIndex = findModeIndex(state, action.payload.modeId);
  const configuration = action.payload.payload;
  state.modes.modesConfigs[currentModeIndex].config.gripSwitchingMode = [configuration];
  state.config.gripSwitchingMode = [configuration];
};

export const setSoftGripReducer = (
  state: any,
  action: PayloadAction<{ payload: 0 | 1; modeId: number }>
) => {
  const currentModeIndex = findModeIndex(state, action.payload.modeId);
  const configuration = action.payload.payload;
  state.modes.modesConfigs[currentModeIndex].config.softGrip = [configuration];
  state.config.softGrip = [configuration];
};

export const setFingerStrengthReducer = (
  state: any,
  action: PayloadAction<{ finger: Fingers; strength: number }>
) => {
  state.commonConfig.fingerStrength = [action.payload.finger, action.payload.strength];
  state.config.fingerStrength = [action.payload.finger, action.payload.strength];
};

export const setEmgSpikeReducer = (
  state: any,
  action: PayloadAction<{ status: 0 | 1; time: number; modeId: number }>
) => {
  const currentModeIndex = findModeIndex(state, action.payload.modeId);
  state.modes.modesConfigs[currentModeIndex].config.emgSpike = [
    action.payload.status,
    action.payload.time
  ];
  state.config.emgSpike = [action.payload.status, action.payload.time];
};

export const setAutograspReducer = (
  state: any,
  action: PayloadAction<{ status: 0 | 1; current: number; modeId: number }>
) => {
  const currentModeIndex = findModeIndex(state, action.payload.modeId);
  state.modes.modesConfigs[currentModeIndex].config.autoGrasp = [
    action.payload.status,
    action.payload.current
  ];
  state.config.autoGrasp = [action.payload.status, action.payload.current];
};

export const setHoldOpenReducer = (
  state: any,
  action: PayloadAction<{ short: number; long: number; modeId: number }>
) => {
  const currentModeIndex = findModeIndex(state, action.payload.modeId);
  const holdOpenPayload = [action.payload.short, action.payload.long];
  state.modes.modesConfigs[currentModeIndex].config.holdOpen = holdOpenPayload;
  state.config.holdOpen = holdOpenPayload;
};

export const setPulseTimingsReducer = (
  state: any,
  action: PayloadAction<{
    minimumPulse: number;
    maximumPulse: number;
    minimumBetweenPulses: number;
    maximumBetweenPulses: number;
    modeId: number;
  }>
) => {
  const currentModeIndex = findModeIndex(state, action.payload.modeId);
  const pulseTimingsPayload = [
    action.payload.minimumPulse,
    action.payload.maximumPulse,
    action.payload.minimumBetweenPulses,
    action.payload.maximumBetweenPulses
  ];
  state.modes.modesConfigs[currentModeIndex].config.pulseTimings = pulseTimingsPayload;
  state.config.pulseTimings = pulseTimingsPayload;
};

export const setCoContractionTimingsReducer = (
  state: any,
  action: PayloadAction<{
    time: number;
    offset: number;
    modeId: number;
  }>
) => {
  const currentModeIndex = findModeIndex(state, action.payload.modeId);
  const coContractionTimingsPayload = [action.payload.time, action.payload.offset];
  state.modes.modesConfigs[currentModeIndex].config.coContractionTimings =
    coContractionTimingsPayload;
  state.config.coContractionTimings = coContractionTimingsPayload;
};

interface configType {
  common: any;
  modes: any[];
}

export const setSynchronizeConfigsReducer = (state: any, action: PayloadAction<configType>) => {
  const currentModes = state.modes.modesConfigs;
  const apiModes = action.payload.modes;
  for (let index = 0; index < apiModes.length; index += 1) {
    const apiMode = apiModes[index];
    const modeIndex = currentModes.findIndex((mode) => apiMode.slot === mode.slot);
    state.modes.modesConfigs[modeIndex].config = apiMode.config;
  }
};
