import { Grips } from 'bluetooth/Bluetooth/Grips';
import { defaultConfig } from 'consts/deviceConfig/deviceConfig';

type SliceState = {
  device: {};
  bluetoothMode: 'classic' | 'ble';
  config: {
    gripPairsConfig: number[];
    inputSite: number[];
    controlMode: number[];
    speedControlStrategy: number[];
    gripSwitchingMode: number[];
    gripSequentialConfig: number[];
    emgThresholds: number[] | null | false;
    gripsPositions: {};
    fingerStrength: number[] | false;
    autoGrasp: number[] | false;
    emgSpike: number[] | false;
    holdOpen: number[] | false;
    softGrip: number[] | false;
    emgGains: number[] | false;
    pulseTimings: number[] | false;
    coContractionTimings: number[] | false;
  };
  modes: {
    modesConfigs: any;
    modesConfigsTracked: any;
  };
  trackedConfig:
    | {
        gripPairsConfig: number[];
        inputSite: number;
        controlMode: number;
        speedControlStrategy: number;
        gripSwitchingMode: number;
        gripSequentialConfig: number[];
        emgThresholds: number[] | null | false;
        gripsPositions: {};
        fingerStrength: number[] | false;
        autoGrasp: number[] | false;
        emgSpike: number[] | false;
        holdOpen: number[] | false;
        softGrip: number[] | false;
        emgGains: number[] | false;
        pulseTimings: number[] | false;
        coContractionTimings: number[] | false;
      }
    | {};
  commonConfig:
    | {
        inputSite: number;
        gripsPositions: {};
        fingerStrength: number[] | false;
      }
    | {};
  trackedCommonConfig:
    | {
        inputSite: number;
        gripsPositions: {};
        fingerStrength: number[] | false;
      }
    | {};
  chooseGripsHistory: number[][];
  gripsConfiguration: {
    currentGrip: Grips;
    gripsConfigurationHistory: { grip: Grips; values: number[][] }[];
    handMovementAllowed: Boolean;
  };
  gripsConfigurationHistory: number[][];
  emgSettingsHistory: number[][];
  emgThresholdsHistory: number[][] | null;
  gripPairsConfigHistory: number[][];
  gripSequentialConfigHistory: number[][];
  controlConfigHistory: number[][];
  prosthesisSettingsHistory: number[][][];
  emgGainsHistory: number[][];
  telemetryInitiated: boolean;
  updateFilesReady: boolean;
  updateStatus: number;
  freezeMode: number[] | false;
  procedureReply: number[] | null;
  maxParts: number;
  newGrips: boolean;
  versions: {
    current: string | null;
    minimal: string | null;
    recent: string;
    numerical: number | null;
  };
  physician: boolean;
  unsaved: {
    initialLoaded: boolean;
    unsavedChanges: boolean;
  };
  configConflict: boolean;
  firmwareConflict: boolean;
  firstConnection: boolean | null;
  supported: boolean;
  configHistory: any[];
  localConfigFetched: boolean;
};

export const initialState: SliceState = {
  device: {
    connected: false,
    serialNumber: ''
  },
  config: defaultConfig,
  trackedConfig: {},
  modes: {
    modesConfigs: [],
    modesConfigsTracked: []
  },
  commonConfig: {},
  trackedCommonConfig: {},
  bluetoothMode: 'ble',
  emgThresholdsHistory: [],
  gripsConfiguration: {
    currentGrip: Grips.kGripTypeUnknown,
    gripsConfigurationHistory: [],
    handMovementAllowed: false
  },
  gripsConfigurationHistory: [],
  telemetryInitiated: false,
  chooseGripsHistory: [],
  gripPairsConfigHistory: [],
  gripSequentialConfigHistory: [],
  controlConfigHistory: [],
  prosthesisSettingsHistory: [],
  emgSettingsHistory: [],
  emgGainsHistory: [],
  updateFilesReady: false,
  updateStatus: 0,
  freezeMode: [0],
  procedureReply: null,
  maxParts: 0,
  newGrips: false,
  versions: {
    current: null,
    minimal: null,
    recent: '01.07.05',
    numerical: null
  },
  physician: true,
  unsaved: { initialLoaded: false, unsavedChanges: false },
  configConflict: false,
  firmwareConflict: false,
  firstConnection: null,
  supported: true,
  configHistory: [],
  localConfigFetched: false
};
