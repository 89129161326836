import React from 'react';
import Divider from 'components/atoms/Divider/Divider';
import Modal from 'utils/Modal/Modal';
import { Input } from '@progress/kendo-react-inputs';
import { CustomLabel } from 'components/Label/Label';
import { setItemUI } from 'reducers/uiReducer';
import { useDispatch } from 'react-redux';
import { changeRoute } from 'utils/funcs';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Button } from '@progress/kendo-react-buttons';
import ModalBase from './ModalBase';

type Inputs = {
  user: string;
  password: string;
};
const AuthorizeModal = () => {
  const history = useHistory();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError
  } = useForm<Inputs>();

  const dispatch = useDispatch();

  const authorize = (data) => {
    if (data.user !== 'clinician' || data.password !== 'Zeus2021') {
      setError('password', { type: 'custom', message: 'User or password is incorrect' });
      return;
    }
    if (data.user === 'clinician' && data.password === 'Zeus2021') {
      dispatch(setItemUI({ type: 'isAuthorized', payload: true }));
      dispatch(setItemUI({ type: 'shownGlobalModal', payload: null }));
      changeRoute(history, '/service-menu');
    }
  };

  const abortAuthorization = () => {
    dispatch(setItemUI({ type: 'shownGlobalModal', payload: null }));
    history.push('/')
  };

  return (
    <Modal>
      <ModalBase header='Authorization' handleClick={abortAuthorization}>
        <form onSubmit={handleSubmit(authorize)}>
          <Divider margin='20px' />
          <div>
            <CustomLabel>User</CustomLabel>
            <Input {...register('user')} required />
            {errors.password && <span>{errors.password?.message}</span>}
          </div>
          <Divider margin='10px' />
          <div>
            <CustomLabel>Password</CustomLabel>
            <Input {...register('password')} required />
            {errors.password && <span>{errors.password?.message}</span>}
          </div>
          <Divider margin='20px' />
          <Button themeColor='secondary' type='submit' style={{ width: '50%' }}>
            Enter
          </Button>
        </form>
      </ModalBase>
    </Modal>
  );
};

export default AuthorizeModal;
