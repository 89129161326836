/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import styled, { css, keyframes } from 'styled-components';
import PropTypes from 'prop-types';
import Modal from 'utils/Modal/Modal';
import useCompatibilities from 'hooks/useCompatibilities';
import { useDispatch, useSelector } from 'react-redux';
import Divider from 'components/atoms/Divider/Divider';
import BootloaderController from 'bluetooth-handler/bootloaderController';
import { setItem } from 'reducers/bluetoothReducer/bluetoothReducer';
import { useDeviceUpdate } from 'hooks/api/useDevice';
import { disconnectDevice } from 'reducers/bluetoothReducer/bluetoothHelpers/asyncThunks';
import ProgressBar from '@ramonak/react-progress-bar';
import { useHistory } from 'react-router-dom';
import { HeaderM, TextS } from 'components/atoms/Typography/Typography';
import { delay } from 'bluetooth/Bluetooth/Utilities';
import { ALLOW_MANUAL_UPDATE, MAXIMAL_BOOTLOADER_FIX_VERSION } from 'consts/consts';
import { useQueryClient } from 'react-query';
import { DEVICE_QUERY_KEY } from 'hooks/api/useDeviceInfo';
import { Button } from '@progress/kendo-react-buttons';
import { Chip } from 'components/Chip/Chip';
import { Notification } from 'components/Notification/Notification';
import ModalBase from './ModalBase';

const Header1 = styled.h3`
  ${HeaderM};
`;

const Paragraph = styled.p`
  ${TextS}
`;

const Regular = styled.span`
  font-weight: 400;
`;

const StyledIcon = css`
  width: 20px;
  margin-right: 5px;

  @media (max-height: 924px) {
    width: 15px;
  }
`;

const InfoWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.dimensions.spacing}px;
`;

const VersionWrapper = styled.div`
  display: flex;
  justify-content: space-around;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: ${({ theme }) => theme.dimensions.spacing}px;

  button {
    width: 100%;
  }
`;

const ChipWrapper = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.dimensions.spacing * 0.5}px;
`;

const loaderAnimation = keyframes`
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
  `;

const Loader = styled.div`
  width: 20px;
  height: 20px;
  z-index: 999;
  margin-right: 5px;

  &::after {
    content: ' ';
    display: block;
    width: 20px;
    height: 20px;
    margin: 2px;
    border-radius: 50%;
    border: 2px solid ${({ theme }) => theme.colorPrimary};
    border-color: ${({ theme }) => theme.colorPrimary} transparent
      ${({ theme }) => theme.colorPrimary} transparent;
    animation: ${loaderAnimation} 1.2s linear infinite;
  }
`;

const Bootloader = new BootloaderController();

const UpdateStates = {
  notInititated: 0,
  fetchSuccesful: 1,
  failedToFetch: 2,
  inProgress: 3,
  succesful: 4,
  failed: 5,
  inProgressFix: 6,
  fixSuccessful: 7,
  fixFailed: 8
};

const handleUpdateStateNotification = (type) => {
  switch (type) {
    case UpdateStates.succesful || UpdateStates.fixSuccessful:
      return 'success';
    case UpdateStates.inProgress || UpdateStates.inProgressFix:
      return 'progress';
    default:
      return 'info';
  }
};

const FirmwareModal = ({ handleClose }) => {
  const { versions, updateStatus, maxParts, firmwareConflict, supported } = useSelector(
    (state) => state.bluetooth
  );
  const [message, setMessage] = useState(null);
  const [currentPart, setCurrentPart] = useState(0);
  const { deviceId, firmware } = useSelector((state) => state.deviceInfo);
  const { availableFirmwares } = useCompatibilities();
  const { mutateAsync: updateDevice } = useDeviceUpdate();
  const dispatch = useDispatch();
  const history = useHistory();
  const queryClient = useQueryClient();

  const recentFirmware = `${process.env.REACT_APP_API_BASE_URL}/${availableFirmwares?.[0]?.file_firmware}`;
  const recentBootloader = `${process.env.REACT_APP_API_BASE_URL}/${availableFirmwares?.[0]?.file_bootloader}`;

  const { bluetoothMode } = useSelector((state) => state.bluetooth);

  const updateFirmwareWeb = async (firmwareURL, duringFix = false) => {
    try {
      const request = await fetch(firmwareURL);
      if (request.ok) {
        const bytes = await request.arrayBuffer();
        Bootloader.updateBootloaderParts(bytes);
        const max = Bootloader.getMaxParts();
        setCurrentPart(0);
        await dispatch(setItem({ type: 'maxParts', payload: max }));
        return true;
      }
      throw new Error('Bad response status');
    } catch (err) {
      await dispatch(setItem({ type: 'updateStatus', payload: UpdateStates.failedToFetch }));
      return false;
    }
  };

  const update = async () => {
    dispatch(setItem({ type: 'updateStatus', payload: UpdateStates.inProgress }));
    const status = await Bootloader.updateFirmwareNew(bluetoothMode);
    dispatch(
      setItem({
        type: 'updateStatus',
        payload: status === 2 || status === false ? UpdateStates.succesful : UpdateStates.failed
      })
    );
    // If update is successful, set isAfterUpdate and copy current settings
    if (status === 2 || status === false) {
      await updateDevice({ deviceId, data: { firmware_version_id: availableFirmwares[0].id } });
      await queryClient.invalidateQueries(DEVICE_QUERY_KEY);
    }
    dispatch(disconnectDevice({}));
  };

  const updateWithFix = async () => {
    const fetchStatus = await updateFirmwareWeb(recentFirmware);
    if (fetchStatus) {
      let statusRecent;
      dispatch(setItem({ type: 'updateStatus', payload: UpdateStates.inProgress }));
      const bootloaderVersion = await Bootloader.checkBootloaderVersion(bluetoothMode);
      if (bootloaderVersion < MAXIMAL_BOOTLOADER_FIX_VERSION) {
        dispatch(setItem({ type: 'updateStatus', payload: UpdateStates.inProgressFix }));
        await updateFirmwareWeb(recentBootloader, true);
        const statusFix = await Bootloader.updateFirmware(bluetoothMode);
        dispatch(
          setItem({
            type: 'updateStatus',
            payload: statusFix === 2 ? UpdateStates.fixSuccessful : UpdateStates.fixFailed
          })
        );
      }
      if (updateStatus !== UpdateStates.fixFailed) {
        await delay(2000);
        await updateFirmwareWeb(recentFirmware);
        statusRecent = await Bootloader.updateFirmwareNew(bluetoothMode);
        dispatch(
          setItem({
            type: 'updateStatus',
            payload:
              statusRecent === 2 || statusRecent === false
                ? UpdateStates.succesful
                : UpdateStates.failed
          })
        );
      }
      // If update is successful, set isAfterUpdate and copy current settings
      if (statusRecent === 2 || statusRecent === false) {
        await updateDevice({ deviceId, data: { firmware_version_id: availableFirmwares[0].id } });
        await queryClient.invalidateQueries(DEVICE_QUERY_KEY);
      }
      dispatch(disconnectDevice({}));
    }
  };

  const loadFile = async () => {
    const firmware = document.querySelector('#firmwareInput').files[0];
    await updateFirmwareWeb(window.URL.createObjectURL(firmware));
    update();
  };

  useEffect(() => {
    switch (updateStatus) {
      case UpdateStates.notInititated:
        setMessage(null);
        break;
      case UpdateStates.fetchSuccesful:
        setMessage('Files ready, click update to proceed');
        break;
      case UpdateStates.failedToFetch:
        setMessage('Failed to download files');
        break;
      case UpdateStates.inProgress:
        setMessage('Update in progress');
        break;
      case UpdateStates.succesful:
        setMessage('Update succesfull, reconnect the device');
        break;
      case UpdateStates.failed:
        setMessage('Update failed, reconnect the device');
        break;
      case UpdateStates.inProgressFix:
        setMessage('Bootloader update in progress');
        break;
      case UpdateStates.fixSuccessful:
        setMessage('Bootloader updated successfully, wait for the firmware update to finish');
        break;
      case UpdateStates.fixFailed:
        setMessage('Bootloader update failed');
        break;
      default:
        break;
    }
  }, [updateStatus]);

  const updateCurrentPart = (data) => {
    setCurrentPart(data.detail);
  };

  useEffect(() => {
    history.push('choose-grips');
    window.addEventListener(`bootloaderProgressUpdate`, updateCurrentPart);

    return function clean() {
      window.removeEventListener(`bootloaderProgressUpdate`, updateCurrentPart);
    };
  }, []);

  const isInProgress =
    updateStatus === UpdateStates.inProgress ||
    updateStatus === UpdateStates.succesful ||
    updateStatus === UpdateStates.failed ||
    updateStatus === UpdateStates.inProgressFix ||
    updateStatus === UpdateStates.fixSuccessful;

  const isInProgressStrict =
    updateStatus === UpdateStates.inProgress ||
    updateStatus === UpdateStates.inProgressFix ||
    updateStatus === UpdateStates.fixSuccessful;

  const updateStatesSucessfull =
    updateStatus === UpdateStates.fetchSuccesful || updateStatus === UpdateStates.succesful;

  const updateStatesFailed =
    updateStatus === UpdateStates.failedToFetch || updateStatus === UpdateStates.failed;

  const firmwareVersionCurrentParsed = `${versions.current[1]}.${versions.current[4]}.${versions.current[7]}`;

  return (
    <Modal>
      <ModalBase
        header='Update firmware'
        hideCloseButton={isInProgressStrict || !supported}
        handleClick={isInProgressStrict || !supported ? () => true : handleClose}>
        <VersionWrapper>
          <ChipWrapper>
            <Paragraph>Device version: </Paragraph>
            <Chip>{firmwareVersionCurrentParsed}</Chip>
          </ChipWrapper>
          {firmwareConflict && (
            <Header1>
              Device version (ADP): <Regular>{firmware?.name}</Regular>
            </Header1>
          )}
          <ChipWrapper>
            <Paragraph>Newest version: </Paragraph>
            <Chip>{availableFirmwares?.[0]?.name ?? 'No firmware detected for this device'}</Chip>
          </ChipWrapper>
        </VersionWrapper>
        <Divider margin='10px' />
        {message && (
          <InfoWrapper>
            <Notification type={handleUpdateStateNotification(updateStatus)}>
              {message}
            </Notification>
          </InfoWrapper>
        )}
        {(updateStatus === UpdateStates.notInititated ||
          updateStatus === UpdateStates.failedToFetch) && (
          <ButtonsWrapper>
            {ALLOW_MANUAL_UPDATE && (
              <>
                <input
                  type='file'
                  id='firmwareInput'
                  accept='.bin'
                  onChange={loadFile}
                  style={{ display: 'none' }}
                />
                <Button onClick={() => document.querySelector('#firmwareInput').click()}>
                  Update from file
                </Button>
              </>
            )}
            <Button themeColor='secondary' onClick={updateWithFix}>
              Update
            </Button>
          </ButtonsWrapper>
        )}
        {!supported && (
          <>
            <Divider margin='10px' />
            <span style={{ color: 'red' }}>
              Version 1.8.0 is needed to use ADP, please update firmware.
            </span>
          </>
        )}
        {isInProgress && (
          <>
            <Divider margin='10px' />
            <ProgressBar
              completed={maxParts ? Math.ceil((currentPart / maxParts) * 100) : 0}
              bgColor='#F1C152'
              transitionDuration='0.3s'
            />
            <Divider margin='10px' />
            {isInProgressStrict && (
              <span style={{ color: 'red' }}>
                Do not turn off the device, or close the application!
              </span>
            )}
          </>
        )}
      </ModalBase>
    </Modal>
  );
};

FirmwareModal.propTypes = {
  handleClose: PropTypes.func.isRequired
};

export default FirmwareModal;
