/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';
import styled from 'styled-components';
import { TextXS } from 'components/atoms/Typography/Typography';
import { DisableFunctionality } from 'utils/disableFunctionalityModifiier';
import { RadioButton } from '@progress/kendo-react-inputs';
import { SvgIcon } from '@progress/kendo-react-common';
import * as svgIcons from '@progress/kendo-svg-icons';
import theme from 'theme/theme';
import { TooltipSettings } from 'components/TooltipSettings/TooltipSettings';
import { HeaderSmall } from 'components/Typography/Header';

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.dimensions.spacing * 0.5}px;
`;

const Description = styled.p`
  ${TextXS};
  color: ${({ theme }) => theme.typography.colors.subtitle};
  margin-top: ${({ theme }) => theme.dimensions.spacing}px;
`;

const OptionsList = styled.ul`
  display: grid;
  ${({ disable }) => (disable ? DisableFunctionality : true)};
  list-style: none;
  grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
  margin-top: ${({ theme }) => theme.dimensions.spacing}px;

  label {
    margin-inline-start: 0.5rem !important;
  }
`;

type InputOptionsProps = {
  header: string;
  options: string[];
  id: string;
  onChange?: Function;
  value: string;
  description?: string;
  disable?: boolean;
  tooltip?: {
    description: string;
    header: string;
    video?: any;
  };
};

const InputOptions = ({
  header,
  options,
  id,
  onChange = () => true,
  value,
  description = '',
  disable = false,
  tooltip,
  ...props
}: InputOptionsProps) => {
  const anchor = React.useRef(null);
  const [show, setShow] = React.useState(false);
  const updateSelectedOption = (option) => {
    onChange(id, option);
  };

  return (
    <div>
      <div>
        <HeaderWrapper>
          <HeaderSmall>{header}</HeaderSmall>
          <div ref={anchor}>
            <SvgIcon
              style={{ width: `${theme.dimensions.iconSize}px`, cursor: 'pointer' }}
              icon={svgIcons.infoCircleIcon}
              themeColor='primary'
              onClick={() => setShow((prev) => !prev)}
            />
          </div>
        </HeaderWrapper>
        {description && <Description>{description}</Description>}
      </div>
      <OptionsList disable={disable}>
        {options.map((option) => (
          <li>
            <RadioButton
              onChange={() => updateSelectedOption(option)}
              label={option}
              // eslint-disable-next-line eqeqeq
              checked={value == option}
              name={option}
            />
          </li>
        ))}
      </OptionsList>
      {tooltip && (
        <TooltipSettings
          show={show}
          anchor={anchor.current}
          header={tooltip.header}
          description={tooltip.description}
          video={tooltip?.video}
        />
      )}
    </div>
  );
};

export default InputOptions;
