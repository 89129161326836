import React, { useState } from 'react';
import styled from 'styled-components';
import {
  TEMPLATE_NOTES_KEY,
  useCreateTemplateNote,
  useDeleteTemplateNote,
  useDeviceConfigTemplates,
  useTemplateNotes
} from 'hooks/api/useDevice';
import { Table } from 'components/atoms/Table/Table';
import { useHistory } from 'react-router-dom';
import Pagination from 'components/atoms/Pagination/Pagination';
import dayjs from 'dayjs';
import Divider from 'components/atoms/Divider/Divider';
import { StyledEye, StyledNotesIcon, StyledPlus, StyledUndo } from 'components/atoms/Icons/Icons';
import { useDeviceManager } from 'hooks/api/useDeviceManager';
import ImportTemplateModal from 'components/molecules/Modals/ImportTemplateModal';
import { useModal } from 'hooks/useModal';
import { SortDirs } from 'types';
import { useQueryClient } from 'react-query';
import ModalPortal from 'utils/Modal/ModalPortal';
import Modal from 'utils/Modal/Modal';
import NotesList from 'components/organisms/NotesList/NotesList';
import AddNote from 'components/molecules/Modals/AddNoteModal';
import ModalBase from 'components/molecules/Modals/ModalBase';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Button } from '@progress/kendo-react-buttons';
import { HeaderWrapper } from '../../DeviceConfig/styled';
import {
  ConfigNotesSortOptions,
  ConfigTemplatesExtendOptions,
  ConfigTemplatesScopeOptions
} from '../../../api/device/device.types';
import { usePagination } from '../../../hooks/usePagination';
import { Header } from '../../../components/Typography/Header';
import Card from '../../../components/Card/Card';

const ActionsWrapper = styled.div`
  display: flex;
  gap: 9px;
  align-items: center;
  flex-wrap: wrap;

  button {
    width: auto;
    display: flex;
    align-items: center;
  }
`;

const ChangeItemWrapper = styled.tr`
  td {
    word-wrap: break-word;

    &:not(:last-child) {
      padding-right: 12px;
    }
  }
`;

const SelectorWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const DropDownTitle = styled.span`
  font-size: 16px;
  width: 100%;
  word-break: keep-all;
`;

const StyledModalBase = styled(ModalBase)`
  width: 450px;
`;

const ChangeItem = ({ changeEntry }) => {
  const { push } = useHistory();
  const { importTemplateConfig } = useDeviceManager();
  const {
    handleClose: handleCloseImportModal,
    handleOpen: handleOpenImportModal,
    isOpen: isOpenImportModal
  } = useModal();
  const { mutateAsync: createTemplateNote } = useCreateTemplateNote();
  const { mutateAsync: deleteTemplateNote } = useDeleteTemplateNote();
  const { handleClose, handleOpen, isOpen } = useModal();
  const {
    handleClose: handleCloseAddNote,
    handleOpen: handleOpenAddNote,
    isOpen: isOpenAddNote
  } = useModal();
  const queryClient = useQueryClient();

  const handlePush = (templateId: number) => {
    push({ pathname: `/config-templates/${templateId}` });
  };

  const { result: notes } = useTemplateNotes({
    templateId: Number(changeEntry.id),
    // @ts-ignore
    params: { sortby: ConfigNotesSortOptions.date, sortdir: SortDirs.desc }
  });

  const handleImport = async (modeId: number) => {
    await importTemplateConfig(modeId, Number(changeEntry.id), JSON.parse(changeEntry.config));
    handleCloseImportModal();
  };

  const handleAddNote = async ({ note }) => {
    await createTemplateNote({ templateId: Number(changeEntry.id), data: { note } });
    queryClient.invalidateQueries(TEMPLATE_NOTES_KEY);
  };

  const handleDeleteNote = async ({ noteId }) => {
    await deleteTemplateNote({ templateId: Number(changeEntry.id), noteId });
    queryClient.invalidateQueries(TEMPLATE_NOTES_KEY);
  };

  return (
    <>
      {isOpenImportModal && (
        <ImportTemplateModal
          header='Import template'
          handleAccept={handleImport}
          handleClose={handleCloseImportModal}
        />
      )}
      {isOpen && (
        <ModalPortal>
          <Modal>
            <StyledModalBase handleClick={handleClose} header='Notes'>
              <NotesList notes={notes} handleDelete={handleDeleteNote} showTypes={false} />
            </StyledModalBase>
          </Modal>
        </ModalPortal>
      )}
      {isOpenAddNote && (
        <ModalPortal>
          <AddNote
            handleClose={handleCloseAddNote}
            handleSubmitNote={handleAddNote}
            showTypes={false}
          />
        </ModalPortal>
      )}
      <ChangeItemWrapper>
        <td>{changeEntry?.name}</td>
        <td>{changeEntry?.description}</td>
        <td>{notes?.[0]?.note}</td>
        <td>{dayjs(changeEntry?.created_at).format('MM.DD.YYYY, HH:mm')}</td>
        <td>
          <ActionsWrapper>
            <Button
              icon="reset-sm"
              themeColor='secondary'
              rounded='large'
              onClick={handleOpenImportModal}>
              Import
            </Button>
            <Button
              icon="preview"
              themeColor='secondary'
              rounded='large'
              onClick={() => handlePush(changeEntry.id)}>
              See
            </Button>
            {notes?.length > 0 && (
              <Button themeColor='base' rounded='large' onClick={handleOpen}>
                <StyledNotesIcon style={{ marginRight: '9px' }} />
                Show notes
              </Button>
            )}
            <Button
              icon="plus"
              themeColor='secondary'
              rounded='large'
              onClick={handleOpenAddNote}>
              Add note
            </Button>
          </ActionsWrapper>
        </td>
      </ChangeItemWrapper>
    </>
  );
};

const HeaderInnerWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  flex-wrap: wrap;
`;

const ownerFilterOptions = [
  { id: ConfigTemplatesScopeOptions.me, name: 'Me' },
  { id: ConfigTemplatesScopeOptions.company, name: 'Company' },
  { id: ConfigTemplatesScopeOptions.global, name: 'Aether' }
];

const ConfigTemplatesComponent = () => {
  const [ownerSelected, setOwnerSelected] = useState<{
    id: ConfigTemplatesScopeOptions;
    name: string;
  }>(ownerFilterOptions[0]);
  const {
    result: configTemplates,
    total,
    page,
    pageSize,
    pageChange
  } = usePagination(useDeviceConfigTemplates, {
    params: {
      extend: ConfigTemplatesExtendOptions.author,
      scope: ownerSelected,
      perpage: 10
    }
  });

  return (
    <>
      <HeaderWrapper>
        <Header margin={false}>Config Templates</Header>
        <HeaderInnerWrapper>
          <SelectorWrapper>
            <DropDownTitle>Sort by:</DropDownTitle>
            <DropDownList
              style={{
                minWidth: 150
              }}
              textField='name'
              dataItemKey='id'
              data={ownerFilterOptions}
              value={ownerSelected}
              onChange={(event) => setOwnerSelected(event.target.value)}
            />
          </SelectorWrapper>
        </HeaderInnerWrapper>
      </HeaderWrapper>
      <Card>
        <Table>
          <tr>
            <th>Name</th>
            <th>Description</th>
            <th>Note</th>
            <th>Created at</th>
            <th style={{ width: '28%' }}>Actions</th>
          </tr>
          {configTemplates?.items.map((deviceConfigHistoryEntry) => (
            <ChangeItem changeEntry={deviceConfigHistoryEntry} />
          ))}
        </Table>
        <Divider margin='20px' />
        <Pagination
          onPageChange={pageChange}
          skip={page.skip}
          take={page.take}
          perPage={pageSize}
          total={total}
          pageSize={[10, 20, 50]}
        />
      </Card>
    </>
  );
};

export default ConfigTemplatesComponent;
