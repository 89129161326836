import React from 'react';
import { Paragraph } from 'components/Typography/Paragraph';
import { Button } from '@progress/kendo-react-buttons';
import Modal from 'utils/Modal/Modal';
import { useHistory } from 'react-router-dom';
import ModalBase from './ModalBase';
import { ModalButtonsWrapper, ModalWrapper } from './ModalStyles';

type ModalProps = {
  handleClose: () => void;
};

const SessionWasEndedServicing = ({ handleClose }: ModalProps) => {
  const { push } = useHistory();
  const accepted = async () => {
    handleClose();
    push('/servicing');
  };

  return (
    <Modal>
      <ModalBase handleClick={handleClose} header='Device is disconnected'>
        <ModalWrapper>
          <Paragraph>
            The local session is over, if there was a replacement of parts, go to the form.
          </Paragraph>
          <ModalButtonsWrapper>
            <Button onClick={handleClose}>No replacement was needed</Button>
            <Button onClick={accepted} themeColor='secondary'>
              Go to replacement form
            </Button>
          </ModalButtonsWrapper>
        </ModalWrapper>
      </ModalBase>
    </Modal>
  );
};

export default SessionWasEndedServicing;
