/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
/* eslint-disable prefer-const */
/* eslint-disable prefer-template */
export const encodeQueryData = (data: any) => {
  const ret = [];
  for (let d in data)
    // prettier-ignore
    // @ts-ignore
    ret.push(encodeURIComponent(d) + "=" + encodeURIComponent(data[d]));
  return ret.join("&");
};
