import styled from 'styled-components';
import { SvgIcon } from '@progress/kendo-react-common';
import * as svgIcons from '@progress/kendo-svg-icons';
import theme from 'theme/theme';

const handleType = (type) => {
  switch (type) {
    case 'success':
      return {
        color: '#28a745',
        backgroundColor: 'rgba(40, 167, 69, 0.2)',
        icon: svgIcons.checkOutlineIcon
      };
    case 'progress':
      return {
        color: '#e7a50d',
        backgroundColor: 'rgba(241, 193, 82, 0.2)',
        icon: svgIcons.arrowRotateCwIcon
      };
    case 'fail':
      return {
        color: '#e81515',
        backgroundColor: 'rgba(232, 21, 21, 0.2)',
        icon: svgIcons.xIcon
      };
    default:
      return {
        color: '#007bff',
        backgroundColor: 'rgba(0, 123, 255, 0.2)',
        icon: svgIcons.infoCircleIcon
      };
  }
};

const NotificationWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 8px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  color: ${({ type }) => handleType(type).color};
  background-color: ${({ type }) => handleType(type).backgroundColor};
  padding: 8px 16px;
`;

export const Notification = ({ type, children, ...props }: any) => (
  <NotificationWrapper {...props} type={type}>
    <SvgIcon style={{ width: `${theme.dimensions.iconSize}px` }} icon={handleType(type).icon} />
    {children}
  </NotificationWrapper>
);
