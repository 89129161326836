/* eslint-disable lines-between-class-members */
/* eslint-disable no-bitwise */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
import BluetoothController from './bluetoothInterface';
import { BluetoothReader, CreateFrame } from '../bluetooth/Bluetooth/Bluetooth';
import { Commands } from '../bluetooth/Bluetooth/Defines';

const blReader = new BluetoothReader();

class BluetoothWebControllerSerial implements BluetoothController {
  static _instance: any;
  port: any;
  reader: any;
  connected: boolean = false;
  continueReading: boolean = true;
  commandDataTransformed: {} = {};
  writer: any;
  readerLoop: any;
  characteristic: any;
  device: any;

  constructor() {
    if (BluetoothWebControllerSerial._instance) {
      return BluetoothWebControllerSerial._instance;
    }
    BluetoothWebControllerSerial._instance = this;
    this.commandDataTransformed = {};
    this.connected = false;
  }

  async initiateBluetooth(): Promise<boolean> {
    if ('serial' in navigator) {
      try {
        this.continueReading = true;
        const newNavigator: any = window.navigator;
        this.port = await newNavigator.serial.requestPort();
        await this.port.open({ baudRate: 9600 });
        this.writer = this.port.writable.getWriter();
        console.log(this.port);
        this.connected = true;
        this.readerLoop = this.readWeb();
        return this.connected;
      } catch (err) {
        console.error('There was an error opening the serial port', err);
        return false;
      }
    } else {
      console.log('Browser doesnt support web bluetooth API');
      return false;
    }
  }

  async disconnectBluetooth() {
    this.continueReading = false;
    await this.reader.cancel();
    await this.writer.abort();
    this.writer.releaseLock();
    this.reader.releaseLock();
    console.log('CLOSING PORT');
    await this.port.close();
    console.log('PORT CLOSED');
    this.connected = false;
    this.writer = null;
    this.port = null;
    blReader.mIncomingBuffer = [];
    blReader.mCRC = [];
    blReader.frameIterator = 2;
    console.log('Port closed');
    return true;
  }

  async readWeb() {
    while (this.port.readable && this.continueReading) {
      console.log('CREATING NEW READABLE STREAM');
      this.reader = this.port.readable.getReader();
      try {
        while (true) {
          // eslint-disable-next-line no-await-in-loop
          const { value, done } = await this.reader.read();
          if (done) {
            break;
          }
          const commandData = blReader.receiveDataFromSerialPort(value);
          if (commandData) {
            this.commandDataTransformed = commandData;
            const event = new CustomEvent(`received${commandData[0].command}`, {
              detail: this.commandDataTransformed
            });
            window.dispatchEvent(event);
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
    return true;
  }

  async writeWeb(command, commandData) {
    try {
      const frame = CreateFrame(command, commandData);
      const data = new Uint8Array(frame);
      const status = await this.writer.write(data);
      return status;
    } catch (err) {
      console.log(err);
      return err;
    }
  }

  async queryResponseCommand(
    command,
    commandData,
    commandAwaited,
    mode = 'classic',
    listenMode = false
  ): Promise<any> {
    if (!listenMode) await this.writeWeb(command, commandData);
    return new Promise((resolve) => {
      window.addEventListener(
        `received${commandAwaited}`,
        (data: any) => {
          console.log('RESOLVED', commandAwaited, data.detail);
          resolve(data.detail);
        },
        {
          capture: true,
          once: true
        }
      );
    });
  }

  async telemetryOn() {
    try {
      await this.writeWeb(Commands.kStartOrStopTransmittingTelemetryData, [1]);
      return true;
    } catch (e) {
      console.log(e);
      return false;
    }
  }

  async telemetryOff() {
    try {
      await this.writeWeb(Commands.kStartOrStopTransmittingTelemetryData, [0]);
      return true;
    } catch (e) {
      console.log(e);
      return false;
    }
  }
}

export default BluetoothWebControllerSerial;
