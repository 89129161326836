import { getCompatibilities } from 'api/versions/versions';
import { useQuery } from 'react-query';
import {
  CompatibilitiesQueryParams,
  CompatibilitiesExtendOptions
} from 'api/versions/versions.types';
import { useSelector } from 'react-redux';
import { parseBluetoothFirmware } from 'utils/funcs';
import useDeviceInfo from './api/useDeviceInfo';

const COMPATIBILITIES_QUERY_KEY = 'compatibilities';
const COMPATIBILITIES_FIRMWARE_QUERY_KEY = 'compatibilities/firmware';
const COMPATIBILITIES_PCB_QUERY_KEY = 'compatibilities/pcb';

const useCompatibilities = (areFeaturesSupported?: Array<string>) => {
  let supportedFeaturesFiltered;
  let availableFirmwares;
  let isFullyCompatible = true;
  let isPcbUpgradeNeeded = false;
  let isFirmwareUpdateNeeded = false;
  let deviceFirmware;
  const { data: deviceInfo } = useDeviceInfo();
  const { firmwareConflict, versions } = useSelector((state: any) => state.bluetooth);
  const { firmware, pcb } = useSelector((state: any) => state.deviceInfo);

  if (firmware?.name) {
    deviceFirmware = firmware.name;
  }

  if (firmwareConflict) {
    deviceFirmware = parseBluetoothFirmware(versions.current);
  }

  const compatibilitiesParams: CompatibilitiesQueryParams = {
    model: deviceInfo?.model_id,
    software: process.env.REACT_APP_SOFTWARE_VERSION,
    firmware: deviceFirmware,
    pcb: pcb?.devicePCB,
    extend: [CompatibilitiesExtendOptions.features]
  };

  const compatibilitiesParamsFirmware: CompatibilitiesQueryParams = {
    model: deviceInfo?.model_id,
    software: process.env.REACT_APP_SOFTWARE_VERSION,
    pcb: pcb?.devicePCB,
    extend: [CompatibilitiesExtendOptions.features, CompatibilitiesExtendOptions.firmwareVersion]
  };

  const compatibilitiesParamsPCB: CompatibilitiesQueryParams = {
    model: deviceInfo?.model_id,
    software: process.env.REACT_APP_SOFTWARE_VERSION,
    firmware: deviceFirmware,
    extend: [CompatibilitiesExtendOptions.features, CompatibilitiesExtendOptions.pcbVersion]
  };

  const {
    data: compatibilityInfo,
    isLoading,
    isError
  } = useQuery(
    [COMPATIBILITIES_QUERY_KEY, deviceInfo?.id, firmwareConflict],
    () => getCompatibilities({ params: compatibilitiesParams }),
    {
      cacheTime: Infinity,
      enabled: Boolean(deviceInfo?.id && deviceFirmware),
      keepPreviousData: true,
      staleTime: Infinity,
      retry: 0
    }
  );

  const { data: compatibilityListFirmware } = useQuery(
    [COMPATIBILITIES_FIRMWARE_QUERY_KEY, deviceInfo?.id, firmwareConflict],
    () => getCompatibilities({ params: compatibilitiesParamsFirmware }),
    {
      cacheTime: Infinity,
      enabled: Boolean(deviceInfo?.id && deviceFirmware),
      keepPreviousData: true,
      staleTime: Infinity,
      retry: 0
    }
  );

  const { data: compatibilityListPCB } = useQuery(
    [COMPATIBILITIES_PCB_QUERY_KEY, deviceInfo?.id, firmwareConflict],
    () => getCompatibilities({ params: compatibilitiesParamsPCB }),
    {
      cacheTime: Infinity,
      enabled: Boolean(deviceInfo?.id && pcb?.devicePCB),
      keepPreviousData: true,
      staleTime: Infinity,
      retry: 0
    }
  );

  if (compatibilityInfo) {
    isFullyCompatible = compatibilityInfo.items?.[0]?.is_fully_compatible;
  }

  if (compatibilityInfo && areFeaturesSupported) {
    const supportedFeatures = compatibilityInfo.items?.[0]?.features || [];
    supportedFeaturesFiltered = areFeaturesSupported
      .filter((feature) => supportedFeatures.includes(feature))
      .map(() => true);
  }

  if (compatibilityListFirmware) {
    availableFirmwares = compatibilityListFirmware.items
      .filter((item) => item.is_fully_compatible === 1)
      .sort((a, b) => {
        const parseFirmware = (firmwareName) => Number(firmwareName.replaceAll('.', ''));
        const parsedFirmwareA = parseFirmware(a.firmware_version.name);
        const parsedFirmwareB = parseFirmware(b.firmware_version.name);

        if (parsedFirmwareA > parsedFirmwareB) return 1;
        return -1;
      })
      .map((item) => item.firmware_version);

    if (availableFirmwares.length > 0 && !isFullyCompatible) {
      isFirmwareUpdateNeeded = true;
    }
  }

  if (compatibilityListPCB && !isFullyCompatible) {
    isPcbUpgradeNeeded =
      compatibilityListPCB.items.find((item) => item.is_fully_compatible === 1)?.length > 0;
  }

  return {
    compatibilityInfo: compatibilityInfo?.items,
    supportedFeaturesFiltered,
    isLoading,
    isError,
    isFullyCompatible,
    availableFirmwares,
    isPcbUpgradeNeeded,
    isFirmwareUpdateNeeded
  };
};

export default useCompatibilities;
