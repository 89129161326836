import useRemoteSession from 'hooks/useRemoteSession';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getInitialConfigAPI } from 'reducers/bluetoothReducer/bluetoothHelpers/asyncThunks';
import { compareConfigs } from 'reducers/bluetoothReducer/bluetoothHelpers/bluetoothHelpers';
import { setUnsaved } from 'reducers/bluetoothReducer/bluetoothReducer';
import { selectDeviceInfo } from 'reducers/deviceInfoReducer';
import { getLiveSessionApi } from 'reducers/liveConfiguratorReducer/liveConfiguratorReducer';
import { getModesListAPI } from 'reducers/modesReducer/modesReducer';
import { setItemUI } from 'reducers/uiReducer';
import { isEmpty } from 'lodash';
import useModes from 'hooks/useModes';
import useCompatibilities from 'hooks/useCompatibilities';
import toast from 'react-hot-toast';
import useUserData from 'hooks/api/useUserData';

const MainViews = ({ children }) => {
  const dispatch = useDispatch();
  const deviceInfo = useSelector(selectDeviceInfo);
  const { data: userData } = useUserData();
  const { configIsFetched } = useRemoteSession();
  const { enabled: liveConfiguratorEnabled, configFetched } = useSelector(
    (state: any) => state.liveConfigurator
  );
  const { device, configConflict, config, trackedConfig } = useSelector(
    (state: any) => state.bluetooth
  );
  const { modesList, modeId } = useSelector((state: any) => state.modes);
  const { handleChangeMode } = useModes();
  const { isFirmwareUpdateNeeded, isPcbUpgradeNeeded, availableFirmwares } = useCompatibilities();

  useEffect(() => {
    const fetchModes = async () => {
      await dispatch(getModesListAPI({ deviceId: deviceInfo.deviceId }));
      await dispatch(getInitialConfigAPI());
    };
    if (!liveConfiguratorEnabled && deviceInfo.deviceId && deviceInfo.serial) {
      fetchModes().catch(console.error);
    }
  }, [deviceInfo]);

  useEffect(() => {
    if (liveConfiguratorEnabled && !configFetched && userData?.id && deviceInfo.deviceId) {
      dispatch(getInitialConfigAPI());
      dispatch(getLiveSessionApi());
      configIsFetched();
    }

    if (liveConfiguratorEnabled && configFetched && deviceInfo.deviceId) {
      dispatch(getModesListAPI({ deviceId: deviceInfo.deviceId }));
    }
  }, [deviceInfo.deviceId, configFetched, userData?.id]);

  useEffect(() => {
    if (configConflict && device.connected) {
      dispatch(setItemUI({ type: 'shownGlobalModal', payload: 'configConflict' }));
    }
  }, [configConflict]);

  useEffect(() => {
    if (!isEmpty(trackedConfig)) {
      const differences = compareConfigs(config, trackedConfig);
      if (!isEmpty(differences)) {
        dispatch(
          setUnsaved({
            type: 'unsaved',
            payload: { unsavedChanges: true }
          })
        );
      } else {
        dispatch(
          setUnsaved({
            type: 'unsaved',
            payload: { unsavedChanges: false }
          })
        );
      }
    }
  }, [config]);

  useEffect(() => {
    if (modesList) {
      handleChangeMode(modeId || modesList[0].id);
    }
  }, [modesList, device.connected]);

  useEffect(() => {
    if (isPcbUpgradeNeeded) {
      toast(
        `This device is not fully compatible with software version ${process.env.REACT_APP_SOFTWARE_VERSION}, please contact support to upgrade it`,
        { duration: 60000, icon: '⚠️' }
      );
    }
  }, [isPcbUpgradeNeeded]);

  useEffect(() => {
    if (isFirmwareUpdateNeeded) {
      toast(
        `Current device firmware ${deviceInfo?.firmware?.name} is not fully compatible with software version ${process.env.REACT_APP_SOFTWARE_VERSION}, please update to latest firmware ${availableFirmwares?.[0]?.name}`,
        { duration: 60000, icon: '⚠️' }
      );
    }
  }, [isFirmwareUpdateNeeded]);

  return children;
};

export default MainViews;
