import React from 'react';
import styled from 'styled-components';
import Card from 'components/Card/Card';
import { ReactComponent as NonOpposedTreeGraph } from 'assets/non-opposed.svg';
import { ReactComponent as OpposedTreeGraph } from 'assets/opposed.svg';
// import OpposedTreeGraphTest from 'assets/choose_grips.png';
import DropdownGrip from 'components/DropdownGrip/DropdownGrip';
import { gripsGroupsOptionsReversedMap } from 'utils/definesLocal';
import { Grips } from '../../bluetooth/Bluetooth/Grips';

const OpposedTreeGraphStyled = styled(OpposedTreeGraph)`
  padding: 0 10%;
  width: 100%;
`;

const NonOpposedTreeGraphStyled = styled(NonOpposedTreeGraph)`
  padding: 0 10% 5px 10%;
  width: 100%;
`;

const DropdownsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 0% 1fr 1fr;
  grid-gap: 20px;

  @media (min-width: ${({ theme }) => theme.breakpoints.small}) {
    grid-template-columns: 1fr 1fr 6% 1fr 1fr;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.large}) {
    padding: 0 10px;
  }
`;

type GraphChooserTreeProps = {
  id?: string;
  grips: Grips[];
  gripsImgs: any;
  values: Grips[];
  nonOpposed?: boolean;
  onChange: Function;
  indexes: number[];
};

const GraphChooserTree = ({
  grips,
  gripsImgs,
  values,
  nonOpposed = false,
  onChange,
  indexes = [0],
  ...props
}: GraphChooserTreeProps) => {
  const updateGrips = (option, gripOrder, isNonOpposed) => {
    onChange(gripsGroupsOptionsReversedMap.get(option), gripOrder, isNonOpposed);
  };

  return (
    <Card {...props}>
      {nonOpposed ? <NonOpposedTreeGraphStyled /> : <OpposedTreeGraphStyled />}
      <DropdownsWrapper>
        {indexes.map((chooseGripsIndex, localIndex) => (
          <>
            {localIndex === 2 ? <div>&nbsp;</div> : null}
            <DropdownGrip
              grips={grips}
              gripsImgs={gripsImgs}
              value={values[localIndex]}
              localIndex={localIndex}
              nonOpposed={nonOpposed}
              updateGrips={updateGrips}
            />
          </>
        ))}
      </DropdownsWrapper>
    </Card>
  );
};

export default GraphChooserTree;
