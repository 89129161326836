import React, { useState } from 'react';
import Modal from 'utils/Modal/Modal';
import { Button } from '@progress/kendo-react-buttons';
import ModalPortal from 'utils/Modal/ModalPortal';
import { useSelector } from 'react-redux';
import { Paragraph } from 'components/Typography/Paragraph';
import { CustomLabel } from 'components/Label/Label';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import ModalBase from './ModalBase';
import { ModalButtonsWrapper, ModalWrapper } from './ModalStyles';

type ConfirmAbortModeProps = {
  handleAccept: Function;
  handleClose: () => void;
  header: string;
};

const ImportTemplateModal = ({ handleAccept, handleClose, header }: ConfirmAbortModeProps) => {
  const { modesList } = useSelector((state: any) => state.modes);
  const [selectedModeId, setSelectedModeId] = useState({ id: 0, name: modesList[0].name });
  const { enabled: remoteSessionEnabled } = useSelector((state: any) => state.liveConfigurator);

  return (
    <ModalPortal>
      <Modal>
        <ModalBase handleClick={handleClose} header={header}>
          <ModalWrapper>
            {modesList && !remoteSessionEnabled && (
              <div>
                <CustomLabel htmlFor='mode'>Selected mode</CustomLabel>
                <DropDownList
                  id='mode'
                  data={modesList.map((mode, index) => ({ id: index, name: mode.name }))}
                  onChange={(e) => setSelectedModeId(e.value)}
                  textField='name'
                  dataItemKey='id'
                  value={selectedModeId}
                />
              </div>
            )}
            <Paragraph>Do you want to import this template, and send it to patient?</Paragraph>
            <ModalButtonsWrapper>
              <Button onClick={handleClose} type='button'>
                Cancel
              </Button>
              <Button
                type='button'
                onClick={() => handleAccept(modesList[selectedModeId.id].id)}
                themeColor='secondary'>
                Yes, send
              </Button>
            </ModalButtonsWrapper>
          </ModalWrapper>
        </ModalBase>
      </Modal>
    </ModalPortal>
  );
};

export default ImportTemplateModal;
