import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Card from 'components/Card/Card';
import DropdownGrip from 'components/DropdownGrip/DropdownGrip';
import { gripsGroupsOptionsMap, gripsGroupsOptionsReversedMap } from 'utils/definesLocal';
import { TextS } from 'components/atoms/Typography/Typography';
import { Checkbox } from '@progress/kendo-react-inputs';
import { Grips } from '../../bluetooth/Bluetooth/Grips';

const DropdownsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
  justify-content: space-around;

  @media (max-width: 1630px) {
    padding: 0 40px;
  }

  @media (max-width: 1200px) {
    padding: 0;
  }
`;

const DropdownWrapper = styled.div`
  display: flex;
  position: relative;
  opacity: ${({ active }) => (active ? '1' : '0.5')};
  pointer-events: ${({ active }) => (active ? 'auto' : 'none')};
`;

const GripWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.dimensions.spacing}px;
  width: 200px;
  align-items: center;
`;

const HeaderSecondary = styled.h4`
  display: flex;
  align-items: center;
  ${TextS};
  text-align: center;
  margin-right: ${({ show }) => (show ? '10px' : '0')};
  opacity: ${({ active }) => (active ? '1' : '0.5')};
`;

const CheckboxWrapper = styled.div`
  display: flex;
  height: 30px;
  justify-content: center;
  align-items: center;
`;

const StyledCheckbox = styled.div`
  display: ${({ show }) => (show ? 'auto' : 'none')};
`;

type GraphChooserTreeProps = {
  id?: string;
  grips: Grips[];
  gripsImgs: any;
  values: Grips[];
  nonOpposed?: boolean;
  onChange: Function;
  indexes: number[];
};

const GripsChooserSequential = ({
  grips,
  gripsImgs,
  values,
  nonOpposed = false,
  onChange,
  indexes = [0],
  ...props
}: GraphChooserTreeProps) => {
  const { t } = useTranslation();
  const [enabledColumns, setEnabledColumns] = useState([true, false, false, false, false]);
  const [visibleCheckboxes, setVisibleCheckboxes] = useState([true, false, false, false, false]);

  const updateGrips = (option, gripOrder, isNonOpposed) => {
    onChange(gripsGroupsOptionsReversedMap.get(option), gripOrder, isNonOpposed);
  };

  const updateEnabledColumns = (index) => {
    const newEnabledColumns = enabledColumns.map((isColumnEnabled, localIndex) => {
      if (index === localIndex) {
        isColumnEnabled = !isColumnEnabled;
        if (isColumnEnabled === false) {
          updateGrips('Disabled', index, nonOpposed);
        } else {
          updateGrips(gripsGroupsOptionsMap.get(grips[0]), index, nonOpposed);
        }
      }
      return isColumnEnabled;
    });
    setEnabledColumns(newEnabledColumns);
  };

  useEffect(() => {
    const gripsSequence = values;
    const updatedEnabledColumns = gripsSequence.map((grip) => {
      if (grip === Grips.kGripTypeUnknown) {
        return false;
      }
      return true;
    });
    setEnabledColumns(updatedEnabledColumns);
  }, [values]);

  useEffect(() => {
    const newVisibleCheckboxes = Array(5).fill(false);
    const lastIndex = values.findIndex(
      (value, index) =>
        value === Grips.kGripTypeUnknown && value[index - 1] !== Grips.kGripTypeUnknown
    );
    if (lastIndex !== 1) {
      newVisibleCheckboxes[lastIndex] = true;
      newVisibleCheckboxes[lastIndex - 1] = true;
    } else {
      newVisibleCheckboxes[1] = true;
    }
    setVisibleCheckboxes(newVisibleCheckboxes);
  }, [enabledColumns]);

  return (
    <Card {...props}>
      <div>{nonOpposed ? 'Non-Opposed' : 'Opposed'}</div>
      <DropdownsWrapper>
        {indexes.map((chooseGripsIndex, localIndex) => (
          <GripWrapper>
            <CheckboxWrapper>
              <HeaderSecondary
                active={enabledColumns[localIndex]}
                show={visibleCheckboxes[localIndex]}>
                {t('prosthesis_settings.grip')} {localIndex + 1}
              </HeaderSecondary>
              <StyledCheckbox show={visibleCheckboxes[localIndex]}>
                <Checkbox
                  onClick={() => updateEnabledColumns(localIndex)}
                  value={enabledColumns[localIndex]}
                  size='large'
                />
              </StyledCheckbox>
            </CheckboxWrapper>
            <DropdownWrapper active={enabledColumns[localIndex]}>
              <DropdownGrip
                grips={grips}
                gripsImgs={gripsImgs}
                value={values[localIndex]}
                localIndex={localIndex}
                nonOpposed={nonOpposed}
                updateGrips={updateGrips}
              />
            </DropdownWrapper>
          </GripWrapper>
        ))}
      </DropdownsWrapper>
    </Card>
  );
};

export default GripsChooserSequential;
