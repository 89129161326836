import React from 'react';
import styled from 'styled-components';
import Snack from 'components/atoms/Snack/Snack';
import AddNote from 'components/molecules/Modals/AddNoteModal';
import { useModal } from 'hooks/useModal';
import ModalPortal from 'utils/Modal/ModalPortal';
import { ReactComponent as Trash } from 'assets/trash.svg';
import dayjs from 'dayjs';
import { Button } from '@progress/kendo-react-buttons';
import { SvgIcon } from '@progress/kendo-react-common';
import * as svgIcons from '@progress/kendo-svg-icons';

const NotesListWrapper = styled.ul`
  list-style: none;

  li {
    border-top: 1px solid #e9ecef;
    font-size: 14px;
    margin-top: 16px;
  }

  p {
    padding: 16px 0;
  }
`;

const NoteDetailsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const NoteSnackWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const TrashBadge = styled.span`
  background-color: rgba(220, 53, 69, 0.16);
  padding: 6px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NoteDate = styled.span`
  color: #808080;
  font-size: 12px;
`;

const StyledTrash = styled(Trash)`
  cursor: pointer;
  width: 100%;
`;

type NotesListProps = {
  notes: any[];
  handleAdd?: Function | null;
  handleDelete: Function;
  showTypes?: boolean;
};

const NotesList = ({ notes, handleAdd = null, handleDelete, showTypes = true }: NotesListProps) => {
  const { handleClose, handleOpen, isOpen } = useModal();

  return (
    <>
      {isOpen && handleAdd && (
        <ModalPortal>
          <AddNote handleClose={handleClose} handleSubmitNote={handleAdd} showTypes={showTypes} />
        </ModalPortal>
      )}
      {handleAdd && (
        <Button
          icon='add'
          rounded='large'
          style={{ width: '100%', fontWeight: 'bold', fontSize: '14px' }}
          themeColor='primary'
          onClick={handleOpen}>
          Add note
        </Button>
      )}
      <NotesListWrapper>
        {notes &&
          notes.map((note) => (
            <li>
              <p>{note?.note}</p>
              <NoteDetailsWrapper>
                <NoteDate>{dayjs(note?.updated_at).format('MM/DD/YYYY HH:mm')}</NoteDate>
                <NoteSnackWrapper>
                  {showTypes && <Snack title={note?.type} />}
                  <TrashBadge>
                    <SvgIcon
                      size='small'
                      style={{ cursor: 'pointer', color: '#d04a57' }}
                      icon={svgIcons.trashIcon}
                      onClick={() => handleDelete({ noteId: note?.id })} />
                  </TrashBadge>
                </NoteSnackWrapper>
              </NoteDetailsWrapper>
            </li>
          ))}
      </NotesListWrapper>
    </>
  );
};

export default NotesList;
