import React from 'react';
import NotesList from 'components/organisms/NotesList/NotesList';
import {
  CONFIG_ENTRY_NOTES_KEY,
  useConfigEntryNotes,
  useCreateConfigNote,
  useDeleteConfigNote
} from 'hooks/api/useDevice';
import { useQueryClient } from 'react-query';
import Modal from 'utils/Modal/Modal';
import { ConfigNotesSortOptions } from 'api/device/device.types';
import { SortDirs } from 'types';
import ModalBase from './ModalBase';

type ShowNotesModalProps = {
  handleClose: () => void;
  deviceId: number;
  configId: number;
};

const ShowNotesModal = ({ handleClose, deviceId, configId }: ShowNotesModalProps) => {
  const { result: notes } = useConfigEntryNotes({
    deviceId,
    configId,
    params: { sortby: ConfigNotesSortOptions.date, sortdir: SortDirs.desc }
  });
  const { mutateAsync: createConfigNote } = useCreateConfigNote();
  const { mutateAsync: deleteConfigNote } = useDeleteConfigNote();
  const queryClient = useQueryClient();

  const handleAddNote = async ({ note, type }) => {
    await createConfigNote({ deviceId, configId: Number(configId), data: { note, type } });
    queryClient.invalidateQueries(CONFIG_ENTRY_NOTES_KEY);
  };

  const handleDeleteNote = async ({ noteId }) => {
    await deleteConfigNote({ deviceId, configId: Number(configId), noteId });
    queryClient.invalidateQueries(CONFIG_ENTRY_NOTES_KEY);
  };

  return (
    <Modal>
      <ModalBase handleClick={handleClose} header='Notes'>
        <NotesList notes={notes} handleAdd={handleAddNote} handleDelete={handleDeleteNote} />
      </ModalBase>
    </Modal>
  );
};

export default ShowNotesModal;
