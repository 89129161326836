/* eslint-disable no-underscore-dangle */
/* eslint-disable no-restricted-syntax */
/* eslint-disable prefer-destructuring */
import toast from 'react-hot-toast';
import { numericalFirmwareVersion } from 'utils/funcs';
import { forEach, isEmpty } from 'lodash';
import { compareConfigs, loadConfig, selectCommonConfig } from './bluetoothHelpers';
import { initialState } from './initialState';

export const resetToDefaultFulfilled = (state, action) => {
  if (action.payload) {
    loadConfig(state, action.payload);
    state.unsaved.unsavedChanges = true;
    state.freezeMode = [0];
    toast.success('Default configuration restored');
  } else {
    toast.error('Device not connected');
  }
};

export const getFirmwareDeviceFulfilled = (state, action) => {
  if (action.payload.firmwareVersion) {
    const currentVersion = action.payload.firmwareVersion
      .slice(0, 8)
      .map((item) => String.fromCharCode(item))
      .join('');
    const minimalVersion = action.payload.firmwareVersion
      .slice(8, 16)
      .map((item) => String.fromCharCode(item))
      .join('');
    state.versions.current = currentVersion;
    state.versions.minimal = minimalVersion;
    const firmwareVersionCurrentParsed = `${currentVersion[1]}.${currentVersion[4]}.${currentVersion[7]}`;
    state.versions.numerical = numericalFirmwareVersion(firmwareVersionCurrentParsed);
  } else {
    toast.error(action.payload);
  }
};

export const disconnectDeviceFulfilled = (state: any, action) => {
  if (action.payload) {
    if (state.device.serialNumber !== '') {
      toast('Device disconnected', { icon: '⚠️' });
    }
    state.device = initialState.device;
    state.newGrips = initialState.newGrips;
    state.gripsConfiguration.handMovementAllowed =
      initialState.gripsConfiguration.handMovementAllowed;
    state.localConfigFetched = false;
  } else {
    toast.error("Couldn't disconnect the device");
  }
};

export const getInitialConfigFulfilled = (state, action) => {
  if (action.payload) {
    state.updateStatus = 0;
    state.updateFilesReady = false;
    state.maxParts = 0;
    if (action.payload.device.serialNumber) {
      const serialNumberSanitized = action.payload.device.serialNumber
        .map((item) => String.fromCharCode(item))
        .join('');
      state.device = {
        ...state.device,
        serialNumber: serialNumberSanitized
      };
    }

    const config = { ...action.payload.commonConfig, ...action.payload.modesConfigs[0].config };
    const modesConfigs = action.payload.modesConfigs;

    loadConfig(state, config);

    const commonConfig = selectCommonConfig(state.config);
    state.commonConfig = commonConfig;

    state.modes.modesConfigs = action.payload.modesConfigs;

    if (action.payload.freezeMode) {
      state.freezeMode = action.payload.freezeMode;
    }
    if (!state.firstConnection) {
      const deviceDifferences: any = [];
      deviceDifferences.push(compareConfigs(state.commonConfig, state.trackedCommonConfig));
      modesConfigs.forEach((modeConfig) => {
        const trackedMode = state.modes.modesConfigsTracked.find(
          (modeConfigTracked) => modeConfigTracked.id === modeConfig.id
        );
        const difference = compareConfigs(modeConfig.config, trackedMode.config);
        deviceDifferences.push(difference);
      });
      for (let index = 0; index < deviceDifferences.length; index += 1) {
        const difference = deviceDifferences[index];
        if (!isEmpty(difference)) {
          state.configConflict = true;
          break;
        }
      }
      console.log(deviceDifferences, 'DIFFERENCES');
    }
    if (action.payload.gripsPositions) {
      if (action.payload.gripsPositions['12']) state.newGrips = true;
    }
    state.unsaved.unsavedChanges = false;
    state.localConfigFetched = true;
    toast.success('Configuration downloaded');
  } else if (action.payload === false) {
    toast.error('Could not connect to the prosthesis');
  } else {
    toast.error(action.payload);
  }
};

export const connectDeviceFulfilled = (state, action) => {
  if (action.payload.status) {
    state.device = { ...state.device, connected: action.payload.status };
    toast.success('Device connected');
  } else if (action.payload === false) {
    toast.error('Could not connect to the prosthesis');
  } else {
    toast.error(action.payload);
  }
};

export const getInitialConfigAPIFulfilled = (state, action) => {
  if (
    action.payload.config &&
    action?.payload?.config?.common &&
    action.payload.config.constructor.name !== 'Array'
  ) {
    const configPayload = action?.payload?.config;
    const modesListPayload = action?.payload?.modesList;
    const modeId = action.payload.modeId;
    state.firstConnection = false;
    const commonConfigJSON = configPayload?.common;
    loadConfig(state, commonConfigJSON);
    state.trackedConfig = state.config;

    delete configPayload.common;

    const modesConfigs = configPayload;
    const apiModes: any = [];

    Object.keys(modesConfigs).forEach((key) => {
      const mode = modesConfigs[key];
      const _modeId = Number(key.replace('mode_', ''));
      const modeSlot = modesListPayload.find((mode) => mode.id === _modeId).slot;
      apiModes.push({
        id: _modeId,
        config: mode,
        slot: modeSlot
      });
    });
    state.modes.modesConfigs = apiModes;
    state.modes.modesConfigsTracked = state.modes.modesConfigs;

    const commonConfig = selectCommonConfig(state.config);
    state.commonConfig = commonConfig;
    state.trackedCommonConfig = commonConfig;

    if (commonConfigJSON.gripsPositions) {
      if (commonConfigJSON.gripsPositions['12']) state.newGrips = true;
    }

    console.log(state.modes.modesConfigs, 'MODES', modeId);

    const currentModeConfig = state.modes.modesConfigs.find((mode) => mode.id === modeId).config;
    loadConfig(state, currentModeConfig);
    state.unsaved.unsavedChanges = false;
    toast.success('Device config retrieved');
  } else {
    state.firstConnection = true;
    toast('Device config could not be retrieved, connect the device and send config', {
      icon: '⚠️'
    });
  }
};

export const getModeConfigAPIFulfilled = (state, action) => {
  const configJSON = JSON.parse(action?.payload?.mode.config);
  const modeId = action.payload.modeId;

  if (configJSON.constructor.name !== 'Array') {
    loadConfig(state, configJSON);
    const modeIndex = state.modes.modesConfigs.findIndex((modeConfig) => modeConfig.id === modeId);
    state.modes.modesConfigs[modeIndex] = configJSON;
  }
  state.unsaved.unsavedChanges = false;
};

export const sendFreezeFulfilled = (state, action: any) => {
  if (action.payload) {
    state.freezeMode[0] = action.payload.newFreezeMode[0];
  } else {
    toast.error('Device not connected');
  }
};

export const handleProcedureFulfilled = (state, action: any) => {
  if (action.payload.procedureReply) {
    state.procedureReply = action.payload.procedureReply;
    toast.success('Procedure successful');
  } else {
    toast.error('Procedure failed');
  }
};

export const sendWholeConfigDeviceFulfilled = (state, action: any) => {
  if (action.payload) {
    state.unsaved.unsavedChanges = false;
    state.trackedConfig = state.config;
    toast.success('Config sent');
  } else {
    toast.error('Device not connected');
  }
};

export const sendAllFingersConfigFulfilled = (state, action) => {
  if (action.payload) {
    toast.success('Fingers config sent');
    state.unsaved.unsavedChanges = false;
  } else if (action.payload === false) {
    toast.error('Device not connected');
  } else {
    toast.error(action.payload);
  }
};
