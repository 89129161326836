/* eslint-disable no-unused-vars */
import { delay } from 'bluetooth/Bluetooth/Utilities';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import {
  compareConfigsAPI,
  selectCommonConfig,
  selectModeConfig
} from 'reducers/bluetoothReducer/bluetoothHelpers/bluetoothHelpers';
import { selectDeviceInfo } from 'reducers/deviceInfoReducer';
import { importConfig, setModesState } from 'reducers/bluetoothReducer/bluetoothReducer';
import {
  getInitialConfig,
  getInitialConfigAPI,
  resetToDefault,
  sendWholeConfigDevice
} from 'reducers/bluetoothReducer/bluetoothHelpers/asyncThunks';
import useSynchronizeBluetooth from 'hooks/bluetooth/useSynchronizeBluetooth';
import toast from 'react-hot-toast';
import { setItemUI } from 'reducers/uiReducer';
import {
  useImportTemplate,
  useRestoreConfig,
  useSendTestConfig,
  useUpdateConfigDemo,
  useUpdateDeviceConfig
} from './useDevice';

export const useDeviceManager = () => {
  const dispatch = useDispatch();
  const { updateConfig } = useUpdateDeviceConfig();
  const { sendTest } = useSendTestConfig();
  const { mutateAsync: restoreConfig } = useRestoreConfig();
  const { mutateAsync: importTemplate } = useImportTemplate();
  const { deviceId, bluetoothId } = useSelector(selectDeviceInfo);
  const { device, config, trackedConfig, trackedCommonConfig } = useSelector(
    (state: any) => state.bluetooth
  );
  const state = useSelector((state: any) => state.bluetooth);
  const { modesList, modeId } = useSelector((state: any) => state.modes);
  const { enabled: remoteSessionEnabled } = useSelector((state: any) => state.liveConfigurator);
  const synchronizeConfigBluetooth = useSynchronizeBluetooth();
  const { mutateAsync: updateConfigDemo } = useUpdateConfigDemo();

  const sendConfigToDevice = async () => {
    try {
      const commonConfig = selectCommonConfig(config);
      const modeConfig = selectModeConfig(config);
      const configToSend = compareConfigsAPI(trackedCommonConfig, commonConfig);
      const modeConfigToSend = compareConfigsAPI(
        state.modes.modesConfigs[`mode_${modeId}`].trackedConfig,
        modeConfig
      );
      if (deviceId) {
        await dispatch(sendWholeConfigDevice({ configToSend }));
        await dispatch(
          sendWholeConfigDevice({
            configToSend: modeConfigToSend
          })
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const restoreConfigDevice = async () => {
    // @ts-ignore
    const restoredConfig = await dispatch(resetToDefault()).unwrap();
    const configToSend = compareConfigsAPI(trackedConfig, restoredConfig);
    await delay(1000);
    await dispatch(getInitialConfigAPI());
  };

  const retrieveConfig = async () => {
    await dispatch(setItemUI({ type: 'shownGlobalModal', payload: 'restoreRecent' }));
  };

  const handleChangingConfigState = async (updatedConfig) => {
    const updatedConfigCopy = { ...updatedConfig };
    await dispatch(setModesState(updatedConfigCopy));
    if (device.connected) {
      await dispatch(setItemUI({ type: 'shownGlobalModal', payload: 'prevent' }));
      const localConfig = await dispatch(
        getInitialConfig()
        // @ts-ignore
      ).unwrap();
      await synchronizeConfigBluetooth({
        deviceConfig: {
          common: localConfig.common,
          modes: localConfig.modesConfigs.map((modeConfig) => ({
            config: modeConfig.config,
            slot: modeConfig.slot,
            id: modeConfig.id
          }))
        },
        apiConfig: {
          common: updatedConfig.common,
          modes: modesList.map((mode) => ({
            config: updatedConfig[`mode_${mode.id}`],
            slot: mode.slot,
            id: mode.id
          }))
        }
      });
      await dispatch(setItemUI({ type: 'shownGlobalModal', payload: null }));
    }
  };

  const restoreConfigHistory = async (modeId: number, configId: number, _config: any) => {
    if (_config && remoteSessionEnabled) {
      await dispatch(importConfig({ config: _config }));
      toast.success('Config imported');
      return;
    }
    const updatedConfig = await restoreConfig({ deviceId, modeId, configId });
    handleChangingConfigState(updatedConfig);
  };

  const importTemplateConfig = async (modeId: number, templateId: number, _config: any) => {
    if (_config && remoteSessionEnabled) {
      await dispatch(importConfig({ config: _config }));
      toast.success('Template imported');
      return;
    }
    const updatedConfig = await importTemplate({ deviceId, modeId, templateId });
    handleChangingConfigState(updatedConfig);
  };

  const sendConfigOnlyToApi = async (description?) => {
    try {
      await dispatch(setItemUI({ type: 'shownGlobalModal', payload: 'prevent' }));
      const commonConfig = selectCommonConfig(config);
      let sendTestConfigResponse;
      if (deviceId && !isEmpty(commonConfig) && modesList) {
        const modeConfig = selectModeConfig(config);
        const configPayload = {
          deviceId,
          data: {
            common: JSON.stringify(commonConfig),
            modes: [{ id: modeId, config: JSON.stringify(modeConfig) }]
          }
        };
        console.log(JSON.stringify(commonConfig));
        sendTestConfigResponse = await sendTest({
          ...configPayload,
          data: { ...configPayload.data, description }
        });
        if (device.connected) {
          const updatedConfig = await updateConfig(configPayload);
          dispatch(setModesState(updatedConfig));
          updateConfigDemo({
            deviceId,
            demoId: sendTestConfigResponse.messages[0].config_demo.id,
            data: { is_accepted: 1 }
          });
        }
      }
      await dispatch(setItemUI({ type: 'shownGlobalModal', payload: null }));
      return sendTestConfigResponse;
    } catch (error) {
      await dispatch(setItemUI({ type: 'shownGlobalModal', payload: null }));
      console.log('error', error);
      return null;
    }
  };

  return {
    sendConfigToDevice,
    restoreConfigDevice,
    restoreConfigHistory,
    importTemplateConfig,
    sendConfigOnlyToApi,
    retrieveConfig
  };
};
