/* eslint-disable no-unused-vars */
export interface DeviceInfoEntry {
  serial: string | null;
  bluetoothId: string | null;
  deviceId: string | null;
  amputeeId: string | null;
}

export enum TypesConfig {
  bool,
  value
}

export enum NoteTypes {
  private = 'Private',
  public = 'Public'
}

export enum TemplateTypes {
  me = 'Me',
  company = 'Company'
}

export enum SortDirs {
  asc = 'asc',
  desc = 'desc'
}

interface Paginator {
  total: number;
  perpage: number;
  current_page: number;
  last_page: number;
}

export enum UserRoles {
  superAdmin = 'SuperAdmin',
  clinician = 'Clinician',
  amputee = 'Amputee'
}

export interface PaginatedResponse<T> {
  paginator: Paginator;
  items: T[];
}
