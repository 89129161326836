import styled from 'styled-components';

const ChipWrapper = styled.div`
  font-size: 14px;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  background-color: #e9ecef;
  padding: 4px 8px;
  border-radius: 100px;
`;

export const Chip = ({ children, ...props }: any) => (
  <ChipWrapper {...props}>{children}</ChipWrapper>
);
