/* eslint-disable no-restricted-syntax */
/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
/* eslint-disable no-bitwise */
/* eslint-disable no-await-in-loop */
import {
  timeoutCommandCustom,
  timeoutCommand,
  timeoutCommandLite,
  timeoutCommandLiteCustom,
  timeoutCommandFirmware
} from 'utils/funcs';
import {
  gripPairsConfigEntry,
  controlConfigEntry,
  gripSequentialConfigEntry,
  emgThresholdsEntry,
  intervalEntry,
  fingerStrengthEntry,
  autoGraspEntry,
  emgSpikeEntry,
  holdOpenEntry,
  softGripEntry,
  emgGainsEntry,
  pulseTimingsEntry,
  coContractionTimingsEntry,
  gripsPositionsEntry,
  rtcDate
} from 'consts/deviceConfig/deviceConfig.types';
import { ProcedureTypes } from 'bluetooth/Bluetooth/Procedures';
import BluetoothWebControllerLE from './bluetoothLE';
import BluetoothWebController from './bluetoothWeb';
import {
  InputSites,
  SpeedControlStrategies,
  GripSwitchingModes,
  ControlModes
} from '../bluetooth/Bluetooth/Control';
import { BootloaderStates, Commands } from '../bluetooth/Bluetooth/Defines';
import { Grips } from '../bluetooth/Bluetooth/Grips';
import { delay } from '../bluetooth/Bluetooth/Utilities';

const bluetooth = new BluetoothWebController();
const bluetoothLE = new BluetoothWebControllerLE();

const errors = {
  badConnection: 'Connection is corrupted, restart the prosthesis and try connecting again',
  badFingersConfig: 'Fingers config could not be sent, check prosthesis connection',
  badInitialConfig: 'Initial config could not be loaded, try again',
  badGripConfig: 'Grip config could not be sent, check prosthesis connection',
  badControlConfig: 'Control config could not be sent, check prosthesis connection',
  bootloaderNotResponding: 'Connection failed, bootloader not responding'
};

export enum bluetoothMode {
  classic = 'classic',
  ble = 'ble'
}

export type statusTypeFreeze = [0] | [1];

export const getBootloaderStatusTimed = async (mode: bluetoothMode): Promise<BootloaderStates> => {
  const [{ payload: bootloaderStatus }] = (await timeoutCommand(
    () =>
      bluetooth.queryResponseCommand(
        Commands.kQueryBootloaderStatus,
        [],
        Commands.kBootloaderStatus,
        mode
      ),
    errors.bootloaderNotResponding
  )) || [{ payload: false }];
  return bootloaderStatus;
};

export const getBootloaderStatus = async (mode: bluetoothMode): Promise<BootloaderStates> => {
  const [{ payload: bootloaderStatus }] = (await timeoutCommandLiteCustom(
    () =>
      bluetooth.queryResponseCommand(
        Commands.kQueryBootloaderStatus,
        [],
        Commands.kBootloaderStatus,
        mode
      ),
    10000,
    1
  )) || [{ payload: null }];
  return bootloaderStatus?.[0];
};

const waitAfterFinishedBootloader = async (mode: bluetoothMode) => {
  await delay(1000);
  let bootloaderStatus = await getBootloaderStatus(mode);
  while (
    (bootloaderStatus === BootloaderStates.waiting || bootloaderStatus === undefined) &&
    bluetooth.connected
  ) {
    bootloaderStatus = await getBootloaderStatus(mode);
  }
  return bootloaderStatus;
};

const waitForBootloader = async (
  awaitedBootloaderStates: BootloaderStates,
  mode: bluetoothMode
) => {
  let bootloaderStatus = await getBootloaderStatus(mode);
  while (bootloaderStatus !== awaitedBootloaderStates) {
    bootloaderStatus = await getBootloaderStatus(mode);
  }
  return bootloaderStatus;
};

// Waits until bootloader either returns fail or success
export const bootloaderAwaitFinished = (mode: bluetoothMode): Promise<BootloaderStates> =>
  timeoutCommandCustom(() => waitAfterFinishedBootloader(mode), 60000);

export const bootloaderAwaitStatus = (
  awaitedBootloaderStates: BootloaderStates,
  mode: bluetoothMode
): Promise<BootloaderStates> =>
  timeoutCommandCustom(() => waitForBootloader(awaitedBootloaderStates, mode), 10000);

export const getEmgThresholds = async (mode: bluetoothMode) => {
  const [{ payload: emgThresholds }] = (await timeoutCommandLite(() =>
    bluetooth.queryResponseCommand(
      Commands.kQueryEmgThresholds,
      [],
      Commands.kSetEmgThresholds,
      mode
    )
  )) || [{ payload: false }];
  return emgThresholds;
};

export const getGripsPairsConfig = async (mode: bluetoothMode) => {
  const [{ payload: gripPairsConfig }] = (await timeoutCommandLite(() =>
    bluetooth.queryResponseCommand(Commands.kQueryGripPairs, [], Commands.kSetGripPairs, mode)
  )) || [[{ payload: false }]];
  return gripPairsConfig;
};

export const getGripsSequentialConfig = async (mode: bluetoothMode) => {
  const [{ payload: gripSequentialConfig }] = (await timeoutCommandLite(() =>
    bluetooth.queryResponseCommand(
      Commands.kQueryGripsSequence,
      [],
      Commands.kSetGripsSequence,
      mode
    )
  )) || [{ payload: false }];
  return gripSequentialConfig;
};

export const getGripPositions = async (grip: Grips, mode: bluetoothMode): Promise<number[]> => {
  const [{ payload: gripPositions }]: [{ payload: number[] }] = (await timeoutCommandLiteCustom(
    () =>
      bluetooth.queryResponseCommand(
        Commands.kQueryInitialGripPositions,
        [grip],
        Commands.kInitialGripPositions,
        mode
      ),
    250,
    5
  )) || [{ payload: false }];
  return gripPositions;
};

export const getGripLimitPositions = async (
  grip: Grips,
  mode: bluetoothMode
): Promise<number[]> => {
  const [{ payload: gripLimitPositions }] = (await timeoutCommandLiteCustom(
    () =>
      bluetooth.queryResponseCommand(
        Commands.kQueryFingerLimits,
        [grip],
        Commands.kSetFingerLimits,
        mode
      ),
    250,
    5
  )) || [{ payload: false }];
  return gripLimitPositions;
};

export const getControlConfig = async (mode: bluetoothMode) => {
  const [{ payload: controlConfig }] = (await timeoutCommandLite(() =>
    bluetooth.queryResponseCommand(Commands.kQueryControlConfig, [], Commands.kControlConfig, mode)
  )) || [{ payload: false }];
  return controlConfig;
};

export const getAutoGrasp = async (mode: bluetoothMode) => {
  const [{ payload: autoGrasp }] = (await timeoutCommandLite(() =>
    bluetooth.queryResponseCommand(
      Commands.kQueryAutograspSettings,
      [],
      Commands.kAutoGraspSettings,
      mode
    )
  )) || [{ payload: false }];
  return autoGrasp;
};

export const getHoldOpen = async (mode: bluetoothMode) => {
  const [{ payload: holdOpen }] = (await timeoutCommandLite(() =>
    bluetooth.queryResponseCommand(
      Commands.kQueryHoldOpenGripSwitchingSettings,
      [],
      Commands.kSetHoldOpenGripSwitchingSettings,
      mode
    )
  )) || [{ payload: false }];
  return holdOpen;
};

export const getSoftGrip = async (mode: bluetoothMode) => {
  const [{ payload: softGrip }] = (await timeoutCommandLite(() =>
    bluetooth.queryResponseCommand(
      Commands.kQueryCurrentControlStrategy,
      [],
      Commands.kSetCurrentControlStrategy,
      mode
    )
  )) || [{ payload: false }];
  return softGrip;
};

export const getEmgSpike = async (mode: bluetoothMode) => {
  const [{ payload: emgSpike }] = (await timeoutCommandLite(() =>
    bluetooth.queryResponseCommand(
      Commands.kQueryEMGSpikesCancelling,
      [],
      Commands.kSetEMGSpikesCancellingSettings,
      mode
    )
  )) || [{ payload: false }];
  return emgSpike;
};

export const getFingerStrength = async (mode: bluetoothMode) => {
  const [{ payload: fingerStrength }] = (await timeoutCommandLite(() =>
    bluetooth.queryResponseCommand(
      Commands.kFrameTypeQueryFingerCurrentThreshold,
      [1],
      Commands.kFrameTypeFingerCurrentThreshold,
      mode
    )
  )) || [{ payload: false }];
  return fingerStrength;
};

const getTelemetryData = async (mode: bluetoothMode) => {
  const data = await bluetooth.queryResponseCommand(
    Commands.kStartOrStopTransmittingTelemetryData,
    [1],
    Commands.kTelemetryData,
    mode
  );
  return data;
};

export const queryTelemetryOnce = async (mode: bluetoothMode) => {
  if (bluetooth.connected || bluetoothLE.connected) {
    const data = await getTelemetryData(mode);
    const telemetryObject = {
      fingers: {
        0: {},
        1: {},
        2: {},
        3: {},
        4: {}
      },
      isThumbOpposed: !(data[0].payload[22] & 0x02)
    };
    for (let i = 0; i < 5; i += 1) {
      telemetryObject.fingers[i].encoderTicks = data[0].payload[i * 4];
    }
    await telemetryEnabled(false, mode);
    return telemetryObject;
  }
  return false;
};

export const telemetryEnabled = async (telemetryStatus: boolean, mode: bluetoothMode) => {
  let telemetryStatusNum: number = 0;
  if (telemetryStatus === true) {
    telemetryStatusNum = 1;
  }

  await bluetooth.writeWeb(
    Commands.kStartOrStopTransmittingTelemetryData,
    [telemetryStatusNum],
    mode
  );
};

export const enterBootloaderMode = async (
  bootloaderMode: BootloaderStates,
  mode: bluetoothMode
) => {
  await bluetooth.writeWeb(Commands.kEnterBootloaderMode, [bootloaderMode], mode);
};

export const getSerialNumber = async (mode: bluetoothMode) => {
  const [{ payload: serialNumber }] = (await timeoutCommandLite(() =>
    bluetooth.queryResponseCommand(
      Commands.kQuerySerialNumber,
      [],
      Commands.kSerialNumberReply,
      mode
    )
  )) || [{ payload: false }];
  return serialNumber;
};

export const getBootloaderVersion = async (mode: bluetoothMode) => {
  const [{ payload: bootloaderVersion }] = (await timeoutCommandLite(() =>
    bluetooth.queryResponseCommand(
      Commands.kQueryBootloaderVersion,
      [],
      Commands.kBootloaderVersion,
      mode
    )
  )) || [{ payload: false }];
  return bootloaderVersion;
};

export const getInterval = async (mode: bluetoothMode) => {
  const [{ payload: interval }] = (await timeoutCommandLite(() =>
    bluetooth.queryResponseCommand(
      Commands.kQueryLongCoContractionTime,
      [],
      Commands.kSetIntervalBetweenCocontractionPulses,
      mode
    )
  )) || [{ payload: false }];
  return interval;
};

export const getFirmwareVersion = async (mode: bluetoothMode) => {
  const [{ payload: firmwareVersion }] = (await timeoutCommandLite(() =>
    bluetooth.queryResponseCommand(
      Commands.kQueryFirmwareAndAppVersions,
      [],
      Commands.kFirmwareAndAppVersions,
      mode
    )
  )) || [{ payload: false }];
  return firmwareVersion;
};

export const getFreezeMode = async (mode: bluetoothMode) => {
  const [{ payload: freezeMode }] = (await timeoutCommandLite(() =>
    bluetooth.queryResponseCommand(
      Commands.kFrameTypeQueryFreezeMode,
      [],
      Commands.kFrameTypeFreezeMode,
      mode
    )
  )) || [{ payload: false }];
  return freezeMode;
};

export const waitRequestAction = async (mode: bluetoothMode) => {
  const [{ payload: action }] = (await timeoutCommandCustom(
    () => bluetooth.queryResponseCommand(null, null, Commands.kRequestAction, mode),
    10000
  )) || [{ payload: false }];
  return action;
};

export const getEmgGains = async (mode: bluetoothMode) => {
  const [{ payload: emgGains }] = (await timeoutCommandLite(() =>
    bluetooth.queryResponseCommand(Commands.kQuerySignalGains, [], Commands.kSetSignalGains, mode)
  )) || [{ payload: false }];
  return emgGains;
};

export const getPulseTimings = async (mode: bluetoothMode) => {
  const [{ payload: pulseTimings }] = (await timeoutCommandLite(() =>
    bluetooth.queryResponseCommand(Commands.kQueryPulseTimings, [], Commands.kPulseTimings, mode)
  )) || [{ payload: false }];
  return pulseTimings;
};

export const getCoContractionTimings = async (mode: bluetoothMode) => {
  const [{ payload: coContractionTimings }] = (await timeoutCommandLite(() =>
    bluetooth.queryResponseCommand(
      Commands.kQueryCoContractionTimings,
      [],
      Commands.kCoContractionTimings,
      mode
    )
  )) || [{ payload: false }];
  return coContractionTimings;
};

export const sendFwPartWithResponse = async (fwPart, mode: bluetoothMode) => {
  const [{ payload: fwPartStatus }] = (await timeoutCommandFirmware(() =>
    bluetooth.queryResponseCommand(Commands.kPartOfFWImage, fwPart, Commands.kFwPartStatus, mode)
  )) || [{ payload: false }];
  return fwPartStatus;
};

export const runProcedure = async (
  procedureNumber: ProcedureTypes,
  mode: bluetoothMode
): Promise<number[]> => {
  const input = Array(120).fill(0);
  const [{ payload: procedureReply }] = (await timeoutCommandCustom(
    () =>
      bluetooth.queryResponseCommand(
        Commands.kFrameTypeRunProcedure,
        [procedureNumber, ...input],
        Commands.kFrameTypeProcedureReply,
        mode
      ),
    20000
  )) || [{ payload: false }];
  return procedureReply;
};

export const postCurrentGrip = async (grip: Grips, mode: bluetoothMode) => {
  await bluetooth.writeWeb(Commands.kSetGripMode, [grip], mode);
};

export const postControlConfig = async (controlConfig: controlConfigEntry, mode: bluetoothMode) =>
  bluetooth.writeWeb(Commands.kControlConfig, controlConfig, mode);

export const postGripPairs = async (gripPairsConfig: gripPairsConfigEntry, mode: bluetoothMode) =>
  bluetooth.writeWeb(Commands.kSetGripPairs, gripPairsConfig, mode);

export const postRtcTime = async (rtcDate: rtcDate, mode: bluetoothMode) => {
  await bluetooth.writeWeb(Commands.kSetRtcTime, rtcDate, mode);
};

export const postGripSequentialConfig = async (
  gripSequentialConfig: gripSequentialConfigEntry,
  mode: bluetoothMode
) => bluetooth.writeWeb(Commands.kSetGripsSequence, gripSequentialConfig, mode);

export const postInitialGripPositions = async (
  grip: Grips,
  initialGripPositions,
  mode: bluetoothMode
) => {
  await bluetooth.writeWeb(Commands.kInitialGripPositions, [grip, ...initialGripPositions], mode);
};

export const postFingerLimits = async (grip: Grips, fingerLimits, mode: bluetoothMode) => {
  await bluetooth.writeWeb(Commands.kSetFingerLimits, [grip, ...fingerLimits], mode);
};

export const postInputSite = async (inputSite: InputSites[], mode: bluetoothMode) => {
  await bluetooth.writeWeb(Commands.kInputSite, inputSite, mode);
};

export const postControlMode = async (controlMode: ControlModes[], mode: bluetoothMode) => {
  await bluetooth.writeWeb(Commands.kSetControlMode, controlMode, mode);
};

export const postSpeedControlStrategy = async (
  speedControlStrategy: SpeedControlStrategies[],
  mode: bluetoothMode
) => {
  await bluetooth.writeWeb(Commands.kSetSpeedControlStrategy, speedControlStrategy, mode);
};

export const postGripSwitchingModes = async (
  gripSwitchingMode: GripSwitchingModes[],
  mode: bluetoothMode
) => {
  const gripSwitchingModesSent = { frame: {}, gripFollowing: 0 | 1 };
  gripSwitchingModesSent.frame = await bluetooth.writeWeb(
    Commands.kSetGripSwitchingMode,
    gripSwitchingMode,
    mode
  );

  if (gripSwitchingMode[0] === GripSwitchingModes.kHoldOpen) {
    await postGripFollowing(0, mode);
    gripSwitchingModesSent.gripFollowing = 0;
  } else {
    await postGripFollowing(1, mode);
    gripSwitchingModesSent.gripFollowing = 1;
  }
  return gripSwitchingModesSent;
};

export const postEmgSpikeCancelling = async (emgSpike: emgSpikeEntry, mode: bluetoothMode) =>
  bluetooth.writeWeb(Commands.kSetEMGSpikesCancellingSettings, emgSpike, mode);

export const postAutoGrasp = async (autoGrasp: autoGraspEntry, mode: bluetoothMode) =>
  bluetooth.writeWeb(Commands.kAutoGraspSettings, autoGrasp, mode);

export const postHoldOpen = async (holdOpen: holdOpenEntry, mode: bluetoothMode) =>
  bluetooth.writeWeb(Commands.kSetHoldOpenGripSwitchingSettings, holdOpen, mode);

export const postSoftGrip = async (softGrip: softGripEntry, mode: bluetoothMode) =>
  bluetooth.writeWeb(Commands.kSetCurrentControlStrategy, softGrip, mode);

export const postEmgThresholds = async (
  emgThresholds: emgThresholdsEntry,
  mode: bluetoothMode,
  speedControlStrategy
) => {
  let emgThresholdsFrame;
  if (speedControlStrategy === SpeedControlStrategies.kThreshold) {
    const thresholdEmgSettings = [
      emgThresholds[5],
      emgThresholds[1],
      emgThresholds[2],
      emgThresholds[0]
    ];
    emgThresholdsFrame = await bluetooth.writeWeb(
      Commands.kSetEMGSettings,
      thresholdEmgSettings,
      mode
    );
  } else {
    emgThresholdsFrame = await bluetooth.writeWeb(Commands.kSetEmgThresholds, emgThresholds, mode);
  }
  return emgThresholdsFrame;
};

export const postInterval = async (interval: intervalEntry, mode: bluetoothMode) => {
  await bluetooth.writeWeb(Commands.kSetIntervalBetweenCocontractionPulses, interval, mode);
};

export const postFingerStrength = async (
  fingerStrength: fingerStrengthEntry,
  mode: bluetoothMode
) => bluetooth.writeWeb(Commands.kFrameTypeFingerCurrentThreshold, fingerStrength, mode);

export const postJointTargetPosition = async (finger, position, mode: bluetoothMode) => {
  await bluetooth.writeWeb(Commands.kSetJointTargetPosition, [finger, position], mode);
};

export const postFreezeMode = async (status: statusTypeFreeze, mode: bluetoothMode) => {
  await bluetooth.writeWeb(Commands.kFrameTypeFreezeMode, status, mode);
};

export const postEmgGains = async (emgGains: number[], mode: bluetoothMode) =>
  bluetooth.writeWeb(Commands.kSetSignalGains, emgGains, mode);

export const postGripFollowing = async (gripFollowing: 0 | 1, mode: bluetoothMode) => {
  await bluetooth.writeWeb(Commands.kSetGripFollowing, [gripFollowing], mode);
};

export const postAppReceivedProcedure = async (receivedProcedureNumber, mode: bluetoothMode) => {
  await bluetooth.writeWeb(Commands.kAppReceivedFrameCallback, [receivedProcedureNumber], mode);
};

export const postPulseTimings = async (pulseTimings: pulseTimingsEntry, mode: bluetoothMode) =>
  bluetooth.writeWeb(Commands.kPulseTimings, pulseTimings, mode);

export const postCoContractionTimings = async (
  coContractionTimings: number[],
  mode: bluetoothMode
) => bluetooth.writeWeb(Commands.kCoContractionTimings, coContractionTimings, mode);

export const postActiveMode = async (activeMode: number, mode: bluetoothMode) => {
  await bluetooth.writeWeb(Commands.kSelectActiveMode, [activeMode], mode);
};

export const postCommunicateMode = async (communicateMode: number, mode: bluetoothMode) => {
  await bluetooth.writeWeb(Commands.kSelectUsageMode, [communicateMode], mode);
};

export const postSaveSettings = async (mode: bluetoothMode) => {
  await bluetooth.writeWeb(Commands.kSaveSettings, [], mode);
};

export const sendFingersConfigHelper = async (
  grip,
  valuesInitial,
  valuesLimit,
  bluetoothMode: bluetoothMode
) => {
  await postInitialGripPositions(grip, valuesInitial, bluetoothMode);
  await postFingerLimits(grip, valuesLimit, bluetoothMode);
  const gripValuesSent = [...valuesInitial, ...valuesLimit];

  return { gripValuesSent };
};

export const sendAllFingersHelper = async (
  gripsPositions: gripsPositionsEntry,
  bluetoothMode: bluetoothMode
) => {
  for (const grip in gripsPositions) {
    if (Object.prototype.hasOwnProperty.call(gripsPositions, grip)) {
      const gripPositions = gripsPositions[grip];
      await sendFingersConfigHelper(
        grip,
        gripPositions.initial,
        gripPositions.limit,
        bluetoothMode
      );
    }
  }
};

const sanitizeGripsSequential = (gripSequentialConfigToSend) => {
  const sanitizedGripPairConfig = [
    ...gripSequentialConfigToSend.slice(0, 5),
    255,
    ...gripSequentialConfigToSend.slice(6, 11),
    255
  ];
  return sanitizedGripPairConfig;
};

export const ConfigToSendFunctionMapping = {
  gripPairsConfig: (gripPairsConfig: gripPairsConfigEntry, mode: bluetoothMode) =>
    postGripPairs(gripPairsConfig, mode),
  inputSite: (inputSite, mode: bluetoothMode) => postInputSite(inputSite, mode),
  controlMode: (controlMode, mode: bluetoothMode) => postControlMode(controlMode, mode),
  speedControlStrategy: (speedControlStrategy, mode: bluetoothMode) =>
    postSpeedControlStrategy(speedControlStrategy, mode),
  gripSwitchingMode: (gripSwitchingMode, mode: bluetoothMode) =>
    postGripSwitchingModes(gripSwitchingMode, mode),
  gripSequentialConfig: (gripSequentialConfig: gripSequentialConfigEntry, mode: bluetoothMode) =>
    postGripSequentialConfig(
      // @ts-ignore
      sanitizeGripsSequential(gripSequentialConfig),
      mode
    ),
  emgThresholds: (emgThresholds: emgThresholdsEntry, mode: bluetoothMode, speedControlStrategy) =>
    postEmgThresholds(emgThresholds, mode, speedControlStrategy),
  gripsPositions: (gripsPositions: gripsPositionsEntry, mode: bluetoothMode) =>
    sendAllFingersHelper(gripsPositions, mode),
  interval: (interval: intervalEntry, mode: bluetoothMode) => postInterval(interval, mode),
  fingerStrength: (fingerStrength: fingerStrengthEntry, mode: bluetoothMode) =>
    postFingerStrength(fingerStrength, mode),
  autoGrasp: (autoGrasp: autoGraspEntry, mode: bluetoothMode) => postAutoGrasp(autoGrasp, mode),
  emgSpike: (emgSpike: emgSpikeEntry, mode: bluetoothMode) =>
    postEmgSpikeCancelling(emgSpike, mode),
  holdOpen: (holdOpen: holdOpenEntry, mode: bluetoothMode) => postHoldOpen(holdOpen, mode),
  softGrip: (softGrip: softGripEntry, mode: bluetoothMode) => postSoftGrip(softGrip, mode),
  emgGains: (emgGains: emgGainsEntry, mode: bluetoothMode) => postEmgGains(emgGains, mode),
  pulseTimings: (pulseTimings: pulseTimingsEntry, mode: bluetoothMode) =>
    postPulseTimings(pulseTimings, mode),
  coContractionTimings: (coContractionTimings: coContractionTimingsEntry, mode: bluetoothMode) =>
    postCoContractionTimings(coContractionTimings, mode)
};
