import styled from 'styled-components';

export const CustomTextArea = styled.textarea`
  width: 100%;
  padding: 16px;
  box-shadow: 0 4px 20px 0 rgba(61, 72, 68, 0.05);
  border-radius: 5px;
  background-color: #fff;
  border: solid 1px #eceef1;
  resize: vertical;
`;
