import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import Modal from 'utils/Modal/Modal';
import { Paragraph } from 'components/Typography/Paragraph';
import { Chip } from 'components/Chip/Chip';
import ModalBase from './ModalBase';
import { ModalWrapper } from './ModalStyles';

const ChipWrapper = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.dimensions.spacing * 0.5}px;
`;

const HandDetails = ({ handleClose }) => {
  const { device, versions } = useSelector((state: any) => state.bluetooth);
  return (
    <Modal>
      <ModalBase handleClick={handleClose} header='Hand details'>
        <ModalWrapper>
          <ChipWrapper>
            <Paragraph>Serial number:</Paragraph>
            <Chip>{device?.serialNumber}</Chip>
          </ChipWrapper>
          <ChipWrapper>
            <Paragraph>Firmware version:</Paragraph>
            <Chip>{versions?.current}</Chip>
          </ChipWrapper>
        </ModalWrapper>
      </ModalBase>
    </Modal>
  );
};

export default HandDetails;
