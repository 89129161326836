import { encodeQueryData } from 'api/utils/encodeQuery';
import {
  ModesConfigForUserQueryParams,
  ModeConfigBodyParams,
  RestoreConfigQueryParams
} from 'api/modes/modes.types';
import api from '../utils/apiClient';

export const GET_MODE_CONFIGS_FOR_USER = 'api/user/{userId}/config-modes';
export const GET_MODE_CONFIGS_FOR_DEVICE = 'api/device/{deviceId}/config-modes';
export const GET_MODE_CONFIG = 'api/device/{deviceId}/config-modes/{modeId}';
export const UPDATE_MODE_CONFIG = 'api/device/{deviceId}/config-modes/{modeId}';
export const RESTORE_CONFIG_HISTORY =
  'api/device/{deviceId}/config-modes/{modeId}/restore/{configId}';
export const IMPORT_CONFIG_TEMPLATE =
  'api/device/{deviceId}/config-modes/{modeId}/from-template/{templateId}';

const withCredentials = {
  withCredentials: true
};

export const getModeConfig = ({ deviceId, modeId }: { deviceId: number; modeId: number }) =>
  api
    .get(
      `${GET_MODE_CONFIG.replace('{deviceId}', `${deviceId}`).replace('{modeId}', `${modeId}`)}`,
      withCredentials
    )
    .then((res) => res.data);

export const getModesConfigForUser = ({
  userId,
  params = {}
}: {
  userId: number;
  params?: ModesConfigForUserQueryParams;
}) => {
  const queryString = encodeQueryData(params);
  return api
    .get(
      `${GET_MODE_CONFIGS_FOR_USER.replace('{userId}', `${userId}`)}?${queryString}`,
      withCredentials
    )
    .then((res) => res.data);
};

export const getModesConfigForDevice = ({ deviceId }: { deviceId: number }) =>
  api
    .get(`${GET_MODE_CONFIGS_FOR_DEVICE.replace('{deviceId}', `${deviceId}`)}`, withCredentials)
    .then((res) => res.data);

export const updateModeConfig = ({
  deviceId,
  modeId,
  data
}: {
  deviceId: number;
  modeId: number;
  data?: ModeConfigBodyParams;
}): Promise<any> =>
  api
    .put(
      UPDATE_MODE_CONFIG.replace('{deviceId}', `${deviceId}`).replace('{modeId}', `${modeId}`),
      data,
      withCredentials
    )
    .then((res) => res.data);

export const restoreConfig = ({
  deviceId,
  modeId,
  configId,
  params = {}
}: {
  deviceId: number;
  modeId: number;
  configId: number;
  params?: RestoreConfigQueryParams;
}): Promise<any> => {
  const queryString = encodeQueryData(params);
  return api
    .post(
      `${RESTORE_CONFIG_HISTORY.replace('{deviceId}', `${deviceId}`)
        .replace('{modeId}', `${modeId}`)
        .replace('{configId}', `${configId}`)}?${queryString}`,
      null,
      withCredentials
    )
    .then((res) => res.data);
};

export const importTemplate = ({
  deviceId,
  modeId,
  templateId
}: {
  deviceId: number;
  modeId: number;
  templateId: number;
}): Promise<any> =>
  api
    .post(
      IMPORT_CONFIG_TEMPLATE.replace('{deviceId}', `${deviceId}`)
        .replace('{modeId}', `${modeId}`)
        .replace('{templateId}', `${templateId}`),
      null,
      withCredentials
    )
    .then((res) => res.data);
