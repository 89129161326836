/* eslint-disable no-unused-vars */
/* eslint-disable dot-notation */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-restricted-syntax */

import React, { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import DefaultLayout from '../../layouts/DefaultLayout';
import { ButtonsWrapper, Header1, StyledForm, TipText, UploadArea } from './styled';
import { CustomInput } from '../../components/atoms/Input/CustomInput';
import Button from '../../components/atoms/Button/Button';
import Card from '../../components/atoms/Card/Card';
import { CustomTextArea } from '../../components/atoms/Input/CustomTextArea';
import Dropdown2 from '../../components/atoms/Dropdown/Dropdown2';
import Divider from '../../components/atoms/Divider/Divider';
import { selectDeviceInfo } from '../../reducers/deviceInfoReducer';
import { useUser } from '../../hooks/api/useUsers';
import { useDevice } from '../../hooks/api/useDevice';
import { useServicingParts, useServicingRepair } from '../../hooks/api/useServicing';
import FileInput from '../../components/atoms/Input/FileInput';

const ServicingForm = () => {
  const [files, setFiles] = useState([]);
  const { amputeeId, deviceId } = useSelector(selectDeviceInfo);
  const { result: amputee } = useUser(amputeeId);
  const { result: device } = useDevice(deviceId);
  const [selectedParts, setSelectedParts] = useState<string[]>([]);
  const [selectedReason, setSelectedReason] = useState();
  const { mutateAsync: createServicingRepair } = useServicingRepair();
  const { result: servicingParts } = useServicingParts({
    perpage: 1000
  });
  const {
    register,
    unregister,
    setValue,
    handleSubmit,
    formState: { errors }
  } = useForm();
  const { push } = useHistory();
  const onSubmit = async (e: any) => {
    try {
      const formData = new FormData();
      formData.append('device_id', deviceId);
      formData.append('user_id', amputeeId);

      if (files) {
        files.forEach((file) => {
          formData.append('files[]', file);
        });
      }

      if (selectedParts) {
        servicingParts
          .filter((item) => selectedParts.includes(item.id))
          .map((item) => ({ part_id: item.id, reason: e[`reason_${item.id}`] ?? null }))
          .filter((item: any) => item.reason !== null)
          .forEach((item, index) => {
            formData.append(`parts[${index}]`, item.part_id);
            formData.append(`parts[${index}]`, item.reason);
          });
      }
      await createServicingRepair(formData);
      push('/');
    } catch (error) {
      console.log('error', error);
    }
  };

  const handleClose = () => {
    push('/');
  };

  const handlePartChange = (option) => {
    if (selectedParts.includes(option)) {
      setSelectedParts(selectedParts.filter((item) => item !== option));
      return;
    }
    setSelectedParts((prev) => [...prev, option]);
  };

  const transformReasonForReplacement = () =>
    servicingParts &&
    servicingParts
      .filter((item) => selectedParts.includes(item.id))
      .map((item) => ({
        id: item.id,
        name: `for ${item.name}`
      }));

  return (
    <DefaultLayout>
      <Header1>Servicing</Header1>
      <Card style={{ width: 700, marginTop: 30 }}>
        <StyledForm onSubmit={handleSubmit((data) => onSubmit(data))}>
          <span>Full name of patient</span>
          <CustomInput readonly={Boolean(amputee)} value={amputee?.name} {...register('amputee')} />
          {errors.amputee && <span>This field is required</span>}
          {device && (
            <Dropdown2
              options={[{ id: device.id, name: device.serial }]}
              label='Devices:'
              onChange={() => {}}
              selected={device.id}
            />
          )}
          <Dropdown2
            options={servicingParts ?? []}
            label='Part:'
            onChange={handlePartChange}
            multi
            selected={selectedParts}
          />
          <Dropdown2
            options={transformReasonForReplacement() ?? []}
            label='Reason for the replacement:'
            onChange={(e) => setSelectedReason(e)}
            selected={selectedReason}
          />
          <CustomTextArea
            rows={6}
            cols={50}
            {...register(`reason_${selectedReason}`)}
            key={`reason_desc__${selectedReason}`}
            placeholder='Describe the change'
          />
          <Divider margin='10px' />
          <span>Upload an attachment</span>
          <FileInput
            register={register}
            setValue={setValue}
            onChange={(files) => setFiles(files)}
            unregister={unregister}
            name='attachments'
            mode='update'
          />
          <ButtonsWrapper>
            <Button onClick={handleClose} secondary type='button'>
              Cancel
            </Button>
            <Button type='submit' disabled={false}>
              Send request
            </Button>
          </ButtonsWrapper>
        </StyledForm>
      </Card>
    </DefaultLayout>
  );
};

export default ServicingForm;
