import { useSelector } from 'react-redux';
import { useDeviceManager } from './api/useDeviceManager';
import useModes from './useModes';
import useRemoteSession from './useRemoteSession';

const useSidebar = () => {
  const { handleOpenSave } = useModes();
  const { retrieveConfig } = useDeviceManager();
  const { rollbackToLastRestorePoint } = useRemoteSession();
  const { enabled: liveConfiguratorEnabled } = useSelector((state: any) => state.liveConfigurator);

  return {
    handleSave: handleOpenSave,
    handleRestoreRecent: () =>
      liveConfiguratorEnabled ? rollbackToLastRestorePoint() : retrieveConfig()
  };
};

export default useSidebar;
