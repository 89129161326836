/* eslint-disable react/prop-types */
/* eslint-disable react/require-default-props */
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Slider from 'components/atoms/Slider/Slider';
import { TextS } from 'components/atoms/Typography/Typography';

const Wrapper = styled.div`
  text-align: center;
  ${({ labelHorizontal }) =>
    labelHorizontal &&
    css`
      display: flex;
      flex-direction: row;
      align-items: center;
      text-align: left;
    `};
`;

const Label = styled.div`
  ${TextS};

  ${({ labelHorizontal }) =>
    labelHorizontal &&
    css`
      margin: 0;
    `};
`;

const InnerWrapper = styled.div`
  width: 100%;
`;

const ValueWrapper = styled.span`
  color: ${({ theme }) => theme.colorPrimary};
`;

const HorizontalGraphSlider = memo(
  ({
    label,
    handleChange,
    handleOnAfterChange,
    handleOnBeforeChange,
    data,
    value,
    valueToDisplay,
    showSliderValue,
    limits,
    labelHorizontal,
    disableSliders,
    trackClass,
    ...props
  }) => (
    <Wrapper labelHorizontal={labelHorizontal}>
      <InnerWrapper>
        <Slider
          handleChange={handleChange}
          handleOnAfterChange={handleOnAfterChange}
          handleOnBeforeChange={handleOnBeforeChange}
          data={data}
          value={value}
          min={limits.min}
          max={limits.max}
          disable={disableSliders}
          trackClass={trackClass || 'track-default'}
          {...props}
        />
      </InnerWrapper>
      {showSliderValue ? (
        <Label labelHorizontal={labelHorizontal}>
          {label}: <ValueWrapper>{valueToDisplay || value}</ValueWrapper>
        </Label>
      ) : (
        <Label labelHorizontal={labelHorizontal}>{label}</Label>
      )}
    </Wrapper>
  )
);

HorizontalGraphSlider.defaultProps = {
  data: {},
  handleChange: () => true,
  handleOnAfterChange: () => true,
  handleOnBeforeChange: () => true,
  showSliderValue: false,
  labelHorizontal: false,
  trackClass: ''
};

HorizontalGraphSlider.propTypes = {
  label: PropTypes.string.isRequired,
  handleChange: PropTypes.func,
  data: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  handleOnAfterChange: PropTypes.func,
  handleOnBeforeChange: PropTypes.func,
  value: PropTypes.number.isRequired,
  showSliderValue: PropTypes.bool,
  limits: PropTypes.objectOf(PropTypes.number).isRequired,
  labelHorizontal: PropTypes.bool,
  trackClass: PropTypes.string
};

export default HorizontalGraphSlider;
