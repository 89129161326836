import { delay } from 'bluetooth/Bluetooth/Utilities';
import {
  MINIMAL_SUPPORTED_FIRMWARE_VERSION,
  REQUIRED_SUPPORTED_FIRMWARE_VERSION
} from 'consts/consts';
import { useUpdateDeviceConfig } from 'hooks/api/useDevice';
import { useUpdateModeConfig } from 'hooks/api/useModesConfig';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import {
  disconnectDevice,
  getInitialConfig,
  getInitialConfigAPI,
  getFirmwareDevice,
  connectDevice
} from 'reducers/bluetoothReducer/bluetoothHelpers/asyncThunks';
import { selectCommonConfig } from 'reducers/bluetoothReducer/bluetoothHelpers/bluetoothHelpers';
import { setItem } from 'reducers/bluetoothReducer/bluetoothReducer';
import { selectDeviceInfo } from 'reducers/deviceInfoReducer';
import { getModesListAPI } from 'reducers/modesReducer/modesReducer';
import { setItemUI } from 'reducers/uiReducer';
import { numericalFirmwareVersion } from 'utils/funcs';

const useBluetooth = () => {
  const dispatch = useDispatch();
  const { bluetoothId, deviceId } = useSelector(selectDeviceInfo);
  const { firstConnection } = useSelector((state: any) => state.bluetooth);
  const { modesList } = useSelector((state: any) => state.modes);
  const { firmware } = useSelector((state: any) => state.deviceInfo);
  const { updateConfig } = useUpdateDeviceConfig();
  const { mutateAsync: updateMode } = useUpdateModeConfig();

  const bluetoothConnect = async () => {
    // @ts-ignore
    const device = await dispatch(connectDevice({ bluetoothId })).unwrap();
    const connected = device.status;
    let firmwareDevice;
    if (connected) {
      // @ts-ignore
      firmwareDevice = await dispatch(getFirmwareDevice()).unwrap();
    }

    if (connected && firmwareDevice) {
      let status;
      const firmwareVersionBluetooth = firmwareDevice.firmwareVersion
        .slice(0, 8)
        .map((item) => String.fromCharCode(item))
        .join('');
      const firmwareVersionBluetoothParsed = `${firmwareVersionBluetooth[1]}.${firmwareVersionBluetooth[4]}.${firmwareVersionBluetooth[7]}`;
      const firmwareVersionAPI = firmware?.name;
      if (firmwareVersionAPI !== firmwareVersionBluetoothParsed) {
        toast(
          'Firmware version of the device is different from the one stored in DTP, contact support.',
          { duration: 8000, icon: '⚠️' }
        );
        dispatch(setItem({ type: 'firmwareConflict', payload: true }));
      }

      if (
        numericalFirmwareVersion(firmwareVersionBluetoothParsed) >=
        MINIMAL_SUPPORTED_FIRMWARE_VERSION
      ) {
        console.log('GET CONFIG');
        // @ts-ignore
        status = await dispatch(getInitialConfig()).unwrap();
      }

      if (
        firstConnection &&
        numericalFirmwareVersion(firmwareVersionBluetoothParsed) >=
          MINIMAL_SUPPORTED_FIRMWARE_VERSION
      ) {
        await dispatch(setItemUI({ type: 'shownGlobalModal', payload: 'prevent' }));
        Promise.all([
          modesList.map((mode, index) =>
            updateMode({
              deviceId,
              modeId: mode.id,
              data: { slot: index }
            })
          )
        ]);
        const modesArray = modesList.map((mode, index) => ({
          id: status.modesConfigs[index].id,
          config: JSON.stringify(status.modesConfigs[index].config)
        }));
        await updateConfig({
          deviceId,
          data: {
            common: JSON.stringify(selectCommonConfig(status.common)),
            modes: modesArray
          }
        });
        await delay(500);
        await dispatch(getInitialConfigAPI());
        await dispatch(getModesListAPI({ deviceId }));
        await dispatch(setItemUI({ type: 'shownGlobalModal', payload: null }));
      }

      if (
        numericalFirmwareVersion(firmwareVersionBluetoothParsed) <
        REQUIRED_SUPPORTED_FIRMWARE_VERSION
      ) {
        await dispatch(setItem({ type: 'supported', payload: false }));
        await dispatch(setItemUI({ type: 'shownGlobalModal', payload: 'firmware' }));
      }
    }
  };

  const bluetoothDisconnect = async () => {
    // @ts-ignore
    dispatch(disconnectDevice({}));
    dispatch(setItemUI({ type: 'shownGlobalModal', payload: 'servicingModal' }));
  };

  return { bluetoothConnect, bluetoothDisconnect };
};

export default useBluetooth;
