import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { ReactComponent as Caret } from 'assets/caret-up-solid.svg';
import { Button } from '@progress/kendo-react-buttons';
import { DropDownList } from '@progress/kendo-react-dropdowns';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const PaginationButton = styled(Button)`
  border-color: ${({ theme }) => theme.palette.separator};
  min-width: 40px;

  &:hover {
    border-color: ${({ theme }) => theme.palette.subtleText};
    background-color: transparent;
    color: ${({ theme }) => theme.palette.primary};
  }

  &:focus {
    box-shadow: none;
  }

  ${({ active }) =>
    active &&
    css`
      border: 1px solid ${({ theme }) => theme.palette.primary};
      background-color: ${({ theme }) => theme.palette.primary};
      color: white;
    `};
`;

const PaginationItemsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  column-gap: 10px;

  ${({ inactive }) =>
    inactive &&
    css`
      opacity: 0.5;
      z-index: -1;
      cursor: auto;
      pointer-events: none;
    `}
`;

const Pagination = ({ onPageChange, skip, take, total, perPage = 12, pageSize = [12, 24, 50] }) => {
  const [currentPage, setCurrentPage] = useState(Math.floor(skip / take) + 1);
  const [totalPages, setTotalPage] = useState(Math.ceil((total || 0) / take));
  const buttonsCount = 5;
  const buttons: number[] = [];

  const changePage = (page: number) => {
    if (onPageChange && page > 0 && page <= totalPages)
      onPageChange({
        skip: (page - 1) * take,
        take
      });
  };

  useEffect(() => {
    setTotalPage(Math.ceil((total || 0) / take));
  }, [total, take]);

  useEffect(() => {
    setCurrentPage(Math.floor(skip / take) + 1);
  }, [take, skip]);

  const start = (): number => {
    const page: number = currentPage;
    const buttonCount: number = buttonsCount;

    if (page > buttonCount) {
      const reminder: number = page % buttonCount;

      return reminder === 0 ? page - buttonCount + 1 : page - reminder + 1;
    }

    return 1;
  };

  const end = (): number => Math.min(start() + buttonsCount - 1, totalPages);

  console.log('start, end', start(), end());
  for (let idx: number = start(); idx <= end(); idx += 1) {
    buttons.push(idx);
  }
  return (
    <Wrapper>
      <PaginationItemsWrapper>
        <div>Show items</div>
        <DropDownList
          data={pageSize}
          onChange={(e) => onPageChange({ skip: 0, take: parseInt(e.target.value, 10) })}
          value={perPage}
        />
      </PaginationItemsWrapper>
      <div>
        <PaginationButton
          fillMode='outline'
          rounded={null}
          icon='arrow-end-left'
          onClick={() => changePage(1)}
          type='button'
        />
        <PaginationButton
          disabled={currentPage === 1}
          fillMode='outline'
          icon='arrow-60-left'
          rounded={null}
          onClick={() => changePage(currentPage - 1)}
        />
        {Array.from(buttons)
          .filter((item) => item !== undefined)
          .map((page = 0) => (
            <PaginationButton
              fillMode='outline'
              rounded={null}
              active={currentPage === page}
              onClick={() => changePage(page)}
              type='button'>
              {page}
            </PaginationButton>
          ))}
        <PaginationButton
          disabled={currentPage >= totalPages}
          fillMode='outline'
          rounded={null}
          icon='arrow-60-right'
          onClick={() => changePage(totalPages)}
          type='button'
        />
        <PaginationButton
          disabled={currentPage >= totalPages}
          fillMode='outline'
          rounded={null}
          icon='arrow-end-right'
          onClick={() => changePage(totalPages)}
          type='button'
        />
      </div>
      <div>
        <span>
          {Math.min(skip + 1, total)}-{Math.min(skip + take, total)} of {total} items
        </span>
      </div>
    </Wrapper>
  );
};

export default Pagination;
