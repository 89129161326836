import { createSlice } from '@reduxjs/toolkit';
import { updateComplexItem, updateItem } from './reducerUtils';

type SliceState = {
  tour: {};
  shownGlobalModal: boolean | null;
  isAuthorized: boolean;
  isNavbarCollapsed: boolean;
  isCalendarHistory: boolean;
  language: 'en' | 'pl';
};

const initialState: SliceState = {
  tour: {
    tourLocation: null,
    run: false
  },
  shownGlobalModal: null,
  isAuthorized: false,
  isNavbarCollapsed: false,
  isCalendarHistory: true,
  language: 'en'
};

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setItemUI: updateItem,
    setComplexItemUI: updateComplexItem
  }
});

export const selectNavbarCollapsedUI = (state) => state.ui.isNavbarCollapsed;
export const selectIsAuthorizedUI = (state) => state.ui.isAuthorized;
export const selectTourUI = (state) => state.ui.tour;
export const selectShownGlobalModalUI = (state) => state.ui.shownGlobalModal;

export const { setItemUI, setComplexItemUI } = uiSlice.actions;

export default uiSlice.reducer;
