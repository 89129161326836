/* eslint-disable no-unused-vars */
import { createAsyncThunk, createSlice, Reducer } from '@reduxjs/toolkit';
import { initVideoSession } from 'api/liveSession/liveSession';
import toast from 'react-hot-toast';

export enum ViewModes {
  standard,
  standardEMG,
  minimal,
  minimalEMG
}

export const viewModesStandard = ViewModes.standard || ViewModes.standardEMG;

type MeetingState = {
  meetingStatus: boolean;
  meetingJwt: string | null;
  meetingJwtPatient: string | null;
  viewMode: ViewModes;
  roomId: string | null;
};

const initialState: MeetingState = {
  meetingStatus: false,
  viewMode: ViewModes.standard,
  roomId: null,
  meetingJwt: null,
  meetingJwtPatient: null
};

export const initVideoMeeting = createAsyncThunk(
  'meeting',
  async ({ guestId }: { guestId: number }) => initVideoSession({ guestId })
);

export const meetingSlice = createSlice({
  name: 'meeting',
  initialState,
  reducers: {
    setMeetingStatus: (state, action) => {
      state.meetingStatus = action.payload;
    },
    setViewMode: (state, action) => {
      state.viewMode = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(initVideoMeeting.fulfilled, (state, action) => {
      state.meetingStatus = true;
      state.roomId = action.payload.room_name;
      state.meetingJwt = action.payload.jwt_moderator;
      state.meetingJwtPatient = action.payload.jwt_guest;
    });
    builder.addCase(initVideoMeeting.rejected, (state) => {
      state.meetingStatus = false;
      toast.error("Couldn't start a video meeting");
    });
  }
});

export const { setMeetingStatus, setViewMode } = meetingSlice.actions;

export default meetingSlice.reducer as Reducer<MeetingState>;
