/* eslint-disable no-unused-vars */
import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { Button } from '@progress/kendo-react-buttons';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, auto);
`;

const OutlineButton = styled(Button)`
  border-color: ${({ theme }) => theme.palette.separator};
  min-width: 40px;

  &:hover {
    border-color: ${({ theme }) => theme.palette.subtleText};
    background-color: transparent;
    color: ${({ theme }) => theme.palette.primary};
  }

  &:focus {
    box-shadow: none;
  }

  ${({ active }) =>
    active &&
    css`
      border: 1px solid ${({ theme }) => theme.palette.primary};
      background-color: ${({ theme }) => theme.palette.primary};
      color: white;
    `};
`;

const ButtonToggle = ({ modes, activeId, toggle = () => false, ...props }) => (
  <Wrapper toggled={activeId} modes={modes} {...props}>
    {modes.map((mode) => (
      <OutlineButton
        fillMode='outline'
        rounded={null}
        onClick={() => toggle(mode.id)}
        active={activeId === mode.id}>
        {mode.name}
      </OutlineButton>
    ))}
  </Wrapper>
);

ButtonToggle.defaultProps = {
  toggled: false
};

ButtonToggle.propTypes = {
  modes: PropTypes.string.isRequired,
  toggled: PropTypes.bool
};

export default ButtonToggle;
