import React, { useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as Caret } from 'assets/caret-up-solid.svg';
import * as svgIcons from '@progress/kendo-svg-icons';
import { SvgIcon } from '@progress/kendo-react-common';

const AccordionWrapper = styled.div`
  display: ${({ open }) => (open ? 'block' : 'none')};
  margin-top: 16px;
`;

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledCaret = styled(Caret)`
  width: 20px;
  cursor: pointer;
  transform: ${({ open }) => (open ? 'rotate(0deg)' : 'rotate(180deg)')};
  color: ${({ theme }) => theme.typography.colors.subtitle};
`;

const ActionsWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 16px;
`;

const Header3 = styled.h3`
  font-size: 20px;
  font-weight: ${({ theme }) => theme.typography.fontWeights.bold};
  color: ${({ theme }) => theme.palette.primary};
`;

type AccordionProps = {
  children: any;
  header?: string;
  isOpen?: boolean;
  actions?: any;
  customHeader?: any;
  [x: string]: any;
};

const Accordion = ({ children, header, isOpen = true, customHeader = undefined, actions, ...props }: AccordionProps) => {
  const [open, setOpen] = useState(isOpen);

  return (
    <div {...props}>
      <HeaderWrapper>
        <ActionsWrapper>
          {customHeader || <Header3>{header}</Header3>}
          {actions}
        </ActionsWrapper>
        <SvgIcon
          style={{ cursor: 'pointer', width: '20px', height: '20px' }}
          icon={open ? svgIcons.chevronUpIcon : svgIcons.chevronDownIcon}
          onClick={() => setOpen((prev) => !prev)}
        />
      </HeaderWrapper>
      <AccordionWrapper open={open}>{children}</AccordionWrapper>
    </div>
  );
};

export default Accordion;
