import { AxiosResponse } from 'axios';
import api from '../utils/apiClient';
import { LiveSessionInitializePayload, P2PSession } from './liveSession.types';

export const CREATE_P2P_SESSION = 'api/p2p/create';
export const CLOSE_P2P_SESSION = 'api/p2p/{id}/close';
export const VIEW_P2P_SESSION = 'api/p2p/{clinicianId}/{amputeeId}';
export const INIT_VIDEO_SESSION = 'api/p2p/video/init/{guestId}';

const withCredentials = {
  withCredentials: true
};

export const initializeP2PSession = (data: LiveSessionInitializePayload): Promise<any> =>
  api
    .post(
      CREATE_P2P_SESSION,
      {
        amputee_id: data.amputee_id,
        amputee_uuid: data.amputee_uuid,
        clinician_uuid: data.clinician_uuid
      },
      withCredentials
    )
    .then((res) => res.data);

export const closeP2PSession = (id: number): Promise<any> =>
  api.post(CLOSE_P2P_SESSION.replace('{id}', `${id}`), {}, withCredentials).then((res) => res.data);

export const getP2PSession = (amputeeId: number, clinicianId: number): Promise<any> =>
  api
    .get<P2PSession>(
      VIEW_P2P_SESSION.replace('{clinicianId}', String(clinicianId)).replace(
        '{amputeeId}',
        String(amputeeId)
      )
    )
    .then((res: AxiosResponse) => res.data);

export const initVideoSession = ({ guestId }: { guestId: number }): Promise<any> =>
  api
    .post(INIT_VIDEO_SESSION.replace('{guestId}', String(guestId)), {}, withCredentials)
    .then((res) => res.data);
