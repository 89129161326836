import styled from 'styled-components';
import DropdownListImg from './DropdownList';

const GripWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const GripImage = styled.img`
  width: 140px;
  height: 140px;
  padding: 10px;
  background-color: ${({ theme }) => theme.palette.secondary};
  border-radius: 50%;
  margin-bottom: ${({ theme }) => theme.dimensions.spacing}px;
`;

const DropdownGrip = ({ grips, gripsImgs, value, localIndex, nonOpposed, updateGrips }) => (
  <GripWrapper data-testid='grips-dropdown'>
    <GripImage src={gripsImgs.get(value)} alt='Choosen grip' />
    <DropdownListImg
      grips={grips}
      gripsImgs={gripsImgs}
      value={value}
      onChange={(option) => updateGrips(option.value.value, localIndex, nonOpposed)}
    />
  </GripWrapper>
);

export default DropdownGrip;
