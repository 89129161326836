/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-shadow */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import {
  CONFIG_ENTRY_NOTES_KEY,
  DEVICE_CONFIG_HISTORY_ENTRY_QUERY_KEY,
  useConfigEntryNotes,
  useCreateConfigNote,
  useDeleteConfigNote,
  useDevice,
  useUpdateDeviceConfigEntry
} from 'hooks/api/useDevice';
import { HeaderM, HeaderS, TextS, TextXS } from 'components/atoms/Typography/Typography';
import { selectDeviceInfo } from 'reducers/deviceInfoReducer';
import { SortDirs } from 'types';
import CompanyImg from 'assets/company_img.jpg';
import { useLocation, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useQueryClient } from 'react-query';
import ButtonToggle from 'components/atoms/ButtonToggle/ButtonToggle';
import {
  configSettingsNamesMap,
  controlModeMap,
  gripsGroupsOptionsMap,
  gripSwitchingMap,
  inputSiteMap,
  speedControlMap
} from 'utils/definesLocal';
import { StatusIndicator } from 'components/atoms/ConfigItem/ConfigItem';
import Accordion from 'components/atoms/Accordion/Accordion';
import Loader from 'components/atoms/Loader/Loader';
import NotesList from 'components/organisms/NotesList/NotesList';
import { ConfigNotesSortOptions } from 'api/device/device.types';
import { AddTemplateModal } from 'components/molecules/Modals/AddTemplate';
import Divider from 'components/atoms/Divider/Divider';
import { useDeviceManager } from 'hooks/api/useDeviceManager';
import { useModal } from 'hooks/useModal';
import ConfigComponent from 'components/organisms/ConfigComponent/ConfigComponent';
import { transformConfig, transformConfigAPI } from 'utils/Config/transformConfig';
import RestoreConfigHistoryModal from 'components/molecules/Modals/RestoreConfigHistoryModal';
import { Button } from '@progress/kendo-react-buttons';
import { Avatar } from '@progress/kendo-react-layout';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Header1, HeaderWrapper } from '../styled';
import { Header } from '../../../components/Typography/Header';
import Card from '../../../components/Card/Card';

const Header4 = styled.h4`
  font-size: 20px;
  font-weight: ${({ theme }) => theme.typography.fontWeights.bold};
`;

const Header3 = styled.h3`
  ${HeaderM};
  margin-bottom: 16px;
`;

const TextMedium = styled.p`
  ${TextS}
`;

const PatientDetailsHeader = styled.p`
  font-size: 16px;
  margin-bottom: 8px;
`;

const PatientDetailsText = styled.p`
  font-size: 16px;
  font-weight: bold;
`;

const PatientDetailsAvatarWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const PatientDetailsAvatar = styled(Avatar)`
  width: 24px;
  height: 24px;
  flex-basis: 24px;
  margin-right: 10px;

  > span {
    font-size: 10px;
  }
`;

const TextSub = styled.p`
  ${TextXS};
  color: ${({ theme }) => theme.typography.colors.subtitle};
`;

const ButtonWrapper = styled.div`
  display: flex;
  gap: 20px;
`;

const Wrapper = styled.div`
  display: grid;
  gap: 32px;
  grid-template-areas:
    'config'
    'version'
    'notes';

  @media (min-width: ${({ theme }) => theme.breakpoints.small}) {
    grid-template-columns: 1fr 3fr 1fr;
    grid-template-areas:
      'patient config version'
      'notes config version';
    grid-template-rows: auto 1fr;
  }
`;

const ConfigDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (min-width: ${({ theme }) => theme.breakpoints.small}) {
    align-items: stretch;
  }
`;

const ValueWrapper = styled.span`
  background-color: ${({ theme }) => theme.colorFill};
  border-radius: 5px;
  padding: 5px;
  margin-bottom: 5px;
  display: inline-block;
`;

enum ToggleTypesChangesHistory {
  all = 0,
  emg = 1,
  grips = 2,
  prosthesis = 3
}

const DetailsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const PatientAvatarWrapper = styled.div`
  margin-right: 16px;
`;

const InnerWrapper = styled.div`
  margin-top: 24px;
  display: grid;
  gap: 16px;
`;

const ConfigurationHeaderWrapper = styled.div`
  display: flex;
  gap: 20px;
  align-items: baseline;
`;

const TableStyle = css`
  display: grid;
  grid-template-columns: 40% 1fr 1fr 1fr;
  justify-items: start;
  align-items: center;
`;

const TableBody = styled.div`
  ${TableStyle};
  border-bottom: 1px solid ${({ theme }) => theme.colorFill};
  padding-bottom: 20px;
`;

const TableBodyConfig = styled.div`
  ${TableStyle};

  &:first-child {
    padding: 0 0 20px 0;
  }

  padding: 20px 0;
  border-bottom: 2px solid ${({ theme }) => theme.colorFill};
`;

const TableHeader = styled.div`
  font-weight: 700;
  font-size: 18px;
`;

const TemplateActions = styled.div`
  display: flex;
  flex-direction: column;

  > button {
    font-weight: ${({ theme }) => theme.typography.fontWeights.bold};
    color: #1d2b4f;
    font-size: 16px;
  }
`;

const DeleteButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 15px 0;
  padding-bottom: 25px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.configFill};
  width: 100%;
`;

const ActionButtonError = styled(Button)`
  background-color: ${({ theme }) => theme.palette.error2};
  border: 1px solid ${({ theme }) => theme.palette.error2};
  font-weight: ${({ theme }) => theme.typography.fontWeights.bold};
  font-size: 16px;
  color: white;
  width: 100%;

  &:hover {
    background-color: ${({ theme }) => theme.palette.error};

    svg {
      fill: #fff;
    }
  }

  svg {
    fill: ${({ theme }) => theme.palette.error2};
  }
`;

export const ConfigToValueFunctionMapping = {
  gripsPositions: (value) => <div>{value}</div>,
  gripPairsConfig: (gripPairsConfig, index) => (
    <ul style={{ listStyle: 'none' }}>
      {index === 0 ? (
        <>
          <li>
            <strong>Primary</strong>
            <ul style={{ marginLeft: '6px', listStyle: 'none' }}>
              {gripPairsConfig.slice(0, 2).map((grip) => (
                <li>{gripsGroupsOptionsMap.get(grip)}</li>
              ))}
            </ul>
          </li>
          <li>
            <strong>Secondary</strong>
            <ul style={{ marginLeft: '6px', listStyle: 'none' }}>
              {gripPairsConfig.slice(2, 4).map((grip) => (
                <li>{gripsGroupsOptionsMap.get(grip)}</li>
              ))}
            </ul>
          </li>
        </>
      ) : (
        <>
          <li>
            <strong>Primary</strong>
            <ul style={{ marginLeft: '6px', listStyle: 'none' }}>
              {gripPairsConfig.slice(4, 6).map((grip) => (
                <li>{gripsGroupsOptionsMap.get(grip)}</li>
              ))}
            </ul>
          </li>
          <li>
            <strong>Secondary</strong>
            <ul style={{ marginLeft: '6px', listStyle: 'none' }}>
              {gripPairsConfig.slice(6, 8).map((grip) => (
                <li>{gripsGroupsOptionsMap.get(grip)}</li>
              ))}
            </ul>
          </li>
        </>
      )}
    </ul>
  ),
  inputSite: (inputSite) => inputSiteMap.get(inputSite),
  controlMode: (controlMode) => controlModeMap.get(controlMode),
  speedControlStrategy: (speedControlStrategy) => speedControlMap.get(speedControlStrategy),
  gripSwitchingMode: (gripSwitchingMode) => gripSwitchingMap.get(gripSwitchingMode),
  gripSequentialConfig: (gripSequentialConfig, index) =>
    index === 0 ? (
      <ul style={{ listStyle: 'none' }}>
        {gripSequentialConfig.slice(0, 5).map((grip) => (
          <li>{gripsGroupsOptionsMap.get(grip)}</li>
        ))}
      </ul>
    ) : (
      <ul style={{ listStyle: 'none' }}>
        {gripSequentialConfig.slice(6, 11).map((grip) => (
          <li>{gripsGroupsOptionsMap.get(grip)}</li>
        ))}
      </ul>
    ),
  emgThresholds: (value) => <div>{value}%</div>,
  fingerStrength: (value) => <div>{value}</div>,
  autoGrasp: (value, index) =>
    index === 0 ? (
      <div>
        <StatusIndicator status={value} />
        {value === 1 ? 'On' : 'Off'}
      </div>
    ) : (
      <div>{value}</div>
    ),
  emgSpike: (value, index) =>
    index === 0 ? (
      <div>
        <StatusIndicator status={value} />
        {value === 1 ? 'On' : 'Off'}
      </div>
    ) : (
      <div>{value} ms</div>
    ),
  holdOpen: (value) => <div>{value} ms</div>,
  softGrip: (value) => (
    <div>
      <StatusIndicator status={value} />
      {value === 1 ? 'On' : 'Off'}
    </div>
  ),
  emgGains: (value) => <div>{value}%</div>,
  pulseTimings: (value) => <div>{value} ms</div>,
  coContractionTimings: (value) => <div>{value} ms</div>
};

export const ConfigToNameFunctionMapping = {
  gripsPositions: (index) => {
    const gripsPositionsNamings = ['Thumb', 'Index', 'Middle', 'Ring', 'Pinky'];
    return gripsPositionsNamings[index];
  },
  gripPairsConfig: (index) => {
    const gripPairsConfigNaming = ['Opposed ', 'Non-Opposed'];
    return gripPairsConfigNaming[index];
  },
  inputSite: () => 'Value',
  controlMode: () => 'Value',
  speedControlStrategy: () => 'Value',
  gripSwitchingMode: () => 'Value',
  gripSequentialConfig: (index) => {
    const gripSequentialConfigNaming = ['Opposed ', 'Non-Opposed'];
    return gripSequentialConfigNaming[index];
  },
  emgThresholds: (index) => {
    const thresholdsNamings = [
      'CS extension threshold',
      'CS flexion threshold',
      'Threshold control open value',
      'Proportional control open second value',
      'Proportional control open third value',
      'Threshold control close value',
      'Proportional control close second value',
      'Proportional control close third value',
      'Soft-grip open threshold second value',
      'Soft-grip close threshold second value'
    ];
    return thresholdsNamings[index];
  },
  fingerStrength: () => 'Value',
  autoGrasp: (index) => {
    const autoGraspNamings = ['Status', 'Value'];
    return autoGraspNamings[index];
  },
  emgSpike: (index) => {
    const emgSpikeNamings = ['Status', 'Value'];
    return emgSpikeNamings[index];
  },
  holdOpen: (index) => {
    const holdOpenNamings = ['Primary hold open time', 'Secondary hold open time'];
    return holdOpenNamings[index];
  },
  softGrip: () => 'Status',
  emgGains: (index) => {
    const emgGainsNamings = ['Open signal gain', 'Close signal gain'];
    return emgGainsNamings[index];
  },
  pulseTimings: (index) => {
    const pulseTimingsNamings = [
      'Min. pulse time',
      'Max. pulse time',
      'Min. time between pulses',
      'Max. time between pulses'
    ];
    return pulseTimingsNamings[index];
  },
  coContractionTimings: (index) => {
    const coContractionNamings = ['Long co-contraction time', 'Signal rise offset time'];
    return coContractionNamings[index];
  }
};

export const ConfigToNameFunctionMappingHeader = {
  gripPairsConfig: (gripPairsConfig) => (
    <ul style={{ listStyle: 'none' }}>
      <li>
        <strong>Opposed</strong>
        <ul style={{ margin: '6px 0 0 6px', listStyle: 'none' }}>
          <li>
            <strong>Primary</strong>
            <ul style={{ marginLeft: '6px', listStyle: 'none' }}>
              {gripPairsConfig.slice(0, 2).map((grip) => (
                <li>{gripsGroupsOptionsMap.get(grip)}</li>
              ))}
            </ul>
          </li>
          <li>
            <strong>Secondary</strong>
            <ul style={{ marginLeft: '6px', listStyle: 'none' }}>
              {gripPairsConfig.slice(2, 4).map((grip) => (
                <li>{gripsGroupsOptionsMap.get(grip)}</li>
              ))}
            </ul>
          </li>
        </ul>
      </li>
      <li style={{ marginTop: '6px' }}>
        <strong>Non-Opposed</strong>
        <ul style={{ margin: '6px 0 0 6px', listStyle: 'none' }}>
          <li>
            <strong>Primary</strong>
            <ul style={{ marginLeft: '6px', listStyle: 'none' }}>
              {gripPairsConfig.slice(4, 6).map((grip) => (
                <li>{gripsGroupsOptionsMap.get(grip)}</li>
              ))}
            </ul>
          </li>
          <li>
            <strong>Secondary</strong>
            <ul style={{ marginLeft: '6px', listStyle: 'none' }}>
              {gripPairsConfig.slice(6, 8).map((grip) => (
                <li>{gripsGroupsOptionsMap.get(grip)}</li>
              ))}
            </ul>
          </li>
        </ul>
      </li>
    </ul>
  ),
  inputSite: () => 'Value',
  controlMode: (controlMode) => controlModeMap.get(controlMode[0]),
  speedControlStrategy: (speedControlStrategy) => speedControlMap.get(speedControlStrategy[0]),
  gripSwitchingMode: (gripSwitchingMode) => gripSwitchingMap.get(gripSwitchingMode[0]),
  gripSequentialConfig: (gripSequentialConfig) => (
    <ul style={{ listStyle: 'none' }}>
      <li>
        <strong>Opposed</strong>
        <ul style={{ margin: '6px 0 0 6px', listStyle: 'none' }}>
          {gripSequentialConfig.slice(0, 5).map((grip) => (
            <li>{gripsGroupsOptionsMap.get(grip)}</li>
          ))}
        </ul>
      </li>
      <li style={{ marginTop: '6px' }}>
        <strong>Non-Opposed</strong>
        <ul style={{ margin: '6px 0 0 6px', listStyle: 'none' }}>
          {gripSequentialConfig.slice(6, 11).map((grip) => (
            <li>{gripsGroupsOptionsMap.get(grip)}</li>
          ))}
        </ul>
      </li>
    </ul>
  ),
  emgThresholds: (index) => {
    const thresholdsNamings = [
      'CS extension threshold',
      'CS flexion threshold',
      'Threshold control open value',
      'Proportional control open second value',
      'Proportional control open third value',
      'Threshold control close value',
      'Proportional control close second value',
      'Proportional control close third value',
      'Soft-grip open threshold second value',
      'Soft-grip close threshold second value'
    ];
    return thresholdsNamings[index];
  },
  fingerStrength: (value) => <div>{value}</div>,
  autoGrasp: (autoGrasp) => (
    <ul style={{ listStyle: 'none' }}>
      <li>
        Status: <StatusIndicator status={autoGrasp[0]} />
        {autoGrasp[0] === 1 ? 'On' : 'Off'}
      </li>
      <li>Value: {autoGrasp[1]}</li>
    </ul>
  ),
  emgSpike: (emgSpike) => (
    <ul style={{ listStyle: 'none' }}>
      <li>
        Status: <StatusIndicator status={emgSpike[0]} />
        {emgSpike[0] === 1 ? 'On' : 'Off'}
      </li>
      <li>Value: {emgSpike[1]} ms</li>
    </ul>
  ),
  holdOpen: (holdOpen) => (
    <ul style={{ listStyle: 'none' }}>
      <li>Primary hold open time: {holdOpen[0]} ms</li>
      <li>Secondary hold open time: {holdOpen[1]} ms</li>
    </ul>
  ),
  softGrip: (softGrip) => (
    <ul style={{ listStyle: 'none' }}>
      <li>
        Status: <StatusIndicator status={softGrip[0]} />
        {softGrip[0] === 1 ? 'On' : 'Off'}
      </li>
    </ul>
  ),
  emgGains: (emgGains) => (
    <ul style={{ listStyle: 'none' }}>
      <li>Open signal gain: {emgGains[0]}%</li>
      <li>Close signal gain: {emgGains[1]}%</li>
    </ul>
  ),
  pulseTimings: (pulseTimings) => (
    <ul style={{ listStyle: 'none' }}>
      <li>Min. pulse time: {pulseTimings[0]} ms</li>
      <li>Max. pulse time: {pulseTimings[1]} ms</li>
      <li>Min. time between pulses: {pulseTimings[2]} ms</li>
      <li>Max. time between pulses: {pulseTimings[3]} ms</li>
    </ul>
  ),
  coContractionTimings: (coContractionTimings) => (
    <ul style={{ listStyle: 'none' }}>
      <li>Long co-contraction time: {coContractionTimings[0]} ms</li>
      <li>Signal rise offset time: {coContractionTimings[1]} ms</li>
    </ul>
  )
};

const SessionHistoryItem = ({ name, configName, after, before, now }) => {
  const HistoricItemWrapper = (type: string, value: any, index, currentConfig = false) => {
    if (type.includes('gripsPositions')) {
      const names = name.split('.');

      if (currentConfig) value = now.gripsPositions[`${names[1]}`][`${names[2]}`][index];

      return value || value === 0 ? (
        <ValueWrapper>{ConfigToValueFunctionMapping[`${names[0]}`](value, index)}</ValueWrapper>
      ) : (
        <ValueWrapper />
      );
    }

    if (type.includes('fingerStrength') && currentConfig) {
      value = now.fingerStrength?.[1];
    }

    return value || value === 0 ? (
      <ValueWrapper>{ConfigToValueFunctionMapping[`${name}`](value, index)}</ValueWrapper>
    ) : (
      <ValueWrapper />
    );
  };

  const HeaderItemWrapper = (type: string, value: any) => {
    if (type.includes('gripsPositions')) {
      const names = name.split('.');
      return value || value === 0 ? (
        <div>{ConfigToNameFunctionMapping[`${names[0]}`](value)}</div>
      ) : (
        <div />
      );
    }

    return value || value === 0 ? (
      <div>{ConfigToNameFunctionMapping[`${name}`](value)}</div>
    ) : (
      <div />
    );
  };

  const NameWrapper = (configName: string) => {
    if (name.includes('gripsPositions')) {
      const names = configName.split('.');
      return `${configSettingsNamesMap.get(names[0])} (${gripsGroupsOptionsMap.get(
        Number(names[1])
      )}, ${names[2]})`;
    }
    return configSettingsNamesMap.get(configName);
  };

  if (name === 'fingerStrength') {
    after = [after?.[1]];
  }

  if (name === 'gripPairsConfig' || name === 'gripSequentialConfig') {
    return (
      <Accordion header={NameWrapper(configName)} style={{ marginTop: '24px' }}>
        <TableBodyConfig>
          {HeaderItemWrapper(name, 0)}
          {HistoricItemWrapper(name, before, 0)}
          {HistoricItemWrapper(name, after, 0)}
          {HistoricItemWrapper(name, now[`${name}`], 0, true)}
        </TableBodyConfig>
        <TableBodyConfig>
          {HeaderItemWrapper(name, 1)}
          {HistoricItemWrapper(name, before, 1)}
          {HistoricItemWrapper(name, after, 1)}
          {HistoricItemWrapper(name, now[`${name}`], 1, true)}
        </TableBodyConfig>
      </Accordion>
    );
  }

  return (
    <Accordion header={NameWrapper(configName)} style={{ marginTop: '24px' }}>
      {after.map((value, index) => (
        <TableBodyConfig>
          {HeaderItemWrapper(name, index)}
          {HistoricItemWrapper(name, before ? before[index] : null, index)}
          {HistoricItemWrapper(name, value, index)}
          {HistoricItemWrapper(name, now[`${name}`] ? now[`${name}`][index] : null, index, true)}
        </TableBodyConfig>
      ))}
    </Accordion>
  );
};

const extractInitials = (name = '') => {
  const parts = name.split(' ');
  return `${parts[0]?.charAt(0) || ''}${parts[1]?.charAt(0) || ''}`;
};

const PatientDetails = ({ deviceInfo }) => (
  <div>
    <Accordion header='Patient details'>
      <DetailsWrapper>
        <PatientAvatarWrapper>
          {deviceInfo?.amputee?.image ? (
            <Avatar size='large' type='image'>
              <img src={deviceInfo?.amputee?.image || CompanyImg} alt='Patient' />
            </Avatar>
          ) : (
            <Avatar size='large'>{extractInitials(deviceInfo?.amputee?.name)}</Avatar>
          )}
        </PatientAvatarWrapper>
        <div>
          <Header4>{deviceInfo?.amputee?.name}</Header4>
          <p>{deviceInfo?.amputee?.email}</p>
        </div>
      </DetailsWrapper>
      <InnerWrapper>
        <div>
          <PatientDetailsHeader>Company</PatientDetailsHeader>
          <PatientDetailsText>{deviceInfo?.company?.name}</PatientDetailsText>
        </div>
        <div>
          <PatientDetailsHeader>Clinician</PatientDetailsHeader>
          <PatientDetailsAvatarWrapper>
            <PatientDetailsAvatar themeColor='success' size='medium'>
              {extractInitials(deviceInfo?.clinician?.name)}
            </PatientDetailsAvatar>
            <PatientDetailsText>{deviceInfo?.clinician?.name}</PatientDetailsText>
          </PatientDetailsAvatarWrapper>
        </div>
      </InnerWrapper>
    </Accordion>
    <Accordion header='Devices' style={{ marginTop: '30px' }}>
      <InnerWrapper>
        <div>
          <PatientDetailsHeader>Name</PatientDetailsHeader>
          <PatientDetailsText>{deviceInfo?.model?.name}</PatientDetailsText>
        </div>
        <div>
          <PatientDetailsHeader>Serial number</PatientDetailsHeader>
          <PatientDetailsText>{deviceInfo?.serial}</PatientDetailsText>
        </div>
        <div>
          <PatientDetailsHeader>Bluetooth ID</PatientDetailsHeader>
          <PatientDetailsText>{deviceInfo?.bluetooth_id}</PatientDetailsText>
        </div>
      </InnerWrapper>
    </Accordion>
  </div>
);

const DeviceConfigComponent = () => {
  const { deviceId } = useSelector(selectDeviceInfo);
  // @ts-ignore
  const { configId } = useParams();
  const { result: device } = useDevice(deviceId, { extend: 'amputee,clinician,company,model' });
  const { result: notes } = useConfigEntryNotes({
    deviceId,
    // @ts-ignore
    configId,
    params: { sortby: ConfigNotesSortOptions.date, sortdir: SortDirs.desc }
  });
  const { mutateAsync: updateConfig } = useUpdateDeviceConfigEntry();
  const [currentToggle, setCurrentToggle] = useState(ToggleTypesChangesHistory.all);
  // @ts-ignore
  const {
    state: { detail: changeEntry }
  }: any = useLocation();
  const [transformedConfig, setTransformedConfig] = useState<any>(null);
  const {
    handleClose: handleCloseTemplateModal,
    handleOpen: handleOpenTemplateModal,
    isOpen
  } = useModal();
  const {
    handleClose: handleCloseRestoreConfigHistoryModal,
    handleOpen: handleOpenRestoreConfigHistoryModal,
    isOpen: isRestoreConfigHistoryModalOpen
  } = useModal();
  const { mutateAsync: createConfigNote } = useCreateConfigNote();
  const { mutateAsync: deleteConfigNote } = useDeleteConfigNote();
  const { restoreConfigHistory } = useDeviceManager();
  const { modesList } = useSelector((state: any) => state.modes);
  const { enabled: remoteSessionEnabled } = useSelector((state: any) => state.liveConfigurator);
  const bluetoothConfig = useSelector((state: any) => state.bluetooth);
  const queryClient = useQueryClient();

  const { modeAffected, modeAffectedId, completeConfig, parsedConfig } = transformConfigAPI(
    changeEntry,
    modesList
  );
  const currentCompleteConfig = {
    ...bluetoothConfig.commonConfig,
    ...bluetoothConfig.modes?.modesConfigs[`mode_${modeAffectedId}`]?.mode
  };

  useEffect(() => {
    if (changeEntry) {
      const { gripsTransformed, gripPairsConfig, gripSequentialConfig } =
        transformConfig(completeConfig);
      setTransformedConfig({ grips: gripsTransformed, gripPairsConfig, gripSequentialConfig });
    }
  }, [changeEntry]);

  const toggleRestorePoint = async () => {
    const data = {
      restore_point: 0
    };
    if (changeEntry.restore_point === 0) {
      data.restore_point = 1;
    }
    try {
      await updateConfig({ deviceId, configId: changeEntry.id, data });
      queryClient.invalidateQueries(DEVICE_CONFIG_HISTORY_ENTRY_QUERY_KEY);
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddNote = async ({ note, type }) => {
    await createConfigNote({ deviceId, configId: Number(configId), data: { note, type } });
    queryClient.invalidateQueries(CONFIG_ENTRY_NOTES_KEY);
  };

  const handleDeleteNote = async ({ noteId }) => {
    await deleteConfigNote({ deviceId, configId: Number(configId), noteId });
    queryClient.invalidateQueries(CONFIG_ENTRY_NOTES_KEY);
  };

  const handleRestore = async () => {
    await restoreConfigHistory(modeAffectedId, changeEntry.id, completeConfig);
    handleCloseRestoreConfigHistoryModal();
  };

  if (bluetoothConfig.modes?.modesConfigs?.length === 0) {
    return null;
  }

  return (
    <>
      {isOpen && (
        <AddTemplateModal
          handleClose={handleCloseTemplateModal}
          config={JSON.stringify(parsedConfig[`mode_${modeAffectedId}`])}
        />
      )}
      {isRestoreConfigHistoryModalOpen && (
        <RestoreConfigHistoryModal
          handleClose={handleCloseRestoreConfigHistoryModal}
          handleAccept={handleRestore}
        />
      )}
      <HeaderWrapper>
        <Header margin={false}>Applied changes</Header>
      </HeaderWrapper>
      <Card>
        <TableBody>
          <TableHeader>Configuration</TableHeader>
          <TableHeader>Value before</TableHeader>
          <TableHeader>Value after</TableHeader>
          <TableHeader>Value now</TableHeader>
        </TableBody>
        {changeEntry.entries.map((entry) => (
          <SessionHistoryItem
            key={Math.random()}
            name={entry.config_entry.key}
            configName={entry.config_entry.key}
            before={JSON.parse(entry.old_value)}
            after={JSON.parse(entry.new_value)}
            now={currentCompleteConfig}
          />
        ))}
      </Card>
      <Divider margin='40px' />
      <HeaderWrapper>
        <ConfigurationHeaderWrapper>
          <Header margin={false}>Configuration Changes History</Header>
          {modesList && !remoteSessionEnabled && (
            <div>
              Mode affected:{' '}
              {modeAffected
                ? modesList.find((mode) => mode.id === modeAffectedId).name
                : 'All modes'}
            </div>
          )}
        </ConfigurationHeaderWrapper>
        <ButtonWrapper style={{ display: 'flex' }}>
          <Button onClick={handleOpenRestoreConfigHistoryModal}>Load</Button>
          <ButtonToggle
            // @ts-ignore
            toggle={setCurrentToggle}
            modes={[
              { id: ToggleTypesChangesHistory.all, name: 'All' },
              { id: ToggleTypesChangesHistory.emg, name: 'EMG' },
              { id: ToggleTypesChangesHistory.grips, name: 'Grips' },
              { id: ToggleTypesChangesHistory.prosthesis, name: 'Prosthesis' }
            ]}
            activeId={currentToggle}
          />
        </ButtonWrapper>
      </HeaderWrapper>
      {changeEntry ? (
        <Wrapper>
          <ConfigDetailsWrapper style={{ gridArea: 'patient' }}>
            {device?.amputee && (
              <Card>
                <PatientDetails deviceInfo={device} />
              </Card>
            )}
          </ConfigDetailsWrapper>
          <ConfigDetailsWrapper style={{ gridArea: 'notes' }}>
            <Card>
              <Accordion header='Notes'>
                <NotesList
                  notes={notes ?? []}
                  handleAdd={handleAddNote}
                  handleDelete={handleDeleteNote}
                />
              </Accordion>
            </Card>
          </ConfigDetailsWrapper>
          <div style={{ gridArea: 'config' }}>
            <Card>
              <ConfigComponent
                config={completeConfig || null}
                transformedConfig={transformedConfig}
                currentToggle={currentToggle}
              />
            </Card>
          </div>
          <ConfigDetailsWrapper style={{ gridArea: 'version' }}>
            <Card>
              <Header4>Version of configuration</Header4>
              <Divider margin='20px' />
              <div>
                <p>Date:</p>
                <DropDownList
                  fillMode='outline'
                  rounded='large'
                  // value={selectedGrip}
                  // onChange={(e) => setSelectedGrip(e.target.value)}
                  // data={transformedConfig?.grips.filter((item) => item !== undefined)}
                />
              </div>
              <DeleteButtonWrapper>
                <ActionButtonError size='large' icon='trash' rounded='large'>
                  Delete version
                </ActionButtonError>
              </DeleteButtonWrapper>
              <TemplateActions>
                <Button
                  themeColor='secondary'
                  size='large'
                  onClick={handleOpenTemplateModal}
                  rounded='large'>
                  Save as template
                </Button>
                <Button
                  rounded='large'
                  themeColor='secondary'
                  onClick={toggleRestorePoint}
                  style={{ marginTop: '16px' }}>
                  {changeEntry.restore_point === 0
                    ? 'Create restore point'
                    : 'Unset as restore point'}
                </Button>
              </TemplateActions>
            </Card>
          </ConfigDetailsWrapper>
        </Wrapper>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default DeviceConfigComponent;
