import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import GlobalStyle from 'theme/globalStyles';
import theme from 'theme/theme';
import Root from 'views/Root';
import store from 'store/store';
import { BrowserRouter } from 'react-router-dom';
import { LastLocationProvider } from 'react-router-last-location';
/* import { TourProvider } from '@reactour/tour';
import { generalTour } from 'utils/Tour/Tour'; */
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import 'utils/i18n/i18n';
import dayjs from 'dayjs';

const utc = require('dayjs/plugin/utc');

dayjs.extend(utc);
const persistor = persistStore(store);
const queryClient = new QueryClient();

// const pubnubConfig = {
//   publishKey: process.env.REACT_APP_PUB_KEY,
//   subscribeKey: process.env.REACT_APP_SUB_KEY,
//   uuid: 'null',
//   ssl: true,
//   presenceTimeout: 130,
//   logVerbosity: true
// };

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <GlobalStyle />
          <ThemeProvider theme={theme}>
            <BrowserRouter>
              <LastLocationProvider>
                {/* <TourProvider steps={generalTour}> */}
                <Root />
                {/* </TourProvider> */}
              </LastLocationProvider>
            </BrowserRouter>
          </ThemeProvider>
        </PersistGate>
      </Provider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
