import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import useUndoFunction from 'hooks/useUndoFunction';
import { List, ListItem, MenuIconCustom } from 'components/Navbar/styled';
import useSidebar from 'hooks/useSidebar';
import useBluetooth from 'hooks/bluetooth/useConnect';
import { ReactComponent as Upload } from 'assets/icons/icon-upload.svg';
import { ReactComponent as Redo } from 'assets/icons/icon-redo.svg';
import { ReactComponent as Reload } from 'assets/icons/icon-reload.svg';
import { ReactComponent as Hand } from 'assets/icons/icon-hand.svg';
import { Panel } from './styled';

const MenuItemButton = ({ action, icon, description, showRequirements = true }) =>
  showRequirements ? (
    <ListItem onClick={action}>
      {icon !== undefined && typeof icon === 'string' && <img src={icon} alt='menu-icon' />}
      {icon !== undefined && typeof icon !== 'string' && <MenuIconCustom>{icon}</MenuIconCustom>}
      <div>{description}</div>
    </ListItem>
  ) : null;

const Toolbar = ({ device, unsaved, ...props }) => {
  const location = useLocation();
  const { firstConnection } = useSelector((state: any) => state.bluetooth);
  const { handleSave, handleRestoreRecent } = useSidebar();
  const { bluetoothConnect, bluetoothDisconnect } = useBluetooth();
  const { handleUndoFunction, isUndoFunctionIsAvailableOnPage } = useUndoFunction(
    location.pathname
  );
  const { enabled: remoteSessionEnabled } = useSelector((state: any) => state.liveConfigurator);

  const TOOLBAR_MENU_ITEMS = [
    {
      description: 'Send current config to hand',
      icon: <Upload />,
      action: () => handleSave(unsaved),
      showRequirements: firstConnection === false && !remoteSessionEnabled
    },
    {
      description: 'Undo last action',
      icon: <Redo />,
      action: handleUndoFunction,
      showRequirements: isUndoFunctionIsAvailableOnPage()
    },
    {
      description: 'Restore recent config',
      icon: <Reload />,
      action: handleRestoreRecent
    },
    {
      description: 'Connect to prosthesis',
      icon: <Hand />,
      action: device.connected ? bluetoothDisconnect : bluetoothConnect
    }
  ];

  return (
    <Panel>
      <List>
        {TOOLBAR_MENU_ITEMS.map((menuItem) => (
          <MenuItemButton
            action={menuItem.action}
            icon={menuItem.icon}
            description={menuItem.description}
            showRequirements={menuItem?.showRequirements}
          />
        ))}
      </List>
    </Panel>
  );
};

export default Toolbar;
