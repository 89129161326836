import React from 'react';
import Modal from 'utils/Modal/Modal';
import ModalPortal from 'utils/Modal/ModalPortal';
import ModalBase from './ModalBase';

const PreventInputModal = () => (
  <ModalPortal>
    <Modal>
      <ModalBase handleClick={() => null} header='' basicModal>
        Synchronizing configs...
      </ModalBase>
    </Modal>
  </ModalPortal>
);

export default PreventInputModal;
