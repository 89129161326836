import React from 'react';
import styled from 'styled-components';
import Card from 'components/Card/Card';
import { ReactComponent as Times } from 'assets/times-solid.svg';
import { OpacityAnimation } from 'components/atoms/Animations/Animations';
import { HeaderSmall } from 'components/Typography/Header';

const StyledCard = styled(Card)`
  z-index: 9;
  animation: ${OpacityAnimation} 0.5s;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  width: 520px;
`;

const InnerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
`;

const StyledTimes = styled(Times)`
  width: 20px;
  cursor: pointer;
  color: ${({ theme }) => theme.typography.colors.subtitle};

  &&:hover {
    transform: scale(1.1, 1.1);
    transition: transform 0.2s;
  }
`;

type ModalBaseProps = {
  handleClick: Function;
  header: string;
  children?: any;
  basicModal?: boolean;
  hideCloseButton?: boolean;
};

const ModalBase = ({
  handleClick,
  header,
  children,
  basicModal = false,
  hideCloseButton = false,
  ...props
}: ModalBaseProps) => (
  <StyledCard padding='30px' {...props}>
    {!basicModal && (
      <InnerWrapper>
        <HeaderSmall>{header}</HeaderSmall>
        {!hideCloseButton && <StyledTimes onClick={handleClick} />}
      </InnerWrapper>
    )}
    {children}
  </StyledCard>
);

export default ModalBase;
