import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { getDevice } from 'api/device/device';
import { DeviceQueryParams, DeviceExtendOptions } from 'api/device/device.types';
import { setItemDeviceInfo } from 'reducers/deviceInfoReducer';
import { useEffect } from 'react';

export const DEVICE_QUERY_KEY = 'device';

const useDeviceInfo = () => {
  const { deviceId } = useSelector((state: any) => state.deviceInfo);
  const dispatch = useDispatch();

  const deviceQueryParams: DeviceQueryParams = {
    extend: [DeviceExtendOptions.firmwareVersion, DeviceExtendOptions.pcbVersion]
  };

  const { data, isLoading, isError } = useQuery(
    [DEVICE_QUERY_KEY, deviceId],
    () => getDevice(deviceId, deviceQueryParams),
    {
      cacheTime: Infinity,
      enabled: Boolean(deviceId),
      keepPreviousData: true,
      staleTime: Infinity,
      retry: 0
    }
  );

  useEffect(() => {
    if (data) {
      dispatch(setItemDeviceInfo({ type: 'serial', payload: data?.serial }));
      dispatch(setItemDeviceInfo({ type: 'bluetoothId', payload: data?.bluetooth_id }));
      dispatch(setItemDeviceInfo({ type: 'pcb', payload: data?.pcb_version }));
      dispatch(setItemDeviceInfo({ type: 'firmware', payload: data?.firmware_version }));
    }
  }, [data]);

  return {
    data,
    isLoading,
    isError
  };
};

export default useDeviceInfo;
