import styled, { css } from 'styled-components';
import { ReactComponent as Check } from 'assets/circle-check-solid.svg';
import { ReactComponent as Undo } from 'assets/undo-solid.svg';
import { ReactComponent as Eye } from 'assets/eye-solid.svg';
import { ReactComponent as Plus } from 'assets/plus-solid.svg';
import { ReactComponent as TableIcon } from 'assets/table.svg';
import { ReactComponent as CalendarIcon } from 'assets/calendar.svg';
import { ReactComponent as EnvelopeIcon } from 'assets/envelope-solid.svg';
import { ReactComponent as NotesIcon } from 'assets/note-sticky-regular.svg';

export const IconStyle = css`
  width: 16px;
  height: 16px;
  fill: white;
`;

export const StyledUndo = styled(Undo)`
  ${IconStyle};
`;

export const StyledEye = styled(Eye)`
  ${IconStyle};
`;

export const StyledPlus = styled(Plus)`
  ${IconStyle};
`;

export const StyledCheck = styled(Check)`
  width: 14px;
  height: 14px;
  fill: green;
`;

export const StyledTableIcon = styled(TableIcon)`
  ${IconStyle};
`;

export const StyledCalendarIcon = styled(CalendarIcon)`
  ${IconStyle};
`;

export const StyledEnvelopeIcon = styled(EnvelopeIcon)`
  ${IconStyle};
`;

export const StyledNotesIcon = styled(NotesIcon)`
  ${IconStyle};
`;
