import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { TRANSLATIONS_EN } from "./translations/en/translations";
import { TRANSLATIONS_PL } from "./translations/pl/translations";

i18n.use(initReactI18next).init({
  debug: true,
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
  resources: {
    en: {
      translation: TRANSLATIONS_EN,
    },
    pl: {
      translation: TRANSLATIONS_PL,
    },
  },
});

export default i18n;
