import styled from 'styled-components';
import { Popover } from '@progress/kendo-react-tooltip';
import { HeaderM } from 'components/atoms/Typography/Typography';
import './popupStyle.css';

type TooltipSettingsProps = {
  show: boolean | undefined;
  anchor: any;
  header: string;
  description: string;
  video?: any;
};

const Header = styled.h3`
  ${HeaderM};
  color: ${({ theme }) => theme.typography.colors.secondary};
`;

const VideoWrapper = styled.video`
  width: 100%;
  height: auto;
`;

export const TooltipSettings = ({
  show,
  anchor,
  header,
  description,
  video
}: TooltipSettingsProps) => (
  <Popover
    show={show}
    anchor={anchor}
    position='right'
    contentStyle={{ backgroundColor: '#e9ebf0', borderRadius: '16px' }}>
    <div style={{ width: '400px', display: 'grid', gap: '24px' }}>
      <Header>{header}</Header>
      <p>{description}</p>
      {video && <VideoWrapper controls src={video} />}
    </div>
  </Popover>
);
