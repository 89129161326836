import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Spacer = styled.div`
  margin-top: ${({ margin }) => margin};
`;

const Divider = ({ margin }) => <Spacer margin={margin} />;

Divider.propTypes = {
  margin: PropTypes.string.isRequired,
};

export default Divider;
