import React from 'react';
import DefaultLayout from 'layouts/DefaultLayout';
import ConfigTemplatesComponent from './ConfigTemplatesComponent';

const ConfigTemplateDetails = () => (
  <DefaultLayout>
    <ConfigTemplatesComponent />
  </DefaultLayout>
);

export default ConfigTemplateDetails;
