/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import useRemoteSession from 'hooks/useRemoteSession';
import { Types } from 'ably';
import { ablyClient } from '../../../utils/LiveConfigurator/AblyClient';
import { RootState } from '../../../store/store';
import { setItemUI } from '../../../reducers/uiReducer';

const LiveConfigurator = ({ children }) => {
  const {
    enabled,
    channel: connection,
    sessionApi
  } = useSelector((state: RootState) => state.liveConfigurator);
  const [mainChannel, setMainChannel] = useState<Types.RealtimeChannelCallbacks | null>();
  const { disconnect } = useRemoteSession();
  const dispatch = useDispatch();
  useEffect(() => {
    if (enabled && sessionApi?.clinician_uuid) {
      setMainChannel(ablyClient(sessionApi.clinician_uuid).channels.get(connection.name));
    }

    if (mainChannel) {
      mainChannel?.presence.subscribe('leave', (member) => {
        toast('Patient has been disconnected from session', { icon: '⚠️' });
        disconnect();
      });

      mainChannel.attach((error) => {
        if (!error) mainChannel.presence.enter();
      });

      mainChannel.subscribe('close_session_patient', () => {
        dispatch(setItemUI({ type: 'shownGlobalModal', payload: 'closeSession' }));
      });

      return () => {
        if (mainChannel) {
          mainChannel.unsubscribe(connection.name);
          mainChannel.unsubscribe();
        }
      };
    }
  }, [enabled, connection, mainChannel, sessionApi]);

  return <>{children}</>;
};

export default LiveConfigurator;
