import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'utils/Modal/Modal';
import { setItem } from 'reducers/bluetoothReducer/bluetoothReducer';
import useSynchronizeBluetooth from 'hooks/bluetooth/useSynchronizeBluetooth';
import { Paragraph } from 'components/Typography/Paragraph';
import { Button } from '@progress/kendo-react-buttons';
import ModalBase from './ModalBase';
import { ModalButtonsWrapper, ModalWrapper } from './ModalStyles';

type AboutProps = {
  handleClose: Function;
};

const ConfigsConflictModal = ({ handleClose }: AboutProps) => {
  const dispatch = useDispatch();
  const synchronizeConfigBluetooth = useSynchronizeBluetooth();
  const {
    commonConfig,
    trackedCommonConfig,
    modes: { modesConfigs, modesConfigsTracked }
  } = useSelector((state: any) => state.bluetooth);

  const accepted = async () => {
    handleClose();
    synchronizeConfigBluetooth({
      deviceConfig: {
        common: commonConfig,
        modes: modesConfigs.map((modeConfig) => ({
          config: modeConfig.config,
          slot: modeConfig.slot,
          id: modeConfig.id
        }))
      },
      apiConfig: {
        common: trackedCommonConfig,
        modes: modesConfigsTracked.map((modeConfig) => ({
          config: modeConfig.config,
          slot: modeConfig.slot,
          id: modeConfig.id
        }))
      }
    });
  };

  const denied = async () => {
    await dispatch(setItem({ payload: false, type: 'configConflict' }));
    handleClose();
  };

  return (
    <Modal>
      <ModalBase handleClick={denied} header='Config conflict'>
        <ModalWrapper>
          <Paragraph>
            Config conflict between API and device has been detected, do you want to send current
            API config to the device?
          </Paragraph>
          <ModalButtonsWrapper>
            <Button onClick={denied}>No</Button>
            <Button themeColor='secondary' onClick={accepted}>
              Yes
            </Button>
          </ModalButtonsWrapper>
        </ModalWrapper>
      </ModalBase>
    </Modal>
  );
};

export default ConfigsConflictModal;
