/* eslint-disable arrow-body-style */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as AngleDown } from 'assets/angle-down-solid.svg';
import { OpacityAnimation } from 'components/atoms/Animations/Animations';
import { MONTHS, YEARS } from 'consts/consts';
import dayjs from 'dayjs';
import ReactTooltip from 'react-tooltip';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Button } from '@progress/kendo-react-buttons';
import { SvgIcon } from '@progress/kendo-react-common';
import * as svgIcons from '@progress/kendo-svg-icons';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 16px;
  align-items: center;
`;

const Box = styled.div`
  position: relative;
  display: flex;
  align-items: space-between;
  min-height: 30px;
  width: auto;
  border: 1px solid #e4e7eb;
  border-radius: 5px;
  font-size: 14px;
  background-color: #e4e7eb;
  cursor: pointer;
  height: auto;
  padding: 10px;
`;

const Choosen = styled.div`
  width: 100%;
`;

const Label = styled.div`
  font-size: 16px;
  width: 100%;
`;

const Select = styled.div`
  position: absolute;
  display: ${({ open }) => (open ? 'grid' : 'none')};
  border-radius: 15px;
  right: auto;
  left: 0;
  top: 100%;
  margin-top: 10px;
  z-index: 99;
  background-color: white;
  animation: ${OpacityAnimation} 0.3s linear;
  box-shadow: 0 4px 20px 0 rgba(61, 72, 68, 0.2);
  padding: 24px;
  cursor: default;
`;

const IconDown = styled(AngleDown)`
  color: ${({ theme }) => theme.colorPrimary};
  right: 25px;
  top: 9px;
  width: 18px;
`;

const CalendarHeaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 12px;
  gap: 16px;
`;

const CalendarTable = styled.table`
  table-layout: fixed;

  tr {
    th {
      min-width: 40px;
      height: 40px;
      font-size: 14px;
      font-weight: bold;
    }
  }

  tr {
    td {
      width: 40px;
      height: 40px;
      font-size: 14px;
      cursor: pointer;
      border-radius: 5px;

      &:hover {
        font-weight: ${({ theme }) => theme.typography.fontWeights.bold};
        background-color: ${({ theme }) => theme.palette.configFill};
      }
    }
  }
`;

const CalendarDay = styled.td`
  color: ${({ active, selected }) => {
    if (selected) return 'white';

    return active ? 'auto' : '#808080';
  }};
  position: relative;
  background-color: ${({ selected, theme }) => (selected ? theme.palette.primary : 'auto')};

  div {
    display: flex;
    justify-content: center;
  }
`;

const CalendarDayActive = styled.span`
  position: absolute;
  height: 4px;
  width: 4px;
  top: 80%;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  background-color: ${({ theme }) => theme.colorPrimary};
  border-radius: 50%;
  display: inline-block;
`;

const transformMonth = (month) => {
  const previousMonth = month.month(month.month() - 1);
  const nextMonth = month.month(month.month() + 1);

  let previousMonthDays: any = [];
  if (month.day() - 1 > 0) {
    previousMonthDays = [...Array(month.day() - 1).keys()].map((previousDay) => ({
      day: previousMonth.daysInMonth() - previousDay,
      month: previousMonth.month(),
      year: previousMonth.year()
    }));
  }

  const coreMonthDays = [...Array(month.daysInMonth()).keys()].map((coreDay) => ({
    day: coreDay + 1,
    month: month.month(),
    year: month.year()
  }));

  const nextMonthDays = [...Array(43 - (month.day() + month.daysInMonth())).keys()].map(
    (nextDay) => ({
      day: nextDay + 1,
      month: nextMonth.month(),
      year: nextMonth.year()
    })
  );
  const calendarDays = [...previousMonthDays.reverse(), ...coreMonthDays, ...nextMonthDays];
  return calendarDays;
};

const CalendarDayItem = ({ active, day, events, selected, ...props }) => {
  return (
    <CalendarDay
      data-tip
      data-for={events ? `configs${events[0].id}` : null}
      active={active}
      selected={selected}
      {...props}>
      {events && (
        <ReactTooltip id={`configs${events[0].id}`} type='light' effect='solid'>
          <span>{events.length} configs</span>
        </ReactTooltip>
      )}
      <div>{day.day}</div>
      {events && <CalendarDayActive />}
    </CalendarDay>
  );
};

const ButtonsWrapper = styled.div`
  display: flex;
  gap: 12px;
  justify-content: center;
  margin-top: 16px;

  button {
    max-width: 125px;
  }
`;

type DropdownProps = {
  events: any;
  handleApply: any;
  handleCancel: any;
  selected: any;
  label?: string;
  responsive?: boolean;
  setOpenParent?: Function;
  style?: any;
};

const CalendarPicker = ({
  label = '',
  selected,
  responsive = false,
  setOpenParent = () => false,
  style,
  events,
  handleApply,
  handleCancel,
  ...props
}: DropdownProps) => {
  const currentMonth = useMemo(() => dayjs().month(), []);
  const currentYear = useMemo(() => dayjs().year(), []);
  const dateSelectedParsed = dayjs(Number(selected) * 1000);
  const [open, setOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(selected ? dateSelectedParsed : dayjs());
  const [selectedMonth, setSelectedMonth] = useState(
    MONTHS.find((item) => item.id === currentMonth) ?? MONTHS[0]
  );
  const [selectedYear, setSelectedYear] = useState(
    YEARS.find((item) => item.id === currentYear) ?? YEARS[0]
  );
  const calendarDays = transformMonth(
    dayjs().year(selectedYear.id).month(selectedMonth.id).date(1)
  );
  const updateOpen = () => {
    setOpen((prev) => !prev);
  };

  const handleOnCancel = () => {
    handleCancel();
    updateOpen();
  };

  const handleOnApply = () => {
    handleApply(selectedDate);
    updateOpen();
  };

  return (
    <Wrapper open={open} responsive={responsive ? 1 : 0} {...props}>
      <Label>{label}</Label>
      <Box responsive={responsive ? 1 : 0} style={style}>
        <Choosen onClick={updateOpen}>{selectedDate.format('MM/DD/YYYY')}</Choosen>
        <SvgIcon
          style={{ cursor: 'pointer', width: '20px', height: '20px' }}
          icon={open ? svgIcons.caretAltUpIcon : svgIcons.caretAltDownIcon}
          onClick={updateOpen}
        />
        <Select open={open}>
          <div>
            <CalendarHeaderWrapper>
              <DropDownList
                style={{ width: '150px' }}
                fillMode='outline'
                rounded='large'
                textField='name'
                dataItemKey='id'
                data={MONTHS}
                value={selectedMonth}
                onChange={(event) => setSelectedMonth(event.target.value)}
              />
              <DropDownList
                style={{ width: '150px' }}
                fillMode='outline'
                rounded='large'
                textField='name'
                dataItemKey='id'
                data={YEARS}
                value={selectedYear}
                onChange={(event) => setSelectedYear(event.target.value)}
              />
            </CalendarHeaderWrapper>
            <div>
              <CalendarTable>
                <tr>
                  <th>Mo</th>
                  <th>Tu</th>
                  <th>We</th>
                  <th>Th</th>
                  <th>Fr</th>
                  <th>Sa</th>
                  <th>Su</th>
                </tr>
                {[...Array(6).keys()].map((week) => (
                  <tr>
                    {[...Array(7).keys()].map((dayOfWeek) => {
                      const currentDate = calendarDays[7 * week + dayOfWeek];
                      const currentDateParsed = dayjs()
                        .year(currentDate.year)
                        .month(currentDate.month)
                        .date(currentDate.day);
                      let groupIndex: null | number = null;
                      for (let index = 0; index < events.length; index += 1) {
                        const eventGroupDate = dayjs(events[index][0].created_at);
                        if (eventGroupDate.isSame(currentDateParsed, 'day')) {
                          groupIndex = index;
                          break;
                        }
                      }
                      return (
                        <CalendarDayItem
                          onClick={() => setSelectedDate(currentDateParsed)}
                          active={
                            currentDate.month ===
                            dayjs().year(selectedYear.id).month(selectedMonth.id).date(1).month()
                          }
                          events={groupIndex !== null ? events[groupIndex] : groupIndex}
                          day={currentDate}
                          selected={selectedDate.isSame(currentDateParsed, 'day')}
                        />
                      );
                    })}
                  </tr>
                ))}
              </CalendarTable>
            </div>
          </div>
          <ButtonsWrapper>
            <Button onClick={handleOnCancel} rounded='large'>
              Cancel
            </Button>
            <Button themeColor='primary' rounded='large' onClick={handleOnApply}>
              Apply
            </Button>
          </ButtonsWrapper>
        </Select>
      </Box>
    </Wrapper>
  );
};

export default CalendarPicker;
