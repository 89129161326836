import React from 'react';
import styled from 'styled-components';
import { DropDownList, ListItemProps } from '@progress/kendo-react-dropdowns';
import { gripsGroupsOptionsMap } from 'utils/definesLocal';
import { Grips } from 'bluetooth/Bluetooth/Grips';

const DropdownImgItem = styled.div`
  text-align: center;
  max-width: 100px;
  margin: 0 auto;
  img {
    width: 100%;
    height: 100%;
  }
`;

type DropdownListImgProps = {
  grips: Grips[];
  gripsImgs: any;
  value: Grips;
  onChange: any;
  style?: any;
};

const DropdownListImg = ({ grips, gripsImgs, value, onChange, style }: DropdownListImgProps) => {
  const itemRender = (li: React.ReactElement<HTMLLIElement>, itemProps: ListItemProps) => {
    const { dataItem } = itemProps;
    const itemChildren = (
      <DropdownImgItem>
        <img src={dataItem.img} alt='Grip' />
        <div>{dataItem.value}</div>
      </DropdownImgItem>
    );

    return React.cloneElement(li, li.props, itemChildren);
  };

  return (
    <DropDownList
      data={grips.map((grip) => ({
        value: gripsGroupsOptionsMap.get(grip),
        img: gripsImgs.get(grip),
        id: grip
      }))}
      textField='value'
      dataItemKey='id'
      fillMode='outline'
      rounded='large'
      itemRender={itemRender}
      value={{ value: gripsGroupsOptionsMap.get(value) }}
      onChange={onChange}
      style={style}
    />
  );
};

export default DropdownListImg;
