import styled from 'styled-components';
import { SvgIcon } from '@progress/kendo-react-common';
import Card from 'components/Card/Card';
import { Menu, MenuItem } from '@progress/kendo-react-layout';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Button } from '@progress/kendo-react-buttons';

export const Wrapper = styled.nav`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 10;
  width: 100%;
  background-color: ${({ theme }) => theme.palette.primary};
  padding: 0 60px;
`;

export const InnerWrapper = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: auto 1fr;
  align-items: center;
  min-height: 100px;
`;

export const List = styled.ul`
  display: flex;
  height: 100%;
  list-style: none;
  justify-content: flex-start;
  align-items: center;
  column-gap: 50px;
  margin: 0 50px;
  max-width: 1100px;
`;

export const ListItem = styled.li`
  display: flex;
  align-items: center;
  height: 100%;
  text-decoration: none;
  border-top: 4px solid transparent;
  transition: border 1s;
  color: ${({ theme }) => theme.palette.secondaryText};
  cursor: pointer;

  &.active {
    border-top: 4px solid ${({ theme }) => theme.palette.secondary};
    font-weight: 700;
  }
`;

export const ListVertical = styled.ul`
  display: flex;
  height: 100%;
  list-style: none;
  flex-direction: column;
`;

export const ListItemVertical = styled.li`
  display: block;
  align-items: center;
  text-decoration: none;
  transition: border 1s;
  color: ${({ theme }) => theme.palette.paragraph};
  padding: 10px;
  cursor: pointer;
  min-width: 100px;
  border-right: 1px solid transparent;

  &:before {
    display: block;
    content: attr(title);
    font-weight: bold;
    height: 0;
    overflow: hidden;
    visibility: hidden;
  }

  &:hover {
    background-color: ${({ theme }) => theme.palette.configFill};
    font-weight: 700;
  }
`;

export const IconsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 35px;
  min-height: 100px;
`;

export const Logo = styled.img`
  height: 30px;
  object-fit: contain;
`;

export const AvatarWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const MenuIcon = styled(SvgIcon)`
  color: #fff;
  margin-left: 10px;
  cursor: pointer;
`;

export const MenuCard = styled(Card)`
  position: absolute;
  padding: 20px;
  width: max-content;
  right: 0;
  z-index: 9999;

  ul {
    display: flex;
    flex-direction: column;
    gap: 20px;
    list-style: none;

    li {
      cursor: pointer;
    }
  }
`;

export const ModeSelector = styled(DropDownList)`
  background-color: #4b59a3;
  border: solid 1px #848dc0;
  border-radius: 8px;
  color: white;
  min-width: 200px;
`;

export const ConnectButton = styled(Button)`
  color: ${({ theme }) => theme.palette.headerTitle};
  min-width: 180px;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`;

export const MenuIconCustom = styled.span`
  color: #fff;
  margin-right: 5px;
  max-width: 20px;
  width: 100%;
  cursor: pointer;
`;
