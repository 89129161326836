/* eslint-disable prefer-destructuring */
/* eslint-disable no-unused-vars */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-debugger */
import {
  getAutoGrasp,
  getCoContractionTimings,
  getControlConfig,
  getEmgGains,
  getEmgSpike,
  getEmgThresholds,
  getFingerStrength,
  getFirmwareVersion,
  getFreezeMode,
  getGripLimitPositions,
  getGripPositions,
  getGripsPairsConfig,
  getGripsSequentialConfig,
  getHoldOpen,
  getInterval,
  getPulseTimings,
  getSoftGrip,
  postFingerLimits,
  postInitialGripPositions,
  telemetryEnabled
} from 'bluetooth-handler/bluetoothFunctions';
import { Grips } from 'bluetooth/Bluetooth/Grips';
import toast from 'react-hot-toast';
import { arraysEqual } from 'utils/funcs';
import { SpeedControlStrategies } from 'bluetooth/Bluetooth/Control';
import {
  emgThresholdsEntry,
  ModeConfigTemplate,
  CommonConfigTemplate
} from 'consts/deviceConfig/deviceConfig.types';
import { chain, get, isEmpty, map } from 'lodash';
import { diff } from 'fast-array-diff';
import { gripsGroupsOptionsMap, historySettingsNameMap } from '../../../utils/definesLocal';

export const compareGripsPositions = (apiGripsPositions, localGripsPositions) => {
  let positionsToSentApi = {};
  const localConfigCopy = { ...localGripsPositions };
  const localConfigCopy2 = { ...localConfigCopy };
  for (const key in localGripsPositions) {
    if (Object.prototype.hasOwnProperty.call(localConfigCopy2, key)) {
      const element = localConfigCopy2[key];
      if (
        !arraysEqual(element?.initial, apiGripsPositions[key]?.initial) ||
        (!arraysEqual(element?.limit, apiGripsPositions[key]?.limit) && element)
      ) {
        positionsToSentApi = { ...positionsToSentApi, [key]: element };
      }
      delete localConfigCopy[key];
    }
  }
  positionsToSentApi = { ...positionsToSentApi, ...localConfigCopy };
  return positionsToSentApi;
};

export interface HistoryItem {
  name: string | unknown;
  configName: string;
  before: any;
  after: any;
  date: any;
  raw: {
    name?: string | null;
    before: any;
    after: any;
  };
  undo: any;
  timestamp: number;
  restorePoint: boolean;
}

export const compareConfigBaseOnHistory = (config, history) => {
  const result: HistoryItem[] = [];
  const localHistoryCopy = history;
  let previousElement = config;
  const simpleMapping = {
    gripPairsConfig: gripsGroupsOptionsMap,
    gripSequentialConfig: gripsGroupsOptionsMap
  };

  const compareSingleElementsInHistory = (items, historyKey) => {
    for (const key in items) {
      if (Object.prototype.hasOwnProperty.call(items, key) && key !== 'timestamp') {
        const currentValue = items[key];
        const oldValue = get(previousElement, key);

        if (currentValue && oldValue && !arraysEqual(currentValue, oldValue)) {
          const diffElement = diff(currentValue, oldValue);
          const date = new Date(items.timestamp);
          const before = diffElement.removed;
          const after = diffElement.added;
          result.push({
            name: historySettingsNameMap.get(historyKey),
            configName: key,
            before: simpleMapping[key]
              ? before.map((b) => simpleMapping[key].get(b)).join(',')
              : before,
            after: simpleMapping[key]
              ? after.map((a) => simpleMapping[key].get(a)).join(',')
              : after,
            date,
            undo: {
              path: `${key}`,
              value: before
            },
            raw: {
              name: historyKey,
              before,
              after
            },
            timestamp: date.getTime(),
            restorePoint: false
          });
        }
      }
    }
    previousElement = items;
  };

  if (
    localHistoryCopy.prosthesisSettingsHistory &&
    localHistoryCopy.prosthesisSettingsHistory.length > 0
  ) {
    let configToCompare = [
      config.inputSite,
      config.controlMode,
      config.speedControlStrategy,
      config.gripSwitchingMode,
      config.emgSpike,
      config.autoGrasp,
      config.holdOpen,
      config.softGrip,
      config.pulseTimings,
      config.coContractionTimings
    ];
    Array.from([...localHistoryCopy.prosthesisSettingsHistory].reverse()).forEach(
      (prosthesisSettingsItem: any) => {
        const settingsDiff = diff(configToCompare, prosthesisSettingsItem.values);
        if (settingsDiff.added.length > 0 || settingsDiff.removed.length) {
          const date = new Date(prosthesisSettingsItem.timestamp);
          result.push({
            name: historySettingsNameMap.get('prosthesisSettingsHistory'),
            configName: '-',
            before: settingsDiff.removed,
            after: settingsDiff.added,
            date,
            raw: {
              name: 'prosthesisSettingsHistory',
              before: settingsDiff.removed,
              after: settingsDiff.added
            },
            undo: {
              path: 'prosthesisSettingsHistory',
              value: settingsDiff.added
            },
            timestamp: date.getTime(),
            restorePoint: false
          });
        }

        configToCompare = prosthesisSettingsItem.values;
      }
    );

    delete localHistoryCopy.prosthesisSettingsHistory;
  }

  if (
    localHistoryCopy.gripsConfigurationHistory &&
    localHistoryCopy.gripsConfigurationHistory.length > 0
  ) {
    const groupedHistory = chain(localHistoryCopy.gripsConfigurationHistory)
      .groupBy('selectedGrip')
      .value();
    let gripToCompare = config.gripsPositions;

    Array.from([...localHistoryCopy.gripsConfigurationHistory].reverse()).forEach(
      (gripHistoryItem: any) => {
        const gripsCompared = compareGripsPositions(gripToCompare, gripHistoryItem.values);
        if (!isEmpty(gripsCompared)) {
          const date = new Date(gripHistoryItem.timestamp);
          const after = Object.keys(gripToCompare)
            .map((gripIndex) =>
              Object.keys(gripsCompared).includes(gripIndex) ? gripToCompare[gripIndex] : undefined
            )
            .filter(Boolean);
          const before = gripsCompared;
          const formatLimits = (items) =>
            map(items, (value) => `Initial: ${value.initial}, Limit: ${value.limit}`);

          result.push({
            name: historySettingsNameMap.get('gripsConfigurationHistory'),
            configName: Object.keys(gripsCompared)
              .map((item) => gripsGroupsOptionsMap.get(parseInt(item, 10)))
              .join(','),
            before: formatLimits(before),
            after: formatLimits(after),
            date,
            raw: {
              name: 'gripsConfigurationHistory',
              before,
              after
            },
            undo: {
              path: `gripsCompared`,
              value: before
            },
            timestamp: date.getTime(),
            restorePoint: false
          });
        }
        gripToCompare = gripHistoryItem.values;
      }
    );

    delete localHistoryCopy.gripsConfigurationHistory;
  }

  for (const historyKey in localHistoryCopy) {
    if (
      Object.prototype.hasOwnProperty.call(localHistoryCopy, historyKey) &&
      localHistoryCopy[historyKey].length > 0
    ) {
      // eslint-disable-next-line array-callback-return
      Array.from([...localHistoryCopy[historyKey]].reverse()).map((item: any) => {
        compareSingleElementsInHistory(item, historyKey);
      });
    }
  }

  return result.sort((a, b) => b.timestamp - a.timestamp);
};

export const compareConfigs = (apiConfig, localConfig) => {
  let configToSentApi = {};
  const localConfigCopy = { ...localConfig };
  delete localConfigCopy.gripsConfiguration;
  const localConfigCopy2 = { ...localConfigCopy };
  for (const key in localConfig) {
    if (Object.prototype.hasOwnProperty.call(localConfigCopy2, key)) {
      const element = localConfigCopy2[key];
      const elementAPI = apiConfig?.[key];

      if (element && elementAPI) {
        if (!arraysEqual(element, elementAPI)) {
          configToSentApi = { ...configToSentApi, [key]: element };
        }
      }
      delete localConfigCopy[key];
    }
  }
  configToSentApi = { ...configToSentApi, ...localConfigCopy };
  if (apiConfig?.gripsPositions && localConfig?.gripsPositions) {
    const gripsCompared = compareGripsPositions(
      apiConfig.gripsPositions,
      localConfig.gripsPositions
    );
    if (!isEmpty(gripsCompared)) {
      return { ...configToSentApi, gripsPositions: gripsCompared };
    }
  }
  return { ...configToSentApi };
};

export const compareHistory = (config, history: any[]) => {
  const historyReversed = history.slice().reverse();
  return historyReversed.map((historyElement, index) => {
    const changed = compareConfigs(
      index ? historyReversed[index - 1].config : config,
      historyElement.config
    );
    const changedAfter = compareConfigs(
      historyElement.config,
      index ? historyReversed[index - 1].config : config
    );
    const key = Object.keys(changed)[0];
    return {
      before: changed[key],
      after: changedAfter[key],
      key,
      timestamp: historyElement.timestamp,
      id: historyElement.id
    };
  });
};

export const compareConfigsAPI = (apiConfig, localConfig) => {
  let configToSentApi = {};
  const localConfigCopy = { ...localConfig };
  delete localConfigCopy.gripsConfiguration;
  const localConfigCopy2 = { ...localConfigCopy };
  for (const key in localConfig) {
    if (Object.prototype.hasOwnProperty.call(localConfigCopy2, key)) {
      const element = localConfigCopy2[key];
      const elementAPI = apiConfig?.[key];

      if (element && elementAPI) {
        if (!arraysEqual(element, apiConfig[key])) {
          configToSentApi = { ...configToSentApi, [key]: element };
        }
      }
      delete localConfigCopy[key];
    }
  }
  configToSentApi = { ...configToSentApi, ...localConfigCopy };
  const gripsCompared = compareGripsPositions(apiConfig.gripsPositions, localConfig.gripsPositions);
  if (!isEmpty(gripsCompared)) {
    return { ...configToSentApi, gripsPositions: localConfig.gripsPositions };
  }
  return { ...configToSentApi };
};

export const loadConfig = (state, importConfig) => {
  state.config = { ...state.config, ...importConfig };
};

export const selectWholeConfig = (state) => state.bluetooth.config;

export const selectModeConfig = (config) => {
  const modeConfig: ModeConfigTemplate = {
    gripSequentialConfig: config.gripSequentialConfig,
    gripPairsConfig: config.gripPairsConfig,
    emgThresholds: config.emgThresholds,
    controlMode: config.controlMode,
    speedControlStrategy: config.speedControlStrategy,
    gripSwitchingMode: config.gripSwitchingMode,
    autoGrasp: config.autoGrasp,
    emgSpike: config.emgSpike,
    softGrip: config.softGrip,
    holdOpen: config.holdOpen,
    emgGains: config.emgGains,
    pulseTimings: config.pulseTimings,
    coContractionTimings: config.coContractionTimings
  };
  return modeConfig;
};

export const selectCommonConfig = (config) => {
  const commonConfig: CommonConfigTemplate = {
    inputSite: config.inputSite,
    gripsPositions: config.gripsPositions,
    fingerStrength: config.fingerStrength
  };
  return commonConfig;
};

export const findModeIndex = (state, modeId) =>
  state.modes.modesConfigs.findIndex((modeConfig) => modeConfig.id === modeId);

const getGripsLimits = async (gripsList: Grips[], mode, fetchingPositionsToast) => {
  const gripsPositionsObject = {};
  let iterator = 0;
  for (const grip of gripsList) {
    const gripPositions = await getGripPositions(grip, mode);
    const gripLimitPositions = await getGripLimitPositions(grip, mode);

    if (gripPositions && gripLimitPositions) {
      gripsPositionsObject[gripPositions[0]] = {
        initial: [
          gripPositions[1],
          gripPositions[2],
          gripPositions[3],
          gripPositions[4],
          gripPositions[5]
        ],
        limit: [
          gripLimitPositions[1],
          gripLimitPositions[2],
          gripLimitPositions[3],
          gripLimitPositions[4],
          gripLimitPositions[5]
        ]
      };
    }
    toast.loading(`Fetching grips positions... (${iterator + 1}/${gripsList.length})`, {
      id: fetchingPositionsToast
    });
    iterator += 1;
  }
  return gripsPositionsObject;
};

export const getCoreConfig = async (bluetoothMode, initialConfigToast, wasTelemetryOn = false) => {
  toast.loading('Fetching control config... 1/3', {
    id: initialConfigToast
  });
  const controlConfig = await getControlConfig(bluetoothMode);
  toast.loading('Fetching prosthesis settings... 2/3', {
    id: initialConfigToast
  });
  const gripPairsConfig = await getGripsPairsConfig(bluetoothMode);
  const gripSequentialConfig = await getGripsSequentialConfig(bluetoothMode);
  let gripSequentialConfigSanitized;
  if (gripSequentialConfig) {
    gripSequentialConfigSanitized = [
      ...gripSequentialConfig.slice(0, 5),
      255,
      ...gripSequentialConfig.slice(6, 11),
      255
    ];
  }
  const emgThresholds = await getEmgThresholds(bluetoothMode);
  const autoGrasp = await getAutoGrasp(bluetoothMode);
  if (autoGrasp?.[1] > 100) autoGrasp[1] = 100;
  const softGrip = await getSoftGrip(bluetoothMode);
  const holdOpen = await getHoldOpen(bluetoothMode);
  const emgSpike = await getEmgSpike(bluetoothMode);
  const fingerStrength = await getFingerStrength(bluetoothMode);
  const pulseTimings = await getPulseTimings(bluetoothMode);
  const coContractionTimings = await getCoContractionTimings(bluetoothMode);
  const gripsToDownload = [
    Grips.kGripPower,
    Grips.kGripHook,
    Grips.kGripFingerPoint,
    Grips.kGripMouse,
    Grips.kGripKey,
    Grips.kGripTrigger,
    Grips.kGripTripodClosed,
    Grips.kGripPrecisionOpen,
    Grips.kGripCamera,
    Grips.kGripRestOpp,
    Grips.kGripRestNopp,
    Grips.kGripPrecisionClosed,
    Grips.kGripTripodOpen,
    Grips.kGripFingerPointOpen
  ];
  toast.loading('Fetching grips positions... 3/3', {
    id: initialConfigToast
  });
  const gripsPositions = await getGripsLimits(gripsToDownload, bluetoothMode, initialConfigToast);
  const freezeMode = await getFreezeMode(bluetoothMode);
  const emgGains = await getEmgGains(bluetoothMode);
  if (wasTelemetryOn) {
    await telemetryEnabled(true, bluetoothMode);
  }
  toast.remove();

  return {
    controlConfig,
    gripPairsConfig,
    gripSequentialConfigSanitized,
    emgThresholds,
    autoGrasp,
    softGrip,
    holdOpen,
    emgSpike,
    fingerStrength,
    gripsPositions,
    freezeMode,
    emgGains,
    pulseTimings,
    coContractionTimings
  };
};

export const getCommonConfig = async (
  bluetoothMode,
  initialConfigToast,
  wasTelemetryOn = false
) => {
  toast.loading(`Fetching common settings`, {
    id: initialConfigToast
  });
  const controlConfig = await getControlConfig(bluetoothMode);
  const fingerStrength = await getFingerStrength(bluetoothMode);
  const gripsToDownload = [
    Grips.kGripPower,
    Grips.kGripHook,
    Grips.kGripFingerPoint,
    Grips.kGripMouse,
    Grips.kGripKey,
    Grips.kGripTrigger,
    Grips.kGripTripodClosed,
    Grips.kGripPrecisionOpen,
    Grips.kGripCamera,
    Grips.kGripRestOpp,
    Grips.kGripRestNopp,
    Grips.kGripPrecisionClosed,
    Grips.kGripTripodOpen,
    Grips.kGripFingerPointOpen
  ];
  const gripsPositions = await getGripsLimits(gripsToDownload, bluetoothMode, initialConfigToast);
  if (wasTelemetryOn) {
    await telemetryEnabled(true, bluetoothMode);
  }
  toast.remove();

  return {
    controlConfig,
    fingerStrength,
    gripsPositions
  };
};

export const getModeConfigBluetooth = async (
  bluetoothMode,
  initialConfigToast,
  wasTelemetryOn = false,
  modeName
) => {
  toast.loading(`Fetching mode ${modeName} settings`, {
    id: initialConfigToast
  });
  const controlConfig = await getControlConfig(bluetoothMode);
  const gripPairsConfig = await getGripsPairsConfig(bluetoothMode);
  const gripSequentialConfig = await getGripsSequentialConfig(bluetoothMode);
  let gripSequentialConfigSanitized;
  if (gripSequentialConfig) {
    gripSequentialConfigSanitized = [
      ...gripSequentialConfig.slice(0, 5),
      255,
      ...gripSequentialConfig.slice(6, 11),
      255
    ];
  }
  const emgThresholds = await getEmgThresholds(bluetoothMode);
  const autoGrasp = await getAutoGrasp(bluetoothMode);
  if (autoGrasp?.[1] > 100) autoGrasp[1] = 100;
  const softGrip = await getSoftGrip(bluetoothMode);
  const holdOpen = await getHoldOpen(bluetoothMode);
  const emgSpike = await getEmgSpike(bluetoothMode);
  const pulseTimings = await getPulseTimings(bluetoothMode);
  const coContractionTimings = await getCoContractionTimings(bluetoothMode);
  const emgGains = await getEmgGains(bluetoothMode);
  if (wasTelemetryOn) {
    await telemetryEnabled(true, bluetoothMode);
  }

  toast.remove();

  return {
    controlConfig,
    gripPairsConfig,
    gripSequentialConfigSanitized,
    emgThresholds,
    autoGrasp,
    softGrip,
    holdOpen,
    emgSpike,
    pulseTimings,
    coContractionTimings,
    emgGains
  };
};

export const sendFingersConfigHelper = async (grip, valuesInitial, valuesLimit, bluetoothMode) => {
  await postInitialGripPositions(grip, valuesInitial, bluetoothMode);
  await postFingerLimits(grip, valuesLimit, bluetoothMode);
  const gripValuesSent = [...valuesInitial, ...valuesLimit];

  return { gripValuesSent };
};

export const sendAllFingersHelper = async (gripsPositions, bluetoothMode) => {
  for (const grip in gripsPositions) {
    if (Object.prototype.hasOwnProperty.call(gripsPositions, grip)) {
      const gripPositions = gripsPositions[grip];
      await sendFingersConfigHelper(
        grip,
        gripPositions.initial,
        gripPositions.limit,
        bluetoothMode
      );
    }
  }
};

export const checkEmgValidity = (speedControlStrategy: any, emgSettings: emgThresholdsEntry) => {
  let speed1Opening = emgSettings[2];
  let speed2Opening = emgSettings[3];
  const speed3Opening = emgSettings[4];
  let speed1Closing = emgSettings[5];
  let speed2Closing = emgSettings[6];
  const speed3Closing = emgSettings[7];
  if (speedControlStrategy === SpeedControlStrategies.kProportional) {
    // Correct wrong values
    if (speed2Opening > speed3Opening) {
      speed2Opening = speed3Opening;
      toast.error("Speed 3 must be bigger than speed 2, check 'Proportional' view in emg settings");
    }
    if (speed1Opening > speed2Opening) {
      speed1Opening = speed2Opening;
      toast.error("Speed 2 must be bigger than speed 1, check 'Proportional' view in emg settings");
    }
    if (speed2Closing > speed3Closing) {
      speed2Closing = speed3Closing;
      toast.error("Speed 3 must be bigger than speed 2, check 'Proportional' view in emg settings");
    }
    if (speed1Closing > speed2Closing) {
      speed1Closing = speed2Closing;
      toast.error("Speed 2 must be bigger than speed 1, check 'Proportional' view in emg settings");
    }
  }
  const validatedEmg = [
    emgSettings[0],
    emgSettings[1],
    speed1Opening,
    speed2Opening,
    speed3Opening,
    speed1Closing,
    speed2Closing,
    speed3Closing,
    emgSettings[8],
    emgSettings[9]
  ];
  return validatedEmg;
};
