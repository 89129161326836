import React from 'react';
import DefaultLayout from 'layouts/DefaultLayout';
import DeviceHistoryComponent from './DeviceHistoryComponent';

const DeviceHistory = () => (
  <DefaultLayout>
    <DeviceHistoryComponent />
  </DefaultLayout>
);

export default DeviceHistory;
