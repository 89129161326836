/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import styled from 'styled-components';
import Divider from 'components/atoms/Divider/Divider';
import {
  controlModeMap,
  genericSwitchingMap,
  gripsGroupsOptionsReversedMap,
  gripSwitchingMap,
  inputSiteMap,
  speedControlMap
} from 'utils/definesLocal';
import Dropdown from 'components/atoms/Dropdown/Dropdown';
import InnerCard from 'components/atoms/Card/InnerCard';
import { TypesConfig } from 'types';
import ConfigItem from 'components/atoms/ConfigItem/ConfigItem';
import { Table } from 'components/atoms/Table/Table';
import Accordion from 'components/atoms/Accordion/Accordion';
import { DropDownList } from '@progress/kendo-react-dropdowns';

export enum ToggleTypesChangesHistory {
  all = 0,
  emg = 1,
  grips = 2,
  prosthesis = 3
}

interface ConfigComponentsProps {
  config?: any;
  transformedConfig?: any;
  currentToggle: ToggleTypesChangesHistory;
}

const ConfigWrapper = styled.div`
  display: grid;
  gap: 24px;
`;

const DividerWrapper = styled.div`
  display: grid;
  gap: 16px;
`;

const DropDownWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const ConfigList = styled.ul`
  display: grid;
  gap: 16px;
  list-style: none;
`;

const ConfigComponent = ({ config, transformedConfig, currentToggle }: ConfigComponentsProps) => {
  const [selectedGrip, setSelectedGrip] = useState('Rest opp');

  const showProsthesis =
    currentToggle === ToggleTypesChangesHistory.all ||
    currentToggle === ToggleTypesChangesHistory.prosthesis;

  const showGrips =
    currentToggle === ToggleTypesChangesHistory.all ||
    currentToggle === ToggleTypesChangesHistory.grips;

  const showEMG =
    currentToggle === ToggleTypesChangesHistory.all ||
    currentToggle === ToggleTypesChangesHistory.emg;

  const gripNumber: any = gripsGroupsOptionsReversedMap.get(selectedGrip);

  return (
    <ConfigWrapper>
      {showGrips && (
        <>
          {config?.gripsPositions && (
            <Accordion header='Grip positions'>
              <DividerWrapper>
                <div style={{ width: '300px' }}>
                  <DropDownWrapper>
                    <span>Grip:</span>
                    <DropDownList
                      fillMode='outline'
                      rounded='large'
                      value={selectedGrip}
                      onChange={(e) => setSelectedGrip(e.target.value)}
                      data={transformedConfig?.grips.filter((item) => item !== undefined)}
                    />
                  </DropDownWrapper>
                </div>
                <Table>
                  <tr>
                    <th style={{ width: '60%' }}>Finger</th>
                    <th>Initial</th>
                    <th>Limit</th>
                  </tr>
                  <tr>
                    <td>Thumb</td>
                    <td>{config?.gripsPositions?.[gripNumber]?.initial?.[0]}</td>
                    <td>{config?.gripsPositions?.[gripNumber]?.limit?.[0]}</td>
                  </tr>
                  <tr>
                    <td>Index</td>
                    <td>{config?.gripsPositions?.[gripNumber]?.initial?.[1]}</td>
                    <td>{config?.gripsPositions?.[gripNumber]?.limit?.[1]}</td>
                  </tr>
                  <tr>
                    <td>Middle</td>
                    <td>{config?.gripsPositions?.[gripNumber]?.initial?.[2]}</td>
                    <td>{config?.gripsPositions?.[gripNumber]?.limit?.[2]}</td>
                  </tr>
                  <tr>
                    <td>Ring</td>
                    <td>{config?.gripsPositions?.[gripNumber]?.initial?.[3]}</td>
                    <td>{config?.gripsPositions?.[gripNumber]?.limit?.[3]}</td>
                  </tr>
                  <tr>
                    <td>Pinky</td>
                    <td>{config?.gripsPositions?.[gripNumber]?.initial?.[4]}</td>
                    <td>{config?.gripsPositions?.[gripNumber]?.limit?.[4]}</td>
                  </tr>
                </Table>
              </DividerWrapper>
            </Accordion>
          )}
          {transformedConfig?.gripPairsConfig && (
            <Accordion header='Pairs config'>
              <DividerWrapper>
                <Table>
                  <caption>Opposed</caption>
                  <tr>
                    <th colSpan={2} style={{ width: '50%' }}>
                      Primary
                    </th>
                    <th colSpan={2} style={{ width: '50%' }}>
                      Secondary
                    </th>
                  </tr>
                  <tr>
                    <td>{transformedConfig?.gripPairsConfig[0]}</td>
                    <td>{transformedConfig?.gripPairsConfig[1]}</td>
                    <td>{transformedConfig?.gripPairsConfig[2]}</td>
                    <td>{transformedConfig?.gripPairsConfig[3]}</td>
                  </tr>
                </Table>
                <Divider margin='20px' />
                <Table>
                  <caption>Non-opposed</caption>
                  <tr>
                    <th colSpan={2}>Primary</th>
                    <th colSpan={2}>Secondary</th>
                  </tr>
                  <tr>
                    <td colSpan={1}>{transformedConfig?.gripPairsConfig[4]}</td>
                    <td colSpan={1}>{transformedConfig?.gripPairsConfig[5]}</td>
                    <td colSpan={1}>{transformedConfig?.gripPairsConfig[6]}</td>
                    <td colSpan={1}>{transformedConfig?.gripPairsConfig[7]}</td>
                  </tr>
                </Table>
              </DividerWrapper>
            </Accordion>
          )}
          {transformedConfig?.gripSequentialConfig && (
            <Accordion header='Grips sequential config'>
              <DividerWrapper>
                <Table>
                  <caption>Opposed</caption>
                  <tr>
                    <td>{transformedConfig?.gripSequentialConfig[0]}</td>
                    <td>{transformedConfig?.gripSequentialConfig[1]}</td>
                    <td>{transformedConfig?.gripSequentialConfig[2]}</td>
                    <td>{transformedConfig?.gripSequentialConfig[3]}</td>
                    <td>{transformedConfig?.gripSequentialConfig[4]}</td>
                  </tr>
                </Table>
                <Divider margin='20px' />
                <Table>
                  <caption>Non-opposed</caption>
                  <tr>
                    <td>{transformedConfig?.gripSequentialConfig[6]}</td>
                    <td>{transformedConfig?.gripSequentialConfig[7]}</td>
                    <td>{transformedConfig?.gripSequentialConfig[8]}</td>
                    <td>{transformedConfig?.gripSequentialConfig[9]}</td>
                    <td>{transformedConfig?.gripSequentialConfig[10]}</td>
                  </tr>
                </Table>
              </DividerWrapper>
            </Accordion>
          )}
        </>
      )}
      <ConfigList>
        {showProsthesis && (
          <>
            {Boolean(config?.inputSite) && (
              <li>
                <ConfigItem
                  header='Input sites'
                  items={[
                    {
                      header: 'Value',
                      content: `${inputSiteMap.get(config.inputSite[0])}`
                    }
                  ]}
                />
              </li>
            )}
            {Boolean(config?.speedControlStrategy) && (
              <li>
                <ConfigItem
                  header='Speed control strategy'
                  items={[
                    {
                      header: 'Value',
                      content: `${speedControlMap.get(config.speedControlStrategy[0])}`
                    }
                  ]}
                />
              </li>
            )}
            {Boolean(config?.gripSwitchingMode) && (
              <li>
                <ConfigItem
                  header='Grip switching modes'
                  items={[
                    {
                      header: 'Value',
                      content: `${gripSwitchingMap.get(config.gripSwitchingMode[0])}`
                    }
                  ]}
                />
              </li>
            )}
            {Boolean(config?.controlMode) && (
              <li>
                <ConfigItem
                  header='Control mode'
                  items={[
                    {
                      header: 'Value',
                      content: `${controlModeMap.get(config.controlMode[0])}`
                    }
                  ]}
                />
              </li>
            )}
            {Boolean(config?.softGrip?.[0]) && (
              <li>
                <ConfigItem
                  header='Soft-grip'
                  items={[
                    {
                      header: 'Status',
                      content: genericSwitchingMap.get(config.softGrip[0]),
                      type: TypesConfig.bool,
                      value: config?.softGrip?.[0]
                    },
                    { header: 'Value', content: `${config?.fingerStrength?.[1]}` }
                  ]}
                />
              </li>
            )}
            {Boolean(config?.autoGrasp) && (
              <li>
                <ConfigItem
                  header='Auto grasp'
                  items={[
                    {
                      header: 'Status',
                      content: `${genericSwitchingMap.get(config.autoGrasp[0])}`,
                      type: TypesConfig.bool,
                      value: config?.autoGrasp?.[0]
                    },
                    { header: 'Value', content: `${config.autoGrasp[1]}` }
                  ]}
                />
              </li>
            )}
            {Boolean(config?.holdOpen) && (
              <li>
                <ConfigItem
                  header='Hold open timings'
                  items={[
                    { header: 'Primary hold open time', content: `${config.holdOpen[0]} ms` },
                    { header: 'Secondary hold open time', content: `${config.holdOpen[1]} ms` }
                  ]}
                />
              </li>
            )}
            {Boolean(config?.pulseTimings) && (
              <li>
                <ConfigItem
                  header='Pulse timings'
                  items={[
                    { header: 'Min. pulse time', content: `${config.pulseTimings[0]} ms` },
                    { header: 'Max. pulse time', content: `${config.pulseTimings[1]} ms` },
                    {
                      header: 'Min. time between pulses',
                      content: `${config.pulseTimings[2]} ms`
                    },
                    { header: 'Max. time between pulses', content: `${config.pulseTimings[3]} ms` }
                  ]}
                />
              </li>
            )}
            {Boolean(config?.coContractionTimings) && (
              <li>
                <ConfigItem
                  header='Co-contraction timings'
                  items={[
                    {
                      header: 'Long co-contraction time',
                      content: `${config?.coContractionTimings[0]} ms`
                    },
                    {
                      header: 'Signal rise offset time',
                      content: `${config?.coContractionTimings[1]} ms`
                    }
                  ]}
                />
              </li>
            )}
          </>
        )}
        {showEMG && (
          <>
            {Boolean(config?.emgSpike) && (
              <li>
                <ConfigItem
                  header='Emg spike'
                  items={[
                    {
                      header: 'Status',
                      content: `${genericSwitchingMap.get(config.emgSpike[0])}`,
                      type: TypesConfig.bool,
                      value: config?.emgSpike?.[0]
                    },
                    { header: 'Value', content: `${config.emgSpike[1]} ms` }
                  ]}
                />
              </li>
            )}
            {Boolean(config?.emgGains) && (
              <li>
                <ConfigItem
                  header='EMG gains'
                  items={[
                    { header: 'Extension', content: `${config.emgGains[0]}%` },
                    { header: 'Flexion', content: `${config.emgGains[1]}%` }
                  ]}
                />
              </li>
            )}
            {Boolean(config?.emgThresholds) && (
              <li>
                <ConfigItem
                  header='EMG thresholds'
                  items={[
                    {
                      header: 'CS extension thresholds',
                      content: `${config.emgThresholds[0]}`
                    },
                    {
                      header: 'CS flexion thresholds',
                      content: `${config.emgThresholds[1]}`
                    },
                    {
                      header: 'Threshold control open value',
                      content: `${config.emgThresholds[2]}`
                    },
                    {
                      header: 'Proportional control open second value',
                      content: `${config.emgThresholds[3]}`
                    },
                    {
                      header: 'Proportional control open third value',
                      content: `${config.emgThresholds[4]}`
                    },
                    {
                      header: 'Threshold control close value',
                      content: `${config.emgThresholds[5]}`
                    },
                    {
                      header: 'Proportional control close second value',
                      content: `${config.emgThresholds[6]}`
                    },
                    {
                      header: 'Proportional control close third value',
                      content: `${config.emgThresholds[7]}`
                    },
                    {
                      header: 'Soft-grip open threshold second value',
                      content: `${config.emgThresholds[8]}`
                    },
                    {
                      header: 'Soft-grip close threshold second value',
                      content: `${config.emgThresholds[9]}`
                    }
                  ]}
                />
              </li>
            )}
          </>
        )}
      </ConfigList>
    </ConfigWrapper>
  );
};

export default ConfigComponent;
