/* eslint-disable no-unused-vars */
interface FeatureEntry {
  id: number;
  name: string;
  slug: string;
  created_at: string;
  updated_at: string;
}

interface FeaturesEntry {
  id: number;
  compatibility_id: number;
  feature_id: number;
  is_compatible: 0 | 1;
  reason: string;
  created_at: string;
  updated_at: string;
  feature: FeatureEntry;
}

export interface CompatibilityEntry {
  id: number;
  device_model_id: number;
  software_version_id: number;
  firmware_version_id: number;
  pcb_version_id: number;
  is_fully_compatible: 0 | 1;
  created_at: string;
  updated_at: string;
  features: Array<FeaturesEntry>;
}

export enum CompatibilitiesExtendOptions {
  features = 'features',
  featuresFeature = 'features.feature',
  deviceModel = 'deviceModel',
  softwareVersion = 'softwareVersion',
  firmwareVersion = 'firmwareVersion',
  pcbVersion = 'pcbVersion'
}

export interface CompatibilitiesQueryParams {
  model?: number;
  software?: string;
  firmware?: string;
  pcb?: string;
  summary?: 0 | 1;
  extend?: CompatibilitiesExtendOptions[];
  perpage?: number;
  page?: number;
}

export interface FirmwareVersionEntry {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
}

export interface PCBVersionEntry {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
}
