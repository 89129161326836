import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserDetails } from '../api/authentication/authentication.types';

type UserState = {
  userAccount: null | UserDetails;
};

const initialState: UserState = {
  userAccount: null
};

export const userReducer = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state: UserState, action: PayloadAction<UserDetails>) => {
      state.userAccount = action.payload;
    }
  }
});

export const userSelector = (state: any) => state.user.userAccount;

export const { setUser } = userReducer.actions;

export default userReducer.reducer;
