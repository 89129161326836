export const HOME = '/';

export const CHOOSE_GRIPS = `${HOME}choose-grips`;
export const EMG_SETTINGS = `${HOME}emg-settings`;
export const GRIPS_CONFIGURATION = `${HOME}grips-configuration`;
export const PROSTHESIS_SETTINGS = `${HOME}prosthesis-settings`;
export const APPLICATION_SETTINGS = `${HOME}application-settings`;
export const HISTORY_CONFIGURATION = `${HOME}device-history/type/:type`;
export const HISTORY_LOGS = `${HOME}device-logs`;
export const CONFIG_TEMPLATES = `${HOME}config-templates`;
export const SERVICE_MENU = `${HOME}service-menu`;

export const DASHBOARD = `${HOME}dashboard`;

export const LOGIN = `${HOME}`;

export const COMPANY = `${HOME}company`;
export const COMPANY_DETAILS = `${COMPANY}/:companyId`;
export const COMPANY_ADD = `${COMPANY}/add`;
export const COMPANY_EDIT = `${COMPANY}/edit`;

export const CLINICIANS = `${HOME}clinicians`;
export const CLINICIAN = `${CLINICIANS}/:clinicianId`;
export const CLINICIAN_ADD = `${CLINICIANS}/add`;
export const CLINICIAN_EDIT = `${CLINICIANS}/edit`;

export const LOCATION = `${HOME}location`;
export const LOCATION_DETAILS = `${LOCATION}/:locationId`;
export const LOCATION_ADD = `${LOCATION}/add`;
export const LOCATION_EDIT = `${LOCATION}/edit`;

export const DEVICE = `${HOME}device`;
export const DEVICE_DETAILS = `${DEVICE}/:deviceId`;
export const DEVICE_ADD = `${DEVICE}/add`;
export const DEVICE_EDIT = `${DEVICE}/edit`;

export const PATIENTS = `${HOME}patients`;
export const PATIENT = `${PATIENTS}/:patientId`;
export const PATIENT_ADD = `${PATIENTS}/add`;
export const PATIENT_EDIT = `${PATIENTS}/edit`;

export const MODES = `${HOME}modes`;

export const RELEASES = `${HOME}releases`;
export const RELEASE_ADD = `${RELEASES}/add`;
export const RELEASE_EDIT = `${RELEASES}/edit`;
export const RELEASE_COMPATIBILITY_ADD = `${RELEASES}/compatibility/add`;
export const RELEASE_COMPATIBILITY_EDIT = `${RELEASES}/compatibility/edit`;

export const VERSIONS = `${HOME}versions`;
export const VERSION_ADD = `${VERSIONS}/add`;

export const ACCOUNT = `${HOME}account`;
export const ACCOUNT_CHANGE_PASSWORD = `${ACCOUNT}/change-password`;

export const USER_MANAGE = `${HOME}manage-user`;
export const USER_MANAGE_EDIT = `${USER_MANAGE}/edit`;

export const MESSAGE = `${HOME}message`;
export const TICKET_REDIRECT = `${HOME}ticket`;

export const SERVICING = `${HOME}servicing`;
