import { ReactNode } from 'react';
import { ReactComponent as HandIcon } from 'assets/icons/icon-hand.svg';
import { ReactComponent as EmgIcon } from 'assets/icons/icon-emg.svg';
import { ReactComponent as ClipboardIcon } from 'assets/icons/icon-clipboard.svg';
import { ReactComponent as DraftIcon } from 'assets/icons/icon-draft.svg';
import { ReactComponent as CloudUploadIcon } from 'assets/icons/icon-cloud-upload.svg';

import * as React from 'react';
import {
  APPLICATION_SETTINGS,
  CHOOSE_GRIPS,
  EMG_SETTINGS,
  HISTORY_CONFIGURATION,
  HISTORY_LOGS,
  GRIPS_CONFIGURATION,
  PROSTHESIS_SETTINGS,
  CONFIG_TEMPLATES,
  SERVICE_MENU
} from './routes';
import { RoleEnum } from '../api/users/users.types';

export type ReactSvgComponent = React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & { title?: string }
>;

export type IconMenuProps = string | ReactNode | ReactSvgComponent;

export interface MenuItem {
  name: string;
  url: string;
  disabled?: boolean;
  icon?: IconMenuProps;
  items?: MenuItem[];
  roles?: RoleEnum[];
}

export const MENU_CONFIG: MenuItem[] = [
  {
    name: 'Grips',
    url: CHOOSE_GRIPS,
    icon: <HandIcon />,
    roles: [
      RoleEnum.superAdmin,
      RoleEnum.clinician,
      RoleEnum.clinicAdmin,
      RoleEnum.clinicianSupport
    ]
  },
  {
    name: 'EMG',
    url: EMG_SETTINGS,
    icon: <EmgIcon />,
    roles: [
      RoleEnum.superAdmin,
      RoleEnum.clinician,
      RoleEnum.clinicAdmin,
      RoleEnum.clinicianSupport
    ]
  },
  {
    name: 'Prosthesis settings',
    url: PROSTHESIS_SETTINGS,
    icon: <ClipboardIcon />,
    roles: [
      RoleEnum.superAdmin,
      RoleEnum.clinician,
      RoleEnum.clinicAdmin,
      RoleEnum.clinicianSupport
    ]
  },
  {
    name: 'Grips configuration',
    url: GRIPS_CONFIGURATION,
    roles: [
      RoleEnum.superAdmin,
      RoleEnum.clinician,
      RoleEnum.clinicAdmin,
      RoleEnum.clinicianSupport
    ]
  },
  {
    name: 'History',
    url: 'history',
    icon: <DraftIcon />,
    items: [
      { name: 'Configuration logs', url: HISTORY_CONFIGURATION.replace(':type', 'calendar') },
      {
        name: 'Config templates',
        url: CONFIG_TEMPLATES,
        roles: [
          RoleEnum.superAdmin,
          RoleEnum.clinician,
          RoleEnum.clinicAdmin,
          RoleEnum.clinicianSupport
        ]
      },
      {
        name: 'Service menu',
        url: SERVICE_MENU,
        roles: [
          RoleEnum.superAdmin,
          RoleEnum.clinician,
          RoleEnum.clinicAdmin,
          RoleEnum.clinicianSupport
        ]
      }
    ],
    roles: [
      RoleEnum.superAdmin,
      RoleEnum.clinician,
      RoleEnum.clinicAdmin,
      RoleEnum.clinicianSupport
    ]
  }
];
