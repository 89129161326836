import { PayloadAction } from '@reduxjs/toolkit';

export const addSessionRestorePointReducer = (state, action: PayloadAction<any>) => {
  state.sessionRestorePoints = [...state.sessionRestorePoints, action.payload];
};

export const addLiveHistoryReducer = (
  state,
  action: PayloadAction<{ type: string; payload: any }>
) => {
  const providerCopy = state.sessionHistory[action.payload.type];
  providerCopy.push(action.payload.payload);
  state.sessionHistory[action.payload.type] = providerCopy;
};

export const consumeLiveHistorySessionReducer = (state) => {
  state.sessionHistory = {
    chooseGripsHistory: [],
    emgSettingsHistory: [],
    emgThresholdsHistory: [],
    gripPairsConfigHistory: [],
    gripSequentialConfigHistory: [],
    controlConfigHistory: [],
    prosthesisSettingsHistory: [],
    emgGainsHistory: [],
    gripsConfigurationHistory: []
  };
};

type liveConfigHistoryProvider =
  | 'chooseGripsHistory'
  | 'prosthesisSettingsHistory'
  | 'gripsConfigurationHistory'
  | 'emgSettingsHistory';

interface ConsumeLiveHistorySection {
  payload: {
    consumer: liveConfigHistoryProvider;
  };
}

export const consumeLiveHistorySessionSectionReducer = (
  state,
  action: ConsumeLiveHistorySection
) => {
  const providerLength = state.sessionHistory[action.payload.consumer].length;
  if (providerLength > 0) {
    state.sessionHistory[action.payload.consumer] = [];
  }
};

export const consumeRestorePointsReducer = (state) => {
  state.sessionRestorePoints = [];
};
