import React from 'react';
import DefaultLayout from 'layouts/DefaultLayout';
import DeviceConfigComponent from './DeviceConfigComponent';

const DeviceConfig = () => (
  <DefaultLayout>
    <DeviceConfigComponent />
  </DefaultLayout>
);

export default DeviceConfig;
