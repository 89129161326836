import styled from 'styled-components';
import { HeaderXL } from '../../components/atoms/Typography/Typography';

export const Header1 = styled.h2`
  ${HeaderXL};
  color: ${({ theme }) => theme.colorPrimary};
`;

export const StyledForm = styled.form`
  display: grid;
  gap: 20px;
`;

export const ButtonsWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  gap: 24px;
`;

export const UploadArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin: 8px 1px 20px;
  padding: 8px 16px;
  border-radius: 5px;
  box-shadow: 0 4px 20px 0 rgba(61, 72, 68, 0.05);
  background-color: #e9ecef;
  position: relative;
`;

export const TipText = styled.span`
  position: absolute;
  right: 0;
  bottom: -20px;
  text-align: right;
  font-size: 12px;
  color: #808080;
`;