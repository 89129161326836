/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo } from 'react';
import InputOptions from 'components/molecules/InputOptions/InputOptions';
import { useDispatch, useSelector } from 'react-redux';
import {
  gripSwitchingMap,
  gripSwitchingReversedMap,
  inputSiteMap,
  inputSiteReversedMap,
  speedControlMap,
  speedControlReversedMap
} from 'utils/definesLocal';
import {
  addHistory,
  addHistoryConfig,
  setAutograsp,
  setCoContractionTimings,
  setControlConfig,
  setEmgSpike,
  setFingerStrength,
  setHoldOpen,
  setPulseTimings,
  setSoftGrip
} from 'reducers/bluetoothReducer/bluetoothReducer';
import HorizontalGraphSlider from 'components/molecules/HorizontalGraphSlider/HorizontalGraphSlider';
import useFingerStrengthStep from 'hooks/useFingerStrengthStep';
import useProcedureReply from 'hooks/useProcedure';
import { GripSwitchingModes } from 'bluetooth/Bluetooth/Control';
import { handleProcedure } from 'reducers/bluetoothReducer/bluetoothHelpers/asyncThunks';
import { ProcedureTypes } from 'bluetooth/Bluetooth/Procedures';
import HorizontalSliderDouble from 'components/molecules/HorizontalGraphSlider/HorizontalSliderDouble';
import { debounce } from 'lodash';
import useRemoteSession from 'hooks/useRemoteSession';
import Card from 'components/Card/Card';
import DefaultLayout from 'layouts/DefaultLayout';
import { Header, HeaderSmall } from 'components/Typography/Header';
import { useTranslation } from 'react-i18next';
import { Switch } from '@progress/kendo-react-inputs';
import { Button } from '@progress/kendo-react-buttons';
import zeusVideo from 'assets/zeus.mp4';
import { addLiveHistory } from 'reducers/liveConfiguratorReducer/liveConfiguratorReducer';
import theme from 'theme/theme';
import * as svgIcons from '@progress/kendo-svg-icons';
import { SvgIcon } from '@progress/kendo-react-common';
import { TooltipSettings } from 'components/TooltipSettings/TooltipSettings';
import {
  AutograspWrapper,
  CalibrationWrapper,
  DoubleLabel,
  EMGIgnoreWrapper,
  HoldOpenWrapper,
  OptionsSliderDescription,
  OptionsWrapperHoldOpen,
  ProcedureReplyWrapper,
  ProcedureRow,
  ProcedureStatusBox,
  ProcedureTable,
  ProcedureTableWrapper,
  SettingsContainer,
  SoftGrip,
  SwitchWrapper,
  TooltipWrapper,
  Wrapper
} from './styled';

const parseNumber = (number: number) => Number(number / 1000).toFixed(2);

type OptionsWithSliderProps = {
  value: number;
  sliderValue: number;
  header: string;
  labelSlider: any;
  limits: { min: number; max: number };
  handleChange: Function;
  onChange: Function;
  handleOnAfterChange: Function;
  handleOnBeforeChange: Function;
  description: string;
  step?: number;
  tooltip?: {
    description: string;
    header: string;
    video?: any;
  };
};

const OptionsWithSlider = ({
  value,
  sliderValue,
  header,
  labelSlider,
  limits,
  handleChange,
  onChange,
  handleOnAfterChange,
  handleOnBeforeChange,
  description,
  tooltip,
  ...props
}: OptionsWithSliderProps) => {
  const anchor = React.useRef(null);
  const [show, setShow] = React.useState(false);

  return (
    <div {...props}>
      <SwitchWrapper>
        <Switch checked={Boolean(value)} onChange={(e) => onChange(e.value ? 1 : 0)} size='small' />
        <TooltipWrapper>
          <HeaderSmall>{header}</HeaderSmall>
          <div ref={anchor}>
            <SvgIcon
              style={{ width: `${theme.dimensions.iconSize}px`, cursor: 'pointer' }}
              icon={svgIcons.infoCircleIcon}
              themeColor='primary'
              onClick={() => setShow((prev) => !prev)}
            />
          </div>
        </TooltipWrapper>
      </SwitchWrapper>
      <OptionsSliderDescription>{description}</OptionsSliderDescription>
      <HorizontalGraphSlider
        // @ts-ignore
        value={sliderValue}
        limits={limits}
        label={labelSlider}
        handleChange={handleChange}
        handleOnAfterChange={handleOnAfterChange}
        handleOnBeforeChange={handleOnBeforeChange}
      />
      {tooltip && (
        <TooltipSettings
          show={show}
          anchor={anchor.current}
          header={tooltip.header}
          description={tooltip.description}
          video={tooltip?.video}
        />
      )}
    </div>
  );
};

const HoldOpen = ({
  sliderValues,
  header,
  labelSliders,
  limits,
  handleChange,
  handleOnAfterChange = () => true,
  handleOnBeforeChange,
  description,
  ...props
}) => (
  <div {...props}>
    <HeaderSmall>{header}</HeaderSmall>
    <OptionsSliderDescription>{description}</OptionsSliderDescription>
    <OptionsWrapperHoldOpen>
      {limits.map((slider, index) => (
        <HorizontalGraphSlider
          // @ts-ignore
          value={sliderValues[index]}
          limits={limits[index]}
          label={labelSliders[index]}
          handleChange={(...args) => handleChange(...args, index)}
          handleOnAfterChange={handleOnAfterChange}
          handleOnBeforeChange={handleOnBeforeChange}
        />
      ))}
    </OptionsWrapperHoldOpen>
  </div>
);

const PushSlider = ({
  sliderValues,
  header,
  labelSlider,
  limits,
  handleChange,
  handleOnAfterChange = () => true,
  handleOnBeforeChange,
  description,
  pearling = false,
  trackClass = undefined,
  ...props
}) => (
  <div {...props}>
    <HeaderSmall>{header}</HeaderSmall>
    <OptionsSliderDescription>{description}</OptionsSliderDescription>
    <OptionsWrapperHoldOpen>
      {limits.map((slider, index) => (
        <HorizontalSliderDouble
          // @ts-ignore
          value={sliderValues[index]}
          limits={limits[index]}
          labels={labelSlider[index]}
          handleChange={(...args) => handleChange(...args, index)}
          handleOnAfterChange={handleOnAfterChange}
          handleOnBeforeChange={handleOnBeforeChange}
          trackClass={trackClass}
          key={`HorizontalSliderDouble-${limits[index].min}`}
        />
      ))}
    </OptionsWrapperHoldOpen>
  </div>
);

const ProsthesisSettings = () => {
  const dispatch = useDispatch();
  const {
    config: {
      inputSite: [inputSite],
      controlMode: [controlMode],
      speedControlStrategy: [speedControlStrategy],
      gripSwitchingMode: [gripSwitchingMode],
      autoGrasp,
      autoGrasp: [autoGraspStatus, autoGraspCurrent],
      emgSpike: [emgSpikeStatus, emgSpikeTime],
      emgSpike,
      holdOpen: [holdOpenShort, holdOpenLong],
      holdOpen,
      softGrip: [softGripStatus],
      softGrip,
      pulseTimings,
      pulseTimings: [minimumPulse, maximumPulse, minimumBetweenPulses, maximumBetweenPulses],
      coContractionTimings,
      coContractionTimings: [longCoContraction, coContractionOffset]
    }
  } = useSelector((state: any) => state.bluetooth);
  const { sendConfig } = useRemoteSession();
  const { modeId } = useSelector((state: any) => state.modes);
  const { fingerStep, fingerStrengthCurrent, setFingerStep } = useFingerStrengthStep();
  const { t } = useTranslation();

  const { procedure } = useProcedureReply();

  const addHistoryProsthesis = async () => {
    await dispatch(addHistoryConfig());
    await dispatch(
      addHistory({
        type: 'prosthesisSettingsHistory',
        payload: [
          [inputSite],
          [controlMode],
          [speedControlStrategy],
          [gripSwitchingMode],
          emgSpike,
          autoGrasp,
          holdOpen,
          softGrip,
          pulseTimings,
          coContractionTimings
        ]
      })
    );

    await dispatch(
      addLiveHistory({
        type: 'prosthesisSettingsHistory',
        payload: {
          values: [
            [inputSite],
            [controlMode],
            [speedControlStrategy],
            [gripSwitchingMode],
            emgSpike,
            autoGrasp,
            holdOpen,
            softGrip,
            pulseTimings,
            coContractionTimings
          ],
          timestamp: Date.now()
        }
      })
    );
  };

  const handleFingerChange = (value) => {
    setFingerStep(value);
  };

  const handleonAfterChangeFinger = () => {
    // @ts-ignore
    dispatch(setFingerStrength({ finger: 1, strength: fingerStrengthCurrent }));
  };

  const handleSpeedControl = async (type, value) => {
    await addHistoryProsthesis();
    const newControlConfig = [
      inputSite,
      controlMode,
      speedControlReversedMap.get(value),
      gripSwitchingMode
    ];
    dispatch(
      setControlConfig({
        payload: newControlConfig,
        modeSelected: modeId
      })
    );
  };

  const handleInputSites = async (type, value) => {
    await addHistoryProsthesis();
    const newControlConfig = [
      inputSiteReversedMap.get(value),
      controlMode,
      speedControlStrategy,
      gripSwitchingMode
    ];
    dispatch(
      setControlConfig({
        payload: newControlConfig,
        modeSelected: modeId
      })
    );
  };

  const handleGripSwitchingModes = async (type, value) => {
    await addHistoryProsthesis();
    const newControlConfig = [
      inputSite,
      controlMode,
      speedControlStrategy,
      gripSwitchingReversedMap.get(value)
    ];
    dispatch(
      setControlConfig({
        payload: newControlConfig,
        modeSelected: modeId
      })
    );
  };

  const handleEmgSlider = (value) => {
    dispatch(setEmgSpike({ status: emgSpikeStatus, time: value, modeId }));
  };

  const handleEmgStatus = async (value) => {
    await addHistoryProsthesis();
    dispatch(
      // @ts-ignore
      setEmgSpike({ status: value, time: emgSpikeTime, modeId })
    );
  };

  const handleAutograspSlider = (value) => {
    dispatch(setAutograsp({ status: autoGraspStatus, current: value, modeId }));
  };

  const handleAutograspStatus = async (value) => {
    await addHistoryProsthesis();
    dispatch(
      // @ts-ignore
      setAutograsp({ status: value, current: autoGraspCurrent, modeId })
    );
  };

  const handleHoldOpenSliders = (...args) => {
    const short = args[0][3] === 0;
    const value = args[0][0];
    let newHoldOpenLong = holdOpenLong;
    let newHoldOpenShort = holdOpenShort;
    if (short && value > holdOpenLong) {
      newHoldOpenLong = value;
    }
    if (!short && value < holdOpenShort) {
      newHoldOpenShort = value;
    }
    if (short) {
      dispatch(setHoldOpen({ short: value, long: newHoldOpenLong, modeId }));
    } else {
      dispatch(setHoldOpen({ short: newHoldOpenShort, long: value, modeId }));
    }
  };

  const handleSoftGripStatus = async (value) => {
    await addHistoryProsthesis();
    dispatch(
      // @ts-ignore
      setSoftGrip({ payload: value, modeId })
    );
  };

  const handlePulseSlider = debounce((...args) => {
    const between = args[0][3] === 1;
    const values = args[0][0];
    let newMinimumPulse = minimumPulse;
    let newMaximumPulse = maximumPulse;
    let newMinimumBetweenPulses = minimumBetweenPulses;
    let newMaximumBetweenPulses = maximumBetweenPulses;
    if (between) {
      [newMinimumBetweenPulses, newMaximumBetweenPulses] = values;
    } else {
      [newMinimumPulse, newMaximumPulse] = values;
    }
    dispatch(
      setPulseTimings({
        minimumPulse: newMinimumPulse,
        maximumPulse: newMaximumPulse,
        minimumBetweenPulses: newMinimumBetweenPulses,
        maximumBetweenPulses: newMaximumBetweenPulses,
        modeId
      })
    );
  }, 10);

  const handleCoContractionSliders = (...args) => {
    const offset = args[0][3] === 1;
    const value = args[0][0];
    let newLongCoContraction = longCoContraction;
    let newOffset = coContractionOffset;
    if (offset && value > longCoContraction) {
      newLongCoContraction = value;
    }
    if (!offset && value < coContractionOffset) {
      newOffset = value;
    }
    if (offset) {
      dispatch(
        setCoContractionTimings({
          time: newLongCoContraction,
          offset: value,
          modeId
        })
      );
    } else {
      dispatch(
        setCoContractionTimings({
          time: value,
          offset: newOffset,
          modeId
        })
      );
    }
  };

  const sendConfigThrottled = debounce(sendConfig, 200);

  useEffect(() => {
    sendConfigThrottled();
  }, [
    JSON.stringify(inputSite),
    JSON.stringify(controlMode),
    JSON.stringify(speedControlStrategy),
    JSON.stringify(gripSwitchingMode),
    JSON.stringify(autoGrasp),
    JSON.stringify(emgSpike),
    JSON.stringify(holdOpen),
    JSON.stringify(softGrip),
    JSON.stringify(pulseTimings),
    JSON.stringify(coContractionTimings),
    modeId
  ]);

  const Checkboxes = useMemo(
    () => (
      <>
        <InputOptions
          header='Input options'
          options={['EMG']}
          id='options'
          value='EMG'
          tooltip={{
            header: 'Input options',
            description: 'Speed control strategy - mode in which you operate the prosthesis.',
            video: zeusVideo
          }}
        />
        <InputOptions
          header='Input sites'
          options={['Dual direct', 'Dual Inverted', 'Single']}
          id='sites'
          onChange={handleInputSites}
          value={inputSiteMap.get(inputSite)}
          tooltip={{
            header: 'Input sites',
            description: 'Speed control strategy - mode in which you operate the prosthesis.',
            video: zeusVideo
          }}
        />
        <InputOptions
          header='Speed control strategy'
          data-tour='strategy'
          options={['One speed', 'Proportional']}
          id='strategy'
          onChange={handleSpeedControl}
          // @ts-ignore
          value={speedControlMap.get(speedControlStrategy)}
          description='Mode in which you operate the prosthesis'
          tooltip={{
            header: 'Speed control strategy',
            description: 'Speed control strategy - mode in which you operate the prosthesis.',
            video: zeusVideo
          }}
        />
        <InputOptions
          header='Grip switching modes'
          data-tour='grip-switching'
          options={['Co-contraction', 'Open-open', 'Hold-open', 'Single electrode']}
          id='mode'
          onChange={handleGripSwitchingModes}
          // @ts-ignore
          value={gripSwitchingMap.get(gripSwitchingMode)}
          description='Different ways of generating Change Signal (CS) and Secondary Change Signal (SCS)'
          disable={gripSwitchingMode === GripSwitchingModes.kSingleGripSwitching}
          tooltip={{
            header: 'Grip switching modes',
            description:
              'Different ways of generating Change Signal (CS) and Secondary Change Signal (SCS)',
            video: zeusVideo
          }}
        />
      </>
    ),
    [inputSite, speedControlStrategy, gripSwitchingMode, procedure]
  );

  const EMG = useMemo(
    () => (
      <OptionsWithSlider
        header='Ignore EMG spikes'
        value={emgSpikeStatus}
        sliderValue={emgSpikeTime}
        handleChange={(value) => handleEmgSlider(value)}
        limits={{ min: 0, max: 500 }}
        labelSlider={`Time ignored: ${parseNumber(emgSpikeTime)} s`}
        onChange={(value) => handleEmgStatus(value)}
        handleOnAfterChange={(value) => handleEmgSlider(value)}
        handleOnBeforeChange={addHistoryProsthesis}
        description='Ignore short EMG spikes of the specified length'
        tooltip={{
          header: 'Ignore EMG spikes',
          description:
            'Different ways of generating Change Signal (CS) and Secondary Change Signal (SCS)',
          video: zeusVideo
        }}
      />
    ),
    [emgSpike]
  );

  const Autograsp = useMemo(
    () => (
      <OptionsWithSlider
        header='Autograsp'
        value={autoGraspStatus}
        sliderValue={autoGraspCurrent}
        handleChange={(value) => handleAutograspSlider(value)}
        limits={{ min: 0, max: 100 }}
        labelSlider={
          <DoubleLabel>
            <span>Gentle</span>
            <span>Strong</span>
          </DoubleLabel>
        }
        onChange={(value) => handleAutograspStatus(value)}
        handleOnAfterChange={(value) => handleAutograspSlider(value)}
        handleOnBeforeChange={addHistoryProsthesis}
        description='Function for preventing slippage of the object being held'
        tooltip={{
          header: 'Autograsp',
          description:
            'Different ways of generating Change Signal (CS) and Secondary Change Signal (SCS)',
          video: zeusVideo
        }}
      />
    ),
    [autoGrasp]
  );

  const Hold = useMemo(
    () => (
      // @ts-ignore
      <HoldOpen
        header='Hold-open timings'
        data-tour='hold'
        sliderValues={holdOpen}
        limits={[
          { min: 250, max: 5000 },
          { min: 300, max: 5000 }
        ]}
        handleChange={(...args) => handleHoldOpenSliders(args)}
        labelSliders={[
          `Primary hold open time: ${parseNumber(holdOpenShort)} s`,
          `Secondary hold open time: ${parseNumber(holdOpenLong)} s`
        ]}
        handleOnBeforeChange={addHistoryProsthesis}
        description='Sets the short and long hold open times for grip switching'
      />
    ),
    [holdOpen]
  );

  return (
    <DefaultLayout>
      <Wrapper>
        <Header>{t('views.prosthesis_settings')}</Header>
        <Card>
          <SettingsContainer>
            <div>
              {Checkboxes}
              {gripSwitchingMode === GripSwitchingModes.kCoContraction && (
                <HoldOpen
                  header='Co-contraction timings'
                  data-tour='co-contraction'
                  sliderValues={coContractionTimings}
                  limits={[
                    { min: 200, max: 2000 },
                    { min: 20, max: 500 }
                  ]}
                  handleChange={(...args) => handleCoContractionSliders(args)}
                  labelSliders={[
                    `Long co-contraction time: ${parseNumber(longCoContraction)} s`,
                    `Signal rise offset time: ${parseNumber(coContractionOffset)} s`
                  ]}
                  handleOnBeforeChange={addHistoryProsthesis}
                  description='Sets length of co-contraction signal'
                />
              )}
              {(gripSwitchingMode === GripSwitchingModes.kOpenOpen ||
                gripSwitchingMode === GripSwitchingModes.kSingleGripSwitching) && (
                <PushSlider
                  header='Pulse timings'
                  sliderValues={[
                    [minimumPulse, maximumPulse],
                    [minimumBetweenPulses, maximumBetweenPulses]
                  ]}
                  limits={[
                    { min: 10, max: 750 },
                    { min: 50, max: 2000 }
                  ]}
                  handleChange={(...args) => handlePulseSlider(args)}
                  labelSlider={[
                    [
                      `Min pulse time: ${parseNumber(minimumPulse)} s`,
                      `Max pulse time: ${parseNumber(maximumPulse)} s`
                    ],
                    [
                      `Min time between pulses: ${parseNumber(minimumBetweenPulses)} s`,
                      `Max time between pulses: ${parseNumber(maximumBetweenPulses)} s`
                    ]
                  ]}
                  handleOnBeforeChange={addHistoryProsthesis}
                  description='Sets time for performing open-open signal'
                  key='Pulse timings slider'
                />
              )}
              {gripSwitchingMode === GripSwitchingModes.kHoldOpen && (
                <HoldOpenWrapper holdOpen={holdOpen}>{Hold}</HoldOpenWrapper>
              )}
              <SoftGrip softGrip={softGrip} data-tour='soft-grip'>
                <OptionsWithSlider
                  header='Soft-grip'
                  value={softGripStatus}
                  sliderValue={fingerStep}
                  handleChange={handleFingerChange}
                  limits={{ min: 0, max: 5 }}
                  labelSlider={
                    <DoubleLabel>
                      <span>Gentle</span>
                      <span>Strong</span>
                    </DoubleLabel>
                  }
                  step={1}
                  onChange={(value) => handleSoftGripStatus(value)}
                  handleOnAfterChange={handleonAfterChangeFinger}
                  handleOnBeforeChange={addHistoryProsthesis}
                  description='Used to limit the strength of the fingers'
                  tooltip={{
                    header: 'Soft grip',
                    description:
                      'Different ways of generating Change Signal (CS) and Secondary Change Signal (SCS)',
                    video: zeusVideo
                  }}
                />
                <CalibrationWrapper>
                  {procedure && (
                    <ProcedureReplyWrapper>
                      <HeaderSmall as='h4'>Last run procedure information</HeaderSmall>
                      <ProcedureTableWrapper>
                        <ProcedureTable>
                          <ProcedureRow>
                            <td>&nbsp;</td>
                            <td>Thumb</td>
                            <td>Index</td>
                            <td>Middle</td>
                            <td>Ring</td>
                            <td>Pinky</td>
                          </ProcedureRow>
                          <ProcedureRow>
                            <td colSpan={1}>Status</td>
                            {procedure.status!.map((status, index) => (
                              <ProcedureStatusBox colSpan={1} status={procedure.status![index]}>
                                {status === 1 ? 'OK' : 'NOT OK'}
                              </ProcedureStatusBox>
                            ))}
                          </ProcedureRow>
                          <ProcedureRow>
                            <td colSpan={1}>Max current</td>
                            {procedure.maxCurrents!.map((current) => (
                              <td colSpan={1}>{current}</td>
                            ))}
                          </ProcedureRow>
                        </ProcedureTable>
                      </ProcedureTableWrapper>
                    </ProcedureReplyWrapper>
                  )}
                  <Button
                    themeColor='secondary'
                    onClick={() =>
                      dispatch(
                        handleProcedure({
                          procedureNumber: ProcedureTypes.calibrateSoftGrip
                        })
                      )
                    }>
                    Calibration procedure
                  </Button>
                </CalibrationWrapper>
              </SoftGrip>
              <EMGIgnoreWrapper emgSpike={emgSpike}>{EMG}</EMGIgnoreWrapper>
              <AutograspWrapper autoGrasp={autoGrasp}>{Autograsp}</AutograspWrapper>
            </div>
          </SettingsContainer>
        </Card>
      </Wrapper>
    </DefaultLayout>
  );
};

export default ProsthesisSettings;
