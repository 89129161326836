import React from 'react';
import styled from 'styled-components';
import DefaultLayout from 'layouts/DefaultLayout';
import Divider from 'components/atoms/Divider/Divider';
import { HeaderM } from 'components/atoms/Typography/Typography';
import { useDispatch, useSelector } from 'react-redux';
import InputOptions from 'components/molecules/InputOptions/InputOptions';
import { setItem } from 'reducers/bluetoothReducer/bluetoothReducer';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { Header } from '../components/Typography/Header';
import Card from '../components/Card/Card';

const SettingsContainer = styled.div`
  display: grid;

  > div {
    &:first-child {
      border-bottom: 2px solid ${({ theme }) => theme.colorFill};
      padding: 0 0 20px 0;
    }

    &:last-child {
      padding: 20px 0 0 0;
    }
  }
`;

const ParagraphBold = styled.p`
  ${HeaderM}
`;

const DefaultParagraph = styled.p`
  font-size: ${({ theme }) => theme.typography.fontSizes.xs};
  color: ${({ theme }) => theme.palette.paragraph};
  line-height: 1.71;
`;

const ApplicationSettings = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { bluetoothMode, device } = useSelector((state: any) => state.bluetooth);

  const handleBluetoothChange = (id, value) => {
    dispatch(setItem({ type: 'bluetoothMode', payload: value.toLowerCase() }));
  };

  return (
    <DefaultLayout style={{ maxWidth: 840 }}>
      <Header>{t('settings.app_settings')}</Header>
      <Card>
        <SettingsContainer>
          {!device.connected && (
            <InputOptions
              header={t('settings.bluetooth_mode')}
              options={['Classic', 'BLE']}
              id='bluetooth'
              onChange={handleBluetoothChange}
              value={bluetoothMode === 'classic' ? 'Classic' : 'BLE'}
            />
          )}
          <div>
            <ParagraphBold>Zeus Configurator DTP v. 1.0.0</ParagraphBold>
            <Divider margin='10px' />
            <DefaultParagraph>Copyright (C) {dayjs().format('YYYY')} Aether Biomedical Sp. z o.o.</DefaultParagraph>
            <Divider margin='15px' />
            <DefaultParagraph>
              The program is provided AS IS with no warranty of any kind,including the warranty of
              design, merchantability and fitness for a particular purpose.
            </DefaultParagraph>

          </div>
        </SettingsContainer>
      </Card>
    </DefaultLayout>
  );
};

export default ApplicationSettings;
