import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DeviceInfoEntry } from '../types';
import { updateItem } from './reducerUtils';

type DeviceInfoState = {
  serial: string | null;
  bluetoothId: string | null;
  deviceId: string | null;
  amputeeId: string | null;
  pcb: {} | null;
  firmware: string | null;
};

const initialState: DeviceInfoState = {
  serial: null,
  bluetoothId: null,
  deviceId: null,
  amputeeId: null,
  pcb: {
    name: null
  },
  firmware: null
};

const setDeviceInfoReducer = (state: DeviceInfoState, action: PayloadAction<DeviceInfoEntry>) => {
  state.serial = action.payload.serial;
  state.bluetoothId = action.payload.bluetoothId;
  state.deviceId = action.payload.deviceId;
  state.amputeeId = action.payload.amputeeId;
};

export const deviceInfoSlice = createSlice({
  name: 'deviceInfo',
  initialState,
  reducers: {
    setDeviceInfo: setDeviceInfoReducer,
    setItemDeviceInfo: updateItem
  }
});

export const selectDeviceInfo = (state) => state.deviceInfo;

export const { setDeviceInfo, setItemDeviceInfo } = deviceInfoSlice.actions;

export default deviceInfoSlice.reducer;
