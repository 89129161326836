import React from "react";
import Loader from "components/atoms/Loader/Loader";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledLoader = styled(Loader)`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
`;

const ConfirmationLoader = () => (
  <Wrapper>
    <StyledLoader />
  </Wrapper>
);

export default ConfirmationLoader;
