import React from 'react';
import Modal from 'utils/Modal/Modal';
import ModalPortal from 'utils/Modal/ModalPortal';
import { Button } from '@progress/kendo-react-buttons';
import { Paragraph } from 'components/Typography/Paragraph';
import ModalBase from './ModalBase';
import { ModalButtonsWrapper, ModalWrapper } from './ModalStyles';

type ConfirmAbortModeProps = {
  handleClose: () => void;
  handleAccept: () => void;
};

const RestoreConfigHistoryModal = ({ handleClose, handleAccept }: ConfirmAbortModeProps) => (
  <ModalPortal>
    <Modal>
      <ModalBase handleClick={handleClose} header='Save configuration'>
        <ModalWrapper>
          <Paragraph>Do you want to restore this configuration, and send it to patient?</Paragraph>
          <ModalButtonsWrapper>
            <Button onClick={handleClose} type='button'>
              Cancel
            </Button>
            <Button type='button' onClick={handleAccept} themeColor='secondary'>
              Yes, send
            </Button>
          </ModalButtonsWrapper>
        </ModalWrapper>
      </ModalBase>
    </Modal>
  </ModalPortal>
);

export default RestoreConfigHistoryModal;
