import { AxiosResponse } from 'axios';
import api, { withCredentials, withCredentialsAndMultipartForm } from '../utils/apiClient';
import { encodeQueryData } from '../utils/encodeQuery';
import { PaginatedResponse } from '../../types';
import {
  ServicingPartEntry,
  ServicingPartQueryParams
} from './servicing.types';

export const GET_SERVICING_PARTS = 'api/servicing/parts';
export const CREATE_SERVICING_REPAIR = 'api/servicing/repair';

export const getServicingParts = (params: ServicingPartQueryParams): Promise<any> => api
  .get<PaginatedResponse<ServicingPartEntry>>(
    `${GET_SERVICING_PARTS}?${encodeQueryData(params)}`,
    withCredentials
  )
  .then((res: any) => res.data);

export const createServicingRepair = (payload: FormData) => api
  .post(CREATE_SERVICING_REPAIR, payload, withCredentialsAndMultipartForm)
  .then((res: AxiosResponse) => res.data);
