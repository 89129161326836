import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Button } from '@progress/kendo-react-buttons';
import Modal from 'utils/Modal/Modal';
import ModalPortal from 'utils/Modal/ModalPortal';
import {
  useCreateConfigTemplate,
  useDeviceConfigTemplate,
  useUpdateConfigTemplate
} from 'hooks/api/useDevice';
import { CustomLabel } from 'components/Label/Label';
import { TemplateTypes } from 'types';
import { Input, TextArea } from '@progress/kendo-react-inputs';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import ModalBase from './ModalBase';
import { ModalButtonsWrapper, ModalWrapper } from './ModalStyles';

type Inputs = {
  name: string;
  description: string;
};

type AddTemplateModalProps = {
  handleClose: () => void;
  config: any;
};

export const AddTemplateModal = ({ handleClose, config }: AddTemplateModalProps) => {
  const {
    mutateAsync: createConfigTemplate,
    isLoading,
    isError: isErrorCreate
  } = useCreateConfigTemplate();

  const onSubmit = async (data, owner) => {
    await createConfigTemplate({
      data: { name: data.name, description: data.description, config, owner: owner.toLowerCase() }
    });

    if (!isErrorCreate) {
      handleClose();
    }
  };

  return <AddTemplateForm handleClose={handleClose} onSubmit={onSubmit} isLoading={isLoading} />;
};

type EditTemplateModalProps = {
  handleClose: () => void;
  handleAfter: Function;
  templateId: number;
};

export const EditTemplateModal = ({
  handleClose,
  handleAfter,
  templateId
}: EditTemplateModalProps) => {
  const { result: templateEntry } = useDeviceConfigTemplate({ templateId });
  const {
    mutateAsync: updateConfigTemplate,
    isLoading: isLoadingUpdate,
    isError: isErrorUpdate
  } = useUpdateConfigTemplate();

  const onSubmit = async (data) => {
    await updateConfigTemplate({
      templateId,
      data: { name: data.name, description: data.description }
    });
    if (!isErrorUpdate) {
      handleAfter();
    }
  };

  return (
    <AddTemplateForm
      handleClose={handleClose}
      onSubmit={onSubmit}
      isLoading={isLoadingUpdate}
      templateEntry={templateEntry}
    />
  );
};

type AddTemplateFormProps = {
  handleClose: () => void;
  onSubmit: any;
  isLoading: boolean;
  templateEntry?: any;
};

const AddTemplateForm = ({
  handleClose,
  onSubmit,
  isLoading,
  templateEntry
}: AddTemplateFormProps) => {
  const {
    control,
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<Inputs>();
  const [selectedType, setSelectedType] = useState(TemplateTypes.me);

  return (
    <ModalPortal>
      <Modal>
        <ModalBase
          handleClick={handleClose}
          header={templateEntry ? 'Edit template' : 'Add template'}>
          <form onSubmit={handleSubmit((data) => onSubmit(data, selectedType))}>
            <ModalWrapper>
              {templateEntry ? (
                <div>
                  <CustomLabel>Name</CustomLabel>
                  <Input {...register('name')} defaultValue={templateEntry?.name} required />
                </div>
              ) : (
                <div>
                  <CustomLabel>Name</CustomLabel>
                  <Input {...register('name')} required />
                </div>
              )}
              {errors.name && <span>This field is required</span>}
              {templateEntry ? (
                <div>
                  <CustomLabel>Description</CustomLabel>
                  <Controller
                    name='description'
                    control={control}
                    render={({ field }) => (
                      <TextArea
                        {...field}
                        rows={6}
                        cols={50}
                        autoSize
                        defaultValue={templateEntry?.description}
                      />
                    )}
                  />
                </div>
              ) : (
                <div>
                  <CustomLabel>Description</CustomLabel>
                  <Controller
                    name='description'
                    control={control}
                    render={({ field }) => (
                      <TextArea
                        {...field}
                        rows={6}
                        cols={50}
                        autoSize
                        placeholder='Write something...'
                      />
                    )}
                  />
                </div>
              )}
              {!templateEntry && (
                <div>
                  <CustomLabel htmlFor='message'>Owner</CustomLabel>
                  <DropDownList
                    data={[TemplateTypes.me, TemplateTypes.company]}
                    onChange={(e) => setSelectedType(e.value)}
                    value={selectedType}
                  />
                </div>
              )}
              <ModalButtonsWrapper>
                <Button onClick={handleClose} type='button'>
                  Cancel
                </Button>
                <Button type='submit' disabled={isLoading} themeColor='secondary'>
                  {templateEntry ? 'Edit template' : 'Add template'}
                </Button>
              </ModalButtonsWrapper>
            </ModalWrapper>
          </form>
        </ModalBase>
      </Modal>
    </ModalPortal>
  );
};
