import { Grips } from 'bluetooth/Bluetooth/Grips';

export const REQUIRED_SUPPORTED_FIRMWARE_VERSION = 180;
export const MINIMAL_SUPPORTED_FIRMWARE_VERSION = 170;
export const RECENT_FIRMWARE_VERSION = 175;
export const MAXIMAL_BOOTLOADER_FIX_VERSION = 150;
export const ALLOW_MANUAL_UPDATE: boolean = process.env.REACT_APP_ALLOW_MANUAL_UPDATE === 'true';

export const GRIPS = [
  Grips.kGripPower,
  Grips.kGripTripodClosed,
  Grips.kGripTripodOpen,
  Grips.kGripPrecisionOpen,
  Grips.kGripPrecisionClosed,
  Grips.kGripTrigger,
  Grips.kGripKey,
  Grips.kGripHook,
  Grips.kGripMouse,
  Grips.kGripFingerPoint,
  Grips.kGripRestOpp,
  Grips.kGripRestNopp,
  Grips.kGripFingerPointOpen
];

export const OpposedGrips = [
  Grips.kGripPower,
  Grips.kGripTripodOpen,
  Grips.kGripTripodClosed,
  Grips.kGripPrecisionOpen,
  Grips.kGripPrecisionClosed,
  Grips.kGripTrigger,
  Grips.kGripRestOpp
];

export const NonOpposedGrips = [
  Grips.kGripHook,
  Grips.kGripKey,
  Grips.kGripFingerPoint,
  Grips.kGripMouse,
  Grips.kGripRestNopp,
  Grips.kGripFingerPointOpen
];

export const FIX_UPDATE =
  'https://aetherfirmware.s3.eu-central-1.amazonaws.com/bump_with_bootloader_many_frames.bin';
export const RECENT_UPDATE = 'https://aetherfirmware.s3.eu-central-1.amazonaws.com/zeus_v1.7.5.bin';

export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];
export const years = ['2022', '2023', '2024'];

export const MONTHS = [
  { id: 0, name: 'January' },
  { id: 1, name: 'February' },
  { id: 2, name: 'March' },
  { id: 3, name: 'April' },
  { id: 4, name: 'May' },
  { id: 5, name: 'June' },
  { id: 6, name: 'July' },
  { id: 7, name: 'August' },
  { id: 8, name: 'September' },
  { id: 9, name: 'October' },
  { id: 10, name: 'November' },
  { id: 11, name: 'December' }
];

export const YEARS = [
  { id: 2022, name: '2022' },
  { id: 2023, name: '2023' },
  { id: 2024, name: '2024' }
];
