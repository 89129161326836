import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { logoutSuccess } from 'reducers/authReducer';
import { LOGIN } from 'consts/routes';
import { logoutAPI } from 'api/authentication/authentication';

export default function useLogout() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const history = useHistory();

  const { isLoading, mutate: logout } = useMutation(logoutAPI, {
    onSuccess() {
      queryClient.resetQueries();
      queryClient.clear();
      dispatch(logoutSuccess());
      history.push(LOGIN);
    },
    onSettled() {}
  });

  const offlineLogout = () => {
    dispatch(logoutSuccess());
    setTimeout(() => {
      queryClient.resetQueries();
    });
  };

  return {
    logout: () => logout(),
    isLoading,
    offlineLogout
  };
}
