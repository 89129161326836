import React from 'react';
import Modal from 'utils/Modal/Modal';
import ModalPortal from 'utils/Modal/ModalPortal';
import { TextArea } from '@progress/kendo-react-inputs';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { Paragraph } from 'components/Typography/Paragraph';
import { Button } from '@progress/kendo-react-buttons';
import ModalBase from './ModalBase';
import { ModalButtonsWrapper, ModalWrapper } from './ModalStyles';

type ConfirmAbortModeProps = {
  handleClose: () => void;
  handleAccept: Function;
  localMode?: boolean;
};

type Inputs = {
  note: string;
};

const ConfirmSave = ({ handleClose, handleAccept, localMode = false }: ConfirmAbortModeProps) => {
  const { handleSubmit, control } = useForm<Inputs>();

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    handleAccept(data);
  };

  return (
    <ModalPortal>
      <Modal>
        <ModalBase handleClick={handleClose} header='Save configuration'>
          <form onSubmit={handleSubmit(onSubmit)}>
            <ModalWrapper>
              <Paragraph>
                Do you want to save current configuration, and send it to patient?
              </Paragraph>
              {!localMode && (
                <>
                  <Controller
                    name='note'
                    control={control}
                    render={({ field }) => (
                      <TextArea
                        {...field}
                        rows={6}
                        cols={50}
                        autoSize
                        required
                        placeholder='Describe the change'
                      />
                    )}
                  />
                </>
              )}
              <ModalButtonsWrapper>
                <Button onClick={handleClose} type='button'>
                  Cancel
                </Button>
                <Button type='submit' themeColor='secondary'>
                  Yes, send
                </Button>
              </ModalButtonsWrapper>
            </ModalWrapper>
          </form>
        </ModalBase>
      </Modal>
    </ModalPortal>
  );
};

export default ConfirmSave;
