import styled from 'styled-components';

export const ModalButtonsWrapper = styled.div`
  display: flex;
  gap: 24px;

  button {
    width: 100%;
  }
`;

export const ModalWrapper = styled.div`
  display: grid;
  gap: 16px;
`;
