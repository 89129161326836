import React, { useRef, useState } from 'react';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import Card from 'components/atoms/Card/Card';
import CompanyImg from 'assets/company_img.jpg';
import { CheckComponent } from 'views/DeviceConfig/components';
import { StyledNotesIcon } from 'components/atoms/Icons/Icons';
import { useHistory } from 'react-router-dom';
import { AvatarSnack } from 'components/atoms/AvatarSnack/AvatarSnack';
import { useModal } from 'hooks/useModal';
import ModalPortal from 'utils/Modal/ModalPortal';
import ShowNotesModal from 'components/molecules/Modals/ShowNotesModal';
import { useDeviceManager } from 'hooks/api/useDeviceManager';
import { transformConfigAPI } from 'utils/Config/transformConfig';
import RestoreConfigHistoryModal from 'components/molecules/Modals/RestoreConfigHistoryModal';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Button } from '@progress/kendo-react-buttons';
import * as svgIcons from '@progress/kendo-svg-icons';
import { useQueryClient } from 'react-query';
import {
  CalendarBadge,
  CalendarDay,
  CalendarDayWrapper,
  CalendarHeaderWrapper,
  CalendarTable,
  ClinicianImg,
  DetailsWrapper,
  EventWrapper,
  ModalButtonWrapper,
  ModalDetail,
  ModalInnerWrapper,
  ModalWrapper,
  StyledTimes
} from './styled';
import { CONFIG_ENTRY_NOTES_KEY, useCreateConfigNote } from '../../../hooks/api/useDevice';
import AddNote from '../../molecules/Modals/AddNoteModal';

const transformMonth = (month) => {
  const previousMonth = month.month(month.month() - 1);
  const nextMonth = month.month(month.month() + 1);

  let previousMonthDays: any = [];
  if (month.day() - 1 > 0) {
    previousMonthDays = [...Array(month.day() - 1).keys()].map((previousDay) => ({
      day: previousMonth.daysInMonth() - previousDay,
      month: previousMonth.month(),
      year: previousMonth.year()
    }));
  }

  const coreMonthDays = [...Array(month.daysInMonth()).keys()].map((coreDay) => ({
    day: coreDay + 1,
    month: month.month(),
    year: month.year()
  }));

  const nextMonthDays = [...Array(43 - (month.day() + month.daysInMonth())).keys()].map(
    (nextDay) => ({
      day: nextDay + 1,
      month: nextMonth.month(),
      year: nextMonth.year()
    })
  );
  const calendarDays = [...previousMonthDays.reverse(), ...coreMonthDays, ...nextMonthDays];
  return calendarDays;
};

const CalendarDayItem = ({ day, active, events }) => {
  const eventsTuple = events?.map((event, index) => ({
    id: index,
    name: dayjs(event.created_at).format('DD.MM.YYYY HH:mm')
  }));
  const [showModal, setShowModal] = useState(false);
  const [modalPositions, setModalPosition] = useState({
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    width: 0,
    height: 0,
    flip: false
  });
  const [selectedEvent, setSelectedEvent] = useState(eventsTuple ? eventsTuple[0] : { id: 0 });
  const refContainer = useRef(null);
  const {
    handleClose: handleCloseAddNote,
    handleOpen: handleOpenAddNote,
    isOpen: isOpenAddNote
  } = useModal();
  const { handleClose, handleOpen, isOpen } = useModal();
  const { push } = useHistory();
  const {
    handleClose: handleCloseRestoreConfigHistoryModal,
    handleOpen: handleOpenRestoreConfigHistoryModal,
    isOpen: isRestoreConfigHistoryModalOpen
  } = useModal();
  const { modesList } = useSelector((state: any) => state.modes);
  const { restoreConfigHistory } = useDeviceManager();
  const { mutateAsync: createConfigNote } = useCreateConfigNote();
  const queryClient = useQueryClient();

  const updateModalPosition = () => {
    if (refContainer.current) {
      const viewPort = document.querySelector('body')!;
      const viewPortWidth = viewPort.getBoundingClientRect().width;
      const viewPortHeight = viewPort.getBoundingClientRect().height;
      // @ts-ignore
      const eventCardPosition = refContainer.current!.getBoundingClientRect();
      let offsetTop = 0;
      let doFlip = false;
      if (eventCardPosition.top < 150) {
        offsetTop = 200;
      }
      if (viewPortHeight - eventCardPosition.bottom < 150) {
        offsetTop = -100;
      }
      if (viewPortWidth - eventCardPosition.right < 280) {
        doFlip = true;
      }
      setModalPosition({
        top: eventCardPosition.top,
        left: eventCardPosition.left,
        bottom: eventCardPosition.bottom,
        right: eventCardPosition.right,
        width: eventCardPosition.width,
        height: eventCardPosition.height + offsetTop,
        flip: doFlip
      });
    }
  };

  const handleOpenModal = () => {
    updateModalPosition();
    setShowModal(true);
  };

  const handleRestore = async () => {
    const { modeAffectedId, completeConfig } = transformConfigAPI(
      events?.[selectedEvent.id],
      modesList
    );
    await restoreConfigHistory(modeAffectedId, events?.[selectedEvent.id].id, completeConfig);
    handleCloseRestoreConfigHistoryModal();
  };

  const handleAddNote = async ({ note, type }) => {
    await createConfigNote({
      deviceId: events?.[selectedEvent.id]?.device_id,
      configId: Number(events?.[selectedEvent.id]?.id),
      data: { note, type }
    });
    await queryClient.invalidateQueries(CONFIG_ENTRY_NOTES_KEY);
  };

  const handlePush = (configId: number, changeEntry: any) => {
    push({ pathname: `/device-history/${configId}`, state: { detail: changeEntry } });
  };

  return (
    <CalendarDayWrapper ref={refContainer}>
      <CalendarDay active={active}>{day.day}</CalendarDay>
      {events && (
        <EventWrapper onClick={handleOpenModal}>
          <DetailsWrapper>
            <span>{events.length} configs</span>
            <ClinicianImg src={CompanyImg} alt='Clinician' />
          </DetailsWrapper>
        </EventWrapper>
      )}
      {showModal && (
        <>
          <ModalWrapper />
          <Card
            padding='20px'
            style={
              modalPositions.flip
                ? {
                    zIndex: '9',
                    position: 'absolute',
                    width: '280px',
                    bottom: `${-modalPositions.height}px`,
                    right: `${modalPositions.width}px`
                  }
                : {
                    zIndex: '9',
                    position: 'absolute',
                    width: '280px',
                    bottom: `${-modalPositions.height}px`,
                    left: `${modalPositions.width}px`
                  }
            }>
            <ModalInnerWrapper>
              <StyledTimes icon={svgIcons.xIcon} onClick={() => setShowModal(false)} />
              <ModalDetail>
                <h5>Entry name</h5>
                <DropDownList
                  fillMode='outline'
                  rounded='large'
                  textField='name'
                  dataItemKey='id'
                  data={eventsTuple}
                  value={selectedEvent}
                  onChange={(e) => setSelectedEvent(e.target.value)}
                />
              </ModalDetail>
              <ModalDetail>
                <h5>Created at</h5>
                <p>{dayjs(events?.[selectedEvent.id]?.created_at).format('DD.MM.YYYY HH:mm')}</p>
              </ModalDetail>
              <ModalDetail>
                <h5>Author name</h5>
                <AvatarSnack
                  img={events?.[selectedEvent.id]?.author?.image}
                  name={events?.[selectedEvent.id]?.author?.name}
                />
              </ModalDetail>
              <ModalDetail>
                <h5>Restore point</h5>
                {events?.[selectedEvent.id]?.restore_point === 1 ? <CheckComponent /> : '-'}
              </ModalDetail>
              <ModalDetail>
                <h5>Note</h5>
                <Button
                  themeColor='primary'
                  icon='plus'
                  rounded='large'
                  style={{ width: '100%' }}
                  onClick={handleOpenAddNote}>
                  <b>Add note</b>
                </Button>
              </ModalDetail>
              <Button onClick={handleOpen} themeColor='secondary'>
                <StyledNotesIcon style={{ marginRight: '9px' }} />
                Show notes
              </Button>
              <ModalButtonWrapper>
                <Button
                  onClick={handleOpenRestoreConfigHistoryModal}
                  rounded='large'
                  themeColor='secondary'
                  icon='reset-sm'>
                  Load
                </Button>
                <Button
                  themeColor='secondary'
                  icon='preview'
                  rounded='large'
                  onClick={() =>
                    handlePush(events?.[selectedEvent.id]?.id, events?.[selectedEvent.id])
                  }>
                  See
                </Button>
              </ModalButtonWrapper>
            </ModalInnerWrapper>
          </Card>
        </>
      )}
      {isRestoreConfigHistoryModalOpen && (
        <RestoreConfigHistoryModal
          handleClose={handleCloseRestoreConfigHistoryModal}
          handleAccept={handleRestore}
        />
      )}
      {isOpenAddNote && (
        <ModalPortal>
          <AddNote handleClose={handleCloseAddNote} handleSubmitNote={handleAddNote} showTypes />
        </ModalPortal>
      )}
      {isOpen && (
        <ModalPortal>
          <ShowNotesModal
            handleClose={handleClose}
            deviceId={events?.[selectedEvent.id]?.device_id}
            configId={events?.[selectedEvent.id]?.id}
          />
        </ModalPortal>
      )}
    </CalendarDayWrapper>
  );
};

const Calendar = ({ month, events: rawEvents, eventsGrouped: events, months }) => {
  const calendarDays = transformMonth(month);
  console.log('events?.length', rawEvents);
  return (
    <div>
      <CalendarHeaderWrapper>
        <h2>
          {months[month.month()]}, {month.year()}
        </h2>
        <p>
          Available configs in this month: <CalendarBadge>{events?.length || 0}</CalendarBadge>
        </p>
      </CalendarHeaderWrapper>
      <div>
        <CalendarTable>
          <tr>
            <th>Monday</th>
            <th>Tuesday</th>
            <th>Wednesday</th>
            <th>Thursday</th>
            <th>Friday</th>
            <th>Saturday</th>
            <th>Sunday</th>
          </tr>
          {[...Array(6).keys()].map((week) => (
            <tr>
              {[...Array(7).keys()].map((dayOfWeek) => {
                const currentDate = calendarDays[7 * week + dayOfWeek];
                const currentDateParsed = dayjs()
                  .year(currentDate.year)
                  .month(currentDate.month)
                  .date(currentDate.day);
                let groupIndex: null | number = null;
                for (let index = 0; index < events.length; index += 1) {
                  const eventGroupDate = dayjs(events[index][0].created_at);
                  if (eventGroupDate.isSame(currentDateParsed, 'day')) {
                    groupIndex = index;
                    break;
                  }
                }
                return (
                  <CalendarDayItem
                    day={currentDate}
                    active={currentDate.month === month.month()}
                    events={groupIndex !== null ? events[groupIndex] : groupIndex}
                  />
                );
              })}
            </tr>
          ))}
        </CalendarTable>
      </div>
    </div>
  );
};

export default Calendar;
