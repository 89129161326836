import React from 'react';
import styled from 'styled-components';
import Modal from 'utils/Modal/Modal';
import { Button } from '@progress/kendo-react-buttons';
import { Paragraph } from 'components/Typography/Paragraph';
import ModalBase from './ModalBase';
import { StyledCheck } from '../../atoms/Icons/Icons';
import { ModalButtonsWrapper, ModalWrapper } from './ModalStyles';

const CheckIcon = styled(StyledCheck)`
  margin: 10px 0;
  width: 48px;
  height: 48px;
`;

const ContentWrapper = styled.div`
  text-align: center;
`;
type ModalProps = {
  handleClose: () => void;
};

const ResetAllChangesModal = ({ handleClose }: ModalProps) => (
  <Modal>
    <ModalBase handleClick={handleClose} header='Save as restore point'>
      <ModalWrapper>
        <ContentWrapper>
          <CheckIcon />
          <Paragraph>The restore point was successfully created.</Paragraph>
        </ContentWrapper>
        <ModalButtonsWrapper>
          <Button style={{ maxWidth: 200 }} themeColor='secondary' onClick={handleClose}>
            Close
          </Button>
        </ModalButtonsWrapper>
      </ModalWrapper>
    </ModalBase>
  </Modal>
);

export default ResetAllChangesModal;
