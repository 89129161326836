/* eslint-disable no-underscore-dangle */
/* eslint-disable no-restricted-globals */
import { postActiveMode, postCommunicateMode } from 'bluetooth-handler/bluetoothFunctions';
import { useDispatch, useSelector } from 'react-redux';
import { getInitialConfigAPI } from 'reducers/bluetoothReducer/bluetoothHelpers/asyncThunks';
import { importConfig, setItem, setModeSelected } from 'reducers/bluetoothReducer/bluetoothReducer';
import {
  setShowConfirmSave,
  setModeId,
  setModeToChange,
  setSwitchModeOnRemoteSession
} from 'reducers/modesReducer/modesReducer';
import { MINIMAL_SUPPORTED_FIRMWARE_VERSION } from 'consts/consts';
import { findModeIndex } from 'reducers/bluetoothReducer/bluetoothHelpers/bluetoothHelpers';
import toast from 'react-hot-toast';
import { useDeviceManager } from './api/useDeviceManager';
import { RootState } from '../store/store';

const useModes = () => {
  const dispatch = useDispatch();
  const { config, device, bluetoothMode, versions } = useSelector((state: any) => state.bluetooth);
  const bluetoothConfig = useSelector((state: any) => state.bluetooth);
  const {
    modals: { showConfirmSave, switchOnRemoteSession },
    modeToChangeId,
    modesList
  } = useSelector((state: RootState) => state.modes);
  const { sendConfigOnlyToApi, sendConfigToDevice } = useDeviceManager();

  const handleChangeMode = async (_modeId) => {
    await dispatch(setModeId(_modeId));
    const modeIndex = findModeIndex(bluetoothConfig, _modeId);
    const mode = bluetoothConfig.modes.modesConfigs[modeIndex];
    if (!device.connected) {
      if (!modesList) return;
      await dispatch(getInitialConfigAPI());
    } else if (versions.numerical >= MINIMAL_SUPPORTED_FIRMWARE_VERSION) {
      await postCommunicateMode(mode.slot, bluetoothMode);
      await postActiveMode(mode.slot, bluetoothMode);
    }
    await dispatch(importConfig({ config: mode.config, isModeLoad: true }));
  };

  const handleChangeModeOnRemoteSession = (modeId: number) => {
    dispatch(setModeToChange(modeId));
    dispatch(setSwitchModeOnRemoteSession(true));
  };

  const handleOpenSave = async (unsaved) => {
    if (!unsaved) {
      toast.error("Configuration hasn't changed");
      return;
    }
    dispatch(setShowConfirmSave(true));
  };

  const handleCloseSave = () => {
    dispatch(setShowConfirmSave(false));
  };

  const handleConfirmChangeMode = async () => {
    await handleChangeMode(modeToChangeId);
    dispatch(setSwitchModeOnRemoteSession(false));
  };

  const handleCancelChangeMode = () => {
    dispatch(setModeToChange(null));
    dispatch(setSwitchModeOnRemoteSession(false));
  };

  const handleSaveMode = async (data) => {
    dispatch(setShowConfirmSave(false));
    if (device.connected) await sendConfigToDevice();
    await sendConfigOnlyToApi(data.note);
    await dispatch(getInitialConfigAPI());
  };

  return {
    showConfirmSave,
    switchOnRemoteSession,
    config: JSON.stringify(config),
    handleOpenSave,
    handleCloseSave,
    handleChangeMode,
    handleSaveMode,
    handleChangeModeOnRemoteSession,
    handleConfirmChangeMode,
    handleCancelChangeMode
  };
};

export default useModes;
