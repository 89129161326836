/* eslint-disable no-bitwise */
/* eslint-disable no-unused-vars */
/* eslint-disable prefer-destructuring */
import BluetoothWebController from 'bluetooth-handler/bluetoothWeb';
import TelemetryController from 'bluetooth-handler/telemetryController';
import { delay } from 'bluetooth/Bluetooth/Utilities';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { ablyClient } from '../../utils/LiveConfigurator/AblyClient';

const bluetooth = new BluetoothWebController();

const useTelemetry = (delayInit: boolean = false) => {
  const { device, bluetoothMode, localConfigFetched } = useSelector(
    (state: any) => state.bluetooth
  );
  const {
    channel,
    enabled: remoteSessionEnabled,
    sessionApi
  } = useSelector((state: RootState) => state.liveConfigurator);

  const client = ablyClient(sessionApi?.amputee_uuid);

  useEffect(() => {
    let channelsAlby;
    if (remoteSessionEnabled && channel.name) {
      channelsAlby = client.channels.get(channel.name);

      channelsAlby.subscribe('emg', (message) => {
        if (message.data) {
          TelemetryController.parseTelemetry({ detail: [{ payload: message.data }] });
        }
      });
    }

    const onUnmount = () => {
      if (channelsAlby) {
        channelsAlby.unsubscribe(channel.name);
      }
    };

    return () => {
      onUnmount();
    };
  }, []);

  const telemetryOn = async () => {
    if (delayInit) {
      await delay(500);
    }
    if (!TelemetryController.telemetryEnabled) {
      console.log('TURN ON TELEMETRY');
      TelemetryController.telemetryOn(bluetoothMode);
    }
  };

  // @ts-ignore
  useEffect(() => {
    if (device.connected && bluetooth.connected && localConfigFetched) {
      telemetryOn();
    }

    return async function clean() {
      if (device.connected && bluetooth.connected && localConfigFetched) {
        await TelemetryController.telemetryOff(bluetoothMode);
      }
    };
  }, [localConfigFetched]);
};

export default useTelemetry;
