import { useQuery } from 'hooks/useQuery';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setModeSelected } from 'reducers/bluetoothReducer/bluetoothReducer';
import { setDeviceInfo } from 'reducers/deviceInfoReducer';
import { setModeId } from 'reducers/modesReducer/modesReducer';
import useRemoteSession from '../../hooks/useRemoteSession';

const DeviceInfo = () => {
  const query = useQuery();
  const bluetoothId = query.get('bluetoothId');
  const serial = query.get('serial');
  const deviceId = query.get('deviceId');
  const amputeeId = query.get('amputeeId');
  const liveConnectionToken = query.get('token');
  const sessionId = query.get('sessionId');
  const modeId = query.get('modeId');
  const dispatch = useDispatch();
  const { enableLiveSession } = useRemoteSession();

  useEffect(() => {
    if (deviceId) {
      dispatch(
        setDeviceInfo({
          serial: serial ?? null,
          bluetoothId: bluetoothId ?? null,
          deviceId,
          amputeeId: amputeeId ?? null
        })
      );
    }
  }, [bluetoothId, serial, deviceId, amputeeId]);

  useEffect(() => {
    if (liveConnectionToken && sessionId) {
      enableLiveSession(liveConnectionToken, Number(sessionId));
    }
  }, [liveConnectionToken, sessionId]);

  useEffect(() => {
    dispatch(setModeId(Number(93)));
  }, [modeId]);

  return null;
};

export default DeviceInfo;
