import { createSlice } from "@reduxjs/toolkit";
import { updateComplexItem, updateItem } from "./reducerUtils";

type SliceState = {
  isAfterUpdate: boolean;
  lastSettings: {};
};

const initialState: SliceState = {
  isAfterUpdate: false,
  lastSettings: {},
};

export const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    setItemSettings: updateItem,
    setComplexItemSettings: updateComplexItem,
    resetAfterConfigImport: (state) => {
      state.isAfterUpdate = initialState.isAfterUpdate;
      state.lastSettings = initialState.lastSettings;
    },
  },
});

export const selectBluetoothMode = (state) => state.settings.bluetoothMode;
export const selectIsAfterUpdate = (state) => state.settings.isAfterUpdate;
export const selectLastSettings = (state) => state.settings.lastSettings;

export const {
  setItemSettings,
  setComplexItemSettings,
  resetAfterConfigImport,
} = settingsSlice.actions;

export default settingsSlice.reducer;
