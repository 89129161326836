/* eslint-disable react/no-array-index-key */
import Slider from 'components/atoms/Slider/Slider';
import { NumericTextBox } from '@progress/kendo-react-inputs';
import { HeaderSmall } from 'components/Typography/Header';
import { ReactComponent as Thumb } from 'assets/thumb.svg';
import { ReactComponent as Middle } from 'assets/middle.svg';
import { ReactComponent as Ring } from 'assets/ring.svg';
import { ReactComponent as Index } from 'assets/index.svg';
import { ReactComponent as Pinky } from 'assets/pinky.svg';
import {
  AdjustBox,
  GridContainer,
  GridWrapper,
  InnerLabel,
  TextVertical,
  UpperBox,
  Wrapper
} from './styled';

type PositionsAdjusterPropsGrips = {
  values: {
    columnPrimary: number[];
    columnSecondary: number[];
  };
  limits: { min: number; max: number }[];
  rows: string[];
  columns: string[];
  handleSliderChange: Function;
  handleOnAfterChange?: Function;
  handleOnBeforeChange?: Function;
  handleInputChange?: Function;
  handleOnAfterChangeInput?: Function;
  graphVersion?: boolean;
  disableInput?: boolean;
  trackClasses?: string[][];
};

const fingersImages = [<Thumb />, <Index />, <Middle />, <Ring />, <Pinky />];

const PositionsAdjusterGrips = ({
  values,
  limits,
  rows,
  columns,
  handleSliderChange,
  handleOnAfterChange = () => true,
  handleOnBeforeChange = () => true,
  handleInputChange = () => true,
  handleOnAfterChangeInput = () => true,
  graphVersion = false,
  disableInput = false,
  trackClasses = undefined,
  ...props
}: PositionsAdjusterPropsGrips) => (
  <Wrapper {...props}>
    <GridContainer>
      {values.columnPrimary.map((valuePrimary, index) => (
        <GridWrapper key={rows[index] + index}>
          <TextVertical>
            {fingersImages[index]}
            <HeaderSmall>{rows[index]}</HeaderSmall>
          </TextVertical>
          <UpperBox>
            <AdjustBox>
              {graphVersion && index === 0 ? (
                <div>&nbsp;</div>
              ) : (
                <>
                  <InnerLabel>{columns[0]}</InnerLabel>
                  <NumericTextBox
                    value={values.columnPrimary[index]}
                    onChange={(e) =>
                      handleInputChange(e.target.value, 0, {
                        index,
                        sliderType: 'primary',
                        min: limits[index].min,
                        max: limits[index].max
                      })
                    }
                    onBlur={(e) =>
                      handleOnAfterChangeInput(e.target.value, 0, {
                        index,
                        sliderType: 'primary',
                        min: limits[index].min,
                        max: limits[index].max
                      })
                    }
                    onFocus={() => handleOnBeforeChange}
                    min={limits[index].min}
                    max={limits[index].max}
                    disabled={disableInput}
                  />
                  {/* <PositionBox
                    type="text"
                    value={values.columnPrimary[index]}
                    min={limits[index].min}
                    max={limits[index].max}
                    onChange={(e) =>
                      handleInputChange(e.target.value, 0, {
                        index,
                        sliderType: "primary",
                        min: limits[index].min,
                        max: limits[index].max,
                      })
                    }
                    onBlur={(e) =>
                      handleOnAfterChangeInput(e.target.value, 0, {
                        index,
                        sliderType: "primary",
                        min: limits[index].min,
                        max: limits[index].max,
                      })
                    }
                    onFocus={handleOnBeforeChange}
                    disabled={disableInput}
                  /> */}
                  <Slider
                    data={{
                      index,
                      sliderType: 'primary',
                      min: limits[index].min,
                      max: limits[index].max
                    }}
                    handleChange={handleSliderChange}
                    handleOnAfterChange={handleOnAfterChange}
                    handleOnBeforeChange={handleOnBeforeChange}
                    value={values.columnPrimary[index]}
                    min={limits[index].min}
                    max={limits[index].max}
                    disable={disableInput}
                    trackClass={trackClasses ? trackClasses[index][0] : undefined}
                  />
                </>
              )}
            </AdjustBox>
            <AdjustBox>
              <InnerLabel>{columns[1]}</InnerLabel>
              <NumericTextBox
                value={values.columnSecondary[index]}
                onChange={(e) =>
                  handleInputChange(e.target.value, 0, {
                    index,
                    sliderType: 'secondary',
                    min: limits[index].min,
                    max: limits[index].max
                  })
                }
                onBlur={(e) =>
                  handleOnAfterChangeInput(e.target.value, 0, {
                    index,
                    sliderType: 'secondary',
                    min: limits[index].min,
                    max: limits[index].max
                  })
                }
                onFocus={() => handleOnBeforeChange}
                disabled={disableInput}
                min={limits[index].min}
                max={limits[index].max}
                id='box'
              />
              <Slider
                data={{
                  index,
                  sliderType: 'secondary',
                  min: limits[index].min,
                  max: limits[index].max
                }}
                handleChange={handleSliderChange}
                handleOnAfterChange={handleOnAfterChange}
                handleOnBeforeChange={handleOnBeforeChange}
                value={values.columnSecondary[index]}
                min={limits[index].min}
                max={limits[index].max}
                disable={disableInput}
                trackClass={trackClasses ? trackClasses[index][1] : undefined}
              />
            </AdjustBox>
          </UpperBox>
        </GridWrapper>
      ))}
    </GridContainer>
  </Wrapper>
);

export default PositionsAdjusterGrips;
