/* eslint-disable no-restricted-syntax */
/* eslint-disable no-shadow */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import { useDispatch, useSelector } from 'react-redux';
import { last } from 'lodash';
import {
  consumeLiveHistorySession,
  consumeRestorePoints,
  setChannel,
  setIsConfigFetched,
  setIsLiveEnabled
} from '../reducers/liveConfiguratorReducer/liveConfiguratorReducer';
import {
  selectCommonConfig,
  selectModeConfig
} from '../reducers/bluetoothReducer/bluetoothHelpers/bluetoothHelpers';
import { ablyClient } from '../utils/LiveConfigurator/AblyClient';
import { RootState } from '../store/store';
import { clearConfigHistory, importConfig } from '../reducers/bluetoothReducer/bluetoothReducer';
import { useLiveSession } from './api/useLiveSession';
import { getInitialConfigAPI } from '../reducers/bluetoothReducer/bluetoothHelpers/asyncThunks';

const useRemoteSession = () => {
  const dispatch = useDispatch();
  const { channel, enabled, sessionApi, sessionRestorePoints } = useSelector(
    (state: RootState) => state.liveConfigurator
  );
  const client = ablyClient(sessionApi?.clinician_uuid);
  const channelsAbly = client.channels.get(channel.name);
  const { closeSession } = useLiveSession();
  const { modeId, modesList } = useSelector((state: RootState) => state.modes);
  const { config: currentConfig } = useSelector((state: any) => state.bluetooth);

  const sendConfig = () => {
    if (!enabled) {
      return;
    }
    const commonConfig = selectCommonConfig(currentConfig);
    const modeConfig = selectModeConfig(currentConfig);
    const config = {
      common: commonConfig,
      mode: modeConfig,
      // @ts-ignore
      slot: modesList?.find((mode) => mode.id === modeId)?.slot,
      // @ts-ignore
      id: modeId
    };
    channelsAbly.publish('config', JSON.stringify(config));
  };

  const sendTempEmg = async (message) => {
    if (!enabled) {
      return;
    }
    channelsAbly.publish('emg', message);
  };

  const sendMeeting = async (message) => {
    if (!enabled) {
      return;
    }
    channelsAbly.publish('meeting', message);
  };

  const sendMeetingSummary = async (message) => {
    if (!enabled) {
      return;
    }
    channelsAbly.publish('summary', message);
  };

  const consumeAllSessionChanges = (refreshConfig = true) => {
    dispatch(consumeLiveHistorySession());
    dispatch(consumeRestorePoints());
    dispatch(clearConfigHistory());

    if (refreshConfig) {
      dispatch(getInitialConfigAPI());
    }
  };

  const rollbackToLastRestorePoint = () => {
    dispatch(importConfig({ config: last(sessionRestorePoints) }));
    consumeAllSessionChanges();
    sendConfig();
  };

  const enableLiveSession = (token: string, sessionId: number) => {
    dispatch(
      setIsLiveEnabled({
        type: 'enabled',
        payload: true
      })
    );
    dispatch(
      setChannel({
        type: 'channel',
        payload: { name: token, connected: false, id: sessionId }
      })
    );
    consumeAllSessionChanges();
  };

  const disconnect = async () => {
    await channelsAbly.publish('close_session', 'close');
    dispatch(
      setIsLiveEnabled({
        type: 'enabled',
        payload: false
      })
    );
    dispatch(
      setChannel({
        type: 'channel',
        payload: { name: '', connected: false }
      })
    );
    dispatch(
      setIsConfigFetched({
        type: 'configFetched',
        payload: false
      })
    );
    if (channel.id) {
      await closeSession(channel.id);
    }
    consumeAllSessionChanges();
    channelsAbly.unsubscribe();
  };

  const configIsFetched = () => {
    dispatch(
      setIsConfigFetched({
        type: 'configFetched',
        payload: true
      })
    );
  };

  return {
    rollbackToLastRestorePoint,
    sendConfig,
    disconnect,
    sendTempEmg,
    enableLiveSession,
    consumeAllSessionChanges,
    configIsFetched,
    sendMeeting,
    sendMeetingSummary
  };
};

export default useRemoteSession;
