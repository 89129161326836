/* eslint-disable dot-notation */
import { AxiosResponse } from 'axios';
import api from '../utils/apiClient';
import { UserDetails, UserLoginPayload } from './authentication.types';

export const REFRESH_TOKEN = 'api/token/refresh';
export const ABOUT_ME = 'api/me';
export const LOGOUT = 'api/logout';

const withCredentials = {
  withCredentials: true
};

export const refreshTokenApi = (): Promise<any> =>
  api
    .post<UserLoginPayload>(REFRESH_TOKEN, {}, withCredentials)
    .then((res: AxiosResponse) => res.data);

export const fetchUserData = (): Promise<any> =>
  api.get<UserDetails>(ABOUT_ME, withCredentials).then((res: any) => res.data);

export const logoutAPI = (): Promise<any> =>
  api.post(LOGOUT, {}, withCredentials).then((res: any) => res.data);
