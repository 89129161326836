import { useMemo } from "react";

export function debounce(func: any, wait: number) {
  let timeout: number | undefined;

  return function debounced(this: any, ...args: any) {
    const context = this;

    if (timeout) {
      clearTimeout(timeout);
    }

    timeout = window.setTimeout(() => {
      func.apply(context, args);
    }, wait);
  };
}

const useDebounce = (fn: any, wait: number) => {
  const debounced = useMemo(() => debounce(fn, wait), [fn, wait]);

  return debounced;
};

export default useDebounce;
