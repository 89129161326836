import { Button } from '@progress/kendo-react-buttons';
import React from 'react';
import Modal from 'utils/Modal/Modal';
import ModalPortal from 'utils/Modal/ModalPortal';
import ModalBase from './ModalBase';
import { ModalButtonsWrapper, ModalWrapper } from './ModalStyles';

type DeleteModalProps = {
  handleClose: () => void;
  handleAccept: () => void;
  text: string;
  id: string;
  header: string;
};

const DeleteModal = ({ handleClose, handleAccept, text, id, header }: DeleteModalProps) => (
  <ModalPortal>
    <Modal>
      <ModalBase handleClick={handleClose} header={header}>
        <ModalWrapper>
          <div>
            <p>
              {text}
              <strong>{id}</strong>? This action cannot be undone.
            </p>
          </div>
          <ModalButtonsWrapper>
            <Button onClick={handleClose} type='button'>
              Cancel
            </Button>
            <Button themeColor='secondary' onClick={handleAccept}>
              Delete
            </Button>
          </ModalButtonsWrapper>
        </ModalWrapper>
      </ModalBase>
    </Modal>
  </ModalPortal>
);

export default DeleteModal;
