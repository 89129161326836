/* eslint-disable no-unused-vars */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  addHistory,
  addHistoryComplex,
  addHistoryConfig,
  resetGripConfiguration,
  setComplexItem,
  setGripsPositions,
  toggleComplexItem
} from 'reducers/bluetoothReducer/bluetoothReducer';
import { debounce } from 'lodash';
import Card from 'components/Card/Card';
import Checkbox from 'components/atoms/Checkbox/Checkbox';
import { gripsImagesMap } from 'utils/gripsImages';
import { postCurrentGrip, postJointTargetPosition } from 'bluetooth-handler/bluetoothFunctions';
import { GRIPS } from 'consts/consts';
import toast from 'react-hot-toast';
import { delay } from 'bluetooth/Bluetooth/Utilities';
import useTelemetry from 'hooks/bluetooth/useTelemetry';
import { timeoutCommandCustom } from 'utils/funcs';
import TelemetryController from 'bluetooth-handler/telemetryController';
import DefaultLayout from 'layouts/DefaultLayout';
import useRemoteSession from 'hooks/useRemoteSession';
import { addLiveHistory } from 'reducers/liveConfiguratorReducer/liveConfiguratorReducer';
import DropdownListImg from 'components/DropdownGrip/DropdownList';
import { Button } from '@progress/kendo-react-buttons';
import { HeaderSmaller } from 'components/Typography/Header';
import { Grips } from '../../bluetooth/Bluetooth/Grips';
import {
  AllowText,
  CardsContainer,
  GripImage,
  HandMovementCheckboxWrapper,
  StyledError,
  StyledPositionAdjuster,
  Viewer,
  ViewersWrapper
} from './styled';

const sendFingers = async (value, index, { index: fingerIndex }, bluetoothMode) => {
  await postJointTargetPosition(fingerIndex, value, bluetoothMode);
};

const sendFingersThrottled = debounce(sendFingers, 100);

const GripsConfiguration = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    device,
    config: { gripsPositions },
    gripsConfiguration: { currentGrip, handMovementAllowed },
    bluetoothMode
  } = useSelector((state: any) => state.bluetooth);
  const [grips, setGrips] = useState<any>(GRIPS);
  const [disableInput, setDisableInput] = useState(true);
  const [selectedGrip, setSelectedGrip] = useState(Grips.kGripTypeUnknown);
  useTelemetry(true);
  const [values, setValues] = useState(() => {
    const initial = {};
    for (const key in Grips) {
      if (Object.hasOwnProperty.call(Grips, key)) {
        initial[Grips[key]] = {
          initial: [0, 0, 0, 0, 0],
          limit: [0, 0, 0, 0, 0]
        };
      }
    }
    return initial;
  });
  const { sendConfig } = useRemoteSession();
  const { modeId } = useSelector((state: any) => state.modes);

  useEffect(() => {
    if (
      (TelemetryController.prosthesisGrip || TelemetryController.prosthesisGrip === 0) &&
      handMovementAllowed &&
      TelemetryController.prosthesisGrip !== 255
    ) {
      dispatch(
        setComplexItem({
          type: 'gripsConfiguration',
          payload: { currentGrip: TelemetryController.prosthesisGrip }
        })
      );
    }
  }, [TelemetryController.prosthesisGrip]);

  // Set grip and finger positions on view enter
  useEffect(() => {
    const setHandPosition = async () => {
      await setHandGripPositions(gripsPositions[currentGrip]);
    };
    if (handMovementAllowed) {
      setHandPosition();
    }
  }, []);

  // Update grips positions from store
  useEffect(() => {
    if (Object.keys(gripsPositions).length > 0) {
      setValues(gripsPositions);
      setGrips(
        Object.keys(gripsPositions)
          .map((position) => parseInt(position, 10))
          .filter(
            (position) =>
              position !== Grips.kGripCamera &&
              position !== Grips.kGripTypeUnknown &&
              !Number.isNaN(position)
          )
      );
    }
  }, [gripsPositions]);

  // Disable input if grip is undefined
  useEffect(() => {
    const setHandPosition = async () => {
      let breakLoop = false;
      const waitForTransitionEnd = async () => {
        while (TelemetryController.gripInTransition && !breakLoop) {
          await delay(100);
        }
        return true;
      };
      await postCurrentGrip(currentGrip, bluetoothMode);
      await delay(300);
      await timeoutCommandCustom(() => waitForTransitionEnd(), 5000);
      await setHandGripPositions(gripsPositions[currentGrip]);
      breakLoop = true;
    };

    if (currentGrip || currentGrip === 0) {
      if (currentGrip !== Grips.kGripTypeUnknown) {
        setDisableInput(false);
      }
      setSelectedGrip(currentGrip);
      if (handMovementAllowed) {
        setHandPosition();
      }
    }
  }, [currentGrip]);

  const setHandGripPositions = async (gripValues) => {
    if (gripValues?.initial && gripValues?.limit) {
      await postJointTargetPosition(0, gripValues.initial[0], bluetoothMode);
      await postJointTargetPosition(1, gripValues.initial[1], bluetoothMode);
      await postJointTargetPosition(2, gripValues.initial[2], bluetoothMode);
      await postJointTargetPosition(3, gripValues.initial[3], bluetoothMode);
      await postJointTargetPosition(4, gripValues.initial[4], bluetoothMode);
    }
  };

  const handleOptions = async (grip) => {
    dispatch(
      addHistoryComplex({
        type: 'gripsConfiguration',
        nestedType: 'gripsConfigurationHistory',
        payload: {
          selectedGrip,
          values
        }
      })
    );
    dispatch(
      setComplexItem({
        type: 'gripsConfiguration',
        payload: { currentGrip: grip }
      })
    );
  };

  const handleSliderChange = async (value, index, { index: fingerIndex, sliderType, min, max }) => {
    let valueSanitized = value;
    if (typeof valueSanitized === 'string') {
      valueSanitized = parseInt(value, 10);
      if (Number.isNaN(valueSanitized)) valueSanitized = min;
    }
    if (value > max) {
      valueSanitized = max;
    }
    if (value < min) {
      valueSanitized = min;
    }
    let newInitial = values[selectedGrip].initial;
    let newLimit = values[selectedGrip].limit;
    if (sliderType === 'primary') {
      newInitial = newInitial.map((v, i) => {
        if (i === fingerIndex) v = valueSanitized;
        return v;
      });
    } else {
      newLimit = newLimit.map((v, i) => {
        if (i === fingerIndex) v = valueSanitized;
        return v;
      });
    }
    const newGripValues = {
      initial: newInitial,
      limit: newLimit
    };
    setValues((prev) => ({
      ...prev,
      [selectedGrip]: newGripValues
    }));
  };

  const handleSliderChangeThrottled = debounce(handleSliderChange, 10);

  // Handle sending data to store
  const handleOnAfterChange = async (...args) => {
    // eslint-disable-next-line no-unused-vars
    const [newValue, _, { index, sliderType }] = args;
    const initialValue = values[selectedGrip].initial[index];
    const limitValue = values[selectedGrip].limit[index];
    const errorMessage =
      "Initial position is greater than the limit position, finger won't be able to move back to initial position";
    if (sliderType === 'primary') {
      if (newValue > limitValue) {
        toast.error(errorMessage);
      }
    } else if (newValue < initialValue) {
      toast.error(errorMessage);
    }
    dispatch(setGripsPositions(values));
  };

  const handleOnBeforeChange = async () => {
    await dispatch(addHistoryConfig());
    dispatch(
      addHistoryComplex({
        type: 'gripsConfiguration',
        nestedType: 'gripsConfigurationHistory',
        payload: {
          selectedGrip,
          values
        }
      })
    );
    dispatch(
      addHistory({
        type: 'gripsConfigurationHistory',
        payload: { values, timestamp: Date.now() }
      })
    );
    dispatch(
      addLiveHistory({
        type: 'gripsConfigurationHistory',
        payload: { values, timestamp: Date.now() }
      })
    );
  };

  const handleRestoreDefaultGripsConfig = async () => {
    await dispatch(resetGripConfiguration({ grip: currentGrip }));
  };

  useEffect(() => {
    if (gripsPositions) {
      sendConfig();
    }
  }, [JSON.stringify(gripsPositions), modeId]);

  return (
    <DefaultLayout>
      <CardsContainer>
        <div>
          <ViewersWrapper as={Card} padding='30px'>
            <HeaderSmaller style={{ textAlign: 'left', width: '100%' }}>Current grip</HeaderSmaller>
            <Viewer>
              <GripImage src={gripsImagesMap.get(selectedGrip)} alt='Graph viewer' />
            </Viewer>
            <DropdownListImg
              grips={grips}
              gripsImgs={gripsImagesMap}
              value={selectedGrip}
              onChange={(e) => handleOptions(e.value.id)}
              style={{ width: '100%' }}
            />
            {device.connected && !disableInput && (
              <HandMovementCheckboxWrapper>
                <div data-tour-gripsConfig='movement'>
                  <AllowText>Allow hand movement</AllowText>
                  <Checkbox
                    onClick={() =>
                      dispatch(
                        toggleComplexItem({
                          type: 'gripsConfiguration',
                          item: 'handMovementAllowed'
                        })
                      )
                    }
                    id='movement'
                    name='movement'
                    checked={handMovementAllowed}
                  />
                </div>
                <StyledError show={handMovementAllowed}>
                  Make sure hand is in safe position!
                </StyledError>
              </HandMovementCheckboxWrapper>
            )}
          </ViewersWrapper>
        </div>
        <Card>
          <StyledPositionAdjuster
            values={{
              columnPrimary: disableInput ? [0, 0, 0, 0, 0] : values[selectedGrip].initial,
              columnSecondary: disableInput ? [0, 0, 0, 0, 0] : values[selectedGrip].limit
            }}
            handleSliderChange={(...args) => {
              // @ts-ignore
              handleSliderChangeThrottled(...args);
              if (handMovementAllowed) {
                // @ts-ignore
                sendFingersThrottled(...args, bluetoothMode);
              }
            }}
            handleInputChange={handleSliderChange}
            handleOnAfterChange={handleOnAfterChange}
            handleOnAfterChangeInput={(...args) => {
              handleOnAfterChange(...args);
              if (handMovementAllowed) {
                // @ts-ignore
                sendFingersThrottled(...args, bluetoothMode);
              }
            }}
            handleOnBeforeChange={handleOnBeforeChange}
            rows={['Thumb', 'Index', 'Middle', 'Ring', 'Pinky']}
            columns={['Initial position', 'Position limit']}
            hideButton
            limits={[
              { min: 0, max: 1000 },
              { min: 0, max: 1000 },
              { min: 0, max: 1000 },
              { min: 0, max: 1000 },
              { min: 0, max: 1000 }
            ]}
            disableInput={disableInput}
          />
          <Button
            themeColor='secondary'
            onClick={handleRestoreDefaultGripsConfig}
            style={{ width: '100%', marginTop: '16px' }}>
            Restore default grips configuration
          </Button>
        </Card>
      </CardsContainer>
    </DefaultLayout>
  );
};

export default GripsConfiguration;
