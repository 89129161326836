import React from 'react';
import Modal from 'utils/Modal/Modal';
import { useHistory } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { Paragraph } from 'components/Typography/Paragraph';
import { TextArea } from '@progress/kendo-react-inputs';
import { Button } from '@progress/kendo-react-buttons';
import ModalBase from './ModalBase';
import { useDeviceManager } from '../../../hooks/api/useDeviceManager';
import useRemoteSession from '../../../hooks/useRemoteSession';
import { ModalButtonsWrapper, ModalWrapper } from './ModalStyles';

type ModalProps = {
  handleClose: () => void;
};

type Inputs = {
  note: string;
};

const CloseSessionModal = ({ handleClose }: ModalProps) => {
  const { sendConfigOnlyToApi } = useDeviceManager();
  const { disconnect, sendMeetingSummary } = useRemoteSession();
  const { push } = useHistory();
  const accepted = async (data) => {
    handleClose();
    const sendTestConfigResponse = await sendConfigOnlyToApi(data.note);
    await sendMeetingSummary(sendTestConfigResponse);
    await disconnect();
    push('/');
  };

  const { handleSubmit, control } = useForm<Inputs>();

  const onSubmit = (data) => {
    accepted(data);
  };

  return (
    <Modal>
      <ModalBase handleClick={handleClose} header='Stop session'>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalWrapper>
            <Paragraph>
              Are you sure want to stop session? Every unsent configuration will be lost.
            </Paragraph>
            <Controller
              name='note'
              control={control}
              render={({ field }) => (
                <TextArea
                  {...field}
                  rows={6}
                  cols={50}
                  autoSize
                  required
                  placeholder='Describe the change'
                />
              )}
            />
            <ModalButtonsWrapper>
              <Button onClick={handleClose} type='button'>
                Cancel
              </Button>
              <Button themeColor='secondary' type='submit'>
                Close session
              </Button>
            </ModalButtonsWrapper>
          </ModalWrapper>
        </form>
      </ModalBase>
    </Modal>
  );
};

export default CloseSessionModal;
