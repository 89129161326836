import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

interface Procedure {
  status?: number[];
  maxCurrents?: number[];
}

const useProcedureReply = () => {
  const { procedureReply } = useSelector((state: any) => state.bluetooth);
  const [procedure, setProcedure] = useState<Procedure | null>();
  useEffect(() => {
    if (procedureReply) {
      const procedureObject: Procedure = {};
      procedureObject.status = [
        procedureReply[1],
        procedureReply[2],
        procedureReply[3],
        procedureReply[4],
        procedureReply[5],
      ];
      procedureObject.maxCurrents = [
        procedureReply[6],
        procedureReply[7],
        procedureReply[8],
        procedureReply[9],
        procedureReply[10],
      ];
      setProcedure(procedureObject);
    }
  }, [procedureReply]);

  return { procedure };
};

export default useProcedureReply;
