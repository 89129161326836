/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-empty-pattern */
/* eslint-disable no-unused-vars */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getDeviceConfig } from 'api/device/device';
import { getModeConfig, getModesConfigForDevice } from 'api/modes/modes';
import {
  ConfigToSendFunctionMapping,
  getFirmwareVersion,
  getSerialNumber,
  postAppReceivedProcedure,
  postCommunicateMode,
  postFreezeMode,
  postRtcTime,
  postSaveSettings,
  runProcedure,
  statusTypeFreeze,
  telemetryEnabled
} from 'bluetooth-handler/bluetoothFunctions';
import BluetoothWebController from 'bluetooth-handler/bluetoothWeb';
import TelemetryController from 'bluetooth-handler/telemetryController';
import { Commands } from 'bluetooth/Bluetooth/Defines';
import { delay } from 'bluetooth/Bluetooth/Utilities';
import toast from 'react-hot-toast';
import dayjs from 'dayjs';
import {
  compareGripsPositions,
  getCommonConfig,
  getCoreConfig,
  getModeConfigBluetooth,
  sendAllFingersHelper
} from './bluetoothHelpers';

const bluetooth = new BluetoothWebController();

export const disconnectDevice = createAsyncThunk<any>(
  'bluetooth/disconnectDevice',
  async (arg = undefined, { getState }: any) => {
    const { bluetoothMode } = getState().bluetooth;
    toast.loading('Disconnecting device...');
    try {
      await bluetooth.disconnectBluetooth(bluetoothMode);
      toast.remove();
      return true;
    } catch (err) {
      toast.remove();
      console.log(err);
      return false;
    }
  }
);

export const getFirmwareDevice = createAsyncThunk<any>(
  'bluetooth/getFirmwareDevice',
  async (arg = undefined, { getState }: any) => {
    const {
      bluetoothMode,
      device: { connected }
    } = getState().bluetooth;
    toast.loading('Checking firmware...');
    try {
      if (connected) {
        console.log('FIRMWARE');
        const firmwareVersion = await getFirmwareVersion(bluetoothMode);
        toast.remove();
        return { firmwareVersion };
      }
      toast.remove();
      return false;
    } catch (err) {
      toast.remove();
      console.log(err);
      return false;
    }
  }
);

export const resetToDefault = createAsyncThunk<any>(
  'bluetooth/resetToDefault',
  async (arg = undefined, { getState }: any) => {
    const resetToast = toast.loading('Setting default config...');
    try {
      const wasTelemetryOn = TelemetryController.telemetryEnabled;
      if (bluetooth.connected) {
        const { bluetoothMode } = getState().bluetooth;
        await telemetryEnabled(false, bluetoothMode);
        await bluetooth.writeWeb(Commands.kResetToDefaults, [], bluetoothMode);

        const {
          controlConfig,
          gripPairsConfig,
          gripSequentialConfigSanitized,
          emgThresholds,
          autoGrasp,
          softGrip,
          holdOpen,
          emgSpike,
          fingerStrength,
          gripsPositions,
          emgGains,
          pulseTimings,
          coContractionTimings
        } = await getCoreConfig(bluetoothMode, resetToast, wasTelemetryOn);

        return {
          gripPairsConfig,
          gripSequentialConfig: gripSequentialConfigSanitized,
          controlConfig,
          gripsPositions,
          emgThresholds,
          autoGrasp,
          holdOpen,
          emgSpike,
          fingerStrength,
          softGrip,
          emgGains,
          pulseTimings,
          coContractionTimings
        };
      }
      toast.remove();
      return false;
    } catch (err) {
      toast.remove();
      console.log(err);
      return false;
    }
  }
);

export const getInitialConfigAPI = createAsyncThunk(
  'bluetooth/getInitialConfigAPI',
  async (arg = undefined, { getState }: any) => {
    try {
      const { deviceId } = getState().deviceInfo;
      const { modeId } = getState().modes;
      const config = await getDeviceConfig(deviceId);
      const modesList = await getModesConfigForDevice({ deviceId });
      return { config, modesList, modeId };
    } catch (err: any) {
      console.log(err);
      return false;
    }
  }
);

export const getModeConfigAPI = createAsyncThunk(
  'bluetooth/getModeConfigAPI',
  async ({
    modeId,
    deviceId,
    localModeId
  }: {
    modeId: number;
    deviceId: number;
    localModeId: number;
  }) => {
    try {
      const mode = await getModeConfig({ deviceId, modeId });
      return { mode, localModeId, modeId };
    } catch (err: any) {
      console.log(err);
      return false;
    }
  }
);

export const connectDevice = createAsyncThunk(
  'bluetooth/connectDevice',
  async ({ bluetoothId }: any, { dispatch, getState }: any) => {
    const { bluetoothMode } = getState().bluetooth;
    toast.loading('Connecting to the hand...');
    try {
      const status = await bluetooth.initiateBluetooth(bluetoothMode, bluetoothId);
      if (status) {
        console.log('TELEMETRY');
        await telemetryEnabled(false, bluetoothMode);
        if (bluetoothMode === 'ble') {
          bluetooth.device.addEventListener(
            'gattserverdisconnected',
            () => dispatch(disconnectDevice()),
            {
              once: true,
              signal: bluetooth.controller.signal
            }
          );
        }
        toast.remove();
        return { status };
      }
      toast.remove();
      return false;
    } catch (err: any) {
      console.log(err, 'Bad connection, disconnecting');
      toast.remove();
      await bluetooth.disconnectBluetooth(bluetoothMode);
      return err.message;
    }
  }
);

export const getInitialConfig = createAsyncThunk(
  'bluetooth/getInitialConfig',
  async (arg = undefined, { getState }: any) => {
    const {
      bluetoothMode,
      device: { connected }
    } = getState().bluetooth;
    console.log(connected, 'CONNECTED');
    const initialConfigToast = toast.loading('Preparing to download config...');
    const dateTime = dayjs.utc();
    const getShortYear = (date) => Number(String(date).slice(2, 4));
    try {
      if (connected) {
        if (TelemetryController.telemetryEnabled) {
          await TelemetryController.telemetryOff(bluetoothMode);
        }
        const serialNumber = await getSerialNumber(bluetoothMode);
        const { modesList } = getState().modes;

        await delay(100);
        await postCommunicateMode(0, bluetoothMode);

        const { controlConfig, fingerStrength, gripsPositions } = await getCommonConfig(
          bluetoothMode,
          initialConfigToast,
          false
        );

        const modesConfigs: any = [];

        for (let index = 0; index < modesList!.length; index += 1) {
          const mode = modesList![index];
          await delay(100);
          await postCommunicateMode(mode.slot, bluetoothMode);
          const {
            controlConfig,
            gripPairsConfig,
            gripSequentialConfigSanitized,
            emgThresholds,
            autoGrasp,
            softGrip,
            holdOpen,
            emgSpike,
            pulseTimings,
            coContractionTimings,
            emgGains
          } = await getModeConfigBluetooth(
            bluetoothMode,
            initialConfigToast,
            false,
            `Mode ${index + 1}`
          );
          modesConfigs.push({
            slot: mode.slot,
            id: mode.id,
            config: {
              controlConfig,
              gripPairsConfig,
              gripSequentialConfigSanitized,
              emgThresholds,
              autoGrasp,
              softGrip,
              holdOpen,
              emgSpike,
              pulseTimings,
              coContractionTimings,
              emgGains
            }
          });
        }

        await postCommunicateMode(modesList![0].slot, bluetoothMode);
        await delay(100);
        await postRtcTime(
          [
            getShortYear(Number(dateTime.year())),
            Number(dateTime.month()) + 1,
            Number(dateTime.date()),
            Number(dateTime.hour()),
            Number(dateTime.minute()),
            Number(dateTime.second())
          ],
          bluetoothMode
        );
        await delay(100);

        return {
          device: {
            serialNumber
          },
          common: {
            inputSite: [controlConfig[1]],
            gripsPositions,
            fingerStrength
          },
          modesConfigs
        };
      }
      toast.remove();
      return false;
    } catch (err: any) {
      console.log(err, 'Bad connection, disconnecting');
      toast.remove();
      await bluetooth.disconnectBluetooth(bluetoothMode);
      return err.message;
    }
  }
);

export const sendFreeze = createAsyncThunk(
  'bluetooth/sendFreeze',
  async (
    {
      newFreezeMode
    }: {
      newFreezeMode: statusTypeFreeze;
    },
    { getState }: any
  ) => {
    try {
      if (bluetooth.connected) {
        const { bluetoothMode, freezeMode } = getState().bluetooth;
        if (freezeMode) {
          await postFreezeMode(newFreezeMode, bluetoothMode);
        }
        return {
          newFreezeMode
        };
      }
      return false;
    } catch (err) {
      return err;
    }
  }
);

export const handleProcedure = createAsyncThunk(
  'bluetooth/handleProcedure',
  async (
    {
      procedureNumber
    }: {
      procedureNumber: number;
    },
    { getState }: any
  ) => {
    try {
      if (bluetooth.connected) {
        toast.loading('Running procedure...');
        const { bluetoothMode } = getState().bluetooth;
        const procedureReply = await runProcedure(procedureNumber, bluetoothMode);
        await postAppReceivedProcedure(Commands.kFrameTypeProcedureReply, bluetoothMode);
        toast.remove();
        return {
          procedureReply
        };
      }
      toast.remove();
      return false;
    } catch (err) {
      toast.remove();
      return err;
    }
  }
);

export const sendAllFingers = createAsyncThunk(
  'bluetooth/sendAllFingers',
  async (_: void, { getState }: any) => {
    try {
      if (bluetooth.connected) {
        toast.loading('Sending grips configuration...');
        const {
          bluetoothMode,
          config: { gripsPositions },
          trackedConfig: { gripsPositions: gripsPositionsTracked }
        } = getState().bluetooth;
        const gripsToSend = compareGripsPositions(gripsPositionsTracked, gripsPositions);
        await sendAllFingersHelper(gripsToSend, bluetoothMode);
        toast.remove();
        return true;
      }
      toast.remove();
      return false;
    } catch (err) {
      toast.remove();
      return err;
    }
  }
);

export const sendWholeConfigDevice = createAsyncThunk(
  'bluetooth/sendWholeConfigDevice',
  async ({ configToSend }: { configToSend: any; isModeLoad?: boolean }, { getState }: any) => {
    try {
      if (bluetooth.connected) {
        const { bluetoothMode, config } = getState().bluetooth;
        const sendConfigToast = toast.loading('Sending changes...');
        for (const key in configToSend) {
          if (Object.prototype.hasOwnProperty.call(configToSend, key)) {
            await ConfigToSendFunctionMapping[key](
              configToSend[key],
              bluetoothMode,
              config.speedControlStrategy
            );
            await delay(100);
          }
        }
        await postSaveSettings(bluetoothMode);
        console.log(configToSend, 'TO SEND');
        toast.remove(sendConfigToast);
        return true;
      }
      toast.remove();
      return false;
    } catch (err) {
      toast.remove();
      return err;
    }
  }
);
