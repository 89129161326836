import React, { useRef, useState, ReactNode } from 'react';
import { NavLink } from 'react-router-dom';
import { Avatar } from '@progress/kendo-react-layout';
import * as svgIcons from '@progress/kendo-svg-icons';
import { Loader } from '@progress/kendo-react-indicators';
import { IconMenuProps, MENU_CONFIG, MenuItem } from 'consts/mainMenu';
import { RoleEnum } from 'api/users/users.types';
import useUserData from 'hooks/api/useUserData';
import { Button } from '@progress/kendo-react-buttons';
import { userCanVisit } from 'utils/permissionUtils';
import { CHOOSE_GRIPS } from 'consts/routes';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import useBluetooth from 'hooks/bluetooth/useConnect';
import logo from 'assets/aether_logo_white.png';
import { ReactComponent as BluetoothIcon } from 'assets/icons/bluetooth-connect.svg';

// eslint-disable-next-line import/no-extraneous-dependencies
import { Popup } from '@progress/kendo-react-popup';

import { setItemUI } from 'reducers/uiReducer';
import toast from 'react-hot-toast';
import {
  AvatarWrapper,
  IconsWrapper,
  InnerWrapper,
  List,
  ListItem,
  ListItemVertical,
  ListVertical,
  Logo,
  MenuCard,
  MenuIcon,
  MenuIconCustom,
  ModeSelector,
  Wrapper
} from './styled';
import useModes from '../../hooks/useModes';

const firstContactImage = 'https://demos.telerik.com/kendo-ui/content/web/Customers/RICSU.jpg';

const MenuCustomItem = ({
  name,
  url,
  icon = undefined,
  items = []
}: {
  name: string;
  url: string;
  icon: IconMenuProps;
  items: MenuItem[];
}) => {
  const { rolesByName } = useUserData();
  const ref = useRef(null);
  const [showMenu, setShowMenu] = useState(false);
  const toggleMenu = () => {
    setShowMenu((prev) => !prev);
  };

  if (items.length === 0) {
    return (
      <ListItem as={NavLink} to={url} activeClassName='active'>
        {icon !== undefined && typeof icon === 'string' && <img src={icon} alt='menu-icon' />}
        {icon !== undefined && typeof icon !== 'string' && <MenuIconCustom>{icon}</MenuIconCustom>}
        <div>{name}</div>
      </ListItem>
    );
  }

  return (
    <>
      <ListItem onClick={toggleMenu} activeClassName='active'>
        {icon !== undefined && typeof icon === 'string' && <img src={icon} alt='menu-icon' />}
        {icon !== undefined && typeof icon !== 'string' && <MenuIconCustom>{icon}</MenuIconCustom>}
        <div ref={ref}>{name}</div>
        {items.length > 0 && (
          <>
            <MenuIcon icon={svgIcons.chevronDownIcon} style={{ position: 'relative' }} />
            <Popup show={showMenu} anchor={ref.current} style={{ marginTop: 10 }}>
              <ListVertical>
                {items.map((item: MenuItem) => {
                  if (!userCanVisit(item?.roles, rolesByName)) {
                    return null;
                  }
                  return (
                    <ListItemVertical title={item.name} to={item.url} as={NavLink} key={item.name}>
                      <div>{item.name}</div>
                    </ListItemVertical>
                  );
                })}
              </ListVertical>
            </Popup>
          </>
        )}
      </ListItem>
    </>
  );
};

const Navbar = () => {
  const { rolesByName, isLoading } = useUserData();
  const [showMenu, setShowMenu] = useState(false);
  const { device } = useSelector((state: any) => state.bluetooth);
  const { bluetoothConnect, bluetoothDisconnect } = useBluetooth();
  const { enabled: remoteSessionEnabled } = useSelector((state: any) => state.liveConfigurator);
  const { modeId, modesList } = useSelector((state: any) => state.modes);
  const { handleChangeMode, handleChangeModeOnRemoteSession } = useModes();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const toggleMenu = () => {
    setShowMenu((prev) => !prev);
  };

  return (
    <Wrapper>
      <InnerWrapper>
        <NavLink to={CHOOSE_GRIPS}>
          <Logo src={logo} />
        </NavLink>
        <List>
          {!isLoading &&
            MENU_CONFIG.map((element) => {
              if (!userCanVisit(element?.roles, rolesByName)) {
                return null;
              }
              return (
                <MenuCustomItem
                  key={element.name}
                  url={element.url}
                  icon={element.icon}
                  name={element.name}
                  items={element.items ?? []}
                />
              );
            })}
          <ListItem
            key='firmware'
            onClick={
              device.connected
                ? () =>
                    dispatch(
                      setItemUI({
                        type: 'shownGlobalModal',
                        payload: 'firmware'
                      })
                    )
                : () => toast.error('Device not connected')
            }>
            <div>Firmware update</div>
          </ListItem>
          {isLoading && <Loader type='pulsing' />}
        </List>
      </InnerWrapper>
      <IconsWrapper>
        {Boolean(modeId !== null && modesList?.length > 0) && (
          <ModeSelector
            style={{ width: '200px' }}
            fillMode='outline'
            rounded='large'
            data={modesList.map((mode) => ({ id: mode.id, name: mode.name }))}
            value={{ name: modesList.find((mode) => mode.id === modeId).name, id: modeId }}
            textField='name'
            dataItemKey='id'
            onChange={
              remoteSessionEnabled
                ? (e) => handleChangeModeOnRemoteSession(e.value.id)
                : (e) => handleChangeMode(e.value.id)
            }
          />
        )}
        <Button
          themeColor='light'
          rounded='large'
          onClick={device.connected ? bluetoothDisconnect : bluetoothConnect}>
          <BluetoothIcon style={{ position: 'relative', top: 3, marginRight: 4, height: 18 }} />
          {device.connected ? t('disconnect') : t('connect')} device
        </Button>
        <AvatarWrapper style={{ position: 'relative' }} onClick={toggleMenu}>
          <Avatar type='image'>
            <img src={firstContactImage} alt='Avatar' />
          </Avatar>
          <div style={{ position: 'relative' }}>
            <MenuIcon icon={svgIcons.chevronDownIcon} style={{ position: 'relative' }} />
            {showMenu && (
              <MenuCard>
                <ul>
                  <li>Account settings</li>
                  <li>Logout</li>
                </ul>
              </MenuCard>
            )}
          </div>
        </AvatarWrapper>
      </IconsWrapper>
    </Wrapper>
  );
};

export default Navbar;
