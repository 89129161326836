import { createSlice } from '@reduxjs/toolkit';
import toast from 'react-hot-toast';
import { updateComplexItem, updateItem } from '../reducerUtils';
import {
  addHistoryComplexReducer,
  addHistoryReducer,
  consumeHistoryChooseGripsReducer,
  consumeHistoryEmgSettingsReducer,
  consumeHistoryGripsConfigurationReducer,
  consumeHistoryProsthesisSettingsReducer,
  consumeHistoryReducer,
  importConfigReducer,
  resetGripConfigurationReducer,
  resetGripsConfigurationReducer,
  setAutograspReducer,
  setCoContractionTimingsReducer,
  setControlConfigReducer,
  setControlModesReducer,
  setEmgGainsReducer,
  setEmgSpikeReducer,
  setEmgThresholdsReducer,
  setFingerStrengthReducer,
  setGripsPairsReducer,
  setGripsPositionsReducer,
  setGripsSequentialReducer,
  setGripSwitchingModesReducer,
  setHoldOpenReducer,
  setInputSitesReducer,
  setModesStateReducer,
  setPulseTimingsReducer,
  setSessionRestorePointReducer,
  setSoftGripReducer,
  setSpeedControlStrategyReducer,
  setUnsavedReducer,
  toggleComplexItemReducer,
  addHistoryConfigReducer,
  consumeConfigHistoryReducer,
  clearConfigHistoryReducer,
  setModeSelectedReducer,
  setSynchronizeConfigsReducer
} from './bluetoothHelpers/reducers';
import { initialState } from './bluetoothHelpers/initialState';
import {
  disconnectDevice,
  getInitialConfig,
  getInitialConfigAPI,
  getModeConfigAPI,
  handleProcedure,
  resetToDefault,
  sendAllFingers,
  sendFreeze,
  sendWholeConfigDevice,
  getFirmwareDevice,
  connectDevice
} from './bluetoothHelpers/asyncThunks';
import {
  disconnectDeviceFulfilled,
  getInitialConfigAPIFulfilled,
  getInitialConfigFulfilled,
  getModeConfigAPIFulfilled,
  handleProcedureFulfilled,
  resetToDefaultFulfilled,
  sendAllFingersConfigFulfilled,
  sendFreezeFulfilled,
  sendWholeConfigDeviceFulfilled,
  getFirmwareDeviceFulfilled,
  connectDeviceFulfilled
} from './bluetoothHelpers/extraReducers';

export const bluetoothSlice = createSlice({
  name: 'bluetooth',
  initialState,
  reducers: {
    setItem: updateItem,
    setComplexItem: updateComplexItem,
    toggleComplexItem: toggleComplexItemReducer,
    addHistory: addHistoryReducer,
    addHistoryComplex: addHistoryComplexReducer,
    addHistoryConfig: addHistoryConfigReducer,
    consumeHistory: consumeHistoryReducer,
    consumeConfigHistory: consumeConfigHistoryReducer,
    consumeHistoryChooseGrips: consumeHistoryChooseGripsReducer,
    consumeHistoryProsthesisSettings: consumeHistoryProsthesisSettingsReducer,
    consumeHistoryGripsConfiguration: consumeHistoryGripsConfigurationReducer,
    consumeHistoryEmgSettings: consumeHistoryEmgSettingsReducer,
    clearConfigHistory: clearConfigHistoryReducer,
    setControlConfig: setControlConfigReducer,
    importConfig: importConfigReducer,
    setUnsaved: setUnsavedReducer,
    resetGripsConfiguration: resetGripsConfigurationReducer,
    resetGripConfiguration: resetGripConfigurationReducer,
    setEmgThresholds: setEmgThresholdsReducer,
    setEmgGains: setEmgGainsReducer,
    setGripsPositions: setGripsPositionsReducer,
    setGripsPairs: setGripsPairsReducer,
    setGripSequential: setGripsSequentialReducer,
    setInputSites: setInputSitesReducer,
    setControlModes: setControlModesReducer,
    setSpeedControlStrategy: setSpeedControlStrategyReducer,
    setGripSwitchingModes: setGripSwitchingModesReducer,
    setSoftGrip: setSoftGripReducer,
    setFingerStrength: setFingerStrengthReducer,
    setEmgSpike: setEmgSpikeReducer,
    setAutograsp: setAutograspReducer,
    setHoldOpen: setHoldOpenReducer,
    setPulseTimings: setPulseTimingsReducer,
    setCoContractionTimings: setCoContractionTimingsReducer,
    setSessionRestorePoint: setSessionRestorePointReducer,
    setModesState: setModesStateReducer,
    setModeSelected: setModeSelectedReducer,
    setSynchronizeConfigs: setSynchronizeConfigsReducer
  },
  extraReducers: (builder) => {
    builder.addCase(resetToDefault.fulfilled, resetToDefaultFulfilled);
    builder.addCase(resetToDefault.rejected, () => {
      toast.remove();
      toast.error("Couldn't restore default config");
    });
    builder.addCase(getFirmwareDevice.fulfilled, getFirmwareDeviceFulfilled);
    builder.addCase(getFirmwareDevice.rejected, () => {
      toast.remove();
      toast.error("Couldn't check firmware version");
    });
    builder.addCase(disconnectDevice.fulfilled, disconnectDeviceFulfilled);
    builder.addCase(disconnectDevice.rejected, () => {
      toast.remove();
      toast.error("Couldn't disconnect the device");
    });
    builder.addCase(getInitialConfig.fulfilled, getInitialConfigFulfilled);
    builder.addCase(getInitialConfig.rejected, () => {
      toast.remove();
      toast.error('Could not download configuration');
    });
    builder.addCase(connectDevice.fulfilled, connectDeviceFulfilled);
    builder.addCase(connectDevice.rejected, () => {
      toast.remove();
      toast.error('Could not connect to the prosthesis');
    });
    builder.addCase(sendFreeze.fulfilled, sendFreezeFulfilled);
    builder.addCase(sendFreeze.rejected, (state, action: any) => {
      toast.remove();
      toast.error(action.payload);
    });
    builder.addCase(handleProcedure.fulfilled, handleProcedureFulfilled);
    builder.addCase(handleProcedure.rejected, (state, action: any) => {
      toast.remove();
      toast.error(action.payload);
    });
    builder.addCase(sendWholeConfigDevice.fulfilled, sendWholeConfigDeviceFulfilled);
    builder.addCase(sendWholeConfigDevice.rejected, () => {
      toast.remove();
      toast.error('Something went wrong when sending config');
    });
    builder.addCase(sendAllFingers.fulfilled, sendAllFingersConfigFulfilled);
    builder.addCase(sendAllFingers.rejected, () => {
      toast.remove();
      toast.error('Something went wrong when sending config');
    });
    builder.addCase(getInitialConfigAPI.fulfilled, getInitialConfigAPIFulfilled);
    builder.addCase(getInitialConfigAPI.rejected, () => {
      toast.error("Couldn't retrieve device configuration");
    });
    builder.addCase(getModeConfigAPI.fulfilled, getModeConfigAPIFulfilled);
    builder.addCase(getModeConfigAPI.rejected, () => {
      toast.error("Couldn't retrieve mode");
    });
  }
});

export const {
  addHistory,
  addHistoryComplex,
  consumeHistory,
  consumeHistoryProsthesisSettings,
  setItem,
  setComplexItem,
  setControlConfig,
  importConfig,
  toggleComplexItem,
  setUnsaved,
  resetGripsConfiguration,
  resetGripConfiguration,
  setInputSites,
  setSpeedControlStrategy,
  setGripSwitchingModes,
  setSoftGrip,
  setFingerStrength,
  setEmgSpike,
  setAutograsp,
  setHoldOpen,
  setControlModes,
  setPulseTimings,
  setCoContractionTimings,
  consumeHistoryGripsConfiguration,
  setGripsPairs,
  setGripSequential,
  consumeHistoryChooseGrips,
  consumeHistoryEmgSettings,
  setEmgThresholds,
  setEmgGains,
  setGripsPositions,
  setSessionRestorePoint,
  setModesState,
  addHistoryConfig,
  consumeConfigHistory,
  clearConfigHistory,
  setModeSelected,
  setSynchronizeConfigs
} = bluetoothSlice.actions;

export default bluetoothSlice.reducer;
