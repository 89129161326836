import { useMutation, useQuery } from 'react-query';
import toast from 'react-hot-toast';
import {
  closeP2PSession,
  getP2PSession,
  initializeP2PSession
} from '../../api/liveSession/liveSession';

export const SESSION_QUERY_KEY = 'session_query';

export const useP2PSession = (clinicianId: number, amputeeId: number) => {
  const { data, isLoading, isError, refetch, isRefetching, isRefetchError } = useQuery(
    [SESSION_QUERY_KEY],
    () => getP2PSession(clinicianId, amputeeId),
    {
      retry: 0,
      cacheTime: 0,
      staleTime: 0
    }
  );

  return {
    result: data || null,
    isLoading,
    isError,
    refetch,
    isRefetching,
    isRefetchError
  };
};

export const useLiveSession = () => {
  const { data, mutateAsync: initSessions } = useMutation(initializeP2PSession, {
    onSuccess: () => {
      toast.success('Waiting for amputee...');
    }
  });

  const { mutateAsync: closeSession } = useMutation(closeP2PSession, {
    onSuccess: () => {
      toast.success('Session are closed');
    }
  });

  return {
    initSessions,
    closeSession,
    data
  };
};
